import React, { useEffect, useRef, useState } from 'react';
import AppUtils from '@control-front-end/utils/utils';
import { Label, Space, Stack } from 'mw-style-react';
import cn from 'classnames';
import scss from './WrappedJSONEditor.scss';
import JSONEditorReact from '../JSONEditorReact/JSONEditorReact';

function WrappedJSONEditor(props) {
  const { id, title, value, onChange, className } = props;

  const editorRef = useRef();

  const [editJson, setEditJson] = useState({});

  useEffect(() => {
    setEditJson(AppUtils.safeJSONParse(value, {}));
  }, [id]);

  const handleChangeJson = () => {
    let newJson;
    try {
      newJson = editorRef.current.get();
    } catch (e) {
      return;
    }
    onChange(newJson);
  };

  return (
    <Stack.V fullWidth fullHeight size={Stack.SIZE.xxsmall}>
      <Label fontSize="small" color={Label.COLOR.gray} value={title} />
      <Space
        className={cn(scss.json__edit__wrapper, className)}
        size={Space.SIZE.large}
        bottom // need for ln and col counter place
      >
        <JSONEditorReact
          jsonEditorRef={editorRef}
          schema={{}}
          json={editJson}
          text=""
          mode="code"
          mainMenuBar={true}
          indentation={2}
          onChange={handleChangeJson}
          onValidationError={() => {}}
          onError={() => {}}
          onEditable={() => true}
        />
      </Space>
    </Stack.V>
  );
}

export default WrappedJSONEditor;
