import { useSelector, useDispatch } from 'react-redux';
import { TOGGLE_FULLSCREEN } from 'constants';
import { useEffect } from 'react';

const events = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'msfullscreenchange',
];

const useFullScreenHandler = (fullScreenDescriptor) => {
  const { isFullScreen, descriptor } = useSelector((state) => state.fullScreen);

  const dispatch = useDispatch();

  const handleOnFullScreen = (isFullScreen) => {
    const descriptor = fullScreenDescriptor; // Unique event handle
    dispatch({
      type: TOGGLE_FULLSCREEN.REQUEST,
      payload: { isFullScreen, descriptor },
    });
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreen = !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      );
      handleOnFullScreen(isFullScreen);
    };

    events.forEach((event) =>
      document.addEventListener(event, handleFullScreenChange)
    );

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, handleFullScreenChange)
      );
    };
  }, [dispatch]);

  const enterFullScreen = (fullScreenElement) => {
    if (!fullScreenElement) return;

    return (
      fullScreenElement.requestFullscreen?.() ||
      fullScreenElement.webkitRequestFullscreen?.()
    );
  };

  const exitFullScreen = () => {
    return document.exitFullscreen?.() || document.webkitExitFullscreen?.();
  };

  function handleFullScreen(fullScreenElement) {
    if (isFullScreen) {
      exitFullScreen();
    } else {
      enterFullScreen(fullScreenElement);
    }
  }

  return [
    isFullScreen && fullScreenDescriptor === descriptor,
    handleFullScreen,
  ];
};

export default useFullScreenHandler;
