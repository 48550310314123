import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Stack, Space, Label, Icon } from 'mw-style-react';
import scss from './ContextMenuItem.scss';

/**
 * Customized context menu item
 * @returns {*}
 * @constructor
 */
function ContextMenuItem(props) {
  const itemRef = useRef();
  const {
    className,
    children,
    icon,
    iconColor,
    iconSize,
    innerMenu,
    label,
    hotKey,
    size = 'medium',
    visibility = 'visible',
    handleClick = () => {},
    handleHover = () => {},
    handleBlur = () => {},
  } = props;

  return (
    <Space
      forwardRef={itemRef}
      className={cn(scss.contextMenuItem, scss[size], className, {
        [scss.disabled]: visibility === 'disabled',
        [scss.hidden]: visibility === 'hidden',
      })}
      right
      left
      fullWidth
      size="small"
      onClick={() => {
        if (visibility === 'disabled') return;
        handleClick();
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <Stack.H alignItems="center" size="small" fullWidth fullHeight>
        {icon ? <Icon type={icon} size={iconSize} color={iconColor} /> : null}
        <Stack.H
          size="small"
          alignItems="center"
          justifyContent="spaceBetween"
          fullWidth
        >
          <Label value={label} overflow="cut" />
          <Label value={hotKey} color={Label.COLOR.gray} />
          {innerMenu ? (
            <Icon className={scss.arrow} type="dropdown" size="micro" />
          ) : null}
        </Stack.H>
        <div
          className={scss.nested}
          style={{ left: itemRef.current?.offsetWidth }}
        >
          {children}
        </div>
      </Stack.H>
    </Space>
  );
}

ContextMenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  innerMenu: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  hotKey: PropTypes.string,
  handleClick: PropTypes.func,
  handleHover: PropTypes.func,
  handleBlur: PropTypes.func,
  visibility: PropTypes.string,
  children: PropTypes.node,
};

export default ContextMenuItem;
