import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCategory(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0, 3);
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.beginPath();
  ctx.moveTo(1.23077, 0);
  ctx.lineTo(6.15385, 0);
  ctx.lineTo(6.15385, 4.8);
  ctx.lineTo(1.23077, 4.8);
  ctx.lineTo(1.23077, 0);
  ctx.closePath();
  ctx.moveTo(2.87179, 1.6);
  ctx.lineTo(2.87179, 3.2);
  ctx.lineTo(4.51282, 3.2);
  ctx.lineTo(4.51282, 1.6);
  ctx.lineTo(2.87179, 1.6);
  ctx.closePath();
  ctx.moveTo(7.79487, 1.6);
  ctx.lineTo(16, 1.6);
  ctx.lineTo(16, 3.2);
  ctx.lineTo(7.79487, 3.2);
  ctx.lineTo(7.79487, 1.6);
  ctx.closePath();
  ctx.moveTo(7.79487, 8);
  ctx.lineTo(16, 8);
  ctx.lineTo(16, 9.6);
  ctx.lineTo(7.79487, 9.6);
  ctx.lineTo(7.79487, 8);
  ctx.closePath();
  ctx.moveTo(2.87179, 12);
  ctx.lineTo(0, 9.2);
  ctx.lineTo(1.15692, 8.072);
  ctx.lineTo(2.87179, 9.736);
  ctx.lineTo(6.63795, 6.072);
  ctx.lineTo(7.79487, 7.2);
  ctx.lineTo(2.87179, 12);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCategory;
