import { takeEvery, select, call, put } from 'redux-saga/effects';
import { RequestStatus } from '@control-front-end/common/constants';
import {
  CREATE_SMART_FORM,
  GET_SMART_FORMS_TEMPLATES,
} from '@control-front-end/common/constants/smartForms';
import api from '@control-front-end/common/sagas/api';
import { CREATE_ACTOR_LIST } from '../constants/graphActors';

export function* getSmartFormsTemplates({ callback }) {
  const accId = yield select((state) => state.accounts.active);
  const { data, result } = yield call(api, {
    method: 'get',
    url: `/smart_forms/list/${accId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  callback?.(data.data);
}

function* createSmartForm({ payload, callback, errorCallback }) {
  const { fileUrl, smartFormRef, title, ref, description } = payload;
  const accId = yield select((state) => state.accounts.active);
  const { data, result } = yield call(api, {
    method: 'post',
    url: `/smart_forms/${accId}`,
    body: {
      fileUrl,
      smartFormRef,
      title,
      ref,
      description,
    },
  });
  if (result !== RequestStatus.SUCCESS) {
    errorCallback?.();
    return;
  }
  callback?.(data?.data);
  const newSmartForm = data?.data?.actor;
  if (!newSmartForm) return;
  const { list } = yield select((state) => state.actorsList);
  const newList = list.slice();
  newList.unshift(newSmartForm);
  yield put({
    type: CREATE_ACTOR_LIST.SUCCESS,
    payload: { list: newList },
  });
}

function* smartForms() {
  yield takeEvery(GET_SMART_FORMS_TEMPLATES.REQUEST, getSmartFormsTemplates);
  yield takeEvery(CREATE_SMART_FORM.REQUEST, createSmartForm);
}

export default smartForms;
