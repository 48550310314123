import React, { useEffect, useMemo, useRef, useState } from 'react';
import GridLayout from 'react-grid-layout';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Popover,
  Stack,
  Icon,
  Tooltip,
  CorezoidLightTheme,
  cr,
} from 'mw-style-react';
import {
  ADD_WIDGET,
  UPDATE_WIDGET,
  WIDGETS_NAMES,
  SET_STARRED_SCRIPTS,
  CUSTOM_BAR_SCRIPT_PREFIX,
  APP_SETTINGS,
  SHOW_SCRIPT_EVENT,
} from 'constants';
import { useReactGridLayout } from 'hooks';
import { GET_SCRIPTS } from '@control-front-end/common/constants/scripts';
import AppUtils from '@control-front-end/utils/utils';
import ListWithSearch from '@control-front-end/common/components/ListWithSearch';

import scss from './CustomBar.scss';

const GRID_LAYOUT = {
  itemWidth: 40,
  rowHeight: 32,
  margin: {
    x: 8,
    y: 0,
  },
  padding: {
    x: 0,
    y: 0,
  },
  maxRows: 1,
  minCols: 1,
  maxCols: 10,
};
const MORE_BUTTON_WIDTH = 36;
const AUTO_SCROLL_THRESHOLD = 25;
const AUTO_SCROLL_SPEED = 3;
const DRAG_THRESHOLD_PX = 5;
const MOUSE_WHEEL_BUTTON_CODE = 1;
const HORIZONTAL_SCROLL_SPEED = 0.15;
const SIDE_SPACE_COUNT = 2;

function CustomBar({ adjustParentWidth }) {
  const customBarLayoutRef = useRef();
  const dispatch = useDispatch();
  const { list: starredScripts } = useSelector((state) => state.starredScripts);
  const widgets = useSelector((state) => state.widgets);
  const { actorId: actorViewId } = useSelector((state) => state.actorView);
  const scriptsOrder = useSelector((state) => state.settings.scriptsOrder);
  const [layout, setLayout] = useState([]);
  const [startPosition, setStartPosition] = useState(null);
  const {
    createLayout,
    calculateGridWidthFromColumns,
    createOrderFromLayout,
    updateOrderSettings,
    handleAutoScroll,
    getElementWidth,
  } = useReactGridLayout();

  const runningScripts = widgets.filter(
    (widget) => widget?.actorId === actorViewId
  );

  const gridWidth = useMemo(
    () =>
      starredScripts.length
        ? calculateGridWidthFromColumns(
            starredScripts.length,
            GRID_LAYOUT.itemWidth,
            GRID_LAYOUT.margin.x
          )
        : 0,
    [starredScripts.length]
  );

  useEffect(() => {
    if (!starredScripts.length) {
      dispatch({
        type: GET_SCRIPTS.REQUEST,
        callback: (data) =>
          dispatch({ type: SET_STARRED_SCRIPTS, payload: data }),
      });
    }
    const runScript = (e) => {
      const script = starredScripts.find((i) => i.id === e.detail?.actorId);
      if (script) handleRunScriptWidget(script); // eslint-disable-line
    };
    document.addEventListener(SHOW_SCRIPT_EVENT, runScript);

    return () => {
      document.removeEventListener(SHOW_SCRIPT_EVENT, runScript);
    };
  }, []);

  useEffect(() => {
    if (starredScripts.length) {
      setLayout(
        createLayout({
          contentItems: starredScripts,
          isFixedLayoutItemWidth: true,
          order: scriptsOrder,
        })
      );
    }
  }, [starredScripts]);

  useEffect(() => {
    if (adjustParentWidth && gridWidth) {
      adjustParentWidth(
        Math.ceil(
          gridWidth +
            MORE_BUTTON_WIDTH +
            CorezoidLightTheme.spaceSize.medium * SIDE_SPACE_COUNT
        )
      );
    }
  }, [adjustParentWidth, gridWidth]);

  const handleRunScriptWidget = (scriptData, multi = true) => {
    if (!customBarLayoutRef.current) return;
    const rect = customBarLayoutRef.current.getBoundingClientRect();
    const parentPosition = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };

    const toggleScriptVisibility = (scriptData, isVisible) => {
      dispatch({
        type: UPDATE_WIDGET,
        payload: {
          id: scriptData.id,
          updates: { isVisible: isVisible ?? !scriptData.isVisible },
        },
      });
    };

    const existingScript = runningScripts.find(
      (widget) =>
        widget.id === scriptData.id + actorViewId &&
        widget.name === WIDGETS_NAMES.scriptWidget
    );

    if (!multi) {
      // Find the visible script if single mode is enabled
      const visibleScript = runningScripts.find(
        (widget) =>
          widget.name === WIDGETS_NAMES.scriptWidget && widget.isVisible
      );

      if (visibleScript) {
        // Hide the currently visible script
        toggleScriptVisibility(visibleScript, false);
      }
    }

    // Find if the script is already running
    if (existingScript) {
      // If the script is already running, toggle its visibility
      toggleScriptVisibility(existingScript);
    } else {
      // If the script is not running, add it as a new widget
      dispatch({
        type: ADD_WIDGET,
        payload: {
          ...omit(scriptData, ['ref']),
          id: scriptData.id + actorViewId,
          scriptRef: scriptData.ref || scriptData.id,
          name: WIDGETS_NAMES.scriptWidget,
          parentPosition,
          actorId: actorViewId,
          isVisible: true,
        },
      });
    }
  };

  const renderDraggableScript = (script) => {
    const handleMouseOrTouchStart = (e) => {
      const event = e.changedTouches?.[0] || e;
      if (event.button === MOUSE_WHEEL_BUTTON_CODE) return;
      setStartPosition(event.clientX);
    };

    // Differentiates between click and drag events: ignores clicks during drag.
    const handleMouseOrTouchEnd = (e, onClick) => {
      const event = e.changedTouches?.[0] || e;
      if (event.button === MOUSE_WHEEL_BUTTON_CODE) return;
      const isClick =
        startPosition &&
        Math.abs(startPosition - event.clientX) < DRAG_THRESHOLD_PX;
      if (isClick) {
        onClick?.(e);
      }
      setStartPosition(null);
    };

    const onClick = () => handleRunScriptWidget(script);

    return (
      <div key={script.id}>
        <Tooltip value={script.title} position="top">
          <Button
            id={`${CUSTOM_BAR_SCRIPT_PREFIX}${script.id}`}
            key={script.id}
            style={{
              backgroundImage: `url(${script?.pictureUrl})`,
            }}
            className={scss.scriptButton}
            icon={script?.pictureUrl ? null : <Icon type="star_full" />}
            type="quinary"
            size="small"
            onMouseDown={(e) => handleMouseOrTouchStart(e)}
            onMouseUp={(e) => handleMouseOrTouchEnd(e, onClick)}
            onTouchStart={(e) => handleMouseOrTouchStart(e)}
            onTouchEnd={(e) => handleMouseOrTouchEnd(e, onClick)}
          />
        </Tooltip>
      </div>
    );
  };

  const handleWheel = (event) => {
    if (!customBarLayoutRef.current || Math.abs(event.deltaX) > 0) return;

    if (event.deltaY) {
      customBarLayoutRef.current.scrollLeft +=
        event.deltaY * HORIZONTAL_SCROLL_SPEED;
    }
  };

  const handleTouchStartCapture = (event) => {
    if (
      AppUtils.checkTouchDevice() &&
      customBarLayoutRef.current?.scrollWidth >
        getElementWidth(customBarLayoutRef.current)
    ) {
      event.stopPropagation();
    }
  };

  const handleDragStop = (newLayout) => {
    const newOrder = createOrderFromLayout(newLayout, true);
    updateOrderSettings(newOrder, APP_SETTINGS.scriptsOrder);
    setLayout(newLayout);
  };

  return (
    <Stack.H size="none" justifyContent="flexEnd" fullWidth>
      <Stack.H
        size="xsmall"
        fullWidth
        justifyContent="flexEnd"
        onWheel={handleWheel}
        onTouchStartCapture={handleTouchStartCapture}
      >
        {cr([
          layout.length && gridWidth,
          <GridLayout
            style={{
              maxWidth: gridWidth,
            }}
            className={scss.customBarLayout}
            innerRef={customBarLayoutRef}
            layout={layout}
            rowHeight={GRID_LAYOUT.rowHeight}
            width={gridWidth}
            cols={starredScripts.length}
            margin={[GRID_LAYOUT.margin.x, GRID_LAYOUT.margin.y]}
            maxRows={GRID_LAYOUT.maxRows}
            isDraggable={starredScripts.length > GRID_LAYOUT.minCols}
            isBounded
            useCSSTransforms
            containerPadding={[GRID_LAYOUT.padding.x, GRID_LAYOUT.padding.y]}
            compactType="horizontal"
            onDrag={(layout, oldItem, newItem, placeholder, event) =>
              handleAutoScroll(
                event,
                customBarLayoutRef.current,
                AUTO_SCROLL_THRESHOLD,
                AUTO_SCROLL_SPEED
              )
            }
            onDragStop={handleDragStop}
          >
            {starredScripts.map(renderDraggableScript)}
          </GridLayout>,
        ])}
      </Stack.H>
      {cr([
        starredScripts.length > 0,
        <Popover
          content={({ onClose }) => (
            <ListWithSearch
              onItemClick={(script) => {
                handleRunScriptWidget(script);
                onClose();
              }}
              actionType={GET_SCRIPTS.REQUEST}
              listWrapperClassName={scss.scriptsListWrapper}
              listClassName={scss.scriptsList}
              itemClassName={scss.scriptItem}
            />
          )}
          anchors={{
            binding: Popover.ANCHOR.center_top,
            content: Popover.ANCHOR.left_bottom,
          }}
          topLevel
        >
          {({ onToggle }) => (
            <Button
              className={scss.scripButton}
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
              icon={<Icon type="more" />}
              type="quinary"
              size="small"
            />
          )}
        </Popover>,
      ])}
    </Stack.H>
  );
}

export default CustomBar;
