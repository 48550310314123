import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconValency(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.arc(18, 6, 6, 0, 6.283185307179586, false);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.arc(4.5, 19.5, 4.5, 0, 6.283185307179586, false);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(3.19141, 19.7471);
  ctx.rotate(-0.7853981633974483);
  ctx.translate(-3.19141, -19.7471);
  ctx.beginPath();
  ctx.moveTo(3.19141, 19.7471);
  ctx.lineTo(25.52281, 19.7471);
  ctx.bezierCurveTo(25.52281, 19.7471, 25.52281, 19.7471, 25.52281, 19.7471);
  ctx.lineTo(25.52281, 21.2471);
  ctx.bezierCurveTo(25.52281, 21.2471, 25.52281, 21.2471, 25.52281, 21.2471);
  ctx.lineTo(3.19141, 21.2471);
  ctx.bezierCurveTo(3.19141, 21.2471, 3.19141, 21.2471, 3.19141, 21.2471);
  ctx.lineTo(3.19141, 19.7471);
  ctx.bezierCurveTo(3.19141, 19.7471, 3.19141, 19.7471, 3.19141, 19.7471);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconValency;
