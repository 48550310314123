import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  attachmentName: {
    id: 'attachmentName',
    defaultMessage: 'File name',
  },
  attachFileSize: {
    id: 'attachFileSize',
    defaultMessage: 'File Size',
  },
  selectedAttachmentsDownloadInQueue: {
    id: 'selectedAttachmentsDownloadInQueue',
    defaultMessage: 'Selected attachments are queued for download',
  },
});

export default { ...globalIntl, ...m };
