import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook to work with BroadcastChannel API.
 * @param {string} channelName - The name of the BroadcastChannel.
 * @param {function} onMessage - Callback function to handle incoming messages.
 * @returns {function} sendMessage - Function to send messages through the channel.
 */
const useBroadcastChannel = (channelName, onMessage) => {
  const broadcastChannelRef = useRef(null);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(channelName);
    broadcastChannelRef.current = broadcastChannel;

    broadcastChannel.onmessage = (event) => {
      if (onMessage) {
        onMessage(event.data);
      }
    };

    return () => {
      if (broadcastChannelRef.current) {
        broadcastChannelRef.current.close();
        broadcastChannelRef.current = null;
      }
    };
  }, [channelName, onMessage]);

  return useCallback((message) => {
    if (broadcastChannelRef.current) {
      try {
        broadcastChannelRef.current.postMessage(message);
      } catch (error) {
        console.error('Error posting message to BroadcastChannel:', error); // eslint-disable-line
      }
    } else {
      console.error('BroadcastChannel is closed or unavailable.'); // eslint-disable-line
    }
  }, []);
};

export default useBroadcastChannel;
