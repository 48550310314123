// https://demo.qunee.com/svg2canvas/
import { mapValues } from 'lodash';
import AppUtils from '@control-front-end/utils/utils';
import SVGIconVerified from './icons/status/SVGIconVerified';
import SVGIconPending from './icons/status/SVGIconPending';
import SVGIconPinned from './icons/SVGIconPinned';
import SVGIconPlus from './icons/SVGIconPlus';
import SVGIconAdd from './icons/SVGIconAdd';
import SVGIconChildren from './icons/counters/SVGIconChildren';
import SVGIconRejected from './icons/status/SVGIconRejected';
import SVGIconEvent from './icons/systemForms/SVGIconEvent';
import SVGIconUp from './icons/balance/SVGIconUp';
import SVGIconDown from './icons/balance/SVGIconDown';
import SVGIconFilter from './icons/systemForms/SVGIconFilter';
import SVGIconTrigger from './icons/systemForms/SVGIconTrigger';
import SVGIconCommentsWidget from './icons/systemForms/SVGIconCommentsWidget';
import SVGIconWidget from './icons/systemForms/SVGIconWidget';
import SVGIconCompany from './icons/systemForms/SVGIconCompany';
import SVGIconCategory from './icons/systemForms/SVGIconCategory';
import SVGIconGraph from './icons/systemForms/SVGIconGraph';
import SVGIconLayers from './icons/systemForms/SVGIconLayers';
import SVGIconScript from './icons/systemForms/SVGIconScript';
import SVGIconSnippet from './icons/systemForms/SVGIconSnippet';
import SVGIconState from './icons/systemForms/SVGIconState';
import SVGIconStream from './icons/systemForms/SVGIconStream';
import SVGIconTag from './icons/systemForms/SVGIconTag';
import SVGIconDashboard from './icons/systemForms/SVGIconDashboard';
import SVGIconTransfer from './icons/systemForms/SVGIconTransfer';
import SVGIconLayersCount from './icons/counters/SVGIconLayersCount';
import SVGIconForms from './icons/counters/SVGIconForms';
import SVGIconActorCount from './icons/counters/SVGIconActor';
import SVGIconActor from './icons/staticLayers/SVGIconActor';
import SVGIconAccount from './icons/staticLayers/SVGIconAccount';
import SVGIconGroup from './icons/staticLayers/SVGIconGroup';
import SVGIconAccess from './icons/staticLayers/SVGIconAccess';
import SVGIconFlag from './icons/SVGIconFlag';
import SVGIconHierarchical from './icons/legend/edges/SVGIconHierarchical';
import SVGIconEdgeActive from './icons/legend/edges/SVGIconEdgeActive';
import SVGIconTraceEdge from './icons/legend/edges/SVGIconTraceEdge';
import SVGIconTransactionEdge from './icons/legend/edges/SVGIconTransactionEdge';
import SVGIconEdgeNumber from './icons/legend/edges/SVGIconEdgeNumber';
import SVGIconRadar from './icons/legend/menu/SVGIconRadar';
import SVGIconLasso from './icons/legend/menu/SVGIconLasso';
import SVGIconList from './icons/legend/menu/SVGIconList';
import SVGIconAlignment from './icons/legend/menu/SVGIconAlignment';
import SVGIconValency from './icons/legend/menu/SVGIconValency';
import SVGIconSettings from './icons/legend/menu/SVGIconSettings';
import SVGIconDiagram from './icons/legend/SVGIconDiagram';
import SVGIconImage from './icons/legend/SVGIconImage';
import SVGIconNote from './icons/legend/SVGIconNote';
import SVGIconLinks from './icons/legend/metrics/SVGIconLinks';
import SVGIconParent from './icons/legend/metrics/SVGIconParent';
import SVGIconSource from './icons/legend/metrics/SVGIconSource';
import SVGIconCursorArrow from './icons/legend/cursors/SVGIconCursorArrow';
import SVGIconCursorHand from './icons/legend/cursors/SVGIconCursorHand';
import SVGIconCursorDrag from './icons/legend/cursors/SVGIconCursorDrag';
import SVGIconCursorState from './icons/legend/cursors/SVGIconCursorState';
import SVGIconCursorLasso from './icons/legend/cursors/SVGIconCursorLasso';
import SVGIconCursorSides from './icons/legend/cursors/SVGIconCursorSides';
import SVGIconCursorNoEdit from './icons/legend/cursors/SVGIconCursorNoEdit';
import SVGIconCursorNoView from './icons/legend/cursors/SVGIconCursorNoView';
import SVGIconCursorResize from './icons/legend/cursors/SVGIconCursorResize';
import SVGIconCursorArrowEdge from './icons/legend/cursors/SVGIconCursorArrowEdge';
import SVGIconCursorText from './icons/legend/cursors/SVGIconCursorText';
import SVGIconDiagramTerminator from './icons/legend/diagram/SVGIconDiagramTerminator';
import SVGIconDiagramOffPage from './icons/legend/diagram/SVGIconDiagramOffPage';
import SVGIconCondition from './icons/legend/diagram/SVGIconDiagramCondition';
import SVGIconDiagramData from './icons/legend/diagram/SVGIconDiagramData';
import SVGIconDiagramPreparation from './icons/legend/diagram/SVGIconDiagramPreparation';
import SVGIconDiagramProcess from './icons/legend/diagram/SVGIconDiagramProcess';
import SVGIconDiagramPredefProcess from './icons/legend/diagram/SVGIconDiagramPredefProcess';
import SVGIconDiagramDoc from './icons/legend/diagram/SVGIconDiagramDoc';
import SVGIconDiagramDocs from './icons/legend/diagram/SVGIconDiagramDocs';
import SVGIconCredit from './icons/legend/SVGIconCredit';
import SVGIconDebit from './icons/legend/SVGIconDebit';

const NODE_LABEL_ICONS = {
  // common
  pinned: SVGIconPinned,
  flag: SVGIconFlag,
  plus: SVGIconPlus,
  add: SVGIconAdd,
  // actor status
  verified: SVGIconVerified,
  pending: SVGIconPending,
  rejected: SVGIconRejected,
  // counters
  children: SVGIconChildren,
  layersCount: SVGIconLayersCount,
  linksCount: SVGIconLayersCount,
  tplsCount: SVGIconForms,
  actorsCount: SVGIconActorCount,
  graphsCount: SVGIconGraph,
  // system forms
  AccountTriggers: SVGIconTrigger,
  ActorFilters: SVGIconFilter,
  CommentsWidgets: SVGIconCommentsWidget,
  CompanyCard: SVGIconCompany,
  CompanyCategories: SVGIconCategory,
  Dashboards: SVGIconDashboard,
  Events: SVGIconEvent,
  Graphs: SVGIconGraph,
  Layers: SVGIconLayers,
  Scripts: SVGIconScript,
  Snippets: SVGIconSnippet,
  State: SVGIconState,
  Streams: SVGIconStream,
  Tags: SVGIconTag,
  TransactionFilters: SVGIconFilter,
  TransferFilters: SVGIconFilter,
  Transfers: SVGIconTransfer,
  Widgets: SVGIconWidget,
  // balance vector
  up: SVGIconUp,
  down: SVGIconDown,
  // system layers
  actor: SVGIconActor,
  graph: SVGIconGraph,
  account: SVGIconAccount,
  group: SVGIconGroup,
  events: SVGIconStream,
  access: SVGIconAccess,
  // legend system layer
  hierarchical: SVGIconHierarchical,
  activeEdge: SVGIconEdgeActive,
  traceEdge: SVGIconTraceEdge,
  transactionEdge: SVGIconTransactionEdge,
  edgeNumber: SVGIconEdgeNumber,
  radar: SVGIconRadar,
  lasso: SVGIconLasso,
  list: SVGIconList,
  alignment: SVGIconAlignment,
  valency: SVGIconValency,
  settings: SVGIconSettings,
  diagram: SVGIconDiagram,
  image: SVGIconImage,
  note: SVGIconNote,
  links: SVGIconLinks,
  parent: SVGIconParent,
  child: SVGIconSource,
  cursorArrow: SVGIconCursorArrow,
  cursorHand: SVGIconCursorHand,
  cursorDrag: SVGIconCursorDrag,
  cursorState: SVGIconCursorState,
  cursorLasso: SVGIconCursorLasso,
  cursorSides: SVGIconCursorSides,
  noAccessEdit: SVGIconCursorNoEdit,
  noAccessView: SVGIconCursorNoView,
  cursorResize: SVGIconCursorResize,
  cursorArrowEdge: SVGIconCursorArrowEdge,
  cursorText: SVGIconCursorText,
  blockTerminator: SVGIconDiagramTerminator,
  blockOffPage: SVGIconDiagramOffPage,
  blockCondition: SVGIconCondition,
  blockData: SVGIconDiagramData,
  blockPreparation: SVGIconDiagramPreparation,
  blockProcess: SVGIconDiagramProcess,
  blockPredefProcess: SVGIconDiagramPredefProcess,
  blockDoc: SVGIconDiagramDoc,
  blockDocs: SVGIconDiagramDocs,
  credit: SVGIconCredit,
  debit: SVGIconDebit,
};

const colorMap = {};

function hexToRgba(hex, opacity = 1) {
  if (!colorMap[hex]) {
    const [r, g, b] = AppUtils.hexToFullRgb(hex);
    colorMap[hex] = { r, g, b };
  }
  const { r, g, b } = colorMap[hex];
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const renderIconOnCanvas = () => {
  const drawOnCanvas = ({ ctx, position, renderFunc, opacity }) => {
    const { x, y } = position;
    ctx.save();
    ctx.translate(x, y);
    renderFunc(ctx, opacity, hexToRgba);
    ctx.closePath();
    ctx.restore();
  };

  return mapValues(NODE_LABEL_ICONS, (renderFunc) => {
    return (ctx, position, opacity = 1) =>
      drawOnCanvas({ ctx, position, renderFunc, opacity });
  });
};

export default renderIconOnCanvas;
