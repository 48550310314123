import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconWidget(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.411764705882353, 1.411764705882353);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.beginPath();
  ctx.moveTo(2, 5);
  ctx.lineTo(2, 2);
  ctx.lineTo(5, 2);
  ctx.lineTo(5, 5);
  ctx.lineTo(2, 5);
  ctx.closePath();
  ctx.moveTo(0, 1);
  ctx.bezierCurveTo(0, 0.447715, 0.447715, 0, 1, 0);
  ctx.lineTo(6, 0);
  ctx.bezierCurveTo(6.55228, 0, 7, 0.447715, 7, 1);
  ctx.lineTo(7, 6);
  ctx.bezierCurveTo(7, 6.55228, 6.55228, 7, 6, 7);
  ctx.lineTo(1, 7);
  ctx.bezierCurveTo(0.447715, 7, 0, 6.55228, 0, 6);
  ctx.lineTo(0, 1);
  ctx.closePath();
  ctx.moveTo(2, 14);
  ctx.lineTo(2, 11);
  ctx.lineTo(5, 11);
  ctx.lineTo(5, 14);
  ctx.lineTo(2, 14);
  ctx.closePath();
  ctx.moveTo(0, 10);
  ctx.bezierCurveTo(0, 9.44771, 0.447715, 9, 1, 9);
  ctx.lineTo(6, 9);
  ctx.bezierCurveTo(6.55228, 9, 7, 9.44772, 7, 10);
  ctx.lineTo(7, 15);
  ctx.bezierCurveTo(7, 15.5523, 6.55228, 16, 6, 16);
  ctx.lineTo(1, 16);
  ctx.bezierCurveTo(0.447715, 16, 0, 15.5523, 0, 15);
  ctx.lineTo(0, 10);
  ctx.closePath();
  ctx.moveTo(11, 2);
  ctx.lineTo(11, 5);
  ctx.lineTo(14, 5);
  ctx.lineTo(14, 2);
  ctx.lineTo(11, 2);
  ctx.closePath();
  ctx.moveTo(10, 0);
  ctx.bezierCurveTo(9.44772, 0, 9, 0.447715, 9, 1);
  ctx.lineTo(9, 6);
  ctx.bezierCurveTo(9, 6.55228, 9.44771, 7, 10, 7);
  ctx.lineTo(15, 7);
  ctx.bezierCurveTo(15.5523, 7, 16, 6.55228, 16, 6);
  ctx.lineTo(16, 1);
  ctx.bezierCurveTo(16, 0.447715, 15.5523, 0, 15, 0);
  ctx.lineTo(10, 0);
  ctx.closePath();
  ctx.moveTo(13.2071, 8.25789);
  ctx.bezierCurveTo(12.8166, 7.86736, 12.1834, 7.86736, 11.7929, 8.25789);
  ctx.lineTo(8.25736, 11.7934);
  ctx.bezierCurveTo(7.86684, 12.1839, 7.86684, 12.8171, 8.25736, 13.2076);
  ctx.lineTo(11.7929, 16.7432);
  ctx.bezierCurveTo(12.1834, 17.1337, 12.8166, 17.1337, 13.2071, 16.7432);
  ctx.lineTo(16.7426, 13.2076);
  ctx.bezierCurveTo(17.1332, 12.8171, 17.1332, 12.1839, 16.7426, 11.7934);
  ctx.lineTo(13.2071, 8.25789);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconWidget;
