import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconPending(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.white, opacity);
  ctx.strokeStyle = hexToRgba(theme.fullPalette.disabled, opacity);
  ctx.beginPath();
  ctx.arc(8, 8, 7.5, 0, 6.283185307179586, false);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = hexToRgba('#F8AB03', opacity);
  ctx.beginPath();
  ctx.moveTo(8, 2);
  ctx.bezierCurveTo(11.3137085, 2, 14, 4.6862915, 14, 8);
  ctx.bezierCurveTo(14, 11.3137085, 11.3137085, 14, 8, 14);
  ctx.bezierCurveTo(4.6862915, 14, 2, 11.3137085, 2, 8);
  ctx.bezierCurveTo(2, 4.6862915, 4.6862915, 2, 8, 2);
  ctx.closePath();
  ctx.moveTo(8, 4);
  ctx.bezierCurveTo(7.44771525, 4, 7, 4.44771525, 7, 5);
  ctx.lineTo(7, 5);
  ctx.lineTo(7, 8);
  ctx.bezierCurveTo(7, 8.55228475, 7.44771525, 9, 8, 9);
  ctx.lineTo(8, 9);
  ctx.lineTo(11, 9);
  ctx.bezierCurveTo(11.5522847, 9, 12, 8.55228475, 12, 8);
  ctx.bezierCurveTo(12, 7.44771525, 11.5522847, 7, 11, 7);
  ctx.lineTo(11, 7);
  ctx.lineTo(9, 7);
  ctx.lineTo(9, 5);
  ctx.bezierCurveTo(9, 4.44771525, 8.55228475, 4, 8, 4);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconPending;
