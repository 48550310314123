import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorNoView(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.4565217391304355, 0);
  ctx.scale(1.0869565217391304, 1.0869565217391304);
  ctx.translate(-4, 0);
  ctx.save();
  ctx.fillStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(6.80029, 14.3164);
  ctx.bezierCurveTo(6.54767, 13.9744, 6.24079, 13.2752, 5.69464, 12.4264);
  ctx.bezierCurveTo(5.38509, 11.9462, 4.61745, 11.0421, 4.38885, 10.5829);
  ctx.bezierCurveTo(4.19049, 10.1771, 4.21184, 9.99515, 4.25898, 9.65886);
  ctx.bezierCurveTo(4.34259, 9.06058, 4.91543, 8.59473, 5.52652, 8.6576);
  ctx.bezierCurveTo(5.98817, 8.70429, 6.37956, 9.03105, 6.7318, 9.33971);
  ctx.bezierCurveTo(6.94439, 9.52548, 7.2059, 9.88654, 7.36335, 10.0904);
  ctx.bezierCurveTo(7.50833, 10.2771, 7.54391, 10.3543, 7.69869, 10.5753);
  ctx.bezierCurveTo(7.90327, 10.8678, 7.96732, 11.0126, 7.88904, 10.6906);
  ctx.bezierCurveTo(7.82589, 10.2181, 7.7227, 9.41116, 7.57327, 8.69762);
  ctx.bezierCurveTo(7.45941, 8.1565, 7.43184, 8.07171, 7.32332, 7.65635);
  ctx.bezierCurveTo(7.20857, 7.21431, 7.14986, 6.9047, 7.04223, 6.43598);
  ctx.bezierCurveTo(6.96752, 6.10446, 6.8332, 5.42711, 6.79673, 5.04604);
  ctx.bezierCurveTo(6.74603, 4.52493, 6.71935, 3.67515, 7.03156, 3.28456);
  ctx.bezierCurveTo(7.27617, 2.97875, 7.83745, 2.88635, 8.18525, 3.07497);
  ctx.bezierCurveTo(8.64067, 3.32171, 8.89952, 4.0305, 9.01782, 4.31344);
  ctx.bezierCurveTo(9.23041, 4.82217, 9.36206, 5.40996, 9.4768, 6.18162);
  ctx.bezierCurveTo(9.62268, 7.16382, 9.89131, 8.52709, 9.90021, 8.81384);
  ctx.bezierCurveTo(9.92155, 8.46231, 9.83972, 7.72208, 9.89665, 7.38484);
  ctx.bezierCurveTo(9.94824, 7.07903, 10.1884, 6.72369, 10.4891, 6.62747);
  ctx.bezierCurveTo(10.7435, 6.54649, 11.0414, 6.51696, 11.3038, 6.57507);
  ctx.bezierCurveTo(11.5823, 6.63604, 11.8758, 6.84944, 11.9852, 7.05045);
  ctx.bezierCurveTo(12.3072, 7.64492, 12.3134, 8.85957, 12.3268, 8.79479);
  ctx.bezierCurveTo(12.4033, 8.43659, 12.3899, 7.62396, 12.5794, 7.28576);
  ctx.bezierCurveTo(12.7039, 7.06284, 13.0215, 6.86183, 13.1905, 6.82944);
  ctx.bezierCurveTo(13.452, 6.7799, 13.7731, 6.76465, 14.048, 6.82181);
  ctx.bezierCurveTo(14.2694, 6.86849, 14.5692, 7.15048, 14.6501, 7.28576);
  ctx.bezierCurveTo(14.8441, 7.61348, 14.9544, 8.54043, 14.9873, 8.86528);
  ctx.bezierCurveTo(15.0006, 8.99961, 15.0531, 8.49184, 15.2479, 8.16412);
  ctx.bezierCurveTo(15.609, 7.55537, 16.8872, 7.43724, 16.9362, 8.77288);
  ctx.bezierCurveTo(16.9584, 9.39592, 16.954, 9.36734, 16.954, 9.78652);
  ctx.bezierCurveTo(16.954, 10.279, 16.9433, 10.5753, 16.9184, 10.9316);
  ctx.bezierCurveTo(16.8908, 11.3127, 16.8143, 12.1739, 16.7031, 12.5912);
  ctx.bezierCurveTo(16.6266, 12.8779, 16.3731, 13.5229, 16.1232, 13.9097);
  ctx.bezierCurveTo(16.1232, 13.9097, 15.1678, 15.1005, 15.0638, 15.6368);
  ctx.bezierCurveTo(14.9588, 16.1722, 14.9935, 16.1761, 14.973, 16.5562);
  ctx.bezierCurveTo(14.9526, 16.9353, 15.0807, 17.4345, 15.0807, 17.4345);
  ctx.bezierCurveTo(15.0807, 17.4345, 14.3673, 17.5336, 13.983, 17.4679);
  ctx.bezierCurveTo(13.6352, 17.4079, 13.2047, 16.6667, 13.0935, 16.4399);
  ctx.bezierCurveTo(12.9405, 16.1275, 12.6141, 16.1875, 12.4869, 16.418);
  ctx.bezierCurveTo(12.2867, 16.7829, 11.8562, 17.4374, 11.552, 17.4784);
  ctx.bezierCurveTo(10.9578, 17.5584, 9.72497, 17.5079, 8.75986, 17.4974);
  ctx.bezierCurveTo(8.75986, 17.4974, 8.92442, 16.5343, 8.55795, 16.2037);
  ctx.bezierCurveTo(8.28665, 15.9569, 7.81966, 15.4568, 7.54036, 15.1939);
  ctx.lineTo(6.80029, 14.3164);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.black;
  ctx.lineWidth = 0.681818;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.beginPath();
  ctx.moveTo(6.80029, 14.3164);
  ctx.bezierCurveTo(6.54767, 13.9744, 6.24079, 13.2752, 5.69464, 12.4264);
  ctx.bezierCurveTo(5.38509, 11.9462, 4.61745, 11.0421, 4.38885, 10.5829);
  ctx.bezierCurveTo(4.19049, 10.1771, 4.21184, 9.99515, 4.25898, 9.65886);
  ctx.bezierCurveTo(4.34259, 9.06058, 4.91543, 8.59473, 5.52652, 8.6576);
  ctx.bezierCurveTo(5.98817, 8.70429, 6.37956, 9.03105, 6.7318, 9.33971);
  ctx.bezierCurveTo(6.94439, 9.52548, 7.2059, 9.88654, 7.36335, 10.0904);
  ctx.bezierCurveTo(7.50833, 10.2771, 7.54391, 10.3543, 7.69869, 10.5753);
  ctx.bezierCurveTo(7.90327, 10.8678, 7.96732, 11.0126, 7.88904, 10.6906);
  ctx.bezierCurveTo(7.82589, 10.2181, 7.7227, 9.41116, 7.57327, 8.69762);
  ctx.bezierCurveTo(7.45941, 8.1565, 7.43184, 8.07171, 7.32332, 7.65635);
  ctx.bezierCurveTo(7.20857, 7.21431, 7.14986, 6.9047, 7.04223, 6.43598);
  ctx.bezierCurveTo(6.96752, 6.10446, 6.8332, 5.42711, 6.79673, 5.04604);
  ctx.bezierCurveTo(6.74603, 4.52493, 6.71935, 3.67515, 7.03156, 3.28456);
  ctx.bezierCurveTo(7.27617, 2.97875, 7.83745, 2.88635, 8.18525, 3.07497);
  ctx.bezierCurveTo(8.64067, 3.32171, 8.89952, 4.0305, 9.01782, 4.31344);
  ctx.bezierCurveTo(9.23041, 4.82217, 9.36206, 5.40996, 9.4768, 6.18162);
  ctx.bezierCurveTo(9.62268, 7.16382, 9.89131, 8.52709, 9.90021, 8.81384);
  ctx.bezierCurveTo(9.92155, 8.46231, 9.83972, 7.72208, 9.89665, 7.38484);
  ctx.bezierCurveTo(9.94824, 7.07903, 10.1884, 6.72369, 10.4891, 6.62747);
  ctx.bezierCurveTo(10.7435, 6.54649, 11.0414, 6.51696, 11.3038, 6.57507);
  ctx.bezierCurveTo(11.5823, 6.63604, 11.8758, 6.84944, 11.9852, 7.05045);
  ctx.bezierCurveTo(12.3072, 7.64492, 12.3134, 8.85957, 12.3268, 8.79479);
  ctx.bezierCurveTo(12.4033, 8.43659, 12.3899, 7.62396, 12.5794, 7.28576);
  ctx.bezierCurveTo(12.7039, 7.06284, 13.0215, 6.86183, 13.1905, 6.82944);
  ctx.bezierCurveTo(13.452, 6.7799, 13.7731, 6.76465, 14.048, 6.82181);
  ctx.bezierCurveTo(14.2694, 6.86849, 14.5692, 7.15048, 14.6501, 7.28576);
  ctx.bezierCurveTo(14.8441, 7.61348, 14.9544, 8.54043, 14.9873, 8.86528);
  ctx.bezierCurveTo(15.0006, 8.99961, 15.0531, 8.49184, 15.2479, 8.16412);
  ctx.bezierCurveTo(15.609, 7.55537, 16.8872, 7.43724, 16.9362, 8.77288);
  ctx.bezierCurveTo(16.9584, 9.39592, 16.954, 9.36734, 16.954, 9.78652);
  ctx.bezierCurveTo(16.954, 10.279, 16.9433, 10.5753, 16.9184, 10.9316);
  ctx.bezierCurveTo(16.8908, 11.3127, 16.8143, 12.1739, 16.7031, 12.5912);
  ctx.bezierCurveTo(16.6266, 12.8779, 16.3731, 13.5229, 16.1232, 13.9097);
  ctx.bezierCurveTo(16.1232, 13.9097, 15.1678, 15.1005, 15.0638, 15.6368);
  ctx.bezierCurveTo(14.9588, 16.1722, 14.9935, 16.1761, 14.973, 16.5562);
  ctx.bezierCurveTo(14.9526, 16.9353, 15.0807, 17.4345, 15.0807, 17.4345);
  ctx.bezierCurveTo(15.0807, 17.4345, 14.3673, 17.5336, 13.983, 17.4679);
  ctx.bezierCurveTo(13.6352, 17.4079, 13.2047, 16.6667, 13.0935, 16.4399);
  ctx.bezierCurveTo(12.9405, 16.1275, 12.6141, 16.1875, 12.4869, 16.418);
  ctx.bezierCurveTo(12.2867, 16.7829, 11.8562, 17.4374, 11.552, 17.4784);
  ctx.bezierCurveTo(10.9578, 17.5584, 9.72497, 17.5079, 8.75986, 17.4974);
  ctx.bezierCurveTo(8.75986, 17.4974, 8.92442, 16.5343, 8.55795, 16.2037);
  ctx.bezierCurveTo(8.28665, 15.9569, 7.81966, 15.4568, 7.54036, 15.1939);
  ctx.lineTo(6.80029, 14.3164);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(10.5907, 11.6135);
  ctx.bezierCurveTo(10.5907, 11.3624, 10.3872, 11.1589, 10.1362, 11.1589);
  ctx.bezierCurveTo(9.88515, 11.1589, 9.68164, 11.3624, 9.68164, 11.6135);
  ctx.lineTo(9.68164, 14.3408);
  ctx.bezierCurveTo(9.68164, 14.5918, 9.88515, 14.7953, 10.1362, 14.7953);
  ctx.bezierCurveTo(10.3872, 14.7953, 10.5907, 14.5918, 10.5907, 14.3408);
  ctx.lineTo(10.5907, 11.6135);
  ctx.closePath();
  ctx.moveTo(11.9544, 11.1589);
  ctx.bezierCurveTo(12.2054, 11.1589, 12.4089, 11.3624, 12.4089, 11.6135);
  ctx.lineTo(12.4089, 14.3408);
  ctx.bezierCurveTo(12.4089, 14.5918, 12.2054, 14.7953, 11.9544, 14.7953);
  ctx.bezierCurveTo(11.7033, 14.7953, 11.4998, 14.5918, 11.4998, 14.3408);
  ctx.lineTo(11.4998, 11.6135);
  ctx.bezierCurveTo(11.4998, 11.3624, 11.7033, 11.1589, 11.9544, 11.1589);
  ctx.closePath();
  ctx.moveTo(13.7725, 11.1589);
  ctx.bezierCurveTo(14.0236, 11.1589, 14.2271, 11.3624, 14.2271, 11.6135);
  ctx.lineTo(14.2271, 14.3408);
  ctx.bezierCurveTo(14.2271, 14.5918, 14.0236, 14.7953, 13.7725, 14.7953);
  ctx.bezierCurveTo(13.5215, 14.7953, 13.318, 14.5918, 13.318, 14.3408);
  ctx.lineTo(13.318, 11.6135);
  ctx.bezierCurveTo(13.318, 11.3624, 13.5215, 11.1589, 13.7725, 11.1589);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(20.8562, 11.3524);
  ctx.bezierCurveTo(20.8288, 11.3215, 20.7955, 11.2963, 20.7582, 11.2783);
  ctx.bezierCurveTo(20.721, 11.2604, 20.6806, 11.25, 20.6393, 11.2478);
  ctx.bezierCurveTo(20.598, 11.2455, 20.5566, 11.2515, 20.5177, 11.2654);
  ctx.bezierCurveTo(20.4787, 11.2793, 20.4429, 11.3007, 20.4123, 11.3285);
  ctx.bezierCurveTo(20.3817, 11.3564, 20.3569, 11.39, 20.3394, 11.4274);
  ctx.bezierCurveTo(20.3219, 11.4649, 20.312, 11.5055, 20.3102, 11.5468);
  ctx.bezierCurveTo(20.3085, 11.5881, 20.315, 11.6293, 20.3293, 11.6681);
  ctx.bezierCurveTo(20.3437, 11.7069, 20.3656, 11.7425, 20.3937, 11.7727);
  ctx.lineTo(21.1453, 12.5997);
  ctx.bezierCurveTo(19.7266, 13.4704, 19.1164, 14.8126, 19.0894, 14.8735);
  ctx.bezierCurveTo(19.0717, 14.9135, 19.0625, 14.9567, 19.0625, 15.0005);
  ctx.bezierCurveTo(19.0625, 15.0442, 19.0717, 15.0875, 19.0894, 15.1274);
  ctx.bezierCurveTo(19.1031, 15.1583, 19.434, 15.8919, 20.1695, 16.6274);
  ctx.bezierCurveTo(21.1496, 17.6071, 22.3875, 18.1251, 23.75, 18.1251);
  ctx.bezierCurveTo(24.4502, 18.1291, 25.1434, 17.9849, 25.784, 17.702);
  ctx.lineTo(26.6434, 18.6477);
  ctx.bezierCurveTo(26.6708, 18.6787, 26.7041, 18.7039, 26.7414, 18.7218);
  ctx.bezierCurveTo(26.7786, 18.7398, 26.819, 18.7502, 26.8603, 18.7524);
  ctx.bezierCurveTo(26.9016, 18.7546, 26.943, 18.7486, 26.9819, 18.7348);
  ctx.bezierCurveTo(27.0209, 18.7209, 27.0567, 18.6994, 27.0873, 18.6716);
  ctx.bezierCurveTo(27.1179, 18.6438, 27.1427, 18.6102, 27.1602, 18.5727);
  ctx.bezierCurveTo(27.1777, 18.5353, 27.1876, 18.4947, 27.1894, 18.4534);
  ctx.bezierCurveTo(27.1911, 18.4121, 27.1846, 18.3708, 27.1703, 18.332);
  ctx.bezierCurveTo(27.1559, 18.2933, 27.134, 18.2577, 27.1059, 18.2274);
  ctx.lineTo(20.8562, 11.3524);
  ctx.closePath();
  ctx.moveTo(22.7051, 14.3149);
  ctx.lineTo(24.3328, 16.1059);
  ctx.bezierCurveTo(24.0877, 16.2349, 23.8067, 16.2788, 23.5339, 16.2307);
  ctx.bezierCurveTo(23.2611, 16.1827, 23.0121, 16.0454, 22.8258, 15.8404);
  ctx.bezierCurveTo(22.6395, 15.6354, 22.5266, 15.3744, 22.5047, 15.0983);
  ctx.bezierCurveTo(22.4829, 14.8222, 22.5533, 14.5466, 22.7051, 14.3149);
  ctx.closePath();
  ctx.moveTo(23.75, 17.5001);
  ctx.bezierCurveTo(22.5477, 17.5001, 21.4973, 17.063, 20.6277, 16.2013);
  ctx.bezierCurveTo(20.2708, 15.8466, 19.9673, 15.442, 19.7266, 15.0001);
  ctx.bezierCurveTo(19.9098, 14.6567, 20.4945, 13.6958, 21.5762, 13.0712);
  ctx.lineTo(22.2793, 13.8427);
  ctx.bezierCurveTo(22.0071, 14.1913, 21.8669, 14.6249, 21.8835, 15.067);
  ctx.bezierCurveTo(21.9001, 15.509, 22.0724, 15.9309, 22.37, 16.2581);
  ctx.bezierCurveTo(22.6676, 16.5853, 23.0713, 16.7968, 23.5098, 16.8552);
  ctx.bezierCurveTo(23.9482, 16.9135, 24.3932, 16.815, 24.766, 16.577);
  ctx.lineTo(25.3414, 17.2099);
  ctx.bezierCurveTo(24.8336, 17.4047, 24.2939, 17.5031, 23.75, 17.5001);
  ctx.closePath();
  ctx.moveTo(23.9844, 13.7724);
  ctx.bezierCurveTo(23.9029, 13.7568, 23.831, 13.7096, 23.7844, 13.641);
  ctx.bezierCurveTo(23.7378, 13.5724, 23.7204, 13.4882, 23.7359, 13.4067);
  ctx.bezierCurveTo(23.7515, 13.3253, 23.7987, 13.2534, 23.8673, 13.2068);
  ctx.bezierCurveTo(23.9359, 13.1602, 24.0201, 13.1428, 24.1016, 13.1583);
  ctx.bezierCurveTo(24.4998, 13.2355, 24.8625, 13.4394, 25.1354, 13.7396);
  ctx.bezierCurveTo(25.4083, 14.0397, 25.5769, 14.4201, 25.616, 14.8239);
  ctx.bezierCurveTo(25.6237, 14.9064, 25.5983, 14.9886, 25.5455, 15.0524);
  ctx.bezierCurveTo(25.4926, 15.1162, 25.4165, 15.1564, 25.334, 15.1641);
  ctx.bezierCurveTo(25.3242, 15.1647, 25.3144, 15.1647, 25.3047, 15.1641);
  ctx.bezierCurveTo(25.2266, 15.1645, 25.1512, 15.1356, 25.0933, 15.0831);
  ctx.bezierCurveTo(25.0355, 15.0306, 24.9994, 14.9583, 24.9922, 14.8806);
  ctx.bezierCurveTo(24.9659, 14.612, 24.8536, 14.359, 24.672, 14.1594);
  ctx.bezierCurveTo(24.4904, 13.9597, 24.2493, 13.824, 23.9844, 13.7724);
  ctx.closePath();
  ctx.moveTo(28.4094, 15.1274);
  ctx.bezierCurveTo(28.393, 15.1641, 27.9973, 16.0403, 27.1062, 16.8384);
  ctx.bezierCurveTo(27.0758, 16.8665, 27.0401, 16.8883, 27.0011, 16.9025);
  ctx.bezierCurveTo(26.9622, 16.9167, 26.9208, 16.923, 26.8794, 16.921);
  ctx.bezierCurveTo(26.838, 16.9191, 26.7973, 16.9089, 26.7599, 16.8911);
  ctx.bezierCurveTo(26.7225, 16.8733, 26.689, 16.8482, 26.6613, 16.8173);
  ctx.bezierCurveTo(26.6337, 16.7864, 26.6124, 16.7503, 26.5989, 16.7112);
  ctx.bezierCurveTo(26.5853, 16.672, 26.5797, 16.6305, 26.5823, 16.5891);
  ctx.bezierCurveTo(26.5849, 16.5478, 26.5957, 16.5073, 26.6141, 16.4702);
  ctx.bezierCurveTo(26.6325, 16.433, 26.6581, 16.3999, 26.6894, 16.3727);
  ctx.bezierCurveTo(27.1266, 15.98, 27.4938, 15.5159, 27.7754, 15.0001);
  ctx.bezierCurveTo(27.5341, 14.5578, 27.2299, 14.1529, 26.8723, 13.7981);
  ctx.bezierCurveTo(26.0027, 12.9372, 24.9523, 12.5001, 23.75, 12.5001);
  ctx.bezierCurveTo(23.4967, 12.4998, 23.2437, 12.5203, 22.9937, 12.5614);
  ctx.bezierCurveTo(22.9531, 12.5686, 22.9114, 12.5677, 22.8711, 12.5587);
  ctx.bezierCurveTo(22.8308, 12.5497, 22.7927, 12.5328, 22.759, 12.5089);
  ctx.bezierCurveTo(22.7253, 12.4851, 22.6966, 12.4549, 22.6747, 12.4199);
  ctx.bezierCurveTo(22.6527, 12.3849, 22.6379, 12.346, 22.6311, 12.3053);
  ctx.bezierCurveTo(22.6243, 12.2645, 22.6256, 12.2229, 22.635, 12.1827);
  ctx.bezierCurveTo(22.6444, 12.1425, 22.6616, 12.1045, 22.6858, 12.071);
  ctx.bezierCurveTo(22.7099, 12.0375, 22.7404, 12.0092, 22.7756, 11.9875);
  ctx.bezierCurveTo(22.8107, 11.9659, 22.8498, 11.9514, 22.8906, 11.945);
  ctx.bezierCurveTo(23.1747, 11.8981, 23.4621, 11.8747, 23.75, 11.8751);
  ctx.bezierCurveTo(25.1125, 11.8751, 26.3504, 12.3931, 27.3305, 13.3731);
  ctx.bezierCurveTo(28.066, 14.1087, 28.3969, 14.8427, 28.4105, 14.8735);
  ctx.bezierCurveTo(28.4283, 14.9135, 28.4375, 14.9567, 28.4375, 15.0005);
  ctx.bezierCurveTo(28.4375, 15.0442, 28.4283, 15.0875, 28.4105, 15.1274);
  ctx.lineTo(28.4094, 15.1274);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorNoView;
