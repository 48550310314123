import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconSource(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.2, 1.2);
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(10.0004, 7.60039);
  ctx.bezierCurveTo(11.9886, 7.60039, 13.6004, 5.98862, 13.6004, 4.00039);
  ctx.bezierCurveTo(13.6004, 2.01217, 11.9886, 0.400391, 10.0004, 0.400391);
  ctx.bezierCurveTo(8.01215, 0.400391, 6.40037, 2.01217, 6.40037, 4.00039);
  ctx.bezierCurveTo(6.40037, 5.98862, 8.01215, 7.60039, 10.0004, 7.60039);
  ctx.closePath();
  ctx.moveTo(10.0002, 8.7998);
  ctx.bezierCurveTo(10.6629, 8.7998, 11.2002, 9.33706, 11.2002, 9.9998);
  ctx.lineTo(11.2002, 13.5998);
  ctx.lineTo(14.5616, 13.5998);
  ctx.bezierCurveTo(15.0947, 13.5998, 15.363, 14.2432, 14.9879, 14.622);
  ctx.lineTo(10.9028, 18.7471);
  ctx.bezierCurveTo(10.4364, 19.218, 9.6766, 19.2217, 9.2057, 18.7553);
  ctx.lineTo(5.03641, 14.6261);
  ctx.bezierCurveTo(4.65562, 14.249, 4.92268, 13.5998, 5.45863, 13.5998);
  ctx.lineTo(8.80015, 13.5998);
  ctx.lineTo(8.80015, 9.9998);
  ctx.bezierCurveTo(8.80015, 9.33706, 9.33741, 8.7998, 10.0002, 8.7998);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconSource;
