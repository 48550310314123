import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Space, TextField, Label, Tooltip, Icon } from 'mw-style-react';
import { SPACE_SIZE } from 'mw-style-react/build/constants';
import { useIntl } from 'hooks';
import scss from './SmartFormSettings.scss';
import mes from './intl';

function SmartFormSettings({ fields, values, errors, onChange }) {
  const t = useIntl();

  const renderLabelWithHintIcon = (id, hint) => (
    <Stack.H fullWidth size={Stack.SIZE.xxsmall} alignItems="center">
      <Label value={t(mes[id])} fontSize="small" />
      {hint ? (
        <Tooltip value={t(mes[`${id}Hint`])} breakWord topLevel>
          <Icon type="info" size="small" />
        </Tooltip>
      ) : null}
    </Stack.H>
  );

  return (
    <Space top left right size={SPACE_SIZE.medium}>
      <Stack.H fullWidth>
        {fields.map(({ id, autofocus, hint }) => (
          <TextField
            key={id}
            className={scss.settingsField}
            autoFocus={autofocus}
            bordered
            unspaced
            color={TextField.COLOR.white}
            label={renderLabelWithHintIcon(id, hint)}
            value={values[id]}
            error={!!errors[id]}
            helperText={errors[id]}
            onChange={({ value }) => onChange(id, value)}
          />
        ))}
      </Stack.H>
    </Space>
  );
}

SmartFormSettings.propTypes = {
  fields: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SmartFormSettings;
