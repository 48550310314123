import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconSnippet(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.2, 1.2);
  ctx.translate(-2, -2);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.beginPath();
  ctx.moveTo(4, 6);
  ctx.bezierCurveTo(4, 4.89543, 4.89543, 4, 6, 4);
  ctx.lineTo(7, 4);
  ctx.lineTo(7, 6);
  ctx.lineTo(6, 6);
  ctx.lineTo(6, 10);
  ctx.bezierCurveTo(6, 11.1046, 5.10457, 12, 4, 12);
  ctx.bezierCurveTo(5.10457, 12, 6, 12.8954, 6, 14);
  ctx.lineTo(6, 18);
  ctx.lineTo(7, 18);
  ctx.lineTo(7, 20);
  ctx.lineTo(6, 20);
  ctx.bezierCurveTo(4.89543, 20, 4, 19.1046, 4, 18);
  ctx.lineTo(4, 14);
  ctx.bezierCurveTo(2.89543, 14, 2, 13.1046, 2, 12);
  ctx.bezierCurveTo(2, 10.8954, 2.89543, 10, 4, 10);
  ctx.lineTo(4, 6);
  ctx.closePath();
  ctx.moveTo(20, 6);
  ctx.bezierCurveTo(20, 4.89543, 19.1046, 4, 18, 4);
  ctx.lineTo(17, 4);
  ctx.lineTo(17, 6);
  ctx.lineTo(18, 6);
  ctx.lineTo(18, 10);
  ctx.bezierCurveTo(18, 11.1046, 18.8954, 12, 20, 12);
  ctx.bezierCurveTo(18.8954, 12, 18, 12.8954, 18, 14);
  ctx.lineTo(18, 18);
  ctx.lineTo(17, 18);
  ctx.lineTo(17, 20);
  ctx.lineTo(18, 20);
  ctx.bezierCurveTo(19.1046, 20, 20, 19.1046, 20, 18);
  ctx.lineTo(20, 14);
  ctx.bezierCurveTo(21.1046, 14, 22, 13.1046, 22, 12);
  ctx.bezierCurveTo(22, 10.8954, 21.1046, 10, 20, 10);
  ctx.lineTo(20, 6);
  ctx.closePath();
  ctx.moveTo(9, 7);
  ctx.bezierCurveTo(8.44772, 7, 8, 7.44772, 8, 8);
  ctx.bezierCurveTo(8, 8.55228, 8.44772, 9, 9, 9);
  ctx.lineTo(15, 9);
  ctx.bezierCurveTo(15.5523, 9, 16, 8.55228, 16, 8);
  ctx.bezierCurveTo(16, 7.44772, 15.5523, 7, 15, 7);
  ctx.lineTo(9, 7);
  ctx.closePath();
  ctx.moveTo(8, 12);
  ctx.bezierCurveTo(8, 11.4477, 8.44772, 11, 9, 11);
  ctx.lineTo(15, 11);
  ctx.bezierCurveTo(15.5523, 11, 16, 11.4477, 16, 12);
  ctx.bezierCurveTo(16, 12.5523, 15.5523, 13, 15, 13);
  ctx.lineTo(9, 13);
  ctx.bezierCurveTo(8.44772, 13, 8, 12.5523, 8, 12);
  ctx.closePath();
  ctx.moveTo(9, 15);
  ctx.bezierCurveTo(8.44772, 15, 8, 15.4477, 8, 16);
  ctx.bezierCurveTo(8, 16.5523, 8.44772, 17, 9, 17);
  ctx.lineTo(15, 17);
  ctx.bezierCurveTo(15.5523, 17, 16, 16.5523, 16, 16);
  ctx.bezierCurveTo(16, 15.4477, 15.5523, 15, 15, 15);
  ctx.lineTo(9, 15);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconSnippet;
