import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconFlag(ctx, opacity, hexToRgba) {
  ctx.beginPath();
  ctx.arc(0, 0, 4, 0, Math.PI * 2);
  ctx.fillStyle = hexToRgba(theme.palette.red, opacity);
  ctx.fill();
}

export default SVGIconFlag;
