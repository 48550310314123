import React, { useState } from 'react';
import { Stack, Card, Space } from 'mw-style-react';
import CustomBar from './CustomBar';

function FreezedCustomBar() {
  const [cardWidth, setCardWidth] = useState(0);

  return (
    <Stack.H fullWidth justifyContent="flexEnd">
      <Card fullWidth withBorder={cardWidth} style={{ maxWidth: cardWidth }}>
        <Space>
          <CustomBar adjustParentWidth={setCardWidth} />
        </Space>
      </Card>
    </Stack.H>
  );
}

export default FreezedCustomBar;
