import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_SYSTEM_LAYERS,
  GET_SYSTEM_LAYERS,
} from '@control-front-end/common/constants/graphLayers';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  list: [],
};

export default createReducer(initState, {
  [GET_SYSTEM_LAYERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CLEAR_SYSTEM_LAYERS]() {
    return initState;
  },
});
