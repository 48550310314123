function SVGIconDebit(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(-13, -13);
  ctx.save();
  ctx.fillStyle = '#F84545';
  ctx.translate(35, 35);
  ctx.rotate(3.141592653589793);
  ctx.translate(-35, -35);
  ctx.globalAlpha = 0.1;
  ctx.beginPath();
  ctx.moveTo(45, 35);
  ctx.lineTo(45, 35);
  ctx.bezierCurveTo(50.52284749830794, 35, 55, 39.47715250169206, 55, 45);
  ctx.lineTo(55, 45);
  ctx.bezierCurveTo(55, 50.52284749830794, 50.52284749830794, 55, 45, 55);
  ctx.lineTo(45, 55);
  ctx.bezierCurveTo(39.47715250169206, 55, 35, 50.52284749830794, 35, 45);
  ctx.lineTo(35, 45);
  ctx.bezierCurveTo(35, 39.47715250169206, 39.47715250169206, 35, 45, 35);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#F84545';
  ctx.beginPath();
  ctx.moveTo(29.7537, 26.2236);
  ctx.lineTo(25.5905, 29.7963);
  ctx.bezierCurveTo(25.5113, 29.8614, 25.4179, 29.9124, 25.3157, 29.9464);
  ctx.bezierCurveTo(25.113, 30.0179, 24.8856, 30.0179, 24.6829, 29.9464);
  ctx.bezierCurveTo(24.5807, 29.9124, 24.4873, 29.8614, 24.4081, 29.7963);
  ctx.lineTo(20.2449, 26.2236);
  ctx.bezierCurveTo(20.0337, 26.0424, 19.9512, 25.7782, 20.0285, 25.5306);
  ctx.bezierCurveTo(20.1058, 25.283, 20.3312, 25.0896, 20.6197, 25.0233);
  ctx.bezierCurveTo(20.9082, 24.957, 21.216, 25.0278, 21.4272, 25.209);
  ctx.lineTo(24.1667, 27.567);
  ctx.lineTo(24.1667, 20.7145);
  ctx.bezierCurveTo(24.1667, 20.3199, 24.5394, 20, 24.9993, 20);
  ctx.bezierCurveTo(25.4591, 20, 25.832, 20.3199, 25.832, 20.7145);
  ctx.lineTo(25.832, 27.567);
  ctx.lineTo(28.5713, 25.209);
  ctx.bezierCurveTo(28.7277, 25.0737, 28.9405, 24.9976, 29.1625, 24.9976);
  ctx.bezierCurveTo(29.3845, 24.9976, 29.5974, 25.0737, 29.7537, 25.209);
  ctx.bezierCurveTo(29.9113, 25.3432, 30, 25.5258, 30, 25.7163);
  ctx.bezierCurveTo(30, 25.9068, 29.9113, 26.0895, 29.7537, 26.2236);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDebit;
