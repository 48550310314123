import React from 'react';
import PropTypes from 'prop-types';
import { Stack, MenuItem } from 'mw-style-react';
import cn from 'classnames';
import Search from '@control-front-end/app/src/components/Search';
import scss from './CategoriesList.scss';

function CategoriesList({
  categories,
  activeCategory,
  handleSearch,
  handleSelect,
}) {
  return (
    <Stack.V size={Stack.SIZE.medium}>
      <Search
        style={{ flex: '1 1 auto', marginRight: '0', maxWidth: '200px' }}
        onSearch={handleSearch}
        onSelect={() => {}}
        onClear={() => handleSearch('')}
        onClose={() => {}}
        autoSelect={false}
      />
      <Stack.V size={Stack.SIZE.xxsmall} className={scss.categoriesList}>
        {categories.map((item, index) => (
          <MenuItem
            key={item.category}
            className={cn({
              [scss.activeCategory]: activeCategory === index,
            })}
            label={item.category}
            onClick={() => handleSelect(index)}
          />
        ))}
      </Stack.V>
    </Stack.V>
  );
}

CategoriesList.defaultProps = {
  categories: [],
  activeCategory: 0,
};

CategoriesList.propTypes = {
  categories: PropTypes.array,
  activeCategory: PropTypes.number,
  handleSearch: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default CategoriesList;
