import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconFilter(ctx, opacity, hexToRgba) {
  const color = hexToRgba(theme.palette.primary, opacity);
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = color;
  ctx.translate(-724, -91);
  ctx.save();
  ctx.fillStyle = color;
  ctx.translate(714, 81);
  ctx.save();
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(23.6, 10);
  ctx.lineTo(12.4000001, 10);
  ctx.bezierCurveTo(11.0745167, 10, 10, 11.0745166, 10, 12.4);
  ctx.lineTo(10, 13.336);
  ctx.bezierCurveTo(10, 13.6663543, 10.0679731, 13.9931752, 10.2000001, 14.296);
  ctx.lineTo(10.2000001, 14.344);
  ctx.bezierCurveTo(
    10.3130238,
    14.600777,
    10.4731139,
    14.8341287,
    10.6720001,
    15.032
  );
  ctx.lineTo(15.6000001, 19.9279999);
  ctx.lineTo(15.6000001, 25.1999999);
  ctx.bezierCurveTo(
    15.599447,
    25.476401,
    15.7416117,
    25.7335075,
    15.9760001,
    25.8799999
  );
  ctx.bezierCurveTo(
    16.1033131,
    25.9589031,
    16.2502199,
    26.0004805,
    16.4000001,
    26
  );
  ctx.bezierCurveTo(
    16.5252339,
    25.999249,
    16.6485422,
    25.9691069,
    16.7600001,
    25.9119999
  );
  ctx.lineTo(19.9600001, 24.3119999);
  ctx.bezierCurveTo(
    20.2290238,
    24.1764397,
    20.399087,
    23.9012464,
    20.4000001,
    23.5999999
  );
  ctx.lineTo(20.4000001, 19.9279999);
  ctx.lineTo(25.296, 15.032);
  ctx.bezierCurveTo(
    25.4948862,
    14.8341287,
    25.6549763,
    14.600777,
    25.768,
    14.344
  );
  ctx.lineTo(25.768, 14.296);
  ctx.bezierCurveTo(25.9110514, 13.9955445, 25.9900574, 13.6686234, 26, 13.336);
  ctx.lineTo(26, 12.4);
  ctx.bezierCurveTo(26, 11.0745166, 24.9254834, 10, 23.6, 10);
  ctx.closePath();
  ctx.moveTo(24.4, 13.2);
  ctx.lineTo(11.6000001, 13.2);
  ctx.lineTo(11.6000001, 12.4);
  ctx.bezierCurveTo(11.6000001, 11.9581722, 11.9581723, 11.6, 12.4000001, 11.6);
  ctx.lineTo(23.6000001, 11.6);
  ctx.bezierCurveTo(24.0418278, 11.6, 24.4, 11.9581722, 24.4, 12.4);
  ctx.lineTo(24.4, 13.2);
  ctx.closePath();
  ctx.fill('nonzero');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconFilter;
