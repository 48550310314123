import { put, takeEvery, call, select, delay, fork } from 'redux-saga/effects';
import history from '@control-front-end/app/src/store/history';
import {
  CALL_MEETING,
  ACCEPT_MEETING,
  CREATE_MEETING,
  INIT_MEETING,
  JOIN_MEETING,
  MEETING_NOTIFY,
  REJECT_MEETING,
  GET_MEETING_PARTICIPANTS,
  WS_SIP_CALL,
  WS_SIP_JOIN,
  WS_SIP_ACCEPT,
  WS_SIP_REJECT,
  RECORD_MEETING,
  CALL_STATUS,
  MANAGE_MEETING_PARTICIPANTS,
  MEETING_MODERATOR_CHANGED,
  WS_MEETING_REMINDER_ACTOR,
  WS_MEETING_STARTED_ACTOR,
  GET_ACTIVE_MEETINGS,
  ADD_ACTIVE_MEETING,
  REMOVE_ACTIVE_MEETING,
} from '@control-front-end/common/constants/meeting';
import {
  UPDATE_ACTOR_VIEW,
  WS_UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import {
  wsAddActor,
  wsRemoveActor,
} from '@control-front-end/app/src/sagas/graph/graphRealtime';
import { STREAM } from '@control-front-end/common/constants/streams';
import {
  RequestStatus,
  SOUND_TYPE,
  SOUND_CATEGORY,
  MAKE_SOUND,
  STOP_SOUND,
  HIDE_NOTIFY,
} from 'constants';
import { DateUtils } from 'mw-style-react';
import api from './api';

/**
 * callTo user live limit (seconds)
 */
const CALL_TO_LIMIT = 30;
/**
 * Interval delay
 */
const DELAY_CHECK = 10000;
/**
 * Time range when we have to run sound about meeting start
 */
const SOUND_START_RANGE = 5 * 60;

/**
 * Clear active meeting notifications
 */
function* hideMeetingNotify({ payload }) {
  const callbackEvents = [WS_MEETING_REMINDER_ACTOR, WS_MEETING_STARTED_ACTOR];
  for (const callback of callbackEvents) {
    yield put({
      type: HIDE_NOTIFY.REQUEST,
      payload: { id: `${callback}_${payload.id}` },
    });
  }
}

/**
 * Checks if the Livekit URL is available in the application configuration.
 */
function* checkLivekit() {
  const config = yield select((state) => state.config);
  return !!config.livekitUrl;
}

/**
 * Join to the meeting room
 */
function* joinMeeting({ payload, callback }) {
  if (!(yield checkLivekit())) return;
  const { actorId } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/join/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({ type: JOIN_MEETING.SUCCESS, payload: data.data });
}

/**
 * Create new meeting room
 */
function* createMeeting({ payload, callback }) {
  if (!(yield checkLivekit())) return;
  const { participants, parentActorId } = payload;
  const accounts = yield select((state) => state.accounts);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/create/${accounts.active}`,
    body: { participants, parentActorId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({ type: CREATE_MEETING.SUCCESS, payload: data.data });
}

/**
 * Generator function to mute or remove user in meeting via an API call
 */
function* manageMeetingParticipants({ payload }) {
  if (!(yield checkLivekit())) return;
  const { action, actorId, participants } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/participants/${action}/${actorId}`,
    body: { participants },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: MANAGE_MEETING_PARTICIPANTS.SUCCESS, payload: data.data });
}

/**
 * Get meeting participants
 */
function* getMeetingParticipants({ payload, callback }) {
  if (!(yield checkLivekit())) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/sip/room/participants/${payload.actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  const actorView = yield select((state) => state.actorView);
  yield put({
    type: GET_MEETING_PARTICIPANTS.SUCCESS,
    payload: {
      participants: data.data,
      room: actorView.activeMeeting,
      model: { id: payload.actorId },
    },
  });
}

/**
 * Start/stop meeting recording
 */
function* recordingMeeting({ payload, callback }) {
  if (!(yield checkLivekit())) return;
  const { actorId, action } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/recording/${actorId}/${action}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({ type: RECORD_MEETING.SUCCESS });
}

/**
 * A generator function that sends a server notification related to a meeting state.
 */
function* meetingServerNotification({ type, notify, actorId, body }) {
  if (!(yield checkLivekit())) return;
  const meeting = yield select((state) => state.meeting);
  actorId = actorId || meeting?.actor?.id;
  yield put({ type });
  yield call(api, {
    method: 'post',
    url: `/sip/notification/${actorId}/${notify}`,
    body,
  });
}

/**
 * Call user to the meeting
 */
function* callMeeting({ payload }) {
  const { userId } = payload;
  const actorView = yield select((state) => state.actorView);
  if (!actorView?.id) return;
  const curCallTo = actorView.callTo || {};
  yield meetingServerNotification({
    type: CALL_MEETING.SUCCESS,
    notify: MEETING_NOTIFY.CALL,
    actorId: actorView.id,
    body: { callTo: [userId] },
  });
  yield put({
    type: UPDATE_ACTOR_VIEW.REQUEST,
    payload: {
      actorData: {
        id: actorView.id,
        callTo: {
          ...curCallTo,
          [userId]: { status: CALL_STATUS.CALL, time: DateUtils.unixtime() },
        },
      },
    },
  });
}

/**
 * Handles accepting a meeting by generating a notification to the meeting server.
 */
function* acceptMeeting() {
  const meeting = yield select((state) => state.meeting);
  const { actor } = meeting;
  yield meetingServerNotification({
    type: ACCEPT_MEETING.SUCCESS,
    notify: MEETING_NOTIFY.ACCEPT,
  });
  yield put({ type: STOP_SOUND });
  history.push(`/actors_graph/${actor.accId}/view/${actor.id}?tab=meeting`);
}

/**
 * Generator function to handle the rejection of a meeting.
 */
function* rejectMeeting() {
  yield meetingServerNotification({
    type: REJECT_MEETING.SUCCESS,
    notify: MEETING_NOTIFY.REJECT,
  });
  yield put({ type: STOP_SOUND });
}

/**
 * WS new meeting room created
 */
function* wsMeetingCall({ payload }) {
  const { user: caller, model, extra } = payload;
  const callTo = extra?.callTo || [];
  const accounts = yield select((state) => state.accounts);
  const auth = yield select((state) => state.auth);
  if (accounts.active !== model.accId || !callTo.includes(auth.id)) return;
  yield put({
    type: INIT_MEETING.SUCCESS,
    payload: { actor: model, caller },
  });
  yield put({
    type: MAKE_SOUND,
    payload: {
      type: SOUND_TYPE.calling,
      category: SOUND_CATEGORY.meeting,
      settings: { loop: true },
    },
  });
}

/**
 * Save meetings list to activeMeetings list
 */
export function* getActiveMeetings({ payload }) {
  const { list, total, loadMore } = payload;
  const { list: meetingsList } = yield select((state) => state.activeMeetings);
  const clonedMeetingsList = structuredClone(meetingsList);
  const newList =
    loadMore && list.length ? clonedMeetingsList.concat(list) : list;
  yield put({
    type: GET_ACTIVE_MEETINGS.SUCCESS,
    payload: { list: newList, total },
  });
}

/**
 * Add meeting to activeMeetings list
 */
function* addToActiveMeetings({ payload }) {
  const { room, model, user } = payload;
  const { list: meetingsList, total } = yield select(
    (state) => state.activeMeetings
  );
  const isAlreadyInMeetingsList = meetingsList.some(
    (i) => i.name === room.name
  );
  if (isAlreadyInMeetingsList) return;
  const clonedMeetingsList = structuredClone(meetingsList);
  clonedMeetingsList.push(room);
  yield put({
    type: ADD_ACTIVE_MEETING.SUCCESS,
    payload: { list: clonedMeetingsList, total: total + 1 },
  });
  const { active: activeStream } = yield select((state) => state.streams);
  if (activeStream !== STREAM.live) return;
  yield wsAddActor({
    payload: {
      model: {
        ...model,
        user,
        privs: user.privs || { view: true },
        activeMeeting: true,
      },
      isLiveStream: true,
    },
  });
}

/**
 * Remove meeting from activeMeetings list
 */
export function* removeFromActiveMeetings({ payload }) {
  const { model, user } = payload;
  const { list: meetingsList, total } = yield select(
    (state) => state.activeMeetings
  );
  const meetingIndex = meetingsList.findIndex((i) => i.name === model.id);
  if (meetingIndex === -1) return;
  const clonedMeetingsList = structuredClone(meetingsList);
  clonedMeetingsList.splice(meetingIndex, 1);
  yield put({
    type: REMOVE_ACTIVE_MEETING.SUCCESS,
    payload: { list: clonedMeetingsList, total: total - 1 },
  });
  const { active: activeStream } = yield select((state) => state.streams);
  if (activeStream !== STREAM.live) return;
  yield wsRemoveActor({
    payload: {
      model,
      userId: user.id,
    },
  });
}

/**
 * WS join call
 */
function* wsMeetingJoin({ payload }) {
  const { user, model, room: activeMeeting } = payload;
  const actorView = yield select((state) => state.actorView);
  yield call(addToActiveMeetings, { payload });
  if (actorView?.id !== model.id) return;
  yield put({ type: STOP_SOUND });
  // yield put({
  //   type: MAKE_SOUND,
  //   payload: { type: SOUND_TYPE.meetingJoin, category: SOUND_CATEGORY.meeting },
  //   settings: { loop: false },
  // });
  const callTo = structuredClone(actorView.callTo || {});
  delete callTo[user.id];
  yield put({
    type: UPDATE_ACTOR_VIEW.REQUEST,
    payload: { actorData: { id: actorView.id, callTo, activeMeeting } },
  });
  yield call(hideMeetingNotify, { payload: model });
  yield put({ type: ACCEPT_MEETING.SUCCESS });
}

/**
 * WS accept call
 */
function* wsMeetingAccept({ payload }) {
  const { user, model } = payload;
  const meeting = yield select((state) => state.meeting);
  const auth = yield select((state) => state.auth);
  if (user?.id !== auth?.id || meeting?.actor?.id !== model.id) return;
  yield put({ type: STOP_SOUND });
  yield put({ type: ACCEPT_MEETING.SUCCESS });
}

/**
 * WS reject call
 */
function* wsMeetingReject({ payload }) {
  const { user, model } = payload;
  const actorView = yield select((state) => state.actorView);
  const meeting = yield select((state) => state.meeting);
  const auth = yield select((state) => state.auth);
  if (user?.id === auth?.id && meeting?.actor?.id === model.id) {
    yield put({ type: STOP_SOUND });
    yield put({ type: REJECT_MEETING.SUCCESS });
  }
  if (actorView?.actorId !== model.id) return;
  const callTo = structuredClone(actorView.callTo || {});
  if (callTo[user.id]) callTo[user.id].status = CALL_STATUS.REJECT;
  yield put({
    type: UPDATE_ACTOR_VIEW.REQUEST,
    payload: { actorData: { id: actorView.id, callTo } },
  });
}

/**
 * Cleans up the `callTo` data in the actor view by updating the status of call entries that meet specific conditions.
 * Calls are updated to a rejected status if their current status is `CALL`
 * and the time elapsed is greater than 60 seconds.
 * The state is updated if there are changes made to `callTo`.
 */
function* cleanUpCallTo() {
  const actorView = yield select((state) => state.actorView);
  const callTo = structuredClone(actorView.callTo || {});
  const currentTime = DateUtils.unixtime();
  const newCallTo = {};
  let changed = false;
  Object.entries(callTo).forEach(([key, value]) => {
    if (
      value.status === CALL_STATUS.CALL &&
      currentTime - value.time > CALL_TO_LIMIT
    ) {
      newCallTo[key] = { ...value, status: CALL_STATUS.REJECT };
      changed = true;
    } else {
      newCallTo[key] = { ...value };
    }
  });
  const callToLen = Object.keys(callTo).length;
  const newCallToLen = Object.keys(newCallTo).length;
  if (callToLen !== newCallToLen || changed) {
    yield put({
      type: UPDATE_ACTOR_VIEW.REQUEST,
      payload: { actorData: { id: actorView.id, callTo: newCallTo } },
    });
  }
}

/**
 * Generator function that dispatches a sound action when a meeting is about to start.
 * It checks whether there is an active meeting and whether the start time of the meeting is in the future.
 * If the conditions are met, it triggers a sound indicating the meeting's start time.
 */
function* runSoundMeetingStarted() {
  const actorView = yield select((state) => state.actorView);
  const { id, activeMeeting, data } = actorView || {};
  const currentTime = DateUtils.unixtime();
  const started = activeMeeting?.soundStarted;
  const startDate = data?.startDate || -Infinity;
  const diffTime = currentTime - startDate;
  const timeToRun = diffTime > 0 && diffTime < SOUND_START_RANGE;
  if (!activeMeeting || started || !timeToRun) return;
  // yield put({
  //   type: MAKE_SOUND,
  //   payload: {
  //     type: SOUND_TYPE.meetingStartTime,
  //     category: SOUND_CATEGORY.meeting,
  //   },
  // });
  yield put({
    type: UPDATE_ACTOR_VIEW.REQUEST,
    payload: {
      actorData: {
        id,
        activeMeeting: { ...activeMeeting, soundStarted: true },
      },
    },
  });
}

/**
 * Generator function that periodically performs cleanup operations.
 */
function* periodicCleanUpCallToUsers() {
  while (true) {
    yield fork(cleanUpCallTo);
    yield delay(DELAY_CHECK);
  }
}

/**
 * A generator function that continuously forks the `runSoundMeetingStarted` process
 * and delays subsequent iterations by N seconds.
 */
function* soundMeetingStarted() {
  while (true) {
    yield fork(runSoundMeetingStarted);
    yield delay(DELAY_CHECK);
  }
}

/**
 * Handles WS event of updated meeting moderator
 */
function* wsMeetingActorUpdate({ payload: { model } }) {
  const actorView = yield select((state) => state.actorView);
  if (
    actorView?.id !== model.id ||
    !model.activeMeeting ||
    !model.data.moderator
  )
    return;
  const auth = yield select((state) => state.auth);
  const [moderator] = actorView.data.moderator || [];
  const [newModerator] = model.data.moderator || [];
  // if authorized user becomes moderator - show notification
  if (
    newModerator?.value === auth.id &&
    newModerator.value !== moderator?.value
  ) {
    yield put({ type: MEETING_MODERATOR_CHANGED });
  }
}

function* meeting() {
  yield takeEvery(JOIN_MEETING.REQUEST, joinMeeting);
  yield takeEvery(CREATE_MEETING.REQUEST, createMeeting);
  yield takeEvery(CALL_MEETING.REQUEST, callMeeting);
  yield takeEvery(ACCEPT_MEETING.REQUEST, acceptMeeting);
  yield takeEvery(REJECT_MEETING.REQUEST, rejectMeeting);
  yield takeEvery(GET_MEETING_PARTICIPANTS.REQUEST, getMeetingParticipants);
  yield takeEvery(
    MANAGE_MEETING_PARTICIPANTS.REQUEST,
    manageMeetingParticipants
  );
  yield takeEvery(RECORD_MEETING.REQUEST, recordingMeeting);
  yield takeEvery(WS_SIP_CALL, wsMeetingCall);
  yield takeEvery(WS_SIP_JOIN, wsMeetingJoin);
  yield takeEvery(WS_SIP_ACCEPT, wsMeetingAccept);
  yield takeEvery(WS_SIP_REJECT, wsMeetingReject);
  yield takeEvery(WS_UPDATE_ACTOR, wsMeetingActorUpdate);
  yield fork(periodicCleanUpCallToUsers);
  yield fork(soundMeetingStarted);
}

export default meeting;
