import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';
import { DescriptionEditor } from 'components';
import './Description.scss';

function Description(props) {
  const {
    refEdit,
    description,
    attendees,
    error,
    onChange,
    onUpload,
    attachments = [],
  } = props;
  const accId = AppUtils.getAccountId(history.location.pathname);

  function removeUnnecessaryAttachments(oldValue, newValue) {
    if (!attachments?.length) return;

    const oldIds = new Set(AppUtils.extractImgTags(oldValue));
    const newIds = new Set(AppUtils.extractImgTags(newValue));

    const deletedIds = new Set([...oldIds].filter((id) => !newIds.has(id)));
    if (!deletedIds.size) return;

    // Filtering of deleted attachments
    const newAttachments = attachments.filter(
      (attach) => !deletedIds.has(attach.fileName)
    );

    setTimeout(
      () => onChange({ id: 'attachments', value: newAttachments }),
      100
    );
  }

  return (
    <div id="eventEditor" styleName={cn('event__editor', { error })}>
      <DescriptionEditor
        refEdit={refEdit}
        accId={accId}
        description={description}
        access={attendees}
        onChange={(value) => {
          removeUnnecessaryAttachments(description, value);
          onChange({ id: 'description', value });
        }}
        onUpload={(data) => onUpload(data, true)}
      />
    </div>
  );
}

Description.propTypes = {
  description: PropTypes.string,
  attendees: PropTypes.array,
  error: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default Description;
