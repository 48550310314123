import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Stack, CorezoidLightTheme } from 'mw-style-react';

import { FOOTER_HEIGHT } from '../TransferItems';

function SwapButtons({ from, to, onSwap }) {
  const maxLength = Math.max(from.length, to.length);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${maxLength}, 1fr)`,
        height: `calc(100% - ${
          FOOTER_HEIGHT + CorezoidLightTheme.spaceSize.medium
        }px)`,
        gap: CorezoidLightTheme.spaceSize.medium,
      }}
    >
      {Array.from({ length: maxLength }).map((_, index) => (
        <Stack.V key={`swap-${index}`} size="xxsmall" justifyContent="center">
          <Button
            type="quinary"
            disabled={!from[index] || !to[index]}
            size="xxsmall"
            icon={<Icon type="transaction" size="large" />}
            onClick={() => onSwap(index)}
          />
        </Stack.V>
      ))}
    </div>
  );
}

SwapButtons.propTypes = {
  from: PropTypes.array.isRequired,
  to: PropTypes.array.isRequired,
  onSwap: PropTypes.func.isRequired,
};

export default SwapButtons;
