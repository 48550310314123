import {
  SYSTEM_LAYERS_NAMES,
  SYSTEM_SUBLAYERS,
} from '@control-front-end/common/constants/graphLayers';
import { Utils } from 'mw-style-react';

const makeSystemLayerTitle = (id) => {
  const underN = Utils.toUnderscoreCase(id).split('_');
  const title = underN.map((i) => Utils.capitalize(i)).join(' ');
  return Utils.toUpperLatter(title);
};

const findSystemLayerById = (layers, targetId) => {
  if (!layers) return null;
  for (const layer of layers) {
    if (layer.id === targetId) return layer;
    if (layer.sublayers.length > 0) {
      const result = findSystemLayerById(layer.sublayers, targetId);
      if (result) return result;
    }
  }
  return null;
};

// Make bread crumbs for using in layer header
const getSublayerBreadCrumbs = (layers, targetId, parentBreadcrumbs = []) => {
  for (const layer of layers) {
    const currentBreadcrumbs = [...parentBreadcrumbs, layer];

    if (layer.id === targetId) return currentBreadcrumbs;

    if (layer.sublayers.length > 0) {
      const result = getSublayerBreadCrumbs(
        layer.sublayers,
        targetId,
        currentBreadcrumbs
      );
      if (result) return result;
    }
  }
  return [];
};

const makeUniqueSublayers = (rootId, parentId, sublayers) =>
  sublayers.map((item) => {
    // make complex ID because some IDs (e.g. 'dashboard', 'access map') are not unique
    const id = `${parentId}_${item.id}`;
    return {
      ...item,
      name: item.id,
      originalId: item.id,
      id,
      rootId,
      isSystem: true,
      title: makeSystemLayerTitle(item.id),
      sublayers: makeUniqueSublayers(rootId, id, item.sublayers || []),
    };
  });

// Function to generate the full tree of system layers
const generateLayerTree = () =>
  Object.values(SYSTEM_LAYERS_NAMES).map((id) => ({
    id,
    name: id,
    originalId: id,
    rootId: id,
    isSystem: true,
    title: makeSystemLayerTitle(id),
    sublayers: makeUniqueSublayers(id, id, SYSTEM_SUBLAYERS[id] || []),
  }));

export default {
  findSystemLayerById,
  getSublayerBreadCrumbs,
  generateLayerTree,
};
