import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconImage(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(14.951, 15.073);
  ctx.lineTo(10, 8);
  ctx.lineTo(3, 18);
  ctx.lineTo(21, 18);
  ctx.lineTo(17, 12);
  ctx.lineTo(14.951, 15.073);
  ctx.closePath();
  ctx.moveTo(3, 0);
  ctx.lineTo(21, 0);
  ctx.bezierCurveTo(21.7956, 0, 22.5587, 0.31607, 23.1213, 0.87868);
  ctx.bezierCurveTo(23.6839, 1.44129, 24, 2.20435, 24, 3);
  ctx.lineTo(24, 21);
  ctx.bezierCurveTo(24, 21.7956, 23.6839, 22.5587, 23.1213, 23.1213);
  ctx.bezierCurveTo(22.5587, 23.6839, 21.7956, 24, 21, 24);
  ctx.lineTo(3, 24);
  ctx.bezierCurveTo(2.20435, 24, 1.44129, 23.6839, 0.87868, 23.1213);
  ctx.bezierCurveTo(0.31607, 22.5587, 0, 21.7956, 0, 21);
  ctx.lineTo(0, 3);
  ctx.bezierCurveTo(0, 2.20435, 0.31607, 1.44129, 0.87868, 0.87868);
  ctx.bezierCurveTo(1.44129, 0.31607, 2.20435, 0, 3, 0);
  ctx.closePath();
  ctx.moveTo(16, 10);
  ctx.bezierCurveTo(16.5304, 10, 17.0391, 9.78929, 17.4142, 9.41421);
  ctx.bezierCurveTo(17.7893, 9.03914, 18, 8.53043, 18, 8);
  ctx.bezierCurveTo(18, 7.46957, 17.7893, 6.96086, 17.4142, 6.58579);
  ctx.bezierCurveTo(17.0391, 6.21071, 16.5304, 6, 16, 6);
  ctx.bezierCurveTo(15.4696, 6, 14.9609, 6.21071, 14.5858, 6.58579);
  ctx.bezierCurveTo(14.2107, 6.96086, 14, 7.46957, 14, 8);
  ctx.bezierCurveTo(14, 8.53043, 14.2107, 9.03914, 14.5858, 9.41421);
  ctx.bezierCurveTo(14.9609, 9.78929, 15.4696, 10, 16, 10);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconImage;
