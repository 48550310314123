import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconEdgeActive(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(41, 7.80591);
  ctx.bezierCurveTo(22.553, 28.4749, 8.99988, 18.0961, 9, 43.1941);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconEdgeActive;
