import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorDrag(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.2, 1.2);
  ctx.translate(-1, 0);
  ctx.save();
  ctx.fillStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(6.67273, 4.8478);
  ctx.bezierCurveTo(7.15998, 4.66244, 8.12129, 4.77595, 8.37506, 5.34036);
  ctx.bezierCurveTo(8.59128, 5.82147, 8.77704, 6.63269, 8.7872, 6.45982);
  ctx.bezierCurveTo(8.81156, 6.07556, 8.76283, 5.24456, 8.92627, 4.81031);
  ctx.bezierCurveTo(9.04503, 4.49374, 9.27851, 4.19591, 9.62263, 4.09074);
  ctx.bezierCurveTo(9.91193, 4.00118, 10.252, 3.96994, 10.5525, 4.03346);
  ctx.bezierCurveTo(10.8702, 4.10011, 11.2042, 4.33233, 11.329, 4.5531);
  ctx.bezierCurveTo(11.6965, 5.20186, 11.7026, 6.53063, 11.7198, 6.45982);
  ctx.bezierCurveTo(11.7848, 6.17657, 11.7909, 5.18, 12.0071, 4.81031);
  ctx.bezierCurveTo(12.1502, 4.56559, 12.5116, 4.34691, 12.7045, 4.3115);
  ctx.bezierCurveTo(13.0029, 4.25735, 13.3704, 4.24069, 13.683, 4.30317);
  ctx.bezierCurveTo(13.9358, 4.3542, 14.2779, 4.6614, 14.3703, 4.81031);
  ctx.bezierCurveTo(14.5926, 5.16854, 14.7174, 6.18074, 14.755, 6.53688);
  ctx.bezierCurveTo(14.7712, 6.68371, 14.8301, 6.12763, 15.0524, 5.77044);
  ctx.bezierCurveTo(15.4646, 5.10502, 16.9243, 4.97589, 16.9791, 6.43587);
  ctx.bezierCurveTo(17.0055, 7.11692, 16.9994, 7.08568, 16.9994, 7.54387);
  ctx.bezierCurveTo(16.9994, 8.08122, 16.9872, 8.40612, 16.9588, 8.79559);
  ctx.bezierCurveTo(16.9283, 9.21109, 16.841, 10.1535, 16.7142, 10.6096);
  ctx.bezierCurveTo(16.6269, 10.9231, 16.3375, 11.6281, 16.0513, 12.0509);
  ctx.bezierCurveTo(16.0513, 12.0509, 14.9611, 13.3526, 14.8423, 13.9378);
  ctx.bezierCurveTo(14.7235, 14.5241, 14.7631, 14.5283, 14.7388, 14.9427);
  ctx.bezierCurveTo(14.7154, 15.3582, 14.8616, 15.9039, 14.8616, 15.9039);
  ctx.bezierCurveTo(14.8616, 15.9039, 14.0485, 16.0122, 13.6089, 15.9393);
  ctx.bezierCurveTo(13.212, 15.8747, 12.7207, 15.0646, 12.5938, 14.8167);
  ctx.bezierCurveTo(12.4192, 14.4751, 12.0467, 14.5408, 11.9015, 14.7928);
  ctx.bezierCurveTo(11.6742, 15.1916, 11.1818, 15.907, 10.8357, 15.9518);
  ctx.bezierCurveTo(10.1566, 16.0393, 8.74964, 15.983, 7.64825, 15.9726);
  ctx.bezierCurveTo(7.64825, 15.9726, 7.83604, 14.9198, 7.41782, 14.5585);
  ctx.bezierCurveTo(7.10821, 14.2877, 6.57528, 13.742, 6.25654, 13.4546);
  ctx.lineTo(5.41197, 12.4955);
  ctx.bezierCurveTo(5.1247, 12.1206, 4.39484, 11.5281, 4.1502, 10.4284);
  ctx.bezierCurveTo(3.93398, 9.45372, 3.9553, 8.97574, 4.18776, 8.58523);
  ctx.bezierCurveTo(4.42326, 8.18848, 4.86788, 7.97187, 5.05466, 7.93438);
  ctx.bezierCurveTo(5.2658, 7.89065, 5.75711, 7.89377, 5.94287, 7.99895);
  ctx.bezierCurveTo(6.16924, 8.12704, 6.2606, 8.16452, 6.43824, 8.40612);
  ctx.bezierCurveTo(6.67172, 8.72582, 6.75496, 8.88098, 6.65446, 8.53212);
  ctx.bezierCurveTo(6.57731, 8.25929, 6.3276, 7.91252, 6.21391, 7.52201);
  ctx.bezierCurveTo(6.10326, 7.14608, 5.80685, 6.54001, 5.82817, 5.9329);
  ctx.bezierCurveTo(5.83629, 5.70276, 5.93272, 5.13001, 6.67273, 4.8478);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.black;
  ctx.lineWidth = 0.75;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.beginPath();
  ctx.moveTo(6.67273, 4.8478);
  ctx.bezierCurveTo(7.15998, 4.66244, 8.12129, 4.77595, 8.37506, 5.34036);
  ctx.bezierCurveTo(8.59128, 5.82147, 8.77704, 6.63269, 8.7872, 6.45982);
  ctx.bezierCurveTo(8.81156, 6.07556, 8.76283, 5.24456, 8.92627, 4.81031);
  ctx.bezierCurveTo(9.04503, 4.49374, 9.27851, 4.19591, 9.62263, 4.09074);
  ctx.bezierCurveTo(9.91193, 4.00118, 10.252, 3.96994, 10.5525, 4.03346);
  ctx.bezierCurveTo(10.8702, 4.10011, 11.2042, 4.33233, 11.329, 4.5531);
  ctx.bezierCurveTo(11.6965, 5.20186, 11.7026, 6.53063, 11.7198, 6.45982);
  ctx.bezierCurveTo(11.7848, 6.17657, 11.7909, 5.18, 12.0071, 4.81031);
  ctx.bezierCurveTo(12.1502, 4.56559, 12.5116, 4.34691, 12.7045, 4.3115);
  ctx.bezierCurveTo(13.0029, 4.25735, 13.3704, 4.24069, 13.683, 4.30317);
  ctx.bezierCurveTo(13.9358, 4.3542, 14.2779, 4.6614, 14.3703, 4.81031);
  ctx.bezierCurveTo(14.5926, 5.16854, 14.7174, 6.18074, 14.755, 6.53688);
  ctx.bezierCurveTo(14.7712, 6.68371, 14.8301, 6.12763, 15.0524, 5.77044);
  ctx.bezierCurveTo(15.4646, 5.10502, 16.9243, 4.97589, 16.9791, 6.43587);
  ctx.bezierCurveTo(17.0055, 7.11692, 16.9994, 7.08568, 16.9994, 7.54387);
  ctx.bezierCurveTo(16.9994, 8.08122, 16.9872, 8.40612, 16.9588, 8.79559);
  ctx.bezierCurveTo(16.9283, 9.21109, 16.841, 10.1535, 16.7142, 10.6096);
  ctx.bezierCurveTo(16.6269, 10.9231, 16.3375, 11.6281, 16.0513, 12.0509);
  ctx.bezierCurveTo(16.0513, 12.0509, 14.9611, 13.3526, 14.8423, 13.9378);
  ctx.bezierCurveTo(14.7235, 14.5241, 14.7631, 14.5283, 14.7388, 14.9427);
  ctx.bezierCurveTo(14.7154, 15.3582, 14.8616, 15.9039, 14.8616, 15.9039);
  ctx.bezierCurveTo(14.8616, 15.9039, 14.0485, 16.0122, 13.6089, 15.9393);
  ctx.bezierCurveTo(13.212, 15.8747, 12.7207, 15.0646, 12.5938, 14.8167);
  ctx.bezierCurveTo(12.4192, 14.4751, 12.0467, 14.5408, 11.9015, 14.7928);
  ctx.bezierCurveTo(11.6742, 15.1916, 11.1818, 15.907, 10.8357, 15.9518);
  ctx.bezierCurveTo(10.1566, 16.0393, 8.74964, 15.983, 7.64825, 15.9726);
  ctx.bezierCurveTo(7.64825, 15.9726, 7.83604, 14.9198, 7.41782, 14.5585);
  ctx.bezierCurveTo(7.10821, 14.2877, 6.57528, 13.742, 6.25654, 13.4546);
  ctx.lineTo(5.41197, 12.4955);
  ctx.bezierCurveTo(5.1247, 12.1206, 4.39484, 11.5281, 4.1502, 10.4284);
  ctx.bezierCurveTo(3.93398, 9.45372, 3.9553, 8.97574, 4.18776, 8.58523);
  ctx.bezierCurveTo(4.42326, 8.18848, 4.86788, 7.97187, 5.05466, 7.93438);
  ctx.bezierCurveTo(5.2658, 7.89065, 5.75711, 7.89377, 5.94287, 7.99895);
  ctx.bezierCurveTo(6.16924, 8.12704, 6.2606, 8.16452, 6.43824, 8.40612);
  ctx.bezierCurveTo(6.67172, 8.72582, 6.75496, 8.88098, 6.65446, 8.53212);
  ctx.bezierCurveTo(6.57731, 8.25929, 6.3276, 7.91252, 6.21391, 7.52201);
  ctx.bezierCurveTo(6.10326, 7.14608, 5.80685, 6.54001, 5.82817, 5.9329);
  ctx.bezierCurveTo(5.83629, 5.70276, 5.93272, 5.13001, 6.67273, 4.8478);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'black';
  ctx.font = "   15px ''";
  ctx.beginPath();
  ctx.moveTo(10, 9.5);
  ctx.bezierCurveTo(10, 9.22386, 9.77614, 9, 9.5, 9);
  ctx.bezierCurveTo(9.22386, 9, 9, 9.22386, 9, 9.5);
  ctx.lineTo(9, 12.5);
  ctx.bezierCurveTo(9, 12.7761, 9.22386, 13, 9.5, 13);
  ctx.bezierCurveTo(9.77614, 13, 10, 12.7761, 10, 12.5);
  ctx.lineTo(10, 9.5);
  ctx.closePath();
  ctx.moveTo(11.5, 9);
  ctx.bezierCurveTo(11.7761, 9, 12, 9.22386, 12, 9.5);
  ctx.lineTo(12, 12.5);
  ctx.bezierCurveTo(12, 12.7761, 11.7761, 13, 11.5, 13);
  ctx.bezierCurveTo(11.2239, 13, 11, 12.7761, 11, 12.5);
  ctx.lineTo(11, 9.5);
  ctx.bezierCurveTo(11, 9.22386, 11.2239, 9, 11.5, 9);
  ctx.closePath();
  ctx.moveTo(13.5, 9);
  ctx.bezierCurveTo(13.7761, 9, 14, 9.22386, 14, 9.5);
  ctx.lineTo(14, 12.5);
  ctx.bezierCurveTo(14, 12.7761, 13.7761, 13, 13.5, 13);
  ctx.bezierCurveTo(13.2239, 13, 13, 12.7761, 13, 12.5);
  ctx.lineTo(13, 9.5);
  ctx.bezierCurveTo(13, 9.22386, 13.2239, 9, 13.5, 9);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorDrag;
