import { Utils } from 'mw-style-react';

export const GET_STREAMS = Utils.createRequestTypes('GET_STREAMS');
export const GET_STREAM_ACTORS = Utils.createRequestTypes('GET_STREAM_ACTORS');
export const ADD_STREAM = Utils.createRequestTypes('ADD_STREAM');
export const HIDE_STREAM = Utils.createRequestTypes('HIDE_STREAM');
export const UPDATE_STREAM = Utils.createRequestTypes('UPDATE_STREAM');
export const STREAM_ACCESS = Utils.createRequestTypes('STREAM_ACCESS');
export const READ_ALL_STREAM = Utils.createRequestTypes('READ_ALL_STREAM');
export const GET_STREAMS_UNREAD_COUNTERS = Utils.createRequestTypes(
  'GET_STREAMS_UNREAD_COUNTERS'
);
export const GET_EVENTS_BY_STREAM = 'GET_EVENTS_BY_STREAM';

export const CLEAR_STREAMS = 'CLEAR_STREAMS';
export const SET_ACTIVE_STREAM = 'SET_ACTIVE_STREAM';

/**
 * Available event streams
 */
export const STREAM = {
  all: 'all',
  sign: 'sign',
  execute: 'execute',
  starred: 'starred',
  live: 'live',
};

/**
 * Event streams that have direct relation to the privs to this events
 */
export const STREAM_BY_PRIVS = {
  execute: STREAM.execute,
  sign: STREAM.sign,
};

export const SYSTEM_STREAMS = [
  {
    id: STREAM.all,
    title: 'All',
    isSystem: 'true',
    color: '#1973E8',
    disabled: true,
    data: {
      orderBy: [{ value: 'updated_at' }],
      orderValue: [{ value: 'DESC' }],
    },
  },
];

export const ADDITIONAL_SYSTEM_STREAMS = [
  {
    id: STREAM.sign,
    title: 'To Sign',
    isSystem: 'true',
    color: '#F8AB03',
    data: {
      orderBy: [{ value: 'updated_at' }],
      orderValue: [{ value: 'DESC' }],
    },
  },
  {
    id: STREAM.execute,
    title: 'To Do',
    isSystem: 'true',
    color: '#00B754',
    data: {
      orderBy: [{ value: 'updated_at' }],
      orderValue: [{ value: 'DESC' }],
    },
  },
  {
    id: STREAM.starred,
    title: 'Starred',
    isSystem: 'true',
    color: '#FC8D6A',
    data: {
      orderBy: [{ value: 'updated_at' }],
      orderValue: [{ value: 'DESC' }],
    },
  },
  {
    id: STREAM.live,
    title: 'Live',
    isSystem: 'true',
    color: '#D41E06',
    icon: 'live_stream',
    data: {
      orderBy: [{ value: 'updated_at' }],
      orderValue: [{ value: 'DESC' }],
    },
  },
];

export const LIVE_STREAM_QUERY_FILTER = 'isActiveMeeting=true';
