import {
  call,
  takeLatest,
  select,
  put,
  putResolve,
  takeEvery,
  take,
  actionChannel,
  delay,
} from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  STREAM,
  SYSTEM_STREAMS,
  ADDITIONAL_SYSTEM_STREAMS,
  GET_STREAMS,
  GET_STREAM_ACTORS,
  GET_EVENTS_BY_STREAM,
  SET_ACTIVE_STREAM,
  ADD_STREAM,
  HIDE_STREAM,
  UPDATE_STREAM,
  GET_STREAMS_UNREAD_COUNTERS,
  READ_ALL_STREAM,
  STREAM_ACCESS,
  LIVE_STREAM_QUERY_FILTER,
} from '@control-front-end/common/constants/streams';
import AppUtils from '@control-front-end/utils/utils';
import { DateUtils } from 'mw-style-react';
import {
  CLEAR_LIST_ACTORS,
  SET_ACTORS_REQ_STATUS,
  WS_UPDATE_ACTOR,
  GET_ACTORS_LIST,
} from '@control-front-end/common/constants/graphActors';
import {
  ACCOUNT_ROLE_ACTION,
  RequestStatus,
  UPDATE_USER_SETTINGS,
  WS_DELETE_ACCESS,
} from 'constants';
import { getActiveMeetings } from '@control-front-end/common/sagas/meeting';
import { groupActorsByTitle } from './actorsFilters';
import { extendModelWithAvatars } from './graph/graphHelpers';
import { getActor } from './graph/graphNodes';
import { checkUserAccess } from './graph/graphRealtime';

/**
 * Получить временную метку до которой все события потока являются прочитанными
 */
function* getStreamReadAllTimePoint(streamId) {
  const settings = yield select((state) => state.settings);
  const workspaces = yield select((state) => state.accounts);
  const allSystemStreams = SYSTEM_STREAMS.concat(ADDITIONAL_SYSTEM_STREAMS);
  const readAllKeyId = allSystemStreams.find((i) => i.id === streamId)
    ? `${workspaces.active}:${streamId}`
    : streamId;
  const readAllKey = `readAllStream_${readAllKeyId}`;
  return settings[readAllKey];
}

/**
 * Получить список потоков
 */
function* getStreams({ payload, callback }) {
  const { loadMore, starred, localState } = payload;
  let streamsState;
  if (localState) {
    streamsState = localState;
  } else {
    streamsState = yield select((state) => state.streams);
  }
  const systemForms = yield select((state) => state.systemForms);
  const { systemStreamsSettings } = yield select((state) => state.settings);
  const formId = systemForms.streams.id;
  const { limit, offset, endList } = streamsState;
  if (endList) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams: {
      limit,
      offset,
      orderBy: 'created_at',
      orderValue: 'ASC',
      starred,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list, total } = data.data;
  const selectedSystemStreams = [...SYSTEM_STREAMS];
  ADDITIONAL_SYSTEM_STREAMS.forEach((stream) => {
    if (stream.id === STREAM.live && !systemStreamsSettings?.[stream.id])
      return;
    if (!systemStreamsSettings || !!systemStreamsSettings[stream.id]) {
      selectedSystemStreams.push(stream);
    }
  });
  const newActorsList = loadMore
    ? structuredClone(streamsState.list)
    : selectedSystemStreams.slice();
  newActorsList.push(...list);
  const systemCount = newActorsList.filter((i) => i.isSystem).length;
  const newPayload = {
    list: newActorsList,
    limit,
    offset: offset + limit,
    endList: newActorsList.length - systemCount === total,
    total,
    init: true,
  };
  if (localState) {
    callback(newPayload);
    return;
  }
  yield put({
    type: GET_STREAMS.SUCCESS,
    payload: newPayload,
  });
  yield put({
    type: GET_STREAMS_UNREAD_COUNTERS.REQUEST,
    payload: { list: data.data.list.map((i) => i.id) },
  });
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Получить акторы потока
 */
export function* getStreamsActors({ payload, callback }) {
  const {
    streamId,
    loadMore,
    starred,
    from,
    to,
    ownerIds,
    userId,
    localState,
    limit: payloadLimit,
    offset: payloadOffset,
    userRole = false,
  } = payload;
  let { q, withStats } = payload;
  const actorsState = yield select((state) => state.actorsList);
  const systemForms = yield select((state) => state.systemForms);
  const streams = yield select((state) => state.streams);
  const eventsFormId = systemForms.events.id;
  if (!eventsFormId || !streams.list.length) return;
  const allSystemStreams = SYSTEM_STREAMS.concat(ADDITIONAL_SYSTEM_STREAMS);
  let activeStream = streams.list
    .concat(allSystemStreams)
    .find((i) => i.id === streamId);
  const systemStreamsId = allSystemStreams.map((i) => i.id);
  // Добавить в список stream по прямой ссылке
  if (!localState && !activeStream && !systemStreamsId.includes(streamId)) {
    activeStream = yield getActor({ payload: { id: streamId } });
    const copyList = streams.list.slice();
    copyList.push(activeStream);
    yield put({
      type: ADD_STREAM.SUCCESS,
      payload: {
        list: copyList,
        offset: streams.offset + 1,
        total: streams.total + 1,
      },
    });
  }
  const { reqStatus, list, offset: stateOffset, endList } = actorsState;
  const limit = payloadLimit || 20;
  if (reqStatus === RequestStatus.PROGRESS || endList) return;
  if (!localState) {
    // Ставим флаг прогресса загрузки
    yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.PROGRESS });
    yield put({ type: SET_ACTIVE_STREAM, payload: streamId });
  }
  const { orderBy, orderValue } = activeStream.data;
  const edgeType = 'hierarchy';
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === edgeType);
  const filter = localState
    ? {}
    : {
        lastReaction: true,
        reactionsCount: true,
        reactionsStats: true,
      };
  if (AppUtils.isNumeric(from) && AppUtils.isNumeric(to)) {
    filter.from = from / 1000;
    filter.to = to / 1000;
  }
  if (!AppUtils.isUndefined(ownerIds)) filter.ownerIds = ownerIds;
  const streamFilters = ['nameId', 'currencyId', 'incomeType', 'accountType'];
  streamFilters.forEach((fKey) => {
    const fVal = activeStream.data[fKey] || [];
    if (fVal[0]) filter[fKey] = fVal[0].value;
  });
  let streamProperties = {};

  switch (streamId) {
    case STREAM.all:
      if (userRole)
        streamProperties = { reactionName: ACCOUNT_ROLE_ACTION.VIEW };
      break;
    case STREAM.starred:
      streamProperties = {
        starred: true,
      };
      break;
    case STREAM.sign:
    case STREAM.execute:
      streamProperties = { reactionName: streamId };
      break;
    case STREAM.live:
      q = [q, LIVE_STREAM_QUERY_FILTER].filter(Boolean).join('&');
      withStats = true;
      break;
    default:
      streamProperties = { linkedToActorId: streamId };
  }
  const formId = payload.formId || eventsFormId;
  const offset = AppUtils.isUndefined(payloadOffset)
    ? stateOffset
    : payloadOffset;
  const queryParams = {
    edgeTypeId: edgeTypeH ? edgeTypeH.id : undefined,
    formId,
    limit,
    offset,
    orderBy: orderBy[0].value,
    orderValue: orderValue[0].value,
    starred,
    ...streamProperties,
    withStats,
    q,
    userId,
    ...filter,
  };
  const reqTime = new Date().getTime();
  const res = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams,
  });

  const { result, data } = res;
  // Ставим флаг окончания загрузки
  yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.SUCCESS });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data.list) {
    yield call(extendModelWithAvatars, obj);
  }
  if (localState && callback) {
    callback(data.data);
    return;
  }
  const readAllTimePoint = yield getStreamReadAllTimePoint(streamId);
  const newActorsList = loadMore ? structuredClone(list) : [];
  newActorsList.push(...data.data.list);
  newActorsList.forEach((i) => {
    i.actorId = i.id;
    if (readAllTimePoint && i.createdAt <= readAllTimePoint) i.unread = false;
  });
  groupActorsByTitle(newActorsList);
  const total = data.data.total;
  // Отсекаем ответ на пред. запрос
  const { reqTime: lastReqTime } = yield select((state) => state.actorsList);
  if (lastReqTime && reqTime < lastReqTime) return;
  yield put({
    type: GET_ACTORS_LIST.SUCCESS,
    payload: {
      list: newActorsList,
      limit,
      offset: offset + limit,
      endList: data.data.list.length < limit,
      total,
      formId: +eventsFormId,
      filter,
      reqTime,
      orderBy: orderBy[0].value,
      orderValue: orderValue[0].value,
    },
  });
  if (streamId === STREAM.live) {
    const meetingsList = newActorsList.map(({ id }) => ({
      name: id,
    }));
    yield call(getActiveMeetings, {
      payload: { list: meetingsList, total, loadMore },
    });
  }
}

export function* getEventsByStream({ payload, callback }) {
  const { streamId, q, limit = 20 } = payload;
  const formId = yield select((state) => state.systemForms.events.id);
  if (!formId) return;

  const streamsList = yield select((state) => state.streams.list);
  const streamsListClone = structuredClone(streamsList);
  const actorsList = yield select((state) => state.actorsList);
  const actorsListClone = structuredClone(actorsList);

  const edgeType = 'hierarchy';
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === edgeType);

  const res = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams: {
      edgeTypeId: edgeTypeH ? edgeTypeH.id : undefined,
      formId,
      limit,
      offset: 0,
      ...(streamId ? { linkedToActorId: streamId } : {}),
      withStats: false,
      q,
    },
  });

  const { result, data } = res;
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data.list) {
    yield call(extendModelWithAvatars, obj);
  }

  yield put({
    type: GET_STREAMS.SUCCESS,
    payload: {
      active: streamId || STREAM.all,
      list: streamId
        ? [...streamsListClone, { id: streamId }]
        : streamsListClone,
    },
  });

  yield put({
    type: GET_ACTORS_LIST.SUCCESS,
    payload: {
      ...actorsListClone,
      formId,
      list: data.data.list,
    },
  });

  callback?.(data.data.list);
}

/**
 * Добавить поток в активный список
 */
function* addStream({ payload, callback }) {
  const streams = yield select((state) => state.streams);
  const systemForms = yield select((state) => state.systemForms);
  if (systemForms.streams.id !== payload.formId && !payload.isSystem) return;
  const copyList = structuredClone(streams.list);
  const index = payload.isSystem
    ? copyList.findIndex((stream) => !stream.isSystem)
    : copyList.length;
  if (payload.id === STREAM.sign) {
    copyList.splice(1, 0, payload);
  } else {
    copyList.splice(index !== -1 ? index : copyList.length, 0, payload);
  }
  yield put({
    type: ADD_STREAM.SUCCESS,
    payload: {
      list: copyList,
      offset: streams.offset + 1,
      total: streams.total + 1,
    },
  });
  const customStreams = copyList.filter((i) => !i.isSystem);
  yield put({
    type: GET_STREAMS_UNREAD_COUNTERS.REQUEST,
    payload: { list: customStreams.map((i) => i.id) },
  });
  if (callback) callback(payload.id);
}

/**
 * Убрать из активных поток
 */
function* hideStream({ payload, callback }) {
  const streams = yield select((state) => state.streams);
  const copyList = streams.list.filter((i) => i.id !== payload.id);
  yield put({
    type: HIDE_STREAM.SUCCESS,
    payload: {
      list: copyList,
      offset: streams.offset - 1 < 0 ? 0 : streams.offset - 1,
    },
  });
  if (callback) callback(payload.id);
}

/**
 * Изменение потока по WS
 */
function* wsUpdateStream({ payload }) {
  const { model: updatedStream } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  if (updatedStream.accId !== accId) return;
  const { list, active } = yield select((state) => state.streams);
  const findIndex = list.findIndex((i) => i.id === updatedStream.id);
  if (findIndex === -1) return;
  const copyList = structuredClone(list);
  const stream = { ...copyList[findIndex], ...updatedStream };
  copyList.splice(findIndex, 1, stream);
  yield put({
    type: UPDATE_STREAM.SUCCESS,
    payload: { list: copyList },
  });
  // перезагрузить список событий активного потока
  if (updatedStream.id === active) {
    yield put({ type: CLEAR_LIST_ACTORS });
    yield getStreamsActors({
      payload: { streamId: payload.model.id, loadMore: false, starred: true },
    });
  }
}

/**
 * Remove stream if no access (WS event)
 */
function* wsRemoveNoAccessStream({ payload }) {
  const { model, objType } = payload;
  if (objType !== 'actor') return;
  const { active: accId } = yield select((state) => state.accounts);
  if (model.accId !== accId) return;
  const { list, active } = yield select((state) => state.streams);
  const access = model.access.concat(payload.model.defaultAccess);
  const hasAccess = yield call(checkUserAccess, access);
  if (hasAccess) return;
  const findIndex = list.findIndex((i) => i.id === model.id);
  if (findIndex === -1) return;
  if (model.id === active) {
    yield put({ type: CLEAR_LIST_ACTORS });
    yield getStreamsActors({
      payload: { streamId: 'all', loadMore: false },
    });
  }
  yield put({ type: HIDE_STREAM.REQUEST, payload: { id: model.id } });
}

/**
 * Изменить права доступа на поток
 */
function* manageStreamAccess({ payload }) {
  const { id, access } = payload;
  const streams = yield select((state) => state.streams);
  const copyList = structuredClone(streams.list);
  const streamActor = copyList.find((i) => i.id === id);
  if (!streamActor) return;
  streamActor.access = access;
  yield put({
    type: STREAM_ACCESS.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Получить счетчики непрочитанных акторов в потоке
 */
function* getUnreadCounters({ payload = {} }) {
  const streams = yield select((state) => state.streams);
  const workspaces = yield select((state) => state.accounts);
  const activeW = workspaces.active;
  const prefixSysStream = `_${activeW}`;
  const listStreams =
    payload.list ||
    streams.list.map((i) => (i.isSystem ? null : i.id)).filter((i) => !!i);
  if (!payload.noSystem)
    listStreams.unshift(`sign${prefixSysStream}`, `execute${prefixSysStream}`);
  if (!listStreams.length) return;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/streams_unread/${activeW}`,
    body: { streams: listStreams },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const counters = {};
  for (const i of data.data) {
    counters[i.streamId.replace(prefixSysStream, '')] = i.count;
  }
  const copyList = streams.list;
  const list = copyList.map((i) => ({
    ...i,
    unreadCounter: AppUtils.isUndefined(counters[i.id])
      ? i.unreadCounter
      : counters[i.id],
  }));
  yield putResolve({
    type: GET_STREAMS_UNREAD_COUNTERS.SUCCESS,
    payload: { list },
  });
  yield delay(10);
}

/**
 * Создать очередь запросов для получения счетчиков потоков
 */
function* unreadCountersQueue() {
  const requestChan = yield actionChannel(GET_STREAMS_UNREAD_COUNTERS.REQUEST);
  while (true) {
    try {
      const { payload } = yield take(requestChan);
      yield call(getUnreadCounters, { payload });
    } catch (e) {
      console.error(e); // eslint-disable-line
      break;
    }
  }
}

/**
 * Отметить как readAll поток
 */
function* readAllStream() {
  const streams = yield select((state) => state.streams);
  const workspaces = yield select((state) => state.accounts);
  const actorsList = yield select((state) => state.actorsList);
  if (streams.active === STREAM.all) return;
  const actorId = streams.active;
  const copyList = structuredClone(streams.list);
  const activeStreamIndex = copyList.findIndex((i) => i.id === streams.active);
  const activeStream = streams.list[activeStreamIndex];
  const stream = activeStream?.isSystem
    ? `${actorId}_${workspaces.active}`
    : actorId;
  const { result } = yield call(api, {
    method: 'post',
    url: `/accounts/streams_readall/${workspaces.active}`,
    body: { streams: [stream] },
  });
  if (result !== RequestStatus.SUCCESS) return;
  copyList.splice(activeStreamIndex, 1, { ...activeStream, unreadCounter: 0 });
  yield put({
    type: READ_ALL_STREAM.SUCCESS,
    payload: { list: copyList },
  });
  const copyStreamActors = structuredClone(actorsList.list);
  copyStreamActors.forEach((i) => (i.unread = false));
  yield put({
    type: GET_STREAM_ACTORS.SUCCESS,
    payload: { list: copyStreamActors },
  });
  yield put({
    type: UPDATE_USER_SETTINGS.SUCCESS,
    payload: { [`readAllStream_${actorId}`]: DateUtils.unixtime() },
  });
}

function* streamsSaga() {
  yield takeLatest(GET_STREAMS.REQUEST, getStreams);
  yield takeEvery(GET_STREAM_ACTORS.REQUEST, getStreamsActors);
  yield takeLatest(ADD_STREAM.REQUEST, addStream);
  yield takeLatest(HIDE_STREAM.REQUEST, hideStream);
  yield takeLatest(STREAM_ACCESS.REQUEST, manageStreamAccess);
  yield takeLatest(READ_ALL_STREAM.REQUEST, readAllStream);
  yield takeEvery(WS_UPDATE_ACTOR, wsUpdateStream);
  yield takeEvery(WS_DELETE_ACCESS, wsRemoveNoAccessStream);
  yield takeEvery(GET_EVENTS_BY_STREAM, getEventsByStream);
  yield unreadCountersQueue();
}

export default streamsSaga;
