import { createReducer } from '@reduxjs/toolkit';
import {
  ACCEPT_MEETING,
  INIT_MEETING,
  JOIN_MEETING,
  REJECT_MEETING,
} from '../constants/meeting';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = null;

export default createReducer(initState, {
  [INIT_MEETING.SUCCESS](state, action) {
    return action.payload;
  },
  [JOIN_MEETING.SUCCESS]() {
    return initState;
  },
  [ACCEPT_MEETING.SUCCESS]() {
    return initState;
  },
  [REJECT_MEETING.SUCCESS]() {
    return initState;
  },
});
