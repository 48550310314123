import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorResize(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.5, 1.5);
  ctx.translate(0, -2);
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(7.18082, 13.0448);
  ctx.lineTo(11.3277, 13.6372);
  ctx.bezierCurveTo(11.7424, 13.6964, 12.0979, 13.341, 12.0386, 12.9263);
  ctx.lineTo(11.4462, 8.77937);
  ctx.bezierCurveTo(11.373, 8.26678, 10.746, 8.05779, 10.3799, 8.42392);
  ctx.lineTo(9.49125, 9.31255);
  ctx.lineTo(5.49242, 5.31373);
  ctx.lineTo(3.71517, 7.09098);
  ctx.lineTo(7.71399, 11.0898);
  ctx.lineTo(6.82537, 11.9784);
  ctx.bezierCurveTo(6.45923, 12.3446, 6.66823, 12.9716, 7.18082, 13.0448);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(7.00688, 11.0898);
  ctx.lineTo(6.47181, 11.6249);
  ctx.bezierCurveTo(5.81433, 12.2824, 6.18964, 13.4083, 7.1101, 13.5398);
  ctx.lineTo(11.257, 14.1322);
  ctx.bezierCurveTo(12.0017, 14.2386, 12.64, 13.6003, 12.5336, 12.8556);
  ctx.lineTo(11.9412, 8.70866);
  ctx.bezierCurveTo(11.8097, 7.78819, 10.6838, 7.41289, 10.0263, 8.07037);
  ctx.lineTo(9.49125, 8.60544);
  ctx.lineTo(5.84598, 4.96017);
  ctx.lineTo(5.49242, 4.60662);
  ctx.lineTo(5.13887, 4.96017);
  ctx.lineTo(3.36162, 6.73743);
  ctx.lineTo(3.00806, 7.09098);
  ctx.lineTo(3.36162, 7.44453);
  ctx.lineTo(7.00688, 11.0898);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorResize;
