import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorNoEdit(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0, 1.5);
  ctx.translate(-3.7, 0);
  ctx.save();
  ctx.fillStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(7.20556, 14.4731);
  ctx.bezierCurveTo(6.92768, 14.0969, 6.59012, 13.3277, 5.98935, 12.394);
  ctx.bezierCurveTo(5.64884, 11.8658, 4.80444, 10.8713, 4.55298, 10.3662);
  ctx.bezierCurveTo(4.33478, 9.91979, 4.35827, 9.71964, 4.41012, 9.34972);
  ctx.bezierCurveTo(4.5021, 8.69162, 5.13222, 8.17918, 5.80442, 8.24834);
  ctx.bezierCurveTo(6.31224, 8.29969, 6.74276, 8.65913, 7.13022, 8.99866);
  ctx.bezierCurveTo(7.36407, 9.20301, 7.65174, 9.60017, 7.82492, 9.82443);
  ctx.bezierCurveTo(7.98441, 10.0298, 8.02355, 10.1147, 8.1938, 10.3578);
  ctx.bezierCurveTo(8.41884, 10.6795, 8.48929, 10.8388, 8.40319, 10.4846);
  ctx.bezierCurveTo(8.33372, 9.96486, 8.22022, 9.07726, 8.05584, 8.29235);
  ctx.bezierCurveTo(7.9306, 7.69713, 7.90026, 7.60386, 7.78089, 7.14696);
  ctx.bezierCurveTo(7.65467, 6.66072, 7.5901, 6.32014, 7.4717, 5.80456);
  ctx.bezierCurveTo(7.38951, 5.43988, 7.24177, 4.6948, 7.20165, 4.27562);
  ctx.bezierCurveTo(7.14588, 3.7024, 7.11652, 2.76765, 7.45996, 2.33799);
  ctx.bezierCurveTo(7.72904, 2.00161, 8.34644, 1.89996, 8.72901, 2.10745);
  ctx.bezierCurveTo(9.22998, 2.37886, 9.51471, 3.15852, 9.64485, 3.46976);
  ctx.bezierCurveTo(9.8787, 4.02936, 10.0235, 4.67593, 10.1497, 5.52476);
  ctx.bezierCurveTo(10.3102, 6.60518, 10.6057, 8.10477, 10.6155, 8.4202);
  ctx.bezierCurveTo(10.639, 8.03351, 10.5489, 7.21927, 10.6116, 6.8483);
  ctx.bezierCurveTo(10.6683, 6.51191, 10.9325, 6.12103, 11.2632, 6.01519);
  ctx.bezierCurveTo(11.543, 5.92612, 11.8708, 5.89363, 12.1595, 5.95756);
  ctx.bezierCurveTo(12.4657, 6.02462, 12.7886, 6.25936, 12.909, 6.48048);
  ctx.bezierCurveTo(13.2632, 7.13439, 13.27, 8.4705, 13.2847, 8.39924);
  ctx.bezierCurveTo(13.3688, 8.00522, 13.3542, 7.11133, 13.5626, 6.73931);
  ctx.bezierCurveTo(13.6996, 6.4941, 14.0489, 6.27298, 14.2348, 6.23735);
  ctx.bezierCurveTo(14.5224, 6.18286, 14.8757, 6.1661, 15.178, 6.22897);
  ctx.bezierCurveTo(15.4216, 6.28032, 15.7514, 6.59051, 15.8404, 6.73931);
  ctx.bezierCurveTo(16.0537, 7.0998, 16.175, 8.11944, 16.2112, 8.47679);
  ctx.bezierCurveTo(16.2259, 8.62455, 16.2836, 8.066, 16.4979, 7.70551);
  ctx.bezierCurveTo(16.8952, 7.03588, 18.3012, 6.90594, 18.355, 8.37514);
  ctx.bezierCurveTo(18.3795, 9.06049, 18.3746, 9.02905, 18.3746, 9.49014);
  ctx.bezierCurveTo(18.3746, 10.0319, 18.3629, 10.3578, 18.3355, 10.7498);
  ctx.bezierCurveTo(18.3051, 11.1689, 18.221, 12.1163, 18.0987, 12.5753);
  ctx.bezierCurveTo(18.0145, 12.8907, 17.7357, 13.6001, 17.4607, 14.0256);
  ctx.bezierCurveTo(17.4607, 14.0256, 16.4099, 15.3355, 16.2954, 15.9255);
  ctx.bezierCurveTo(16.1799, 16.5144, 16.2181, 16.5186, 16.1956, 16.9368);
  ctx.bezierCurveTo(16.1731, 17.3538, 16.314, 17.903, 16.314, 17.903);
  ctx.bezierCurveTo(16.314, 17.903, 15.5293, 18.0119, 15.1066, 17.9396);
  ctx.bezierCurveTo(14.724, 17.8736, 14.2504, 17.0583, 14.1281, 16.8089);
  ctx.bezierCurveTo(13.9598, 16.4652, 13.6007, 16.5312, 13.4608, 16.7848);
  ctx.bezierCurveTo(13.2407, 17.1862, 12.7671, 17.9061, 12.4325, 17.9512);
  ctx.bezierCurveTo(11.7789, 18.0392, 10.4227, 17.9836, 9.36109, 17.9721);
  ctx.bezierCurveTo(9.36109, 17.9721, 9.54211, 16.9127, 9.13899, 16.549);
  ctx.bezierCurveTo(8.84056, 16.2776, 8.32687, 15.7274, 8.01964, 15.4382);
  ctx.lineTo(7.20556, 14.4731);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.black;
  ctx.lineWidth = 0.75;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.beginPath();
  ctx.moveTo(7.20556, 14.4731);
  ctx.bezierCurveTo(6.92768, 14.0969, 6.59012, 13.3277, 5.98935, 12.394);
  ctx.bezierCurveTo(5.64884, 11.8658, 4.80444, 10.8713, 4.55298, 10.3662);
  ctx.bezierCurveTo(4.33478, 9.91979, 4.35827, 9.71964, 4.41012, 9.34972);
  ctx.bezierCurveTo(4.5021, 8.69162, 5.13222, 8.17918, 5.80442, 8.24834);
  ctx.bezierCurveTo(6.31224, 8.29969, 6.74276, 8.65913, 7.13022, 8.99866);
  ctx.bezierCurveTo(7.36407, 9.20301, 7.65174, 9.60017, 7.82492, 9.82443);
  ctx.bezierCurveTo(7.98441, 10.0298, 8.02355, 10.1147, 8.1938, 10.3578);
  ctx.bezierCurveTo(8.41884, 10.6795, 8.48929, 10.8388, 8.40319, 10.4846);
  ctx.bezierCurveTo(8.33372, 9.96486, 8.22022, 9.07726, 8.05584, 8.29235);
  ctx.bezierCurveTo(7.9306, 7.69713, 7.90026, 7.60386, 7.78089, 7.14696);
  ctx.bezierCurveTo(7.65467, 6.66072, 7.5901, 6.32014, 7.4717, 5.80456);
  ctx.bezierCurveTo(7.38951, 5.43988, 7.24177, 4.6948, 7.20165, 4.27562);
  ctx.bezierCurveTo(7.14588, 3.7024, 7.11652, 2.76765, 7.45996, 2.33799);
  ctx.bezierCurveTo(7.72904, 2.00161, 8.34644, 1.89996, 8.72901, 2.10745);
  ctx.bezierCurveTo(9.22998, 2.37886, 9.51471, 3.15852, 9.64485, 3.46976);
  ctx.bezierCurveTo(9.8787, 4.02936, 10.0235, 4.67593, 10.1497, 5.52476);
  ctx.bezierCurveTo(10.3102, 6.60518, 10.6057, 8.10477, 10.6155, 8.4202);
  ctx.bezierCurveTo(10.639, 8.03351, 10.5489, 7.21927, 10.6116, 6.8483);
  ctx.bezierCurveTo(10.6683, 6.51191, 10.9325, 6.12103, 11.2632, 6.01519);
  ctx.bezierCurveTo(11.543, 5.92612, 11.8708, 5.89363, 12.1595, 5.95756);
  ctx.bezierCurveTo(12.4657, 6.02462, 12.7886, 6.25936, 12.909, 6.48048);
  ctx.bezierCurveTo(13.2632, 7.13439, 13.27, 8.4705, 13.2847, 8.39924);
  ctx.bezierCurveTo(13.3688, 8.00522, 13.3542, 7.11133, 13.5626, 6.73931);
  ctx.bezierCurveTo(13.6996, 6.4941, 14.0489, 6.27298, 14.2348, 6.23735);
  ctx.bezierCurveTo(14.5224, 6.18286, 14.8757, 6.1661, 15.178, 6.22897);
  ctx.bezierCurveTo(15.4216, 6.28032, 15.7514, 6.59051, 15.8404, 6.73931);
  ctx.bezierCurveTo(16.0537, 7.0998, 16.175, 8.11944, 16.2112, 8.47679);
  ctx.bezierCurveTo(16.2259, 8.62455, 16.2836, 8.066, 16.4979, 7.70551);
  ctx.bezierCurveTo(16.8952, 7.03588, 18.3012, 6.90594, 18.355, 8.37514);
  ctx.bezierCurveTo(18.3795, 9.06049, 18.3746, 9.02905, 18.3746, 9.49014);
  ctx.bezierCurveTo(18.3746, 10.0319, 18.3629, 10.3578, 18.3355, 10.7498);
  ctx.bezierCurveTo(18.3051, 11.1689, 18.221, 12.1163, 18.0987, 12.5753);
  ctx.bezierCurveTo(18.0145, 12.8907, 17.7357, 13.6001, 17.4607, 14.0256);
  ctx.bezierCurveTo(17.4607, 14.0256, 16.4099, 15.3355, 16.2954, 15.9255);
  ctx.bezierCurveTo(16.1799, 16.5144, 16.2181, 16.5186, 16.1956, 16.9368);
  ctx.bezierCurveTo(16.1731, 17.3538, 16.314, 17.903, 16.314, 17.903);
  ctx.bezierCurveTo(16.314, 17.903, 15.5293, 18.0119, 15.1066, 17.9396);
  ctx.bezierCurveTo(14.724, 17.8736, 14.2504, 17.0583, 14.1281, 16.8089);
  ctx.bezierCurveTo(13.9598, 16.4652, 13.6007, 16.5312, 13.4608, 16.7848);
  ctx.bezierCurveTo(13.2407, 17.1862, 12.7671, 17.9061, 12.4325, 17.9512);
  ctx.bezierCurveTo(11.7789, 18.0392, 10.4227, 17.9836, 9.36109, 17.9721);
  ctx.bezierCurveTo(9.36109, 17.9721, 9.54211, 16.9127, 9.13899, 16.549);
  ctx.bezierCurveTo(8.84056, 16.2776, 8.32687, 15.7274, 8.01964, 15.4382);
  ctx.lineTo(7.20556, 14.4731);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(11.375, 11.5);
  ctx.bezierCurveTo(11.375, 11.2239, 11.1511, 11, 10.875, 11);
  ctx.bezierCurveTo(10.5989, 11, 10.375, 11.2239, 10.375, 11.5);
  ctx.lineTo(10.375, 14.5);
  ctx.bezierCurveTo(10.375, 14.7761, 10.5989, 15, 10.875, 15);
  ctx.bezierCurveTo(11.1511, 15, 11.375, 14.7761, 11.375, 14.5);
  ctx.lineTo(11.375, 11.5);
  ctx.closePath();
  ctx.moveTo(12.875, 11);
  ctx.bezierCurveTo(13.1511, 11, 13.375, 11.2239, 13.375, 11.5);
  ctx.lineTo(13.375, 14.5);
  ctx.bezierCurveTo(13.375, 14.7761, 13.1511, 15, 12.875, 15);
  ctx.bezierCurveTo(12.5989, 15, 12.375, 14.7761, 12.375, 14.5);
  ctx.lineTo(12.375, 11.5);
  ctx.bezierCurveTo(12.375, 11.2239, 12.5989, 11, 12.875, 11);
  ctx.closePath();
  ctx.moveTo(14.875, 11);
  ctx.bezierCurveTo(15.1511, 11, 15.375, 11.2239, 15.375, 11.5);
  ctx.lineTo(15.375, 14.5);
  ctx.bezierCurveTo(15.375, 14.7761, 15.1511, 15, 14.875, 15);
  ctx.bezierCurveTo(14.5989, 15, 14.375, 14.7761, 14.375, 14.5);
  ctx.lineTo(14.375, 11.5);
  ctx.bezierCurveTo(14.375, 11.2239, 14.5989, 11, 14.875, 11);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(20.4165, 16.1667);
  ctx.bezierCurveTo(20.4165, 15.4666, 20.4165, 15.1166, 20.5527, 14.8492);
  ctx.bezierCurveTo(20.6726, 14.614, 20.8638, 14.4228, 21.099, 14.3029);
  ctx.bezierCurveTo(21.3664, 14.1667, 21.7164, 14.1667, 22.4165, 14.1667);
  ctx.lineTo(25.0832, 14.1667);
  ctx.bezierCurveTo(25.7832, 14.1667, 26.1333, 14.1667, 26.4007, 14.3029);
  ctx.bezierCurveTo(26.6359, 14.4228, 26.8271, 14.614, 26.9469, 14.8492);
  ctx.bezierCurveTo(27.0832, 15.1166, 27.0832, 15.4666, 27.0832, 16.1667);
  ctx.lineTo(27.0832, 16.75);
  ctx.bezierCurveTo(27.0832, 17.4501, 27.0832, 17.8001, 26.9469, 18.0675);
  ctx.bezierCurveTo(26.8271, 18.3027, 26.6359, 18.4939, 26.4007, 18.6138);
  ctx.bezierCurveTo(26.1333, 18.75, 25.7832, 18.75, 25.0832, 18.75);
  ctx.lineTo(22.4165, 18.75);
  ctx.bezierCurveTo(21.7164, 18.75, 21.3664, 18.75, 21.099, 18.6138);
  ctx.bezierCurveTo(20.8638, 18.4939, 20.6726, 18.3027, 20.5527, 18.0675);
  ctx.bezierCurveTo(20.4165, 17.8001, 20.4165, 17.4501, 20.4165, 16.75);
  ctx.lineTo(20.4165, 16.1667);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 0.625;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.font = "   15px ''";
  ctx.beginPath();
  ctx.moveTo(25.8332, 14.1667);
  ctx.lineTo(25.8332, 13.3333);
  ctx.bezierCurveTo(25.8332, 12.1827, 24.9004, 11.25, 23.7498, 11.25);
  ctx.bezierCurveTo(22.5992, 11.25, 21.6665, 12.1827, 21.6665, 13.3333);
  ctx.lineTo(21.6665, 14.1667);
  ctx.moveTo(23.7498, 16.0417);
  ctx.lineTo(23.7498, 16.875);
  ctx.moveTo(22.4165, 18.75);
  ctx.lineTo(25.0832, 18.75);
  ctx.bezierCurveTo(25.7832, 18.75, 26.1333, 18.75, 26.4007, 18.6138);
  ctx.bezierCurveTo(26.6359, 18.4939, 26.8271, 18.3027, 26.9469, 18.0675);
  ctx.bezierCurveTo(27.0832, 17.8001, 27.0832, 17.4501, 27.0832, 16.75);
  ctx.lineTo(27.0832, 16.1667);
  ctx.bezierCurveTo(27.0832, 15.4666, 27.0832, 15.1166, 26.9469, 14.8492);
  ctx.bezierCurveTo(26.8271, 14.614, 26.6359, 14.4228, 26.4007, 14.3029);
  ctx.bezierCurveTo(26.1333, 14.1667, 25.7832, 14.1667, 25.0832, 14.1667);
  ctx.lineTo(22.4165, 14.1667);
  ctx.bezierCurveTo(21.7164, 14.1667, 21.3664, 14.1667, 21.099, 14.3029);
  ctx.bezierCurveTo(20.8638, 14.4228, 20.6726, 14.614, 20.5527, 14.8492);
  ctx.bezierCurveTo(20.4165, 15.1166, 20.4165, 15.4666, 20.4165, 16.1667);
  ctx.lineTo(20.4165, 16.75);
  ctx.bezierCurveTo(20.4165, 17.4501, 20.4165, 17.8001, 20.5527, 18.0675);
  ctx.bezierCurveTo(20.6726, 18.3027, 20.8638, 18.4939, 21.099, 18.6138);
  ctx.bezierCurveTo(21.3664, 18.75, 21.7164, 18.75, 22.4165, 18.75);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorNoEdit;
