import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'hooks';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

import { UploadItem } from 'mw-style-react';

const DEFAULT_REMOVE_TIMEOUT = 1000;

const useUploadProgress = ({
  filesUpdated,
  removeTimeout = DEFAULT_REMOVE_TIMEOUT,
  removeOnTimeout = true,
}) => {
  const [filesUpdatedState, setFilesUpdatedState] = useState([]);
  const t = useIntl();

  // Universal method for retrieving text
  const getText = (uploadingStatus, uploadProgress) => ({
    textValue:
      t(globalIntl[uploadingStatus]) ??
      `${t(globalIntl.status)} ${uploadProgress}%`,
  });

  // Using useCallback to prevent unnecessary re-renders
  const updateProgress = useCallback((fileId, progress) => {
    setFilesUpdatedState((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId
          ? {
              ...file,
              uploadProgress: progress,
              ...getText(file.uploadingStatus, progress),
            }
          : file
      )
    );
  }, []);

  const removeFileFromList = useCallback((fileId) => {
    setFilesUpdatedState((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
  }, []);

  const uploadingStop = useCallback((fileId) => {
    setFilesUpdatedState((prevFiles) => {
      return prevFiles.filter((file) => {
        if (file.id === fileId) {
          file.abortController?.abort();
          return false;
        }
        return true;
      });
    });
  }, []);

  const updateStatus = useCallback((fileId, status) => {
    setFilesUpdatedState((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId
          ? {
              ...file,
              uploadingStatus: status,
              ...getText(status, file.uploadProgress),
            }
          : file
      )
    );
  }, []);

  useEffect(() => {
    if (typeof filesUpdated === 'function') {
      filesUpdated(filesUpdatedState);
    }
  }, [filesUpdatedState, filesUpdated]);

  const prepareFilesArray = useCallback(
    (files, retryFileUploadHandler) => {
      if (!files?.length) return;

      const newFiles = files.map((file) => ({
        id: file.id,
        file,
        fileName: file.label,
        uploadProgress: 0,
        progressLineValue: '',
        textValue: `${t(globalIntl.status)} 0%`,
        uploadingStatus: UploadItem.STATUSES.UPLOADING,
        abortController: new AbortController(),
        uploadProgressHandler: (data) => updateProgress(file.id, data),
        removeFileUpload: () => uploadingStop(file.id),
        retryFileUpload: () => {
          removeFileFromList(file.id);
          retryFileUploadHandler?.(file);
        },
        onUploadFinished: (isDone) => {
          if (isDone) {
            updateStatus(file.id, UploadItem.STATUSES.DONE);
            if (removeOnTimeout) {
              const timeoutId = setTimeout(() => {
                removeFileFromList(file.id);
                clearTimeout(timeoutId);
              }, removeTimeout);
            }
          } else {
            updateStatus(file.id, UploadItem.STATUSES.ERROR);
          }
        },
      }));

      setFilesUpdatedState((prevFiles) => [...prevFiles, ...newFiles]);
      return newFiles;
    },
    [
      removeOnTimeout,
      removeTimeout,
      updateProgress,
      uploadingStop,
      removeFileFromList,
      updateStatus,
    ]
  );

  const clearArray = useCallback(() => {
    setFilesUpdatedState([]);
  }, []);

  useEffect(() => {
    return () => {
      setFilesUpdatedState((prevFiles) => {
        prevFiles.forEach((file) => file.abortController?.abort());
        return [];
      });
    };
  }, []);

  return { prepareFilesArray, clearArray };
};

export default useUploadProgress;
