/**
 * Overrides the makeLayer method to add padding to the bounding box (BB).
 * This ensures that when a layer is transformed into a texture (image),
 * additional space is added around the edges to prevent cropping of
 * extra drawn information that might extend beyond the original boundaries.
 *
 * @param {Object} graph - The Cytoscape graph instance.
 */
const overrideMakeLayer = (graph) => {
  const renderer = graph?.renderer();
  const lyrTxrCache = renderer?.data?.lyrTxrCache;

  if (lyrTxrCache) {
    const { makeLayer: originalMakeLayer } = lyrTxrCache;

    lyrTxrCache.makeLayer = function (bb, lvl) {
      const padding = 50;

      const modifiedBB = {
        x1: bb.x1 - padding,
        y1: bb.y1 - padding,
        x2: bb.x2 + padding,
        y2: bb.y2 + padding,
        w: bb.w + padding * 2,
        h: bb.h + padding * 2,
      };

      return originalMakeLayer.call(this, modifiedBB, lvl);
    };
  }
};

export default overrideMakeLayer;
