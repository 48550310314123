import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  CREATE_ACTOR,
  GET_TEMPLATE_ACTORS,
  SEARCH_ACTORS,
  SYSTEM_ACTOR_CUSTOM_MODALS,
} from '@control-front-end/common/constants/graphActors';
import FormUtils from '@control-front-end/utils/formUtils';
import { RECENT, SET_MODAL, SEARCH_DEBOUNCE_INTERVAL } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import useDebouncedEffect from '@control-front-end/app/src/hooks/useDebouncedEffect';
import SelectEntityField from '../SelectEntityField';
import { GET_FORM } from '../../constants/forms';

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

/**
 * Actors select component
 */
function SelectActors(props) {
  const {
    formId,
    multiselect = true,
    exclude = [],
    excludeFormIds = [],
    manageLayer = false,
    reduxEvent = false,
    position,
    fullModel = false,
    ignoreFormIdForList = false,
    filter,
    onBlur,
    onFocus,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const isFirstRenderRef = useRef(true);
  const auth = useSelector((state) => state.auth);
  const systemForms = useSelector((state) => state.systemForms);
  const defaultTpl = useSelector((state) => state.defaultActorTpl) || {};
  const [actors, setActors] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const excludeSet = exclude.map((i) => i.id);
  const isScriptSelect = formId === systemForms.scripts?.id;
  const isEventSelect = formId === systemForms.events?.id;
  const debouncedQuery = useDebouncedEffect(query, SEARCH_DEBOUNCE_INTERVAL);

  const searchActors = (searchQuery, loadMore = false) => {
    if (!searchQuery.length) return;
    setLoading(true);
    const localState = loadMore ? actors : initState;
    dispatch({
      type: SEARCH_ACTORS.REQUEST,
      payload: {
        query: searchQuery,
        params: { formId: ignoreFormIdForList ? null : formId },
        localState,
        loadMore,
      },
      callback: (data) => {
        const { list, limit, offset } = localState;
        const newList = structuredClone(list);
        newList.push(...data);
        setActors({
          list: newList,
          limit,
          offset: limit + offset,
          endList: !data.length,
        });
        setLoading(false);
      },
    });
  };

  const getTemplateActors = ({ loadMore = false, localState = actors }) => {
    if (localState.endList) return;
    setLoading(true);
    dispatch({
      type: GET_TEMPLATE_ACTORS.REQUEST,
      payload: {
        formId,
        loadMore,
        orderBy: 'updated_at',
        localState,
      },
      callback: (data) => {
        if (data) setActors(data);
        setLoading(false);
      },
    });
  };

  const getData = () => {
    if (!formId || ignoreFormIdForList) {
      setLoading(true);
      dispatch({
        type: RECENT.REQUEST,
        payload: { objType: 'actor' },
        callback: (data) => {
          setLoading(false);
          setActors({ ...initState, list: data });
        },
      });
    } else {
      getTemplateActors({ localState: initState });
    }
  };

  useEffect(() => {
    // Skip trigger on first render to prevent doubling request with onFocus
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
    if (debouncedQuery.length) searchActors(debouncedQuery);
    else getData();
  }, [debouncedQuery]);

  const handleScroll = () => {
    if (isLoading || actors.endList) return;
    if (query.length) {
      searchActors(query, true);
      return;
    }
    if (formId && !ignoreFormIdForList) getTemplateActors({ loadMore: true });
  };

  const handleBlur = () => {
    setQuery('');
    setActors(initState);
    if (onBlur) onBlur();
  };

  /**
   * Выбрать созданный актор
   */
  const setCreatedActor = (actor) => {
    if (!actor) return;
    const { id, value } = props;
    let val;
    if (multiselect) {
      val = value.slice();
      val.push(actor);
    } else {
      val = actor;
    }
    props.onChange({ id, value: val });
  };

  /**
   * Создать актор через модальное окно (есть обязательные поля или валидация)
   */
  const showCreateActorModal = (modalName, form, title) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: modalName,
        data: {
          form,
          title,
          disableActorsTemplate: !!formId,
          reduxEvent: false,
        },
        callback: (actor) => setCreatedActor(actor),
      },
    });
  };

  /**
   * Создать событие через модальное окно
   */
  const showCreateEventModal = (title = '') => {
    const date = AppUtils.getDefaultDuedate();
    onClose();
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'EventCard',
        data: {
          id: 'tmp_1',
          title,
          ownerId: auth.id,
          ownerAvatar: auth.avatar,
          access: [AppUtils.makeAuthUserAsOwner(auth)],
          attachments: [],
          startDate: date,
          endDate: date,
          color: AppUtils.getRandomActorsColor(),
        },
        closeConfirm: true,
        callback: (event) => setCreatedActor(event),
      },
    });
  };

  const createActor = ({ id, title, form, type }, actorTitle = '') => {
    const hasCustomModal =
      type === 'system' && SYSTEM_ACTOR_CUSTOM_MODALS[title];
    const { hasFieldValidation, areRequiredFieldsFilled } =
      FormUtils.checkValidation(form);
    if (isEventSelect) {
      showCreateEventModal(actorTitle);
    } else if (
      (hasFieldValidation && !areRequiredFieldsFilled && !props.formData) ||
      hasCustomModal
    ) {
      const modalName = SYSTEM_ACTOR_CUSTOM_MODALS[title] || 'CreateActor';
      const formData = { ...form, id, title };
      showCreateActorModal(modalName, formData, actorTitle);
      onClose();
    } else {
      const formData = areRequiredFieldsFilled
        ? FormUtils.getFormData(form)
        : {};
      dispatch({
        type: CREATE_ACTOR.REQUEST,
        payload: {
          title: actorTitle,
          formId: id,
          formData: props.formData || formData,
          color: AppUtils.getRandomActorsColor(),
          manageLayer,
          reduxEvent,
          position,
        },
        callback: (actor) => setCreatedActor(actor),
        errorCallback: () => onClose(),
      });
    }
  };

  /**
   * Создание нового актора
   */
  const handleCreateClick = ({ value: actorTitle }) => {
    if (formId) {
      dispatch({
        type: GET_FORM.REQUEST,
        payload: { formId },
        callback: (tpl) => createActor(tpl, actorTitle),
      });
    } else {
      createActor(defaultTpl, actorTitle);
    }
  };

  /**
   * Обработка поискового запроса
   */
  const handleSearch = (newQuery) => {
    setQuery(newQuery);
  };

  const itemsList = actors.list.filter(
    (i) =>
      !excludeSet.includes(i.id) &&
      !excludeFormIds.includes(i.formId) &&
      filter(i)
  );
  return (
    <SelectEntityField
      {...props}
      objType="actor"
      multiselect={multiselect}
      list={itemsList}
      fullModel={fullModel}
      formId={ignoreFormIdForList ? null : formId}
      onSearch={handleSearch}
      onFocus={() => {
        getData();
        onFocus?.();
      }}
      onBlur={handleBlur}
      onScroll={handleScroll}
      onCreate={!isScriptSelect ? handleCreateClick : null}
      isLoading={isLoading}
    />
  );
}

SelectActors.defaultProps = {
  filter: () => true,
};

SelectActors.propTypes = {
  filter: PropTypes.func,
  exclude: PropTypes.array,
  excludeFormIds: PropTypes.array,
  fullModel: PropTypes.bool,
  onBlur: PropTypes.func,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
};

export default SelectActors;
