import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import {
  Stack,
  Space,
  Label,
  Icon,
  DateUtils,
  Divider,
  Radio,
  RadioItem,
  ProgressBar,
  Tooltip,
  cr,
} from 'mw-style-react';

import { useIntl, useModal, useInterval, useReduxAction } from 'hooks';
import Chart from '@control-front-end/common/components/Chart';
import AppUtils from '@control-front-end/utils/utils';

import {
  COUNTER_TYPE,
  INCOME_TYPE,
  GET_ACCOUNT_DETAILS,
} from '@control-front-end/common/constants/actorAccounts';
import {
  DASHBOARD_CHART_TYPES,
  DASHBOARD_DATA_INTERVAL,
  DASHBOARD_RANGES,
  LINED_CHART_TYPES,
  GET_ACTOR,
  GET_DASHBOARD,
  GET_TEMPLATE_ACTORS_EVERY,
  DASHBOARD_VIEW_MODES,
  PM_DASHBOARD_READY,
  PM_DASHBOARD_CLICK,
  PM_DASHBOARD_SET_SOURCE,
  DASHBOARD_SOURCE_TYPES,
  POLAR_CHART_TYPES,
} from '@control-front-end/common/constants/graphActors';
import {
  DATE_FORMAT_6,
  DATE_FORMAT_5,
  ERROR_CODES,
  PM_SEND,
  PM_APP_NAME,
  WIDGET_NAMESPACE,
} from 'constants';
import FilterRange from '@control-front-end/app/src/components/FilterRange/FilterRange';

import ChartTitle from './components/ChartTitle';
import TableChart from './components/TableChart';
import DashboardFallback from './components/DashboardFallback';
import mes from './intl';
import './Dashboard.scss';

const DEF_WIDTH = 382;
const DEF_HEIGHT = 350;
const MAX_LABEL_LENGTH = 45;
const REALTIME_INTERVAL = 5000;
const REALTIME_RANGE_VALUES = ['realTime', 'lineRealTime'];

const FULL_DATE_FORMAT = `${DATE_FORMAT_6} ${DATE_FORMAT_5}`;

const DATE_FORMATS_BY_INTERVAL = {
  [DASHBOARD_DATA_INTERVAL.MINUTE]: FULL_DATE_FORMAT,
  [DASHBOARD_DATA_INTERVAL.HOUR]: FULL_DATE_FORMAT,
  [DASHBOARD_DATA_INTERVAL.DAY]: DATE_FORMAT_6,
  [DASHBOARD_DATA_INTERVAL.MONTH]: 'm YYYY',
  [DASHBOARD_DATA_INTERVAL.YEAR]: 'YYYY',
};

const FORMULA_ERROR_PREFIX = '[formula error] ';

const mergeObjects = (...items) =>
  items.reduce(
    (result, item) =>
      typeof item === 'object' ? { ...result, ...item } : result,
    {}
  );

/**
 * Make time series dataset to render Line chart
 */
const makeTimeSeriesDataset = ({ data, interval, steps }) => {
  const makeDateTimeAxesTickLabel = (value) => {
    const unix = new Date(value);
    return DateUtils.toDate(
      unix / 1000,
      DATE_FORMATS_BY_INTERVAL[interval] || FULL_DATE_FORMAT
    );
  };

  // Map all exist values with formatted datetimes
  data.forEach((metric) => {
    // Calculate the timezone offset in milliseconds
    const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
    metric.data = metric.data.map((val) => {
      const unix = new Date(val.date).getTime() - timeZoneOffset;
      return { ...val, date: makeDateTimeAxesTickLabel(unix) };
    });
  });

  // Make chart X-axis ticks labels array of formatted datetimes
  const dataTicks = steps.map((unix) => makeDateTimeAxesTickLabel(unix));

  data.forEach((metric) => {
    let totalValue = 0;
    metric.data = dataTicks.map((date) => {
      const findValue = metric.data.find((val) => val.date === date);
      const value = findValue || { date, value: 0 };
      totalValue += value?.value || 0;
      return { x: value.date, y: value.value };
    });
    metric.value = totalValue;
  });
  return data;
};

const getLabel = (acc, legend) => {
  let label;
  const errorPrefix = `${acc.errorFormula ? FORMULA_ERROR_PREFIX : ''}`;
  if (legend) {
    // if legend is customized - display only TRUE-value fields
    const visibleKeys = Object.keys(legend).filter((key) => !!legend[key]);
    label = `${errorPrefix}${visibleKeys.map((key) => acc[key]).join(', ')}`;
  } else {
    // by default - full label
    label = `${errorPrefix}${acc.actorTitle}, ${acc.accountName}, ${acc.currencyName}`;
  }
  const isOverflow = label.length > MAX_LABEL_LENGTH;
  return isOverflow ? `${label.substring(0, MAX_LABEL_LENGTH)}...` : label;
};

const useDashboardFetch = ({ actorId, range, source }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    if (![range, actorId, source].every(Boolean)) return;
    const { interval, steps } = AppUtils.getChartRangeIntervals(range);
    const { accounts, counterType, chartType, skipNonExistAccounts } = source;

    setLoading(true);
    dispatch({
      type: GET_DASHBOARD.REQUEST,
      payload: {
        actorId,
        interval,
        source: mergeObjects(
          accounts?.length && { accounts },
          counterType && { counterType },
          chartType && { chartType },
          skipNonExistAccounts && { skipNonExistAccounts }
        ),
        ...range,
      },
      callback: (data) => {
        const hasTimeSeries = data.length && data[0].data;
        if (hasTimeSeries) {
          const dataSeries = makeTimeSeriesDataset({
            data,
            interval,
            steps,
          });
          setData(dataSeries);
        } else {
          setData(data);
        }
        setLoading(false);
        setError(null);
      },
      errorCallback: (error) => {
        setLoading(false);
        setError({ ...error, requestType: GET_DASHBOARD.REQUEST });
      },
    });
  }, [range, actorId, source]);

  useEffect(fetch, [fetch]);

  return {
    fetch,
    data,
    error,
    isLoading,
  };
};

const dynamicSourceReducer = (state, { type, payload }) =>
  ({
    initAccounts: () => ({
      ...state,
      accounts: payload,
    }),
    accounts: () => ({
      ...state,
      accounts: state.accounts.map((acc) => ({
        ...acc,
        ...payload,
      })),
    }),
    counterType: () => ({
      ...state,
      counterType: payload,
    }),
    chartType: () => ({
      ...state,
      chartType: payload,
    }),
    skipNonExistAccounts: () => ({
      ...state,
      skipNonExistAccounts: payload,
    }),
  }[type]?.() || state);

const dynamicBalancePeriod = (rangeValue, dynamicRange) => {
  if (dynamicRange) {
    return {
      periodAmountFrom: dynamicRange.from,
      periodAmountTo: dynamicRange.to,
    };
  }
  const { from, to } = AppUtils.getRangeDate(rangeValue);
  if (from && to) {
    return { periodAmountFrom: from * 1000, periodAmountTo: to * 1000 };
  }
  return {};
};

function Dashboard({
  actorId,
  laId,
  actions,
  draggable,
  headerRef,
  canEdit,
  showEditButton,
  onTitleChange,
  staticSource,
  widget,
  zoomPanParams,
  openModalOnClick = true,
  title,
  ...props
}) {
  const t = useIntl();
  const dispatch = useDispatch();

  const [actorData, setActorData] = useState(null);
  const [showSettings, toggleSettings] = useState(false);
  const [boundaryError, setBoundaryError] = useState(null);
  const [repeatRotation, setRepeatRotation] = useState(0);

  const [dynamicSource, dispatchDynamicSource] = useReducer(
    dynamicSourceReducer,
    {
      accounts: [],
      counterType: null,
    }
  );

  useEffect(() => {
    // need not empty actorData for correct work
    if (actorData && title !== actorData.title) {
      setActorData((prev) => ({
        ...prev,
        title,
      }));
    }
  }, [title]);

  const { legend, orderValue = 'default' } = actorData?.data?.source || {};

  const { isOpen: isOpenEditModal, open: openEditActorModal } = useModal(
    'CreateDashboard',
    { actorId }
  );

  const { open: openTransactionHistory } = useModal('TransactionsHistory', {
    actorId,
  });

  const getChartDataByViewMode = (accData = []) => {
    const { chartViewMode, chartType } = actorData?.data?.source || {};
    const isBalanceViewMode =
      chartViewMode === DASHBOARD_VIEW_MODES.balanceChanges &&
      chartType === DASHBOARD_CHART_TYPES.LINE;

    if (isBalanceViewMode) {
      let balance = 0;
      return accData.map(({ x, y }) => {
        balance += y;
        return {
          x,
          y: balance,
        };
      });
    }

    return accData;
  };

  const {
    data: dashboardData,
    error,
    fetch: refetchDashboardData,
    isLoading: isLoadingDashboardData,
  } = useDashboardFetch({
    actorId,
    range: actorData?.data.range,
    source: dynamicSource,
  });

  const { start: startInterval, stop: stopInterval } = useInterval(() => {
    const rangeValue = actorData?.data?.source?.range;
    if (!REALTIME_RANGE_VALUES.includes(rangeValue)) return;
    if (rangeValue === 'lineRealTime') {
      let chartRange = {};
      const { from, to } = AppUtils.getRangeDate(rangeValue);
      if (from && to) chartRange = { from: from * 1000, to: to * 1000 };
      actorData.data.range = chartRange;
      setActorData(actorData);
    }
    refetchDashboardData();
  }, REALTIME_INTERVAL);

  /**
   * Chart data model
   * (convert account data to chart render params)
   */
  const dataSet = useMemo(() => {
    const { accounts: sourceAccounts = [], chartType } =
      actorData?.data?.source || {};
    const defaultPalette = AppUtils.makeDefaultPalette(dashboardData.length);
    const sortedData =
      orderValue !== 'default'
        ? AppUtils.sort(dashboardData, 'value', orderValue)
        : dashboardData;
    const isRadarView = chartType === DASHBOARD_CHART_TYPES.RADAR;
    return sortedData.map((acc, index) => ({
      label: getLabel(acc, legend),
      value: acc.value,
      actorId: isRadarView ? acc.actorId : undefined,
      actorTitle: isRadarView ? acc.actorTitle : undefined,
      accountPair: isRadarView ? `${acc.nameId}_${acc.currencyId}` : undefined,
      accountPairTitle: isRadarView
        ? `${acc.accountName}, ${acc.currencyName}`
        : undefined,
      data: getChartDataByViewMode(acc.data),
      color:
        sourceAccounts?.find(
          (sourceAcc) =>
            sourceAcc.actorId === acc.actorId &&
            sourceAcc.nameId === acc.nameId &&
            sourceAcc.currencyId.toString() === acc.currencyId.toString() &&
            sourceAcc.incomeType === acc.incomeType
        )?.color || defaultPalette[index],
      hidden: !!acc.errorFormula,
    }));
  }, [dashboardData, orderValue, legend, actorData?.data?.source?.accounts]);

  /**
   * Maximum precision to display correct total
   * @type {number}
   */
  const precision = useMemo(() => {
    const allPrecisions = dashboardData.map((acc) => acc.precision);
    return Math.max(...allPrecisions);
  }, [dashboardData]);

  /**
   * Chart date ranges options
   */
  const availableRanges = useMemo(() => {
    const chartType = actorData?.data?.source?.chartType;
    let lineChartRanges = structuredClone(DASHBOARD_RANGES);
    lineChartRanges = lineChartRanges.filter(
      (i) => !REALTIME_RANGE_VALUES.includes(i)
    );
    if (LINED_CHART_TYPES.includes(chartType)) {
      return lineChartRanges.filter((i) => i !== 'allTime');
    }
    return lineChartRanges;
  }, [actorData]);

  const updateActorModel = (updatedActor, jsonSource, dynamicRange) => {
    const currentSource =
      Object.keys(jsonSource).length === 0 ? staticSource : jsonSource;
    let chartRange = {};
    if (dynamicRange) {
      chartRange = dynamicRange;
    } else {
      const { from, to } = AppUtils.getRangeDate(currentSource.range);
      if (from && to) chartRange = { from: from * 1000, to: to * 1000 };
    }
    const model = {
      ...updatedActor,
      data: { source: currentSource, range: chartRange },
    };
    setActorData(model);
    dispatchDynamicSource({
      type: 'initAccounts',
      payload: currentSource.accounts,
    });
    dispatchDynamicSource({
      type: 'counterType',
      payload: currentSource.counterType,
    });
    dispatchDynamicSource({
      type: 'chartType',
      payload: currentSource.chartType,
    });
    dispatchDynamicSource({
      type: 'skipNonExistAccounts',
      payload: currentSource.skipNonExistAccounts,
    });
  };

  const handleActorUpdate = useCallback((updatedActor, dynamicRange) => {
    const { source = '{}' } = updatedActor.data;
    const jsonSource = AppUtils.safeJSONParse(source, source);
    if (jsonSource.sourceType !== DASHBOARD_SOURCE_TYPES.ACTOR_FILTER) {
      updateActorModel(updatedActor, jsonSource);
      return;
    }
    const { id: filterId, top } = jsonSource.dynamicSource || {};
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: {
        id: filterId,
      },
      callback: (filterActor) => {
        const filter = AppUtils.jsonParse(filterActor?.data?.filter, {});
        if (!filter?.accountNameId || !filter?.currencyId) return;
        dispatch({
          type: GET_TEMPLATE_ACTORS_EVERY.REQUEST,
          payload: {
            formId: filter.formId,
            limit: top,
            offset: 0,
            filter: {
              ...filter,
              ...dynamicBalancePeriod(jsonSource.range, dynamicRange),
              orderBy: 'balance',
            },
            localState: true,
          },
          callback: (data) => {
            const newAccounts = data.list
              .filter((item) => !!item.accountFilter)
              .map(({ actorId, accountFilter }) => {
                const oldAccountValue = jsonSource.accounts.find(
                  (acc) => acc.actorId === actorId
                );
                return {
                  actorId,
                  color:
                    oldAccountValue?.color ||
                    AppUtils.getRandomColorByPalette(),
                  incomeType: filter.incomeType || INCOME_TYPE.total,
                  currencyId: accountFilter.currencyId,
                  nameId: accountFilter.accountNameId,
                };
              });
            updateActorModel(
              updatedActor,
              {
                ...jsonSource,
                accounts: newAccounts,
              },
              dynamicRange
            );
          },
        });
      },
      errorCallback: (data) => {
        if (data.statusCode === ERROR_CODES.accessDenied)
          setBoundaryError(data);
      },
    });
  }, []);

  const loadData = useCallback(() => {
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: { id: actorId },
      callback: handleActorUpdate,
      errorCallback: () => {},
    });
  }, []);

  /**
   * Open actor edit modal
   */
  const handleEditActor = useCallback(() => {
    openEditActorModal({
      ...actorData,
      laId,
      onSaveActor: (formId, updatedActor) => handleActorUpdate(updatedActor),
    });
  }, [actorData, laId]);

  const onElementClick = (elementIndex) => {
    const accountDashboardData = dashboardData[elementIndex];
    if (!accountDashboardData) return;
    const {
      nameId,
      currencyId,
      accountName,
      currencyName,
      actorId,
      incomeType,
    } = accountDashboardData;
    if (!openModalOnClick) {
      dispatch({
        type: PM_SEND,
        payload: {
          appName: PM_APP_NAME,
          namespace: WIDGET_NAMESPACE.DASHBOARD,
          actorId,
          type: PM_DASHBOARD_CLICK,
          payload: {
            actorId,
            accountNameId: nameId,
            currencyId,
            incomeType,
          },
        },
      });
      return;
    }
    dispatch({
      type: GET_ACCOUNT_DETAILS.REQUEST,
      payload: {
        actorId,
        currencyId,
        nameId,
      },
      callback: (result) => {
        const { debit, credit } = result?.[0] || {};
        if (!debit || !credit) {
          return;
        }
        openTransactionHistory({
          nameId,
          accountName,
          currencyId: String(currencyId),
          accountType: debit?.type,
          actorId,
          currencyName,
          accKey: `${actorId}-${currencyId}`,
          debit,
          credit,
        });
      },
    });
  };

  useReduxAction(
    ({ payload }) => {
      const { data = {} } = payload;
      updateActorModel({ title: data.title }, data.source);
      refetchDashboardData();
    },
    PM_DASHBOARD_SET_SOURCE,
    []
  );

  useEffect(() => {
    dispatch({
      type: PM_SEND,
      payload: {
        appName: PM_APP_NAME,
        namespace: WIDGET_NAMESPACE.DASHBOARD,
        actorId,
        type: PM_DASHBOARD_READY,
        payload: {},
      },
    });
  }, []);

  useEffect(() => {
    if (actorId && !widget) loadData();
  }, [actorId]);

  useEffect(() => {
    if (isOpenEditModal) {
      stopInterval();
    } else {
      startInterval();
    }
  }, [isOpenEditModal]);

  const renderSettingsPanel = () =>
    showSettings ? (
      <Space left right fullWidth>
        <Space size={Space.SIZE.xxsmall} top fullWidth>
          <Stack.H size="none" alignItems="center" fullWidth>
            <FilterRange
              fullWidth
              from={actorData.data.range.from}
              to={actorData.data.range.to}
              rangeValue={actorData.data?.source?.range}
              dateFormat="HH:mm"
              rangeOptions={[...availableRanges, 'custom']}
              fastNavigation={!!actorData.data.range.from}
              maxDate={null}
              embedded
              compact
              zoomPanParams={zoomPanParams}
              onChange={(range) => {
                if (
                  actorData.data?.source?.sourceType ===
                  DASHBOARD_SOURCE_TYPES.ACTOR_FILTER
                ) {
                  handleActorUpdate(actorData, range);
                } else {
                  setActorData({
                    ...actorData,
                    data: {
                      ...actorData.data,
                      range: { from: range.from, to: range.to },
                    },
                  });
                }
                if (REALTIME_RANGE_VALUES.includes(range.rangeValue)) {
                  startInterval();
                } else {
                  stopInterval();
                }
              }}
            />
            {dynamicSource.counterType ? (
              <Space left top fullWidth size="small" styleName="counterType">
                <Radio
                  label={t(mes.accountCounterType)}
                  value={dynamicSource.counterType}
                  align="horizontal"
                  onChange={({ value }) =>
                    dispatchDynamicSource({
                      type: 'counterType',
                      payload: value,
                    })
                  }
                >
                  {Object.keys(COUNTER_TYPE).map((item) => (
                    <RadioItem key={item} value={item} label={item} />
                  ))}
                </Radio>
              </Space>
            ) : null}
          </Stack.H>
        </Space>
      </Space>
    ) : (
      <Space size={Space.SIZE.xlarge} top bottom fullWidth />
    );

  const renderChart = useCallback(() => {
    const {
      chartType,
      counterType,
      showTotal = true,
      yAxisLabel,
      displayChartDataLabels,
    } = actorData.data.source;
    const currentCounterType = dynamicSource?.counterType || counterType;
    if (chartType === DASHBOARD_CHART_TYPES.TABLE)
      return (
        <TableChart
          showTotal={showTotal}
          dataSet={dataSet}
          onElementClick={onElementClick}
          typeLabel={t(mes[`label_${currentCounterType}`])}
        />
      );
    const getTitle = (sum) => {
      if (!showTotal || (!sum && sum !== 0)) {
        return '';
      }
      return `${t(mes.total)}: ${AppUtils.toNumberFormat(
        Math.round(sum * 10 ** precision) / 10 ** precision
      )}`;
    };
    return (
      <Chart
        type={chartType}
        redraw={false}
        dataSet={dataSet}
        displayChartDataLabels={
          POLAR_CHART_TYPES.includes(chartType) ? false : displayChartDataLabels
        }
        onElementClick={onElementClick}
        getTitle={getTitle}
        yAxis={{
          name: yAxisLabel || t(mes[`label_${currentCounterType}`]),
        }}
      />
    );
  }, [actorData, dataSet]);

  const { range: rangeValue } = actorData?.data?.source || {};
  const showProgressBar =
    isLoadingDashboardData &&
    (!REALTIME_RANGE_VALUES.includes(rangeValue) || !dataSet.length);

  return (
    <Stack.V fullHeight size={Stack.SIZE.none} {...props}>
      <Space forwardRef={headerRef} size="xsmall">
        <Stack.H
          alignItems="center"
          justifyContent="spaceBetween"
          fullWidth={true}
          size={Stack.SIZE.xxsmall}
          styleName={cn('chart__header', { draggable })}
        >
          {draggable ? <Icon size="large" type="drag" /> : null}
          {actorData ? (
            <ChartTitle
              title={staticSource?.chartTitle || actorData.title}
              readOnly={!canEdit}
              onSubmit={(value) => {
                setActorData({ ...actorData, title: value });
                if (onTitleChange) onTitleChange(value);
              }}
            />
          ) : null}
          <Stack.H
            styleName="chart__actions"
            alignItems="center"
            size={Stack.SIZE.xxsmall}
          >
            <Tooltip value={t(mes.refresh)}>
              <div
                styleName="rotate"
                style={{ transform: `rotate(${repeatRotation}deg)` }}
                data-draggable="false"
                onClick={(e) => {
                  e.stopPropagation();
                  setRepeatRotation((i) => i + 180);
                  if (
                    actorData.data?.source?.sourceType ===
                    DASHBOARD_SOURCE_TYPES.ACTOR_FILTER
                  ) {
                    loadData();
                  } else {
                    refetchDashboardData();
                  }
                }}
              >
                <Icon type="repeat" />
              </div>
            </Tooltip>
            {showEditButton ? (
              <Tooltip value={t(mes.editChart)}>
                <div
                  data-draggable="false"
                  styleName={cn('chart__actions__edit', { disabled: !canEdit })}
                  onClick={(e) => {
                    if (!canEdit || boundaryError) return;
                    e.stopPropagation();
                    handleEditActor();
                  }}
                >
                  <Icon
                    type="edit"
                    data-draggable="false"
                    visibility={
                      canEdit && !boundaryError ? 'visible' : 'disabled'
                    }
                  />
                </div>
              </Tooltip>
            ) : null}
            <Tooltip value={t(mes.settings)}>
              <div
                data-draggable="false"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSettings((prevValue) => !prevValue);
                }}
              >
                <Icon type="settings" />
              </div>
            </Tooltip>
            {actions}
          </Stack.H>
        </Stack.H>
      </Space>
      {actorData?.data && !error ? (
        <>
          <Divider />
          {renderSettingsPanel()}
        </>
      ) : null}
      <Space styleName="container" right left bottom fullWidth fullHeight>
        {cr([
          showProgressBar,
          <div styleName={cn('loader', { initial: !dataSet.length })}>
            <ProgressBar rangeValue type="circle" size="large" />
          </div>,
        ])}
        {cr(
          [actorData && dataSet.length, renderChart],
          [
            error?.statusCode === ERROR_CODES.accessDenied,
            <DashboardFallback error={error} />,
          ],
          [
            error,
            <Stack
              fullWidth
              fullHeight
              alignItems="center"
              justifyContent="center"
            >
              <div styleName="errorImg" />
              <Label color={Label.COLOR.gray} value={t(mes.badChart)} />
            </Stack>,
            [boundaryError, <DashboardFallback error={boundaryError} />],
          ]
        )}
      </Space>
    </Stack.V>
  );
}

Dashboard.DEF_WIDTH = DEF_WIDTH;
Dashboard.DEF_HEIGHT = DEF_HEIGHT;

Dashboard.propTypes = {
  actorId: PropTypes.string.isRequired,
  widget: PropTypes.bool,
  laId: PropTypes.number,
  actions: PropTypes.node,
  draggable: PropTypes.bool,
  headerRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  canEdit: PropTypes.bool,
  showEditButton: PropTypes.bool,
  openModalOnClick: PropTypes.bool,
  onTitleChange: PropTypes.func,
  staticSource: PropTypes.shape({
    counterType: PropTypes.oneOf([...Object.values(COUNTER_TYPE)]),
    chartType: PropTypes.oneOf([...Object.values(DASHBOARD_CHART_TYPES)]),
    range: PropTypes.oneOf([...DASHBOARD_RANGES]),
    chartTitle: PropTypes.string,
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        actorId: PropTypes.string,
        nameId: PropTypes.string,
        currencyId: PropTypes.string,
        incomeType: PropTypes.oneOf([...Object.values(INCOME_TYPE)]),
        color: PropTypes.string,
      })
    ),
  }),
  title: PropTypes.string,
};

export default Dashboard;
