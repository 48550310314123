import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useOutsideClick from 'useOutsideClick';
import SelectActors from '@control-front-end/common/components/SelectActors';
import './ActorsPopup.scss';

/**
 * Поиск акторов
 */
function ActorsPopup(props) {
  const {
    fullModel,
    exclude = [],
    excludeFormIds = [],
    formId,
    handleSelect,
    handleBlur,
    handleClose,
    ignoreFormIdForList,
  } = props;
  const popupRef = useRef();
  const systemForms = useSelector((state) => state.systemForms);
  const reactionsFormId = systemForms.reactions.id;
  excludeFormIds.push(reactionsFormId);

  useOutsideClick({ ref: popupRef, callback: handleBlur });

  return (
    <div ref={popupRef} styleName="sa">
      <SelectActors
        bordered={true}
        size="large"
        multiselect={false}
        autoFocus={true}
        value={{}}
        formId={formId}
        manageLayer={false}
        fullModel={fullModel}
        exclude={exclude}
        excludeFormIds={excludeFormIds}
        onChange={({ value: actor }) => handleSelect(actor.id, actor)}
        onClose={handleClose}
        ignoreFormIdForList={ignoreFormIdForList}
      />
    </div>
  );
}

ActorsPopup.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleClose: PropTypes.func,
  exclude: PropTypes.array,
  formId: PropTypes.number,
  fullModel: PropTypes.bool,
  ignoreFormIdForList: PropTypes.bool,
};

export default ActorsPopup;
