import Client from 'mw-fetch';
import { call, put, select } from 'redux-saga/effects';
import {
  APP_AUTH_KEY,
  SSO_AUTH_KEY,
  SHOW_NOTIFY,
  NOTIFY_LEVEL,
  SHOW_SESSION_MODAL,
  RequestStatus,
} from 'constants';
import { Utils } from 'mw-style-react';
import mes from '@control-front-end/app/src/components/Notifications/intl';
import getTranslation from '@control-front-end/utils/getTranslation';
import AppUtils from '@control-front-end/utils/utils';
import { SEARCH_HTML_TITLE } from '../constants/regExp';

/**
 * Sends an error notification with a specified message and status code.
 *
 * @param {Object} params - The parameters for the error notification.
 * @param {string} params.message - The error message to display.
 * Defaults to a generic internal error message if not provided.
 * @param {number} params.statusCode - The HTTP status code associated with the error.
 * @return {Generator} A Redux-Saga effect for dispatching the error notification action.
 */
function* sendError({ message, statusCode }) {
  let id = AppUtils.createRid();
  if (!message) {
    id = 'internalError';
    message = getTranslation(mes.internalError);
  }
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id,
      type: NOTIFY_LEVEL.ERROR,
      label: message,
      statusCode,
    },
  });
}

/**
 * Creates and returns an authorization header string based on the available authentication token.
 *
 * This function first checks for the presence of a global authentication token (SSO_AUTH_KEY).
 * If it exists, it retrieves and returns the token. If not, it attempts to get an application-specific
 * authentication token (APP_AUTH_KEY) from storage and builds a Bearer token string. If neither exists,
 * it returns null.
 *
 * @return {string|null} The authorization header string in the format "Bearer <token>"
 * or null if no token is available.
 */
function makeAuthHeader() {
  if (window[SSO_AUTH_KEY]) return window[SSO_AUTH_KEY];
  const appAuthStr = Utils.fromStorage(APP_AUTH_KEY);
  return appAuthStr ? `Bearer ${appAuthStr}` : null;
}

/**
 * A generator function to create a connector client with the appropriate headers based on the request type.
 *
 * @param {string} reqType - The type of request, e.g., 'multipart' or other types.
 * @return {Generator<Object, Client, any>} A generator that yields a Client instance configured
 * with the appropriate headers.
 */
function* getConnector(reqType) {
  const appInit = yield select((state) => state.appInit);
  const authStr = makeAuthHeader();
  const authHeader = authStr ? { Authorization: authStr } : {};
  if (reqType === 'multipart') {
    return new Client({
      headers: {
        'Content-Type': 'multipart/form-data',
        'Control-Tab-Id': appInit.tabId,
        ...authHeader,
      },
    });
  }
  return new Client({
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Control-Tab-Id': appInit.tabId,
      ...authHeader,
    },
  });
}

/**
 * Makes an API call using a specified method, URL, and other parameters.
 *
 * @param {Object} options The configuration options for the API call.
 * @param {string} [options.method='get'] The HTTP method to use (e.g., 'get', 'post', 'put', etc.).
 * @param {string} [options.url=''] The URL to which the API request is made. This is required.
 * @param {Object} [options.body={}] The request payload to be sent for applicable HTTP methods (e.g., 'post', 'put').
 * @param {string} [options.reqType='json'] The type of request (e.g., 'json', 'multipart', etc.).
 * @param {Object} [options.queryParams={}] The query parameters to append to the URL.
 * @param {Array<number>} [options.handleErrCodes=[]] An array of error status codes
 * to handle gracefully without throwing an error.
 *
 * @return {Generator} A generator that performs the API call and yields the response object.
 */
function* api({
  method = 'get',
  url = '',
  body = {},
  reqType = 'json',
  queryParams = {},
  handleErrCodes = [],
}) {
  if (!url) return;
  url = AppUtils.makeAppUrl(url, queryParams);
  let response = {};
  try {
    const client = yield call(getConnector, reqType);
    response = yield call([client, method], url, body);
    switch (response.result) {
      case RequestStatus.SUCCESS:
        break;
      case RequestStatus.ERROR:
        const throwError = !handleErrCodes.includes(response.data.statusCode);
        if (throwError) {
          yield sendError({
            message: response.data.message,
            statusCode: response.data.statusCode,
          });
        }
        break;
      case RequestStatus.UNAUTH:
        yield put({ type: SHOW_SESSION_MODAL });
        break;
      default:
        break;
    }
  } catch (e) {
    yield sendError({
      message: e.message,
      statusCode: '50X',
    });
  }

  return response;
}

/**
 * Fetches and processes streamed chunks of data from the specified URL,
 * handling errors and invoking a handler for processed data.
 *
 * @param {Object} params - An object containing method parameters.
 * @param {string} [params.method='get'] - The HTTP method to use for the request.
 * @param {string} [params.url=''] - The URL to send the request to. Must not be empty.
 * @param {Object} [params.body={}] - The request payload body, typically for POST or PUT requests.
 * @param {Object} [params.queryParams={}] - Query parameters to append to the URL.
 * @param {Function} params.handler - A callback to handle the processed data or errors.
 * @param {AbortController} params.controller - The AbortController instance to manage the request signal.
 *
 * @return {Promise<void>} Resolves when the request and its processing are complete.
 */
export async function chunks({
  method = 'get',
  url = '',
  body = {},
  queryParams = {},
  handler,
  controller,
}) {
  if (!url) return;
  url = AppUtils.makeAppUrl(url, queryParams);
  const authStr = makeAuthHeader();
  const authHeader = authStr ? { Authorization: authStr } : {};
  const response = await fetch(url, {
    method,
    headers: {
      ...authHeader,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    signal: controller.signal,
  });
  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');
  let dataBuffer = new Uint8Array([]);

  // eslint-disable-next-line
  async function readChunk() {
    try {
      const { done, value } = await reader.read();
      if (!done) {
        const newData = new Uint8Array(value);
        dataBuffer = new Uint8Array([...dataBuffer, ...newData]);
        await readChunk();
      }
      const decodedValue = decoder.decode(dataBuffer);
      const mergedValue = decodedValue.replaceAll('][', ',');
      if (!AppUtils.isJSON(mergedValue)) {
        const errorTitle = decodedValue.match(SEARCH_HTML_TITLE)?.[1];
        handler({
          error: true,
          errorDescription:
            errorTitle || `${getTranslation(mes.invalidJson)}: ${mergedValue}`,
        });
        return;
      }
      handler({
        value: JSON.parse(mergedValue),
        error: false,
      });
    } catch (exception) {
      if (exception.name === 'AbortError') return;
      handler({
        error: true,
        errorDescription: exception.message,
      });
    }
  }

  await readChunk();
}

/**
 * Uploads a file to the specified URL with progress tracking.
 *
 * @param {Object} options - The options for the upload process.
 * @param {string} options.url - The URL to which the file will be uploaded.
 * @param {File} options.file - The file to be uploaded.
 * @param {function} [options.onProgress] - Optional callback function to receive upload progress percentage.
 * @param {function} [options.onUploadFinished] - Optional callback function to receive upload finished.
 * @param {AbortController} params.controller - The AbortController instance to manage the request signal.
 * @return {Promise<Object>} A Promise that resolves with the upload result or rejects with an error.
 */
export function upload({
  url,
  file,
  onProgress,
  onUploadFinished,
  controller,
}) {
  url = AppUtils.makeAppUrl(url, {});
  return new Promise((resolve) => {
    const authStr = makeAuthHeader();
    const authHeader = authStr ? { Authorization: authStr } : {};
    const formData = new FormData();
    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', authHeader.Authorization);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && typeof onProgress === 'function') {
        const percentComplete = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentComplete));
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = AppUtils.safeJSONParse(xhr.responseText);
        onUploadFinished?.(true);
        resolve({ result: RequestStatus.SUCCESS, data: response });
      } else {
        onUploadFinished?.(false);
        resolve({ result: RequestStatus.ERROR, data: null });
      }
    };

    xhr.onerror = () => {
      onUploadFinished?.(false);
      resolve({ result: RequestStatus.ERROR, data: null });
    };

    controller?.addEventListener('abort', () => {
      xhr.abort();
      resolve({ result: RequestStatus.ERROR, data: null });
    });

    xhr.send(formData);
  });
}

export default api;
