import React from 'react';
import PropTypes from 'prop-types';
import { property } from 'lodash';
import { Button, Label, Stack, TextField, Space, Card } from 'mw-style-react';

import { getNumericTransferSum } from '@control-front-end/utils/modules/utilsTransfer';
import { useIntl } from 'hooks';

import mes from '../../intl';

export const FOOTER_HEIGHT = 58;

function TransferItems({ items, errorTotal, onAdd, children, ...rest }) {
  const t = useIntl();
  const total = getNumericTransferSum(items.map(property('value')));
  return (
    <Stack fullWidth {...rest}>
      {items.map((item, index) => (
        <Card key={index} fullWidth>
          <Space fullWidth>{children?.(item, index)}</Space>
        </Card>
      ))}
      <Space left right fullWidth style={{ height: FOOTER_HEIGHT }}>
        <Stack.H fullWidth size="xsmall" alignItems="center">
          <div style={{ flex: '1 1 auto' }}>
            <Button
              type="text"
              label={t(mes.addAccount)}
              icon="add"
              onClick={onAdd}
            />
          </div>
          <Label fontWeight="semibold" value="Total" />
          <div style={{ width: '30%' }}>
            <TextField
              size="large"
              type="float"
              bordered
              unspaced
              visibility="disabled"
              value={total || null}
              error={Boolean(errorTotal)}
              helperText={errorTotal}
            />
          </div>
        </Stack.H>
      </Space>
    </Stack>
  );
}

TransferItems.propTypes = {
  items: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  errorTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.func,
};

export default TransferItems;
