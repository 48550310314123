import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramData(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(8.97703, 36.4998);
  ctx.lineTo(18.1768, 13.5002);
  ctx.lineTo(41.0227, 13.5002);
  ctx.lineTo(31.823, 36.4998);
  ctx.lineTo(8.97703, 36.4998);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramData;
