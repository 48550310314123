import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { GRAPH_NODE_SIZE } from '@control-front-end/common/constants/graphActors';
import { TOOLBAR_SIZES, CUSTOM_WHEEL_EVENT } from 'constants';

const ZOOM_STEP = 0.03;
const ZOOM_KEYS = { '+': ZOOM_STEP, '=': ZOOM_STEP, '-': -ZOOM_STEP };

const centerViewport = () => ({
  x: window.innerWidth / 2,
  y: window.innerHeight / 2,
});

const isOutsideViewport = (position, viewport, zoom) => {
  const halfSize = GRAPH_NODE_SIZE / (2 * zoom);
  const adjustedViewportX1 =
    viewport.x1 + TOOLBAR_SIZES.VERTICAL_PANEL_WIDTH * zoom;
  const adjustedViewportY1 = viewport.y1 + TOOLBAR_SIZES.LINE_HEIGHT * zoom;

  return {
    isLeft: position.x - halfSize < adjustedViewportX1,
    isRight: position.x + halfSize > viewport.x2,
    isTop: position.y - halfSize < adjustedViewportY1,
    isBottom: position.y + halfSize > viewport.y2,
  };
};

const useGesturesCytoscape = ({ cy, clearUIInteractions = () => {} }) => {
  const handleDrag = (event) => {
    const position = event?.target?.position();
    if (!position || !cy) return;

    const viewport = cy.extent();
    const zoom = cy.zoom();
    const PAN_STEP = GRAPH_NODE_SIZE / zoom;

    const { isLeft, isRight, isTop, isBottom } = isOutsideViewport(
      position,
      viewport,
      zoom
    );

    let xDelta = 0;
    let yDelta = 0;

    if (isLeft) xDelta = PAN_STEP;
    if (isRight) xDelta = -PAN_STEP;
    if (isTop) yDelta = PAN_STEP;
    if (isBottom) yDelta = -PAN_STEP;

    if (xDelta || yDelta) {
      cy.panBy({ x: xDelta, y: yDelta });
    }
  };

  const adjustZoom = (delta, position) => {
    const newZoom = cy.zoom() + delta;

    const container = cy.container();
    const rect = container.getBoundingClientRect();
    const extent = cy.extent();

    if (!rect || !position) {
      return;
    }

    clearUIInteractions();

    const renderedX = position.x - rect.left;
    const renderedY = position.y - rect.top;

    const graphX =
      extent.x1 + (renderedX / rect.width) * (extent.x2 - extent.x1);
    const graphY =
      extent.y1 + (renderedY / rect.height) * (extent.y2 - extent.y1);

    cy.zoom({
      level: newZoom,
      position: { x: graphX, y: graphY },
    });
  };

  const preventSwipeNavigation = (event) => {
    if (event.target.tagName !== 'CANVAS') return;
    const container = cy.container();
    const { left, right, top, bottom } = container.getBoundingClientRect();
    const { clientX, clientY } = event;
    const isOutsideBounds =
      clientX < left || clientX > right || clientY < top || clientY > bottom;
    if (isOutsideBounds) return;
    // Prevent default behavior (browser navigation) if the cursor is in the container
    event.preventDefault();
    event.stopPropagation();
  };

  const handleWheel = (event) => {
    event.preventDefault();

    const { deltaY, deltaX, clientX, clientY, ctrlKey, metaKey, shiftKey } =
      event || {};

    const isZoom = ctrlKey || metaKey;

    if ((Math.abs(deltaX) > 0 || Math.abs(deltaY) > 0) && !isZoom) {
      clearUIInteractions();
      if (shiftKey && deltaY !== 0) {
        cy.panBy({ x: -deltaY, y: 0 });
      } else {
        cy.panBy({ x: -deltaX, y: -deltaY });
      }
    }

    if (isZoom && deltaY !== 0) {
      adjustZoom(event.deltaY > 0 ? -ZOOM_STEP : ZOOM_STEP, {
        x: clientX,
        y: clientY,
      });
    }
  };

  const handleKeydown = (event) => {
    const { ctrlKey, metaKey, key } = event || {};

    if (!ctrlKey && !metaKey) return;

    if (ZOOM_KEYS[key]) {
      event.preventDefault();
      adjustZoom(ZOOM_KEYS[key], centerViewport());
    }
  };

  const handleCustomWheel = (event) => {
    const originalEvent = event?.detail?.originalEvent;
    if (originalEvent) handleWheel(originalEvent);
  };

  useEffect(() => {
    if (!cy) return;

    cy.userZoomingEnabled(false);
    // Disable user panning to prevent unintended graph movement during box selection
    cy.userPanningEnabled(false);

    const container = cy.container();

    container?.addEventListener('wheel', handleWheel);
    cy.on('drag', 'node', handleDrag);

    document.addEventListener(CUSTOM_WHEEL_EVENT, handleCustomWheel);
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('wheel', preventSwipeNavigation, {
      passive: false,
    });

    return () => {
      container?.removeEventListener('wheel', handleWheel);
      cy.removeListener('drag', 'node', handleDrag);
      document.removeEventListener(CUSTOM_WHEEL_EVENT, handleCustomWheel);
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('wheel', preventSwipeNavigation);
    };
  }, [cy]);
};

useGesturesCytoscape.propTypes = {
  cy: PropTypes.object.isRequired,
  clearUIInteractions: PropTypes.func,
};

export default useGesturesCytoscape;
