import { defineMessages } from 'react-intl';

const m = defineMessages({
  balanceFrom: {
    id: 'balanceFrom',
    defaultMessage: 'Balance for period from (optional)',
  },
  balanceTo: {
    id: 'balanceTo',
    defaultMessage: 'Balance for period to (optional)',
  },
});

export default m;
