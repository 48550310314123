import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Modal, ModalContent } from 'mw-style-react';
import SmartFormSelection from './components/SmartFormSelection';
import sModal from '../../Modal.scss';
import sLocal from './CreateSmartForm.scss';

function CreateSmartForm({
  data,
  visibility,
  callback,
  onClose,
  onContentChange,
}) {
  const [isLocked, setLocked] = useState(false);
  const handleClose = (event) => onClose(event, isLocked);

  return (
    <Modal
      id="formModal"
      className={cn(sModal.modal, sLocal.modal)}
      size="xlarge"
      visibility={visibility}
      onClose={handleClose}
    >
      <ModalContent className={sLocal.content}>
        <SmartFormSelection
          data={data}
          onCreate={callback}
          onClose={handleClose}
          onContentChange={onContentChange}
          handleLockModal={(value) => setLocked(value)}
        />
      </ModalContent>
    </Modal>
  );
}

CreateSmartForm.propTypes = {
  visibility: PropTypes.bool,
  onContentChange: PropTypes.func,
  onClose: PropTypes.func,
  callback: PropTypes.func,
};

export default CreateSmartForm;
