import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  Label,
  Icon,
  Select,
  TextField,
  Button,
  CorezoidLightTheme,
} from 'mw-style-react';

import { useIntl } from 'hooks';
import SelectActors from '@control-front-end/common/components/SelectActors';
import {
  TRANSFER_SIDE,
  NEW_TRANSFER,
} from '@control-front-end/common/constants/actorAccounts';
// eslint-disable-next-line max-len
import SelectActorsAccountPair from '@control-front-end/app/src/components/SelectActorsAccountPair/SelectActorsAccountPair';

import mes from '../../intl';

const TYPE_BY_SIDE = {
  [TRANSFER_SIDE.SOURCE]: 'from',
  [TRANSFER_SIDE.TARGET]: 'to',
};

const MAX_AMOUNT_LENGTH = 17;

/**
 * Account item for transfer (FROM or one of TO)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TransferAccountItem({ side, index, errors = {} }) {
  const t = useIntl();
  const dispatch = useDispatch();

  const type = TYPE_BY_SIDE[side];

  const transferItems = useSelector((state) => state.newNumericTransfer)[type];

  const item = transferItems[index];

  return (
    <Stack.V style={{ position: 'relative' }} justifyContent="spaceBetween">
      <Stack.H fullWidth>
        <div style={{ flex: '1 1 auto' }}>
          <SelectActors
            size="large"
            type="modal"
            bordered={true}
            multiselect={false}
            unspaced
            label={
              <Select.Label
                value={`${t(mes.actor)} ${type}`}
                color={Label.COLOR.gray}
              />
            }
            placeholder={t(mes.selectActor)}
            value={item.actor}
            error={Boolean(errors.actor)}
            helperText={errors.actor}
            manageLayer={false}
            hideCreateBtn={true}
            fullModel={true}
            onChange={({ value }) => {
              dispatch({
                type: NEW_TRANSFER.NUMERIC[side].SET_ACTOR,
                payload: { value, index },
              });
            }}
            popoverOnTop
          />
        </div>
        <div style={{ width: '30%' }}>
          <TextField
            size="large"
            type="float"
            placeholder={t(mes.enterAmount)}
            bordered
            unspaced
            label={
              <Select.Label value={t(mes.amount)} color={Label.COLOR.gray} />
            }
            value={item.value || ''}
            error={Boolean(item.actor && item.account && errors.value)}
            helperText={item.actor && item.account && errors.value}
            onChange={({ value }) => {
              if (value.length > MAX_AMOUNT_LENGTH) return;
              dispatch({
                type: NEW_TRANSFER.NUMERIC[side].SET_VALUE,
                payload: { value, index },
              });
            }}
          />
        </div>
      </Stack.H>
      <Stack.H fullWidth>
        <div style={{ flex: '1 1 auto' }}>
          <SelectActorsAccountPair
            showBalance={false}
            disableSystemAccounts
            actorId={item.actor?.id || null}
            nameId={(item.account || {}).nameId}
            currencyId={(item.account || {}).currencyId}
            preselectedAccount={item.account}
            error={Boolean(item.actor && errors.account)}
            helperText={item.actor && errors.account}
            visibility={item.actor ? 'visible' : 'disabled'}
            unspaced
            label={<TextField.Label value={t(mes.account)} />}
            withPrivs={true}
            onChange={(value) => {
              dispatch({
                type: NEW_TRANSFER.NUMERIC[side].SET_ACCOUNT,
                payload: { value, index },
              });
            }}
          />
        </div>
        <div style={{ width: '30%' }}>
          <TextField
            size="large"
            type="float"
            bordered
            unspaced
            visibility="disabled"
            label={
              <TextField.Label
                value={t(mes.balance)}
                color={Label.COLOR.gray}
              />
            }
            value={item.account?.total.amount || ''}
          />
        </div>
      </Stack.H>
      <Stack.H
        size={Stack.SIZE.xsmall}
        style={{
          position: 'absolute',
          top: `-${CorezoidLightTheme.spaceSize.medium / 2}px`,
          right: `-${CorezoidLightTheme.spaceSize.medium / 2}px`,
        }}
      >
        <Button
          type="quinary"
          onClick={() => {
            dispatch({
              type: NEW_TRANSFER.NUMERIC.COPY,
              payload: { index, side },
            });
          }}
          icon={<Icon type="copy" />}
          size="xxsmall"
        />
        <Button
          type="quinary"
          disabled={transferItems.length === 1}
          onClick={() =>
            dispatch({
              type: NEW_TRANSFER.NUMERIC[side].REMOVE,
              payload: index,
            })
          }
          icon={<Icon type="trash" />}
          size="xxsmall"
        />
      </Stack.H>
    </Stack.V>
  );
}

TransferAccountItem.propTypes = {
  index: PropTypes.number.isRequired,
  side: PropTypes.oneOf(Object.keys(TRANSFER_SIDE)).isRequired,
  errors: PropTypes.shape({
    actor: PropTypes.string,
    account: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default TransferAccountItem;
