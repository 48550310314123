import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorText(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.2, 1.2);
  ctx.translate(-2, -2);
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(11.5, 8);
  ctx.bezierCurveTo(11.5, 7.17157, 10.8284, 6.5, 10, 6.5);
  ctx.lineTo(10, 5.5);
  ctx.bezierCurveTo(10.8178, 5.5, 11.5439, 5.89267, 12, 6.49976);
  ctx.bezierCurveTo(12.4561, 5.89267, 13.1822, 5.5, 14, 5.5);
  ctx.lineTo(14, 6.5);
  ctx.bezierCurveTo(13.1716, 6.5, 12.5, 7.17157, 12.5, 8);
  ctx.lineTo(12.5, 11.5);
  ctx.lineTo(14, 11.5);
  ctx.lineTo(14, 12.5);
  ctx.lineTo(12.5, 12.5);
  ctx.lineTo(12.5, 16);
  ctx.bezierCurveTo(12.5, 16.8284, 13.1716, 17.5, 14, 17.5);
  ctx.lineTo(14, 18.5);
  ctx.bezierCurveTo(13.1822, 18.5, 12.4561, 18.1073, 12, 17.5002);
  ctx.bezierCurveTo(11.5439, 18.1073, 10.8178, 18.5, 10, 18.5);
  ctx.lineTo(10, 17.5);
  ctx.bezierCurveTo(10.8284, 17.5, 11.5, 16.8284, 11.5, 16);
  ctx.lineTo(11.5, 12.5);
  ctx.lineTo(10, 12.5);
  ctx.lineTo(10, 11.5);
  ctx.lineTo(11.5, 11.5);
  ctx.lineTo(11.5, 8);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorText;
