import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useGetGraphCoord } from 'hooks';
import { EXP_NODE } from '@control-front-end/common/constants/graphActors';
import { makeGetActiveLayer } from '@control-front-end/app/src/selectors/graphs';

import ExpNode from './ExpNode';
import ExpandedActorCardView from './ExpandedActorCardView';
import ExpandedChart from './ExpandedChart';
import NodeWidget from './NodeWidget';

/**
 * Открытые акторы на слое
 */
function ExpandedActors({
  graph,
  els,
  isSingleLayerModel = false,
  isLayerReadOnly = false,
  handleRenameNode,
  handleSaveActorLayerSettings,
  handleNodeDragEnd,
  panCenterNodeViewBox,
  handleMakeActiveElement,
  handleLoadLayer,
  handleShowLinkedLayers,
  handleCopyActorLink,
  handleRemoveNode,
  handleClickOutside,
  useActiveLayerElements = true,
}) {
  const activeLayer = useSelector(makeGetActiveLayer) || {};
  const activeFolder = useSelector((state) => state.graphFolders.list[0]) || {};
  const accounts = useSelector((state) => state.accounts);
  const systemForms = useSelector((state) => state.systemForms);
  const dashboardsFormId = systemForms.dashboards.id;
  const containerRef = useRef(null);
  const updateContainerTransform = useCallback(
    ({ pan, zoom }) => {
      if (!pan || !zoom) return;
      const transformStyle = `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`;
      if (containerRef.current) {
        containerRef.current.style.transform = transformStyle;
      }
    },
    [containerRef.current]
  );
  const coord = useGetGraphCoord(graph, updateContainerTransform);

  /**
   * Обработка перемещения открытого актора
   */
  const handleNodeMove = ({ id, position }, nodes) => {
    handleNodeDragEnd(
      {
        e: null,
        graph: graph.current,
        extra: { nodes: [{ id, type: 'node', position }] },
      },
      nodes
    );
  };

  const renderExpanded = () => {
    const nodes = (useActiveLayerElements ? activeLayer.nodes : els).filter(
      (i) => {
        if (i.data.isTrace) return false;
        if (i.data.type !== 'node') return false;

        const el = graph.$(`#${i.id}`);
        if (el.empty()) return false;

        const settings = el.data('layerSettings') || {};
        const isProfile = settings.expandType === EXP_NODE.contentType.profile;
        const isChart = el.data('formId') === dashboardsFormId && !isProfile;
        /**
         * TOMAKE:
         * Simplify different nodes bihaviour and reduce fields amount!
         * 1) Make unified field nodeType: 'node|chart|menu|actorCard|etc...'
         * 2) And than const EXPANDED_NODE_TYPES = ['chart', 'menu', 'actorCard']
         */

        // Charts are collapsed by default when static layer. Otherwise - expanded by default
        const isExpandedChart = activeLayer.isStatic
          ? false
          : !!settings.expand;

        return (
          !!settings.expand ||
          !!settings.profile ||
          (isChart && el.data('actorId') && isExpandedChart) ||
          el.data('widget')?.name
        );
      }
    );
    if (!nodes.length) return null;
    return nodes
      .map((i) => {
        const el = graph.$(`#${i.id}`);

        const { layerSettings = {} } = i.data;

        if (i.data.widget?.name) {
          const p = el.position();
          return (
            <div
              key={i.id}
              style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${p.x}px, ${p.y}px)`,
              }}
            >
              <NodeWidget {...i.data.widget} />
            </div>
          );
        }
        if (
          layerSettings.expandType === EXP_NODE.contentType.cardView ||
          layerSettings.isCardView
        ) {
          return (
            <ExpandedActorCardView
              key={i.id}
              actor={i.data}
              el={el}
              graph={graph}
              handleNodeMove={handleNodeMove}
              handleSaveActorLayerSettings={handleSaveActorLayerSettings}
            />
          );
        }
        if (
          (layerSettings.expandType === EXP_NODE.contentType.chart ||
            i.data.formId === dashboardsFormId) &&
          layerSettings.expandType !== EXP_NODE.contentType.profile &&
          layerSettings.expandType !== EXP_NODE.contentType.layer
        ) {
          return (
            <ExpandedChart
              key={i.id}
              el={el}
              graph={graph}
              handleRemoveNode={handleRemoveNode}
              handleRenameNode={handleRenameNode}
              handleMakeActiveElement={handleMakeActiveElement}
              handleSaveActorLayerSettings={handleSaveActorLayerSettings}
            />
          );
        }

        return (
          <ExpNode
            key={i.id}
            el={el}
            graph={graph}
            activeWorkspace={accounts.active}
            graphFolderId={activeFolder.id}
            layerId={activeLayer.id}
            isSingleLayerModel={isSingleLayerModel}
            isLayerReadOnly={isLayerReadOnly}
            handleMakeActiveElement={handleMakeActiveElement}
            handleLoadLayer={handleLoadLayer}
            handleShowLinkedLayers={handleShowLinkedLayers}
            handleCopyActorLink={handleCopyActorLink}
            handleRemoveNode={handleRemoveNode}
            handleSaveActorLayerSettings={handleSaveActorLayerSettings}
            handleRenameNode={handleRenameNode}
            panCenterNodeViewBox={panCenterNodeViewBox}
            handleClickOutside={handleClickOutside}
          />
        );
      })
      .filter((i) => !!i);
  };

  return (
    <div
      ref={containerRef}
      id="expandedActor"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        transformOrigin: 'left top',
        zIndex: 6,
      }}
    >
      {coord && (activeLayer.nodes || !useActiveLayerElements)
        ? renderExpanded()
        : null}
    </div>
  );
}

ExpandedActors.propTypes = {
  graph: PropTypes.object.isRequired,
  els: PropTypes.array,
  isSingleLayerModel: PropTypes.bool,
  isLayerReadOnly: PropTypes.bool,
  handleSaveActorLayerSettings: PropTypes.func.isRequired,
  handleNodeDragEnd: PropTypes.func.isRequired,
  panCenterNodeViewBox: PropTypes.func,
  handleMakeActiveElement: PropTypes.func.isRequired,
  handleLoadLayer: PropTypes.func.isRequired,
  handleShowLinkedLayers: PropTypes.func.isRequired,
  handleCopyActorLink: PropTypes.func.isRequired,
  handleRenameNode: PropTypes.func,
  handleRemoveNode: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  // If true - nodes form active layer will be used, otherwise - nodes passed via "els" property
  useActiveLayerElements: PropTypes.bool,
};

export default ExpandedActors;
