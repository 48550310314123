import { useEffect } from 'react';

/**
 * A custom hook that listens for a specific key press and invokes the provided handler when the key is pressed.
 *
 * @param {string} targetKey - The key to listen for, specified as a string (e.g., "Enter", "Escape").
 * @param {Function} handler - The function to execute when the target key is pressed.
 * Receives the keyboard event as a parameter.
 */
const useKeyPress = (targetKey, handler) => {
  useEffect(() => {
    const keyListener = (event) => {
      if (event.key === targetKey) {
        handler(event);
      }
    };

    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [targetKey, handler]);
};

export default useKeyPress;
