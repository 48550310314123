import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconRejected(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.white, opacity);
  ctx.strokeStyle = hexToRgba(theme.fullPalette.disabled, opacity);
  ctx.beginPath();
  ctx.arc(8, 8, 7.5, 0, 6.283185307179586, false);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.fullPalette.red, opacity);
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.beginPath();
  ctx.moveTo(8, 2);
  ctx.bezierCurveTo(11.3137085, 2, 14, 4.6862915, 14, 8);
  ctx.bezierCurveTo(14, 11.3137085, 11.3137085, 14, 8, 14);
  ctx.bezierCurveTo(4.6862915, 14, 2, 11.3137085, 2, 8);
  ctx.bezierCurveTo(2, 4.6862915, 4.6862915, 2, 8, 2);
  ctx.closePath();
  ctx.moveTo(6.64075448, 5.28150896);
  ctx.bezierCurveTo(
    6.2654092,
    4.90616368,
    5.65685425,
    4.90616368,
    5.28150896,
    5.28150896
  );
  ctx.bezierCurveTo(
    4.90616368,
    5.65685425,
    4.90616368,
    6.2654092,
    5.28150896,
    6.64075448
  );
  ctx.lineTo(5.28150896, 6.64075448);
  ctx.lineTo(6.64075448, 8);
  ctx.lineTo(5.28150896, 9.35924552);
  ctx.bezierCurveTo(
    4.90616368,
    9.7345908,
    4.90616368,
    10.3431458,
    5.28150896,
    10.718491
  );
  ctx.bezierCurveTo(
    5.65685425,
    11.0938363,
    6.2654092,
    11.0938363,
    6.64075448,
    10.718491
  );
  ctx.lineTo(6.64075448, 10.718491);
  ctx.lineTo(8, 9.35924552);
  ctx.lineTo(9.35924552, 10.718491);
  ctx.bezierCurveTo(
    9.7345908,
    11.0938363,
    10.3431458,
    11.0938363,
    10.718491,
    10.718491
  );
  ctx.bezierCurveTo(
    11.0938363,
    10.3431458,
    11.0938363,
    9.7345908,
    10.718491,
    9.35924552
  );
  ctx.lineTo(10.718491, 9.35924552);
  ctx.lineTo(9.35924552, 8);
  ctx.lineTo(10.718491, 6.64075448);
  ctx.bezierCurveTo(
    11.0938363,
    6.2654092,
    11.0938363,
    5.65685425,
    10.718491,
    5.28150896
  );
  ctx.bezierCurveTo(
    10.3431458,
    4.90616368,
    9.7345908,
    4.90616368,
    9.35924552,
    5.28150896
  );
  ctx.lineTo(9.35924552, 5.28150896);
  ctx.lineTo(8, 6.64075448);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconRejected;
