import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, Icon, Checkbox, Label } from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import Calendar from './Calendar';
import FormOptionColor from '../../FormOptionColor/FormOptionColor';
import mes from '../intl';

import '../Panel.scss';

const _ITEMS_ = {
  check: Checkbox,
  calendar: Calendar,
  edit: TextField,
};

/**
 * Component Multi
 */
function Multi(props) {
  const { id, onChange, extraFields, errors = {} } = props;
  const t = useIntl();
  const addAnswerEl = useRef(null);

  useEffect(() => {
    // Add empty answers
    if (addAnswerEl.current && (!props.value || !props.value.length))
      addAnswerEl.current.click();
  }, []);

  // Adding new static answer
  const handleAddAnswer = () => {
    const value =
      typeof props.value === 'string' || !props.value
        ? []
        : props.value.slice();
    value.push({ value: AppUtils.createRid(), title: '' });
    onChange({ id, value });
  };

  // Remove static answer
  const handleRemoveAnswer = (index, optLen) => {
    if (optLen <= 1) return;
    const value = props.value.slice();
    value.splice(index, 1);
    onChange({ id, value });
  };

  // Change answer value
  const handleAnswersChange = ({ index, value: optVal, title, color }) => {
    const value = props.value.slice();
    value.splice(index, 1, { value: optVal, title, color });
    onChange({ id, value });
  };

  const renderAnswers = (answers) => {
    if (!answers) return null;
    answers = typeof answers === 'string' ? [] : answers;
    const disableRemove = answers.length === 1;
    return answers.map(({ value, title, color }, index) => (
      <div key={index} styleName="panel__multi__content__item">
        <TextField
          id={`value_${value}`}
          label={t(mes.value)}
          value={value}
          bordered={true}
          onChange={({ value: newValue }) =>
            handleAnswersChange({ index, value: newValue, title, color })
          }
        />
        <TextField
          id={`title_${value}`}
          label={t(mes.title)}
          value={title}
          bordered={true}
          onChange={({ value: newTitle }) =>
            handleAnswersChange({ index, value, title: newTitle, color })
          }
        />
        <div styleName="panel__multi__content__item__color">
          <Label fontSize="small" value={t(mes.color)} />
          <FormOptionColor
            value={color}
            onChange={({ hex }) => {
              handleAnswersChange({ index, value, title, color: hex });
            }}
          />
        </div>
        <div
          styleName={cn('panel__multi__content__item__remove', {
            disabled: disableRemove,
          })}
          onClick={() => handleRemoveAnswer(index, answers.length)}
        >
          <Icon
            type="trash"
            visibility={disableRemove ? 'disabled' : 'visible'}
          />
        </div>
      </div>
    ));
  };

  const renderStaticAnswers = () => {
    return (
      <div>
        <div styleName="panel__multi__content__wrap">
          {renderAnswers(props.value)}
        </div>
        <div
          ref={addAnswerEl}
          styleName="panel__multi__content__add"
          onClick={handleAddAnswer}
        >
          {t(mes.addAnswer)}
        </div>
      </div>
    );
  };

  const handleExtraFieldChange = ({
    className,
    id: fieldId,
    value: fieldValue,
  }) => {
    const copyValue = { ...props.value };
    fieldValue =
      className === 'calendar' && fieldValue
        ? fieldValue.startDate
        : fieldValue;
    copyValue[fieldId] = fieldValue;
    onChange({ id, value: copyValue });
  };

  // Get visibility according to interrelated extra fields
  const getExtraFieldVisibility = (id, value) => {
    switch (id) {
      case 'rows':
        return value.multiline ? 'visible' : 'disabled';
      case 'time':
      case 'timeZone':
      case 'dateRange':
        return value.static ? 'disabled' : 'visible';
      default:
        return 'visible';
    }
  };

  const renderExtraFields = () => {
    const value =
      typeof props.value === 'string' || !props.value ? {} : props.value;
    return extraFields.map(({ id, title, type, class: className }) => {
      const ItemName = _ITEMS_[className]; // NOSONAR
      let fieldValue = value[id] || '';
      fieldValue =
        className === 'calendar' ? { startDate: fieldValue } : fieldValue;
      return (
        <div key={id} styleName="panel__multi__extra">
          <ItemName
            styleName={className}
            id={id}
            type={type}
            label={title}
            title={title}
            value={fieldValue}
            errors={errors}
            bordered={true}
            time={value.time}
            timeZone={value.timeZone}
            visibility={getExtraFieldVisibility(id, value)}
            onChange={(e) => handleExtraFieldChange({ className, ...e })}
          >
            <Label value={title} />
          </ItemName>
        </div>
      );
    });
  };

  const renderStaticExtra = () => (
    <div>{renderExtraFields(props.value, extraFields)}</div>
  );

  const renderStatic = useCallback(
    () => (extraFields ? renderStaticExtra() : renderStaticAnswers()),
    [props.value, extraFields]
  );

  return (
    <div styleName="panel__multi">
      <div styleName="panel__multi__content">{renderStatic()}</div>
    </div>
  );
}

Multi.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  extraFields: PropTypes.array,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default Multi;
