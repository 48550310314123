import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { some, property } from 'lodash';
import { useIntl, useNotifications } from 'hooks';
import {
  TextField,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  Divider,
  Stack,
  cr,
  CorezoidLightTheme,
} from 'mw-style-react';
import {
  NEW_TRANSFER,
  ADD_TRANSFER,
  CREATE_TRANSFER,
  TRANSFER_SIDE,
} from '@control-front-end/common/constants/actorAccounts';
import { getNumericTransferSum } from '@control-front-end/utils/modules/utilsTransfer';
import { SwapButtons, TransferAccountItem, TransferItems } from './components';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateTransfer.scss'; // NOSONAR

const isAllTheSameCurrency = (items, { currencyId } = {}) =>
  items.length &&
  currencyId &&
  items.every(({ account }) =>
    account ? Number(account.currencyId) === Number(currencyId) : true
  );

const makeTransferItem = ({
  actorId,
  actorPicture,
  actorPictureUrl,
  actorTitle,
  color,
  amount,
  incomeType,
  nameId,
  accountName,
  currencyId,
  currencyName,
  availableAmount,
  ...account
}) => {
  return {
    actor: {
      id: actorId,
      actorPicture,
      actorPictureUrl,
      title: actorTitle,
      color,
    },
    account: {
      nameId,
      accountName,
      currencyId,
      currencyName,
      total: {
        ...account,
        amount: availableAmount,
        accountName,
        currencyName,
      },
    },
    value: Math.abs(amount),
  };
};

const initialErrors = {
  from: [],
  to: [],
  total: false,
};

function CreateFormFieldTransferModal({ visibility, onClose, isSubmit, data }) {
  const t = useIntl();
  const dispatch = useDispatch();

  const newTransfer = useSelector((state) => state.newNumericTransfer);

  const [comment, setComment] = useState(data.comment || '');
  const [errors, setErrors] = useState(initialErrors);
  const [failedSubmitAttempt, setFailedSubmitAttempt] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { showNotification } = useNotifications();

  // Prefilling transfer data from props in case of repeat
  useEffect(() => {
    if (!data.from || !data.to) return;
    dispatch({
      type: NEW_TRANSFER.NUMERIC.SET,
      payload: {
        from: data.from.map(makeTransferItem),
        to: data.to.map(makeTransferItem),
      },
    });
  }, [data.from, data.to]);

  useEffect(() => {
    if (!newTransfer.from.length || !newTransfer.to.length) return {};

    const allItems = [...newTransfer.from, ...newTransfer.to];

    const allTheSameCurrency = isAllTheSameCurrency(
      allItems,
      allItems.find(({ account }) => account)?.account
    );

    const sourcesTotal = getNumericTransferSum(
      newTransfer.from.map(property('value'))
    );

    const targetsTotal = getNumericTransferSum(
      newTransfer.to.map(property('value'))
    );

    const getItemErrors = (item) => {
      return {
        actor: cr([!item.actor?.id, t(mes.errorEmptyActor)]),
        account: cr(
          [
            !item.account?.currencyId || !item.account?.nameId,
            t(mes.errorEmptyAccount),
          ],
          [!allTheSameCurrency, t(mes.errorDifferentCurrency)]
        ),
        value: cr([
          !Number.parseFloat(item.value || 0),
          t(mes.errorEmptyValue),
        ]),
      };
    };

    setErrors({
      from: newTransfer.from.map(getItemErrors),
      to: newTransfer.to.map(getItemErrors),
      total: cr(
        [sourcesTotal !== targetsTotal, t(mes.errorDifferentTotal)],
        [!sourcesTotal || !targetsTotal, true]
      ),
    });
  }, [newTransfer]);

  const errorsToShow = failedSubmitAttempt ? errors : initialErrors;

  const haveErrors =
    errors.total ||
    some([...errors.to, ...errors.from], (item) =>
      some(item, (error) => error)
    );

  useEffect(() => {
    if (!haveErrors) setFailedSubmitAttempt(false);
  }, [haveErrors]);

  const handleSwap = (index) => {
    dispatch({
      type: NEW_TRANSFER.NUMERIC.SWAP,
      payload: { index },
    });
  };

  const makeTransfer = ({ from, to }) => {
    setLoading(true);
    dispatch({
      type: CREATE_TRANSFER.REQUEST,
      payload: {
        from: from.map(({ account, value }) => ({
          accountId: account.debit ? account.debit.id : account.total.id,
          amount: Number.parseFloat(value || 0),
        })),
        to: to.map(({ account, value }) => ({
          accountId: account.credit ? account.credit.id : account.total.id,
          amount: Number.parseFloat(value || 0),
        })),
        comment,
      },
      callback: (data) => {
        showNotification('success', t(mes.transferCreated));
        dispatch({
          type: ADD_TRANSFER.REQUEST,
          payload: { transfer: data },
          callback: () => {
            setLoading(false);
          },
        });
        dispatch({ type: NEW_TRANSFER.NUMERIC.CLEAR });
        onClose();
      },
      errorCallback: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      styleName="m.modal l.modal"
      size="xlarge"
      onClose={(...args) => {
        dispatch({ type: NEW_TRANSFER.NUMERIC.CLEAR });
        onClose(...args);
      }}
      label={t(mes.makeTransfer)}
      visibility={visibility}
    >
      <ModalContent
        id="modalContent"
        styleName="m.modal__content l.modal__content"
      >
        <Stack.V>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr',
              gap: CorezoidLightTheme.spaceSize.micro,
              width: '100%',
            }}
          >
            <TransferItems
              key="sources"
              alignItems="flexStart"
              style={{ flex: '1 1 auto' }}
              items={newTransfer.from}
              onAdd={() => dispatch({ type: NEW_TRANSFER.NUMERIC.SOURCE.ADD })}
              errorTotal={errorsToShow.total}
            >
              {(item, index) => (
                <Stack.V key={index}>
                  <TransferAccountItem
                    index={index}
                    item={item}
                    side={TRANSFER_SIDE.SOURCE}
                    errors={errorsToShow.from[index]}
                  />
                </Stack.V>
              )}
            </TransferItems>
            <SwapButtons
              from={newTransfer.from}
              to={newTransfer.to}
              onSwap={handleSwap}
            />
            <TransferItems
              key="targets"
              alignItems="flexStart"
              style={{ flex: '1 1 auto' }}
              items={newTransfer.to}
              onAdd={() => dispatch({ type: NEW_TRANSFER.NUMERIC.TARGET.ADD })}
              errorTotal={errorsToShow.total}
            >
              {(item, index) => (
                <Stack.V key={index}>
                  <TransferAccountItem
                    index={index}
                    item={item}
                    side={TRANSFER_SIDE.TARGET}
                    errors={errorsToShow.to[index]}
                  />
                </Stack.V>
              )}
            </TransferItems>
          </div>
          <Divider />
          <TextField
            styleName="m.textfield"
            size="large"
            bordered
            unspaced
            multiline
            multilineType="text"
            rows={2}
            value={comment}
            label={
              <TextField.Label fontWeight="semibold" value={t(mes.comment)} />
            }
            onChange={({ value }) => setComment(value)}
          />
        </Stack.V>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.makeTransfer)}
          onClick={() => {
            if (failedSubmitAttempt) return; // First of all - fix all errors before new submit attempt
            if (haveErrors) {
              setFailedSubmitAttempt(true);
              return;
            }
            setLoading(true);
            makeTransfer(newTransfer);
          }}
          disabled={failedSubmitAttempt || isLoading}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateFormFieldTransferModal.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool,
  data: PropTypes.shape({
    from: PropTypes.arrayOf(PropTypes.object),
    to: PropTypes.arrayOf(PropTypes.object),
    comment: PropTypes.string,
  }).isRequired,
};

export default CreateFormFieldTransferModal;
