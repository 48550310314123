import { useMemo } from 'react';
import getURLSettings from '@control-front-end/utils/getURLSettings';

const useURLSettings = () => {
  const search = document.location.search;
  const urlSettings = useMemo(getURLSettings, [search]);
  return urlSettings;
};

export default useURLSettings;
