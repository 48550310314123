import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorHand(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.5454545454545467, 0);
  ctx.scale(1.0909090909090908, 1.0909090909090908);
  ctx.save();
  ctx.fillStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(5.83056, 14.4731);
  ctx.bezierCurveTo(5.55268, 14.0969, 5.21512, 13.3277, 4.61435, 12.394);
  ctx.bezierCurveTo(4.27384, 11.8658, 3.42944, 10.8713, 3.17798, 10.3662);
  ctx.bezierCurveTo(2.95978, 9.91979, 2.98327, 9.71964, 3.03512, 9.34972);
  ctx.bezierCurveTo(3.1271, 8.69162, 3.75722, 8.17918, 4.42942, 8.24834);
  ctx.bezierCurveTo(4.93724, 8.29969, 5.36776, 8.65913, 5.75522, 8.99866);
  ctx.bezierCurveTo(5.98907, 9.20301, 6.27674, 9.60017, 6.44992, 9.82443);
  ctx.bezierCurveTo(6.60941, 10.0298, 6.64855, 10.1147, 6.8188, 10.3578);
  ctx.bezierCurveTo(7.04384, 10.6795, 7.11429, 10.8388, 7.02819, 10.4846);
  ctx.bezierCurveTo(6.95872, 9.96486, 6.84522, 9.07726, 6.68084, 8.29235);
  ctx.bezierCurveTo(6.5556, 7.69713, 6.52526, 7.60386, 6.40589, 7.14696);
  ctx.bezierCurveTo(6.27967, 6.66072, 6.2151, 6.32014, 6.0967, 5.80456);
  ctx.bezierCurveTo(6.01451, 5.43988, 5.86677, 4.6948, 5.82665, 4.27562);
  ctx.bezierCurveTo(5.77088, 3.7024, 5.74152, 2.76765, 6.08496, 2.33799);
  ctx.bezierCurveTo(6.35404, 2.00161, 6.97144, 1.89996, 7.35401, 2.10745);
  ctx.bezierCurveTo(7.85498, 2.37886, 8.13971, 3.15852, 8.26985, 3.46976);
  ctx.bezierCurveTo(8.5037, 4.02936, 8.64851, 4.67593, 8.77473, 5.52476);
  ctx.bezierCurveTo(8.93519, 6.60518, 9.23069, 8.10477, 9.24047, 8.4202);
  ctx.bezierCurveTo(9.26395, 8.03351, 9.17394, 7.21927, 9.23656, 6.8483);
  ctx.bezierCurveTo(9.29331, 6.51191, 9.55749, 6.12103, 9.88821, 6.01519);
  ctx.bezierCurveTo(10.168, 5.92612, 10.4958, 5.89363, 10.7845, 5.95756);
  ctx.bezierCurveTo(11.0907, 6.02462, 11.4136, 6.25936, 11.534, 6.48048);
  ctx.bezierCurveTo(11.8882, 7.13439, 11.895, 8.4705, 11.9097, 8.39924);
  ctx.bezierCurveTo(11.9938, 8.00522, 11.9792, 7.11133, 12.1876, 6.73931);
  ctx.bezierCurveTo(12.3246, 6.4941, 12.6739, 6.27298, 12.8598, 6.23735);
  ctx.bezierCurveTo(13.1474, 6.18286, 13.5007, 6.1661, 13.803, 6.22897);
  ctx.bezierCurveTo(14.0466, 6.28032, 14.3764, 6.59051, 14.4654, 6.73931);
  ctx.bezierCurveTo(14.6787, 7.0998, 14.8, 8.11944, 14.8362, 8.47679);
  ctx.bezierCurveTo(14.8509, 8.62455, 14.9086, 8.066, 15.1229, 7.70551);
  ctx.bezierCurveTo(15.5202, 7.03588, 16.9262, 6.90594, 16.98, 8.37514);
  ctx.bezierCurveTo(17.0045, 9.06049, 16.9996, 9.02905, 16.9996, 9.49014);
  ctx.bezierCurveTo(16.9996, 10.0319, 16.9879, 10.3578, 16.9605, 10.7498);
  ctx.bezierCurveTo(16.9301, 11.1689, 16.846, 12.1163, 16.7237, 12.5753);
  ctx.bezierCurveTo(16.6395, 12.8907, 16.3607, 13.6001, 16.0857, 14.0256);
  ctx.bezierCurveTo(16.0857, 14.0256, 15.0349, 15.3355, 14.9204, 15.9255);
  ctx.bezierCurveTo(14.8049, 16.5144, 14.8431, 16.5186, 14.8206, 16.9368);
  ctx.bezierCurveTo(14.7981, 17.3538, 14.939, 17.903, 14.939, 17.903);
  ctx.bezierCurveTo(14.939, 17.903, 14.1543, 18.0119, 13.7316, 17.9396);
  ctx.bezierCurveTo(13.349, 17.8736, 12.8754, 17.0583, 12.7531, 16.8089);
  ctx.bezierCurveTo(12.5848, 16.4652, 12.2257, 16.5312, 12.0858, 16.7848);
  ctx.bezierCurveTo(11.8657, 17.1862, 11.3921, 17.9061, 11.0575, 17.9512);
  ctx.bezierCurveTo(10.4039, 18.0392, 9.04772, 17.9836, 7.98609, 17.9721);
  ctx.bezierCurveTo(7.98609, 17.9721, 8.16711, 16.9127, 7.76399, 16.549);
  ctx.bezierCurveTo(7.46556, 16.2776, 6.95187, 15.7274, 6.64464, 15.4382);
  ctx.lineTo(5.83056, 14.4731);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.black;
  ctx.lineWidth = 0.75;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.beginPath();
  ctx.moveTo(5.83056, 14.4731);
  ctx.bezierCurveTo(5.55268, 14.0969, 5.21512, 13.3277, 4.61435, 12.394);
  ctx.bezierCurveTo(4.27384, 11.8658, 3.42944, 10.8713, 3.17798, 10.3662);
  ctx.bezierCurveTo(2.95978, 9.91979, 2.98327, 9.71964, 3.03512, 9.34972);
  ctx.bezierCurveTo(3.1271, 8.69162, 3.75722, 8.17918, 4.42942, 8.24834);
  ctx.bezierCurveTo(4.93724, 8.29969, 5.36776, 8.65913, 5.75522, 8.99866);
  ctx.bezierCurveTo(5.98907, 9.20301, 6.27674, 9.60017, 6.44992, 9.82443);
  ctx.bezierCurveTo(6.60941, 10.0298, 6.64855, 10.1147, 6.8188, 10.3578);
  ctx.bezierCurveTo(7.04384, 10.6795, 7.11429, 10.8388, 7.02819, 10.4846);
  ctx.bezierCurveTo(6.95872, 9.96486, 6.84522, 9.07726, 6.68084, 8.29235);
  ctx.bezierCurveTo(6.5556, 7.69713, 6.52526, 7.60386, 6.40589, 7.14696);
  ctx.bezierCurveTo(6.27967, 6.66072, 6.2151, 6.32014, 6.0967, 5.80456);
  ctx.bezierCurveTo(6.01451, 5.43988, 5.86677, 4.6948, 5.82665, 4.27562);
  ctx.bezierCurveTo(5.77088, 3.7024, 5.74152, 2.76765, 6.08496, 2.33799);
  ctx.bezierCurveTo(6.35404, 2.00161, 6.97144, 1.89996, 7.35401, 2.10745);
  ctx.bezierCurveTo(7.85498, 2.37886, 8.13971, 3.15852, 8.26985, 3.46976);
  ctx.bezierCurveTo(8.5037, 4.02936, 8.64851, 4.67593, 8.77473, 5.52476);
  ctx.bezierCurveTo(8.93519, 6.60518, 9.23069, 8.10477, 9.24047, 8.4202);
  ctx.bezierCurveTo(9.26395, 8.03351, 9.17394, 7.21927, 9.23656, 6.8483);
  ctx.bezierCurveTo(9.29331, 6.51191, 9.55749, 6.12103, 9.88821, 6.01519);
  ctx.bezierCurveTo(10.168, 5.92612, 10.4958, 5.89363, 10.7845, 5.95756);
  ctx.bezierCurveTo(11.0907, 6.02462, 11.4136, 6.25936, 11.534, 6.48048);
  ctx.bezierCurveTo(11.8882, 7.13439, 11.895, 8.4705, 11.9097, 8.39924);
  ctx.bezierCurveTo(11.9938, 8.00522, 11.9792, 7.11133, 12.1876, 6.73931);
  ctx.bezierCurveTo(12.3246, 6.4941, 12.6739, 6.27298, 12.8598, 6.23735);
  ctx.bezierCurveTo(13.1474, 6.18286, 13.5007, 6.1661, 13.803, 6.22897);
  ctx.bezierCurveTo(14.0466, 6.28032, 14.3764, 6.59051, 14.4654, 6.73931);
  ctx.bezierCurveTo(14.6787, 7.0998, 14.8, 8.11944, 14.8362, 8.47679);
  ctx.bezierCurveTo(14.8509, 8.62455, 14.9086, 8.066, 15.1229, 7.70551);
  ctx.bezierCurveTo(15.5202, 7.03588, 16.9262, 6.90594, 16.98, 8.37514);
  ctx.bezierCurveTo(17.0045, 9.06049, 16.9996, 9.02905, 16.9996, 9.49014);
  ctx.bezierCurveTo(16.9996, 10.0319, 16.9879, 10.3578, 16.9605, 10.7498);
  ctx.bezierCurveTo(16.9301, 11.1689, 16.846, 12.1163, 16.7237, 12.5753);
  ctx.bezierCurveTo(16.6395, 12.8907, 16.3607, 13.6001, 16.0857, 14.0256);
  ctx.bezierCurveTo(16.0857, 14.0256, 15.0349, 15.3355, 14.9204, 15.9255);
  ctx.bezierCurveTo(14.8049, 16.5144, 14.8431, 16.5186, 14.8206, 16.9368);
  ctx.bezierCurveTo(14.7981, 17.3538, 14.939, 17.903, 14.939, 17.903);
  ctx.bezierCurveTo(14.939, 17.903, 14.1543, 18.0119, 13.7316, 17.9396);
  ctx.bezierCurveTo(13.349, 17.8736, 12.8754, 17.0583, 12.7531, 16.8089);
  ctx.bezierCurveTo(12.5848, 16.4652, 12.2257, 16.5312, 12.0858, 16.7848);
  ctx.bezierCurveTo(11.8657, 17.1862, 11.3921, 17.9061, 11.0575, 17.9512);
  ctx.bezierCurveTo(10.4039, 18.0392, 9.04772, 17.9836, 7.98609, 17.9721);
  ctx.bezierCurveTo(7.98609, 17.9721, 8.16711, 16.9127, 7.76399, 16.549);
  ctx.bezierCurveTo(7.46556, 16.2776, 6.95187, 15.7274, 6.64464, 15.4382);
  ctx.lineTo(5.83056, 14.4731);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'black';
  ctx.font = "   15px ''";
  ctx.beginPath();
  ctx.moveTo(10, 11.5);
  ctx.bezierCurveTo(10, 11.2239, 9.77614, 11, 9.5, 11);
  ctx.bezierCurveTo(9.22386, 11, 9, 11.2239, 9, 11.5);
  ctx.lineTo(9, 14.5);
  ctx.bezierCurveTo(9, 14.7761, 9.22386, 15, 9.5, 15);
  ctx.bezierCurveTo(9.77614, 15, 10, 14.7761, 10, 14.5);
  ctx.lineTo(10, 11.5);
  ctx.closePath();
  ctx.moveTo(11.5, 11);
  ctx.bezierCurveTo(11.7761, 11, 12, 11.2239, 12, 11.5);
  ctx.lineTo(12, 14.5);
  ctx.bezierCurveTo(12, 14.7761, 11.7761, 15, 11.5, 15);
  ctx.bezierCurveTo(11.2239, 15, 11, 14.7761, 11, 14.5);
  ctx.lineTo(11, 11.5);
  ctx.bezierCurveTo(11, 11.2239, 11.2239, 11, 11.5, 11);
  ctx.closePath();
  ctx.moveTo(13.5, 11);
  ctx.bezierCurveTo(13.7761, 11, 14, 11.2239, 14, 11.5);
  ctx.lineTo(14, 14.5);
  ctx.bezierCurveTo(14, 14.7761, 13.7761, 15, 13.5, 15);
  ctx.bezierCurveTo(13.2239, 15, 13, 14.7761, 13, 14.5);
  ctx.lineTo(13, 11.5);
  ctx.bezierCurveTo(13, 11.2239, 13.2239, 11, 13.5, 11);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorHand;
