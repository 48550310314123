import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  inviteUsers: {
    id: 'inviteUsers_Menu',
    defaultMessage: 'Invite Users',
  },
  accountTemplates: {
    id: 'accountTemplates_Menu',
    defaultMessage: 'Account Templates',
  },
  smartForms: {
    id: 'smartForms_Menu',
    defaultMessage: 'Smart Forms',
  },
});

export default { ...globalIntl, ...m };
