import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Label,
  Stack,
  Space,
  Button,
  ProgressBar,
  Utils,
  Divider,
  cr,
} from 'mw-style-react';
import { SPACE_SIZE } from 'mw-style-react/build/constants';
import AppUtils from '@control-front-end/utils/utils';
import { ASYNC_TASK_STATUS } from '@control-front-end/common/constants';
import {
  CREATE_SMART_FORM,
  GET_SMART_FORMS_TEMPLATES,
} from '@control-front-end/common/constants/smartForms';
import { RE_APP_SHORT_NAME } from '@control-front-end/common/constants/regExp';
import { useIntl, useNotifications } from 'hooks';
import ScriptParamsEdit from '../../../CreateScriptApp/components/ScriptParamsEdit';
import CategoriesList from './components/CategoriesList';
import TemplatesList from './components/TemplatesList';
import SmartFormSettings from './components/SmartFormSettings';
import scss from './SmartFormSelection.scss';
import mes from './intl';

const SCRIPT_CATEGORY = {
  category: 'Script',
  templates: [{ group: 'script' }],
};

const SETTINGS_FIELDS = [
  { id: 'title', autofocus: true, required: true },
  {
    id: 'shortName',
    autofocus: false,
    required: true,
    hint: true,
    regexp: RE_APP_SHORT_NAME,
  },
  { id: 'description', autofocus: false, required: false },
];

function SmartFormSelection({
  data,
  onCreate,
  onClose,
  onContentChange,
  handleLockModal = () => {},
}) {
  const t = useIntl();
  const onSubmitRef = useRef(null);
  const showNotification = useNotifications();
  const [categoriesList, setCategoriesList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const categoriesToShow = useMemo(() => {
    return searchQuery
      ? categoriesList.filter((category) =>
          category.templates.some(
            (item) =>
              item.data?.title?.toLowerCase().indexOf(searchQuery) !== -1
          )
        )
      : [SCRIPT_CATEGORY, ...categoriesList];
  }, [categoriesList, searchQuery]);

  const activeCategoryData = useMemo(() => {
    return categoriesToShow?.[activeCategory] || {};
  }, [categoriesToShow, searchQuery, activeCategory]);

  const templatesToShow = useMemo(() => {
    if (activeCategoryData.category === SCRIPT_CATEGORY.category) return [];
    const activeCategoryTemplates = activeCategoryData.templates || [];
    return searchQuery
      ? activeCategoryTemplates.filter(
          (item) => item.data?.title?.toLowerCase().indexOf(searchQuery) !== -1
        )
      : activeCategoryTemplates;
  }, [activeCategoryData]);

  const activeTemplateData = useMemo(
    () =>
      AppUtils.isUndefined(activeTemplate) ||
      !templatesToShow?.[activeTemplate] ||
      templatesToShow[activeTemplate].data?.disabled
        ? null
        : templatesToShow?.[activeTemplate],
    [categoriesList, activeCategory, activeTemplate]
  );

  useEffect(() => {
    dispatch({
      type: GET_SMART_FORMS_TEMPLATES.REQUEST,
      payload: {},
      callback: (data) => {
        const groupedCategories = AppUtils.groupBy(data, 'data.category');
        const list = Object.keys(groupedCategories).map((category) => ({
          category: Utils.capitalize(category),
          templates: groupedCategories[category],
        }));
        setCategoriesList(list);
      },
    });
  }, []);

  const handleFieldChange = (id, value) => {
    setValues((prevSettings) => ({
      ...prevSettings,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: '',
    }));
  };

  const handleFieldError = (id, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: value,
    }));
  };

  const handleCreate = useCallback(() => {
    if (AppUtils.isUndefined(activeTemplateData)) {
      showNotification.error(t(mes.errorNoTemplateSelected));
      return;
    }
    const { title, shortName, description } = values;
    const fieldsErrors = [];
    SETTINGS_FIELDS.forEach(({ id, regexp, required }) => {
      const fieldValue = values[id];
      if (required && !fieldValue) {
        fieldsErrors.push(id);
        handleFieldError(id, t(mes.fieldRequired));
        return;
      }
      if (!regexp) return;
      const check = new RegExp(regexp);
      if (!check.test(fieldValue)) {
        fieldsErrors.push(id);
        handleFieldError(id, t(mes.wrongFormat));
      }
    });
    if (fieldsErrors.length > 0) {
      return;
    }
    setLoading(true);
    handleLockModal(true);
    dispatch({
      type: CREATE_SMART_FORM.REQUEST,
      payload: {
        fileUrl: activeTemplateData.url,
        smartFormRef: activeTemplateData.data.ref,
        title,
        ref: shortName,
        description,
      },
      callback: ({ task, actor }) => {
        setLoading(false);
        handleLockModal(false);

        if (task?.status !== ASYNC_TASK_STATUS.completed) {
          showNotification.error(
            `${t(mes.errorCreatingSmartForm)} ${task?.details}`
          );
          return;
        }
        onCreate(actor);
        onClose();
      },
      errorCallback: () => {
        setLoading(false);
        handleLockModal(false);
      },
    });
  }, [values, errors, activeTemplateData]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setActiveCategory(0);
    setActiveTemplate(null);
  };

  return (
    <Stack.V fullWidth fullHeight size={Stack.SIZE.none} divider={<Divider />}>
      <Space top bottom size={SPACE_SIZE.xsmall}>
        <Space left right size={SPACE_SIZE.medium}>
          <Stack.H fullWidth alignItems="center" justifyContent="spaceBetween">
            <Button
              styleName="cf__header__btn"
              type="text"
              size="smallplus"
              fontWeight="normal"
              label={t(mes.cancel)}
              disabled={isLoading}
              onClick={onClose}
            />
            <Label
              value={t(mes.createSmartForm)}
              fontSize="large"
              fontWeight="semibold"
            />
            <Button
              size="smallplus"
              fontWeight="normal"
              label={t(mes.create)}
              disabled={isLoading}
              onClick={
                activeCategoryData.category === SCRIPT_CATEGORY.category
                  ? onSubmitRef?.current
                  : handleCreate
              }
            />
          </Stack.H>
        </Space>
      </Space>
      <Stack.H
        fullWidth
        size={Stack.SIZE.none}
        divider={<Divider className={scss.dividerVertical} />}
        className={scss.sections}
      >
        <Space size={SPACE_SIZE.medium} className={scss.categoriesSection}>
          <CategoriesList
            categories={categoriesToShow}
            activeCategory={activeCategory}
            handleSearch={handleSearch}
            handleSelect={(index) => {
              setActiveCategory(index);
              setActiveTemplate(null);
            }}
          />
        </Space>
        {cr(
          [
            activeCategoryData.category === SCRIPT_CATEGORY.category,

            <Space
              fullWidth
              size={SPACE_SIZE.large}
              className={scss.templateSection}
            >
              <Stack.V fullWidth fullHeight>
                <ScriptParamsEdit
                  data={data}
                  values={values}
                  errors={errors}
                  isEmbedded
                  dispatch={dispatch}
                  handleOnChange={handleFieldChange}
                  onSubmitRef={onSubmitRef}
                  onContentChange={onContentChange}
                />
              </Stack.V>
            </Space>,
          ],
          [
            true,
            <Stack.V
              className={scss.templateSection}
              fullWidth
              fullHeight
              divider={<Divider />}
            >
              <SmartFormSettings
                fields={SETTINGS_FIELDS}
                values={values}
                errors={errors}
                onChange={handleFieldChange}
              />
              <TemplatesList
                className={scss.templatesList}
                templates={templatesToShow}
                activeTemplate={activeTemplate}
                onSelectTemplate={(index) => {
                  if (templatesToShow?.[index]?.data?.disabled) return;
                  setActiveTemplate(index);
                }}
              />
            </Stack.V>,
          ]
        )}
      </Stack.H>
      {isLoading ? (
        <Stack.V fullWidth fullHeight className={scss.loaderBlock}>
          <Stack.H className={scss.loaderLabel}>
            <ProgressBar type="circle" size="small" />
            <Label value={t(mes.pleaseWaitWhileCreating)} />
          </Stack.H>
        </Stack.V>
      ) : null}
    </Stack.V>
  );
}

SmartFormSelection.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleLockModal: PropTypes.func,
  onContentChange: PropTypes.func,
};

export default SmartFormSelection;
