import { call, takeLatest, select, put, takeEvery } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import {
  SET_ACTORS_REQ_STATUS,
  GET_ACTORS_LIST,
  WS_UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import { RequestStatus, ORDER } from '@control-front-end/common/constants';
import {
  ADD_ACTOR_FOLDER,
  GET_ACTORS_FOLDERS,
  GET_FOLDER_ACTORS,
  HIDE_ACTOR_FOLDER,
  SET_ACTIVE_ACTORS_FOLDER,
  UPDATE_ACTOR_FOLDER,
  DEFAULT_FOLDERS_LIMIT,
  FOLDER_ACCESS,
} from '@control-front-end/common/constants/actorsFolders';
import { extendModelWithAvatars } from './graph/graphHelpers';
import { getActor } from './graph/graphNodes';

function* getActorsFolders({ payload, callback }) {
  const { loadMore, starred, localState } = payload;
  let foldersState;
  if (localState) {
    foldersState = localState;
  } else {
    foldersState = yield select((state) => state.actorsFolders);
  }
  const systemForms = yield select((state) => state.systemForms);
  const formId = systemForms.state.id;
  const { list, limit, offset, endList } = foldersState;
  if (endList) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams: {
      limit,
      offset,
      orderBy: 'created_at',
      orderValue: ORDER.ASC,
      starred,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const newActorsList = loadMore ? structuredClone(list) : [];
  newActorsList.push(...data.data.list);
  yield put({
    type: GET_ACTORS_FOLDERS.SUCCESS,
    payload: {
      list: newActorsList,
      limit,
      offset: offset + limit,
      endList: !data.data.list,
    },
  });
  if (callback) callback();
}

/**
 * Get actors added to folder (state actor)
 */
export function* getFolderActors({ payload, callback }) {
  const {
    folderId,
    formId,
    loadMore,
    orderBy = 'updated_at',
    orderValue = ORDER.DESC,
    orderWithStarred = false,
    search,
    limit: payloadLimit,
    offset: payloadOffset,
    withStats = true,
  } = payload;
  const { list: folders } = yield select((state) => state.actorsFolders);
  let activeFolder = folders.find((i) => i.id === folderId);
  if (folders.length && !activeFolder) {
    activeFolder = yield getActor({ payload: { id: folderId } });
    const copyList = folders.slice();
    copyList.push(activeFolder);
    const foldersState = yield select((state) => state.actorsFolders);
    yield put({
      type: ADD_ACTOR_FOLDER.SUCCESS,
      payload: {
        list: copyList,
        offset: foldersState.offset + 1,
        total: foldersState.total + 1,
      },
    });
  }
  const {
    reqStatus,
    list,
    offset: stateOffset,
  } = yield select((state) => state.actorsList);
  const limit = payloadLimit || DEFAULT_FOLDERS_LIMIT;
  if (reqStatus === RequestStatus.PROGRESS) return;
  yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.PROGRESS });
  yield put({ type: SET_ACTIVE_ACTORS_FOLDER, payload: folderId });
  const edgeType = 'hierarchy';
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeId = edgeTypes.find((i) => i.name === edgeType)?.id;
  const offset = AppUtils.isUndefined(payloadOffset)
    ? stateOffset
    : payloadOffset;
  const queryParams = {
    edgeTypeId,
    formId,
    limit,
    offset,
    withStats,
    orderBy,
    orderValue,
    orderWithStarred,
    search,
  };
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph/children/${folderId}`,
    queryParams,
  });
  // Ставим флаг окончания загрузки
  yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.SUCCESS });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data.list) {
    yield call(extendModelWithAvatars, obj);
  }
  const newActorsList = loadMore ? structuredClone(list) : [];
  newActorsList.push(...data.data.list);
  yield put({
    type: GET_ACTORS_LIST.SUCCESS,
    payload: {
      list: newActorsList,
      limit,
      offset: offset + limit,
      endList: data.data.list.length < limit,
      total: data.data.total,
      formId,
      orderBy,
      orderValue,
    },
  });
  if (callback) callback();
}

function* addFolder({ payload, callback }) {
  const { list, offset } = yield select((state) => state.actorsFolders);
  yield put({
    type: ADD_ACTOR_FOLDER.SUCCESS,
    payload: {
      list: [...list, payload],
      offset: offset + 1,
    },
  });
  if (callback) callback(payload);
}

function* hideFolder({ payload, callback }) {
  const { list, offset } = yield select((state) => state.actorsFolders);
  const copyList = list.filter((i) => i.id !== payload.id);
  yield put({
    type: HIDE_ACTOR_FOLDER.SUCCESS,
    payload: {
      list: copyList,
      offset: Math.max(0, offset - 1),
    },
  });
  if (callback) callback(payload);
}

function* manageFolderAccess({ payload }) {
  const { id, access } = payload;
  const { list } = yield select((state) => state.actorsFolders);
  if (!list.some((folder) => folder.id === id)) return;
  const updatedList = list.map((folder) =>
    folder.id === id ? { ...folder, access } : folder
  );
  yield put({
    type: FOLDER_ACCESS.SUCCESS,
    payload: { list: updatedList },
  });
}

function* wsUpdateFolder({ payload }) {
  const { model } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  if (model.accId !== accId) return;
  const { list } = yield select((state) => state.actorsFolders);
  const findIndex = list.findIndex((i) => i.id === model.id);
  if (findIndex === -1) return;
  const copyList = structuredClone(list);
  copyList.splice(findIndex, 1, { ...copyList[findIndex], ...model });
  yield put({
    type: UPDATE_ACTOR_FOLDER.SUCCESS,
    payload: { list: copyList },
  });
}

function* actorsFolders() {
  yield takeLatest(GET_ACTORS_FOLDERS.REQUEST, getActorsFolders);
  yield takeEvery(GET_FOLDER_ACTORS.REQUEST, getFolderActors);
  yield takeLatest(ADD_ACTOR_FOLDER.REQUEST, addFolder);
  yield takeLatest(HIDE_ACTOR_FOLDER.REQUEST, hideFolder);
  yield takeEvery(FOLDER_ACCESS.REQUEST, manageFolderAccess);
  yield takeEvery(WS_UPDATE_ACTOR, wsUpdateFolder);
}

export default actorsFolders;
