import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
} from 'mw-style-react';
import useIntl from 'useIntl';
import mes from './intl';
import ScriptParamsEdit from './components/ScriptParamsEdit';

function CreateScriptApp(props) {
  const { data = {}, visibility, onClose } = props;
  const onSubmitHandlerRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const t = useIntl();

  const handleIsSubmitChange = (value) => {
    setIsSubmit(value);
  };

  return (
    <Modal
      styleName="m.modal l.modal"
      size="xlarge"
      visibility={visibility}
      onClose={onClose}
      label={data.id ? t(mes.editScriptApp) : t(mes.createScriptApp)}
    >
      <ModalContent
        enablePopoverBounding={false}
        styleName="m.modal__content l.modal__content"
      >
        <div styleName="l.content__wrap">
          <ScriptParamsEdit
            {...props}
            handleIsSubmitChange={handleIsSubmitChange}
            onSubmitRef={onSubmitHandlerRef}
          />
        </div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={data.id ? t(mes.save) : t(mes.create)}
          size="large"
          fontWeight="normal"
          onClick={() => {
            onSubmitHandlerRef?.current();
          }}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateScriptApp.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmit: PropTypes.bool,
};

export default CreateScriptApp;
