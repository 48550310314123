import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL, DEL_MODAL, UPDATE_USER_SETTINGS } from 'constants';
import { useModal, useNotifications } from 'hooks';
import {
  UPDATE_ACTOR,
  REMOVE_ACTOR,
  SEARCH_ACTORS,
  CLEAR_LIST_ACTORS,
  CREATE_ACTOR,
  REMOVE_ALL_FORM_ACTORS,
  STARRED_ACTOR,
  COPY_ACTOR,
  GET_ACTORS_LIST,
  ACTORS_LIST_TYPES,
  CREATE_MASS_EDGES,
} from '@control-front-end/common/constants/graphActors';
import { MAKE_AUTO_LAYER_PAGE } from '@control-front-end/common/constants/graphLayers';
import {
  CLEAR_FORM,
  CLEAR_FORMS,
  GET_FORMS,
  GET_FORMS_TREE,
} from '@control-front-end/common/constants/forms';
import {
  ADD_ACTORS_FILTER,
  HIDE_ACTORS_FILTER,
  SET_ACTIVE_ACTORS_FILTER,
  UPDATE_SYSTEM_FILTER,
} from '@control-front-end/common/constants/actorsFilters';
import { SYSTEM_SEARCH_FILTER } from '@control-front-end/common/constants/filters';
import { SMART_FORM_GRAPH_REF_PREFIX } from '@control-front-end/common/constants/smartForms';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import history from '@control-front-end/app/src/store/history';
import {
  GET_FOLDER_ACTORS,
  SET_ACTIVE_ACTORS_FOLDER,
} from '@control-front-end/common/constants/actorsFolders';
import keyBy from 'lodash/keyBy';
import { DEFAULT_NAME } from '../routers/Graph/constants';

/**
 * Ф-ции по работе со акторами
 */
const useActorsBagActions = ({ accId, activeElement }) => {
  const dispatch = useDispatch();
  const showNotification = useNotifications();
  const auth = useSelector((state) => state.auth);
  const edgeTypes = useSelector((state) => state.edgeTypes);
  const actorsFilters = useSelector((state) => state.actorsFilters);
  const systemForms = useSelector((state) => state.systemForms) || {};
  const settings = useSelector((state) => state.settings);
  const { open: openCopyActorsModal } = useModal('MakeActorCopies', {});
  const { open: createCompanyCard } = useModal('CreateCompanyCardActor', {
    form: systemForms.companycard,
  });

  /**
   * Добавить в экспорт
   */
  const handleExportActor = (actor) => {
    const exportGraph = settings.exportGraph || { forms: [], actors: [] };
    const { id, title, color, formId, formTitle } = actor;
    const copyList = exportGraph.actors ? exportGraph.actors.slice() : [];
    copyList.push({
      id,
      title,
      color,
      formId,
      formTitle,
      addedAt: new Date().getTime() / 1000,
    });
    const uniqueItems = AppUtils.uniqueObjArray(copyList, 'id');
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: {
        accId,
        exportGraph: { ...exportGraph, actors: uniqueItems },
      },
      callback: () =>
        showNotification.success('Item successfully added to Export Graph'),
    });
  };

  /**
   * Пометить актор избранным
   */
  const handleStarActor = (actorId, starred) => {
    dispatch({
      type: STARRED_ACTOR.REQUEST,
      payload: { actorId, starred },
    });
  };

  /**
   * Получить страницу шаблонов/акторов для слоя
   */
  const handleMakeItemsPage = ({ type, root, callback }) => {
    dispatch({
      type: MAKE_AUTO_LAYER_PAGE.REQUEST,
      payload: { type, root },
      callback,
    });
  };

  /**
   * Получение списка шаблонов акторов
   */
  const handleGetTemplates = ({
    loadMore = false,
    limit,
    withTotal,
    formTypes,
    callback,
  }) => {
    dispatch({
      type: GET_FORMS.REQUEST,
      payload: {
        loadMore,
        limit,
        withDefault: true,
        formTypes,
        withTotal,
      },
      callback,
    });
  };

  /**
   * Получить настроенные видимые поля для фильтра
   */
  const getFilterVisibleFields = ({ formId, fields, callback }) => {
    if (!formId || !fields || !fields.length) {
      callback([]);
      return;
    }
    dispatch({
      type: GET_FORMS_TREE.REQUEST,
      payload: { formId },
      callback: (graph) => {
        const forms = graph
          .filter((i) => i.data.type === 'node')
          .map((i) => ({
            id: i.data.id,
            parentId: i.data.parentId,
            form: i.data.form,
          }));
        const items = FormUtils.getFormsItemsByIds(forms, fields);
        if (callback) callback(items);
      },
    });
  };

  const handleSetActiveFilter = (filterId) => {
    dispatch({ type: SET_ACTIVE_ACTORS_FILTER, payload: filterId });
    dispatch({ type: SET_ACTIVE_ACTORS_FOLDER, payload: null });
  };

  const handleSetActiveFolder = (folderId) => {
    dispatch({ type: SET_ACTIVE_ACTORS_FOLDER, payload: folderId });
    dispatch({ type: SET_ACTIVE_ACTORS_FILTER, payload: null });
  };

  const handleAddToFolder = (folderId, ids) => {
    dispatch({
      type: CREATE_MASS_EDGES.REQUEST,
      payload: {
        edges: ids.map((actorId) => ({
          source: folderId,
          target: actorId,
          edgeTypeId: keyBy(edgeTypes, 'name').hierarchy.id,
        })),
      },
      callback: () =>
        showNotification.success(
          ids.length > 1
            ? 'Actors were successfully added to folder.'
            : 'Actor was successfully added to folder.'
        ),
    });
  };

  /**
   * Получить список всех доступных акторов
   */
  const handleGetRecentActors = ({ callback }) => {
    dispatch({
      type: GET_ACTORS_LIST.REQUEST,
      payload: { typeList: ACTORS_LIST_TYPES.RECENT },
      callback,
    });
  };

  /**
   * Get folder actors (linked to state actor)
   * @param actorId state actor ID
   * @param formId form of custom tab
   * @param limit
   * @param offset
   * @param callback
   */
  const handleGetFolderActors = ({
    folderId,
    formId,
    limit,
    offset = 0,
    callback,
    ...rest
  }) => {
    dispatch({
      type: GET_FOLDER_ACTORS.REQUEST,
      payload: {
        folderId,
        formId,
        limit,
        offset,
        ...rest,
      },
      callback,
    });
  };

  /**
   * Получение списка акторов
   */
  const handleGetActors = ({
    loadMore,
    limit,
    offset = 0,
    params = {},
    callback,
    ...rest
  }) => {
    const isFirstLoad = offset === 0;
    dispatch({
      type: GET_ACTORS_LIST.REQUEST,
      payload: {
        typeList: ACTORS_LIST_TYPES.TEMPLATE,
        formId: params.formId,
        limit,
        offset,
        loadMore,
        withStats: isFirstLoad,
        filter: params,
        ...rest,
      },
      callback,
    });
  };

  /**
   * Очистка списка акторов
   */
  const handleClearActors = () => {
    dispatch({ type: CLEAR_LIST_ACTORS });
  };

  /**
   * Очистка списка шаблонов
   */
  const handleClearTemplates = () => {
    dispatch({ type: CLEAR_FORM });
    dispatch({ type: CLEAR_FORMS });
  };

  /**
   * Поиск акторов
   */
  const handleSearchActors = ({
    query,
    filterParams,
    loadMore,
    limit,
    offset,
    callback,
  }) => {
    dispatch({
      type: SEARCH_ACTORS.REQUEST,
      payload: {
        query,
        params: filterParams,
        loadMore,
        limit,
        offset,
      },
      callback,
    });
  };

  /**
   * Создать актор-событие
   */
  const handleCreateEventActor = (actorData, callback) => {
    const date = AppUtils.getDefaultDuedate();
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'EventCard',
        data: {
          id: 'tmp_1',
          ownerId: auth.id,
          ownerAvatar: auth.avatar,
          access: [AppUtils.makeAuthUserAsOwner(auth)],
          attachments: [],
          startDate: date,
          endDate: date,
          ...actorData,
        },
        callback,
        closeConfirm: true,
      },
    });
  };

  /**
   * Create new actor via modal window
   */
  const handleCreateChart = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateDashboard',
        data: {},
        closeConfirm: true,
      },
    });
  };

  /**
   * Создание актора приложения CDU
   */
  const handleCreateScriptActor = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateSmartForm',
        data: {
          ownerId: auth.id,
          access: [AppUtils.makeAuthUserAsOwner(auth)],
        },
        closeConfirm: true,
        callback: (smartForm) => {
          const url = AppUtils.makeUrl(`/script/${accId}/edit/${smartForm.id}`);
          window.open(url);
        },
      },
    });
  };

  /**
   * Создание актора фильтра
   */
  const handleCreateFilterActor = (callback) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateActorsFilter',
        data: {},
        closeConfirm: true,
        callback,
      },
    });
  };

  /**
   * Копирование акторов
   */
  const copyActors = useCallback((actorId, count) => {
    dispatch({
      type: COPY_ACTOR.REQUEST,
      payload: { actorId, count },
      callback: () => {
        showNotification.success(
          count === 1
            ? 'Actor copy has been successfully created.'
            : `${count} copies of the selected actor have been successfully created.`
        );
      },
    });
  }, []);

  /**
   * Копирование акторов
   */
  const handleCopyActor = (actorId, initialCount) => {
    if (initialCount) {
      copyActors(actorId, initialCount);
    } else {
      openCopyActorsModal({ callback: (count) => copyActors(actorId, count) });
    }
  };

  /**
   * Создание актора
   */
  const handleCreateActor = (form, callback) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateActor',
        data: {
          form,
        },
        closeConfirm: true,
        callback,
      },
    });
  };

  /**
   * Создать новый актор графа
   */
  const handleGraphFolderActor = (
    { formId, title, manageLayer = false },
    callback
  ) => {
    dispatch({
      type: CREATE_ACTOR.REQUEST,
      payload: { formId, title, manageLayer },
      callback,
    });
  };

  /**
   * Создать новый актор с учетом формы
   */
  const handleCreateActorByTab = ({ tab, tabId, manageLayer, callback }) => {
    if (tab === 'filter' || !tabId) {
      handleCreateActor();
      return;
    }

    switch (+tabId) {
      case systemForms.events.id:
        handleCreateEventActor({});
        break;
      case systemForms.scripts.id:
        handleCreateScriptActor();
        break;
      case systemForms.actorfilters.id:
        handleCreateFilterActor();
        break;
      case systemForms.graphs.id:
        handleGraphFolderActor(
          { formId: +tabId, title: DEFAULT_NAME.GRAPH, manageLayer },
          callback
        );
        break;
      case systemForms.dashboards.id:
        handleCreateChart();
        break;
      case systemForms.companycard.id:
        createCompanyCard();
        break;
      default:
        handleCreateActor({ id: +tabId }, callback);
        break;
    }
  };

  /**
   * Обновить актор
   */
  const handleUpdateActor = (props) => {
    const {
      title,
      description,
      picture,
      color,
      formData,
      formId,
      id,
      ref,
      callback,
    } = props;
    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: formData
        ? { title, description, picture, color, formData, formId, ref, id }
        : { formId, ...activeElement, id, title, picture, color, description },
      callback,
    });
  };

  /**
   * Удалить актор
   */
  const handleRemoveActor = (id, callback) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'removeActorsTitle',
          text: 'removeActorConfirm',
          buttons: [
            {
              type: 'error',
              label: 'Permanently delete',
              onClick: () => {
                dispatch({
                  type: REMOVE_ACTOR.REQUEST,
                  payload: { id, manageLayer: false },
                });
                dispatch({ type: DEL_MODAL });
                if (callback) callback();
              },
            },
          ],
        },
      },
    });
  };

  /**
   * Загрузить слой
   */
  const handleLoadActorLayer = ({ actorId }) => {
    if (!actorId) return;
    history.push(`/actors_graph/${accId}/graph/0/actors/${actorId}`);
  };

  /**
   * Открыть слой дерева
   */
  const handleLoadActorsTree = ({ id }) => {
    const sp = id.split('_');
    history.push(`/actors_graph/${accId}/graph/0/trees/${sp[0]}/${sp[1]}`);
  };

  /**
   * Добавить фильтр с результатами поиска
   */
  const handleAddSearchFilter = ({ callback }) => {
    const findSearchFilter = actorsFilters.list.find((i) => i.id === 'search');
    if (findSearchFilter) {
      callback();
    } else {
      dispatch({
        type: ADD_ACTORS_FILTER.REQUEST,
        payload: SYSTEM_SEARCH_FILTER,
        callback,
      });
    }
  };

  const handleRemoveSearchFilter = () => {
    dispatch({
      type: HIDE_ACTORS_FILTER.REQUEST,
      payload: {
        id: 'search',
      },
    });
  };

  /**
   * Модальное окно подтверждения удаления
   */
  const showModalConfirmDelete = (count, removeCallback) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'removeSelectedItems',
          text: {
            text: count === 1 ? 'removeActorConfirm' : 'removeActorsConfirm',
            values: { count },
          },
          buttons: [
            {
              type: 'error',
              label: 'delPermanently',
              onClick: () => {
                dispatch({ type: DEL_MODAL, payload: 'InfoModal' });
                removeCallback();
              },
            },
          ],
        },
      },
    });
  };

  /**
   * Удалить все акторы по форме
   */
  const handleRemoveAllFormActor = (formId, total) => {
    showModalConfirmDelete(total, () =>
      dispatch({
        type: REMOVE_ALL_FORM_ACTORS.REQUEST,
        payload: { formId },
      })
    );
  };

  /**
   * Добавить счета
   */
  const handleAddAccounts = (actors) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateActorAccount',
        data: {
          actors,
          accounts: [],
        },
      },
    });
  };

  /**
   * Обновить форму в системном фильтре акторов
   */
  const handleUpdateSystemFilter = (id, form) => {
    const { id: formId, title: formTitle, color = null } = form;
    dispatch({
      type: UPDATE_SYSTEM_FILTER.REQUEST,
      payload: {
        id,
        formId,
        formTitle,
        formColor: color,
      },
    });
  };

  /**
   * Get smart form's graph data
   */
  const handleGetSmartFormGraph = (callback) => {
    if (!activeElement) return;
    const graphRef = `${SMART_FORM_GRAPH_REF_PREFIX}${activeElement.id}`;
    dispatch({
      type: SEARCH_ACTORS.REQUEST,
      payload: {
        query: graphRef,
        localState: true,
      },
      callback,
    });
  };

  return {
    handleGetTemplates,
    handleGetActors,
    handleGetRecentActors,
    handleGetFolderActors,
    handleClearActors,
    handleClearTemplates,
    handleSearchActors,
    handleCopyActor,
    handleCreateActor,
    handleCreateActorByTab,
    handleCreateEventActor,
    handleCreateScriptActor,
    handleCreateFilterActor,
    handleStarActor,
    handleUpdateActor,
    handleRemoveActor,
    handleLoadActorLayer,
    handleLoadActorsTree,
    handleMakeItemsPage,
    handleGraphFolderActor,
    handleSetActiveFilter,
    handleSetActiveFolder,
    handleAddSearchFilter,
    handleAddToFolder,
    handleRemoveSearchFilter,
    handleRemoveAllFormActor,
    handleAddAccounts,
    showModalConfirmDelete,
    handleExportActor,
    handleUpdateSystemFilter,
    getFilterVisibleFields,
    handleGetSmartFormGraph,
  };
};

export default useActorsBagActions;
