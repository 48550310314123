import React from 'react';
import { useDispatch } from 'react-redux';
import { HIDE_NOTIFY, NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { Stack, Label, Button, Card, cr } from 'mw-style-react';

/**
 * Hook for showing meeting notifications in custom design with clickable button
 */
function useMeetingNotifications() {
  const dispatch = useDispatch();

  const showMeetingNotify = ({ text, button }) => {
    const id = AppUtils.createRid();
    dispatch({
      id,
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.PRIMARY,
        borderRadius: Card.BORDER_RADIUS.medium,
        content: (
          <Stack.H
            size="small"
            fullWidth
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <Label
              color={Label.COLOR.primary}
              fontWeight="semibold"
              value={text}
            />
            {cr([
              button,
              <Button
                label={button?.label}
                onClick={() => {
                  button?.onClick();
                  dispatch({
                    type: HIDE_NOTIFY.REQUEST,
                    payload: { id },
                  });
                }}
              />,
            ])}
          </Stack.H>
        ),
      },
    });
  };

  return { showMeetingNotify };
}

export default useMeetingNotifications;
