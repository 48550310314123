import React from 'react';
import PropTypes from 'prop-types';
import { Label, Space, Stack, Card } from 'mw-style-react';
import { SPACE_SIZE } from 'mw-style-react/build/constants';
import cn from 'classnames';
import scss from './TemplatesList.scss';

function TemplatesList({
  className,
  templates,
  activeTemplate,
  onSelectTemplate,
}) {
  return (
    <Space left right bottom size={SPACE_SIZE.medium} className={className}>
      <Stack.H fullWidth className={scss.templates}>
        {templates.map((template, index) => (
          <Stack
            key={`template_${index}`}
            justifyContent="center"
            alignItems="center"
          >
            <Card
              borderRadius={Card.BORDER_RADIUS.xxlarge}
              className={cn(scss.templateItem, {
                [scss.activeTemplate]: activeTemplate === index,
              })}
              style={{
                backgroundImage: `url('data:image/svg+xml;base64,${template.data.image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
              onClick={() => onSelectTemplate(index)}
            />
            <Label
              value={template.data.title}
              visibility={template.data.disabled ? 'disabled' : 'visible'}
            />
          </Stack>
        ))}
      </Stack.H>
    </Space>
  );
}

TemplatesList.defaultProps = {
  templates: [],
  activeTemplate: 0,
};

TemplatesList.propTypes = {
  className: PropTypes.string,
  templates: PropTypes.array,
  activeTemplate: PropTypes.number,
  onSelectTemplate: PropTypes.func.isRequired,
};

export default TemplatesList;
