import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Avatar, Badge, Space } from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import ActorColors from '@control-front-end/app/src/components/ActorColors/ActorColors';
import ActorStatusIcon from './components/ActorStatusIcon';
import { INFO_MODE } from '../../constants';
import './ActorAvatar.scss';

const SYSTEM_FORMS_ICONS = {
  AccountTriggers: 'trigger',
  ActorFilters: 'filter',
  TransactionFilters: 'filter',
  TransferFilters: 'filter',
  CommentsWidgets: 'form',
  CompanyCard: 'company_office',
  CompanyCategories: 'checklist',
  Dashboards: 'dashboard',
  Events: 'event',
  Graphs: 'graph',
  Layers: 'layer_filled',
  Locations: 'location',
  PublishedCompanies: 'company',
  Scripts: 'form_pole',
  Snippets: 'snippet',
  Streams: 'stream',
  Tags: 'hash',
  Users: 'client',
  Widgets: 'widget',
  Transfers: 'transaction',
};

/**
 * Аватар актора со статусами
 * @returns {*}
 * @constructor
 */
function ActorAvatar(props) {
  const {
    size,
    spaceSize,
    type,
    formType,
    formTitle,
    icon: propIcon,
    iconLabel,
    pictureUrl,
    colorFilled,
    color,
    colors,
    accessDenied,
    withGradientBorder,
    starred,
    status,
  } = props;
  const icon =
    propIcon || (formType === 'system' ? SYSTEM_FORMS_ICONS[formTitle] : null);
  const actorColor = colors.find((i) => i.type === 'actor') || {};
  const formColor = colors.find((i) => i.type === 'form') || {};
  const mainColor = color || actorColor.color || formColor.color;
  const WHITE_COLOR = '#ffffff';
  const GRAY_COLOR = '#DDE0E5';
  const bgColor = mainColor || WHITE_COLOR;
  const borderColor = AppUtils.isLightColor(bgColor) ? GRAY_COLOR : mainColor;
  let iconSize = size;
  if (size === 'micro') iconSize = 'small';
  else if (size === 'xxlarge') iconSize = 'xlarge';

  return (
    <Space
      size={spaceSize}
      styleName={spaceSize !== Space.SIZE.none ? 'aa__highlighted' : ''}
    >
      <div
        styleName={cn('aa__wrap', size, { withGradientBorder })}
        style={
          colorFilled && !withGradientBorder
            ? { border: `1px solid ${borderColor}` }
            : {}
        }
      >
        {accessDenied ? (
          <Avatar styleName="aa accessDenied" size={size}>
            <Icon type="no_eye" />
          </Avatar>
        ) : (
          <Avatar
            styleName="aa default"
            size={size}
            className="avatar"
            src={pictureUrl}
            bgColor={colorFilled ? bgColor : null}
          >
            {!pictureUrl && icon ? (
              <div styleName={cn('aa__iconWrap', { iconLabel: !!iconLabel })}>
                <Icon
                  styleName={cn('aa__icon', { upload: icon === 'picture' })}
                  size={iconSize}
                  type={icon}
                />
                {iconLabel ? <span>{iconLabel}</span> : null}
              </div>
            ) : null}
          </Avatar>
        )}
        <ActorStatusIcon size={iconSize} status={status} />
        {type === 'full' ? (
          <>
            <div styleName="aa__colors">
              <ActorColors colors={colors} />
            </div>
            {starred ? (
              <Badge
                styleName="aa__flag"
                bgColor="#E63C3C"
                borderColor="#ffffff"
              />
            ) : null}
          </>
        ) : null}
      </div>
    </Space>
  );
}

ActorAvatar.defaultProps = {
  size: 'medium',
  spaceSize: Space.SIZE.none,
  type: 'full',
  icon: null,
  colors: [],
  withGradientBorder: false,
};

ActorAvatar.propTypes = {
  size: PropTypes.oneOf([
    'micro',
    'small',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  spaceSize: PropTypes.oneOf(Object.values(Space.SIZE)),
  type: PropTypes.oneOf(Object.values(INFO_MODE)),
  formType: PropTypes.string,
  formTitle: PropTypes.string,
  icon: PropTypes.string,
  pictureUrl: PropTypes.string,
  color: PropTypes.string,
  colors: PropTypes.array,
  colorFilled: PropTypes.bool,
  accessDenied: PropTypes.bool,
  withGradientBorder: PropTypes.bool,
  starred: PropTypes.bool,
  status: PropTypes.oneOf(['verified', 'rejected', 'pending', null]),
};

export default ActorAvatar;
