import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramDocs(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(12.2903, 16);
  ctx.lineTo(39.2258, 16);
  ctx.bezierCurveTo(39.3861, 16, 39.5161, 16.13, 39.5161, 16.2903);
  ctx.lineTo(39.5161, 33.1832);
  ctx.bezierCurveTo(38.4595, 32.3032, 37.5164, 31.5569, 36.5215, 31.0018);
  ctx.bezierCurveTo(34.9664, 30.134, 33.2974, 29.7258, 30.8387, 29.7258);
  ctx.bezierCurveTo(28.1805, 29.7258, 26.4967, 30.8714, 24.933, 31.9355);
  ctx.lineTo(24.9213, 31.9434);
  ctx.lineTo(24.9096, 31.9513);
  ctx.bezierCurveTo(23.3591, 33.0063, 21.8636, 34.0239, 19.3778, 34.3301);
  ctx.bezierCurveTo(16.7829, 34.6497, 14.9325, 34.1999, 13.7617, 33.3788);
  ctx.bezierCurveTo(12.6203, 32.5785, 12, 31.3404, 12, 29.7581);
  ctx.lineTo(12, 16.2903);
  ctx.bezierCurveTo(12, 16.13, 12.13, 16, 12.2903, 16);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(8.29032, 12);
  ctx.lineTo(35.2258, 12);
  ctx.bezierCurveTo(35.3861, 12, 35.5161, 12.13, 35.5161, 12.2903);
  ctx.lineTo(35.5161, 29.1832);
  ctx.bezierCurveTo(34.4595, 28.3032, 33.5164, 27.5569, 32.5215, 27.0018);
  ctx.bezierCurveTo(30.9664, 26.134, 29.2974, 25.7258, 26.8387, 25.7258);
  ctx.bezierCurveTo(24.1805, 25.7258, 22.4967, 26.8714, 20.933, 27.9355);
  ctx.lineTo(20.9213, 27.9434);
  ctx.lineTo(20.9096, 27.9513);
  ctx.bezierCurveTo(19.3591, 29.0063, 17.8636, 30.0239, 15.3778, 30.3301);
  ctx.bezierCurveTo(12.7829, 30.6497, 10.9325, 30.1999, 9.76166, 29.3788);
  ctx.bezierCurveTo(8.62032, 28.5785, 8, 27.3404, 8, 25.7581);
  ctx.lineTo(8, 12.2903);
  ctx.bezierCurveTo(8, 12.13, 8.12998, 12, 8.29032, 12);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#F6F7F8';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(16.2903, 20);
  ctx.lineTo(43.2258, 20);
  ctx.bezierCurveTo(43.3861, 20, 43.5161, 20.13, 43.5161, 20.2903);
  ctx.lineTo(43.5161, 37.1832);
  ctx.bezierCurveTo(42.4595, 36.3032, 41.5164, 35.5569, 40.5215, 35.0018);
  ctx.bezierCurveTo(38.9664, 34.134, 37.2974, 33.7258, 34.8387, 33.7258);
  ctx.bezierCurveTo(32.1805, 33.7258, 30.4967, 34.8714, 28.933, 35.9355);
  ctx.lineTo(28.9213, 35.9434);
  ctx.lineTo(28.9096, 35.9513);
  ctx.bezierCurveTo(27.3591, 37.0063, 25.8636, 38.0239, 23.3778, 38.3301);
  ctx.bezierCurveTo(20.7829, 38.6497, 18.9325, 38.1999, 17.7617, 37.3788);
  ctx.bezierCurveTo(16.6203, 36.5785, 16, 35.3404, 16, 33.7581);
  ctx.lineTo(16, 20.2903);
  ctx.bezierCurveTo(16, 20.13, 16.13, 20, 16.2903, 20);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramDocs;
