import { call, takeLatest, select, all, put } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  DEFAULT_OWNER_PRIVS,
  MY_VERSE,
  OPEN_STATIC_LAYER,
  RequestStatus,
} from 'constants';
import {
  GET_LAYER,
  GET_SYSTEM_LAYERS,
  SYSTEM_SUBLAYERS,
  SYSTEM_LAYERS_NAMES,
} from '@control-front-end/common/constants/graphLayers';
import { makeLayerModel } from '@control-front-end/app/src/routes/ActorsGraph/sagas/layers/layerHelpers';
import AppUtils from '@control-front-end/utils/utils';
import { getSystemActor } from './graph/systemActors';
import { getRecentObjs } from './suggestions';

export function* getMyVerseLayerData({ callback }) {
  const auth = yield select((state) => state.auth);

  const systemForms = yield select((state) => state.systemForms);

  // FETCH RECENT ACTORS before all other data in order not to overwrite them and get duplicates
  const actorBag = yield call(getRecentObjs, {
    payload: { objType: 'actor', limit: 5 },
  });

  const accId = yield select((state) => state.accounts.active);

  const [profileActor, users, events, graphs, accounts] = yield all([
    call(getSystemActor, {
      payload: { objType: 'user', objId: auth.id },
    }),
    call(getRecentObjs, {
      payload: { objType: 'user', limit: 3 },
    }),
    call(api, {
      method: 'get',
      url: `/actors_filters/${systemForms.events.id}`,
      queryParams: {
        formId: systemForms.events.id,
        limit: 2,
        offset: 0,
      },
    }),
    call(api, {
      method: 'get',
      url: `/actors_filters/${systemForms.graphs.id}`,
      queryParams: {
        formId: systemForms.graphs.id,
        limit: 2,
        offset: 0,
      },
    }),
    call(api, {
      method: 'get',
      url: `/account_names/${accId}`,
      queryParams: { limit: 2 },
    }),
  ]);

  const usersActors = yield all(
    users.map(({ id }) =>
      call(getSystemActor, {
        payload: { objType: 'user', objId: id },
      })
    )
  );
  const data = {
    profileActor,
    actorBag: actorBag.filter(({ id }) => id !== profileActor.id),
    // There can be no access for some users actors in this case at least provide user data from the account
    users: users.map((user) => {
      const userActor = usersActors.find(
        (userActor) => userActor?.userId === user.id && user.id !== auth.id
      );
      return userActor || user;
    }),
    events:
      events.result === RequestStatus.SUCCESS ? events.data.data.list : [],
    graphs:
      graphs.result === RequestStatus.SUCCESS ? graphs.data.data.list : [],
    accounts:
      accounts.result === RequestStatus.SUCCESS ? accounts.data.data.list : [],
  };

  if (callback) callback(data);
  return data;
}

function* openStaticLayer({ payload: { graphFolderId = '0', id, ...rest } }) {
  const graphLayers = yield select((state) => state.graphLayers);

  let staticLayer = graphLayers.list.find((item) => item.id === id);

  if (staticLayer) {
    yield put({
      type: GET_LAYER.SUCCESS,
      payload: {
        ...graphLayers,
        graphFolderId,
        active: id,
      },
    });
    return;
  }

  staticLayer = {
    id,
    key: id,
    name: id,
    type: 'graph',
    isSystem: true,
    isStatic: true,
    graphFolderId,
    isAuto: !SYSTEM_LAYERS_NAMES[id],
    isCustom: true,
    hasSublayers: Boolean(SYSTEM_SUBLAYERS[id]),
    privs: DEFAULT_OWNER_PRIVS,
    ...rest,
  };

  yield call(makeLayerModel, staticLayer);

  yield put({
    type: GET_LAYER.SUCCESS,
    payload: {
      ...graphLayers,
      graphFolderId,
      active: id,
      list: [...graphLayers.list, staticLayer],
    },
  });
}

function* getSystemLayers() {
  const layers = AppUtils.generateLayerTree();
  yield makeLayerModel(layers, 'layers');
  yield put({
    type: GET_SYSTEM_LAYERS.SUCCESS,
    payload: { list: layers },
  });
}

function* staticLayer() {
  yield takeLatest(MY_VERSE.REQUEST, getMyVerseLayerData);
  yield takeLatest(OPEN_STATIC_LAYER, openStaticLayer);
  yield takeLatest(GET_SYSTEM_LAYERS.REQUEST, getSystemLayers);
}

export default staticLayer;
