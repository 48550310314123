import { createReducer } from '@reduxjs/toolkit';
import {
  ADD_ACTOR_FOLDER,
  CLEAR_ACTORS_FOLDERS,
  UPDATE_ACTOR_FOLDER,
  FOLDER_ACCESS,
  GET_ACTORS_FOLDERS,
  HIDE_ACTOR_FOLDER,
  SET_ACTIVE_ACTORS_FOLDER,
} from '@control-front-end/common/constants/actorsFolders';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  active: null,
  limit: 100,
  offset: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_ACTORS_FOLDERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_ACTOR_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_ACTOR_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [FOLDER_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [HIDE_ACTOR_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_ACTORS_FOLDER](state, action) {
    return { ...state, active: action.payload };
  },
  [CLEAR_ACTORS_FOLDERS]() {
    return initState;
  },
});
