import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconTransactionEdge(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.fullPalette.green;
  ctx.beginPath();
  ctx.moveTo(41, 7.53613);
  ctx.bezierCurveTo(22.3334, 28.4512, 8.99988, 17.5679, 9, 42.9647);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconTransactionEdge;
