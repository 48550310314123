import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconEdgeNumber(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.fullPalette.gray;
  ctx.beginPath();
  ctx.moveTo(42, 8);
  ctx.bezierCurveTo(24.2, 28, 10, 18.3, 10, 42.5);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(12.7, 23.7);
  ctx.lineTo(9.2, 18.3);
  ctx.lineTo(9.9, 17.8);
  ctx.lineTo(15.4, 20.7);
  ctx.translate(-7.4667, 35.98);
  ctx.rotate(0);
  ctx.arc(0, 0, 27.5, -0.589, -0.6515, 1);
  ctx.rotate(0);
  ctx.translate(7.4667, -35.9762);
  ctx.lineTo(12.4, 16.2);
  ctx.lineTo(12.99, 15.8);
  ctx.lineTo(16.5, 21.2);
  ctx.lineTo(15.8, 21.7);
  ctx.lineTo(10.3, 18.8);
  ctx.translate(-23.614, 44.08);
  ctx.rotate(0);
  ctx.arc(0, 0, 42.3, -0.6406, -0.6, 0);
  ctx.rotate(0);
  ctx.translate(23.6138, -44.0813);
  ctx.lineTo(13.3, 23.3);
  ctx.lineTo(12.7, 23.7);
  ctx.closePath();
  ctx.moveTo(17.2, 20.7);
  ctx.lineTo(16.9, 20.2);
  ctx.lineTo(18.9, 19);
  ctx.lineTo(19.098, 19.5);
  ctx.lineTo(17.2, 20.7);
  ctx.closePath();
  ctx.moveTo(17.5, 19.1);
  ctx.lineTo(16.8, 19.3);
  ctx.lineTo(16.2, 19.2);
  ctx.lineTo(15.6, 18.5);
  ctx.bezierCurveTo(15.3, 18.1, 15.2, 17.8, 15.3, 17.5);
  ctx.bezierCurveTo(15.3, 17.1, 15.5, 16.8, 15.9, 16.5);
  ctx.lineTo(16.6, 16.3);
  ctx.bezierCurveTo(16.8, 16.3, 16.99, 16.3, 17.2, 16.5);
  ctx.lineTo(17.8, 17.1);
  ctx.bezierCurveTo(18.1, 17.5, 18.2, 17.9, 18.1, 18.2);
  ctx.bezierCurveTo(18.1, 18.6, 17.9, 18.9, 17.5, 19.1);
  ctx.closePath();
  ctx.moveTo(17.3, 18.7);
  ctx.bezierCurveTo(17.5, 18.5, 17.6, 18.4, 17.6, 18.1);
  ctx.bezierCurveTo(17.6, 17.9, 17.6, 17.7, 17.3, 17.4);
  ctx.bezierCurveTo(17.2, 17.1, 17, 16.9, 16.8, 16.9);
  ctx.lineTo(16, 16.9);
  ctx.lineTo(15.7, 17.5);
  ctx.bezierCurveTo(15.7, 17.7, 15.7, 18, 15.9, 18.2);
  ctx.lineTo(16.5, 18.8);
  ctx.lineTo(17.2, 18.7);
  ctx.closePath();
  ctx.moveTo(20.7, 12.6);
  ctx.bezierCurveTo(20.9, 12.8, 20.9, 13.1, 21, 13.3);
  ctx.lineTo(20.9, 13.99);
  ctx.translate(19.1431, 13.0443);
  ctx.rotate(0);
  ctx.arc(0, 0, 2, 0.4982, 0.8912, 0);
  ctx.rotate(0);
  ctx.translate(-19.1431, -13.0443);
  ctx.bezierCurveTo(20.9, 14.4, 21.4, 14.3, 21.7, 14.4);
  ctx.translate(21, 16.17);
  ctx.rotate(0);
  ctx.arc(0, 0, 1.9, -1.198, -0.089, 0);
  ctx.rotate(0);
  ctx.translate(-21.008, -16.169);
  ctx.bezierCurveTo(22.9, 16.4, 22.8, 16.7, 22.6, 17);
  ctx.bezierCurveTo(22.4, 17.4, 21.99, 17.7, 21.6, 18);
  ctx.translate(18.735, 13.902);
  ctx.rotate(0);
  ctx.arc(0, 0, 5, 0.96, 1.17, 0);
  ctx.rotate(0);
  ctx.translate(-18.74, -13.9);
  ctx.lineTo(19.9, 18.7);
  ctx.lineTo(19.5, 18.098);
  ctx.translate(18.98, 13.93);
  ctx.rotate(0);
  ctx.arc(0, 0, 4.2, 1.45, 1.01, 1);
  ctx.rotate(0);
  ctx.translate(-18.98, -13.93);
  ctx.bezierCurveTo(21.7, 17.196, 22, 16.797, 22.098, 16.496);
  ctx.bezierCurveTo(22.098, 16.196, 22.098, 15.797, 21.9, 15.496);
  ctx.translate(21.06, 16.04);
  ctx.rotate(0);
  ctx.arc(0, 0, 1, -0.57, -1.22, 1);
  ctx.rotate(0);
  ctx.translate(-21.06, -16.04);
  ctx.bezierCurveTo(21.098, 14.996, 20.9, 14.996, 20.598, 15.096);
  ctx.lineTo(19.7, 15.496);
  ctx.lineTo(19.2, 15.897);
  ctx.lineTo(18.9, 15.297);
  ctx.lineTo(19.4, 14.996);
  ctx.lineTo(20.098, 14.397);
  ctx.lineTo(20.297, 13.698);
  ctx.bezierCurveTo(20.297, 13.498, 20.297, 13.297, 20.098, 12.998);
  ctx.translate(19.3, 13.6);
  ctx.rotate(0);
  ctx.arc(0, 0, 1, -0.64, -1.47, 1);
  ctx.rotate(0);
  ctx.translate(-19.3, -13.6);
  ctx.bezierCurveTo(19.098, 12.598, 18.797, 12.598, 18.5, 12.797);
  ctx.translate(19.99, 14.93);
  ctx.rotate(0);
  ctx.arc(0, 0, 2.6, -2.2, -2.65, 1);
  ctx.rotate(0);
  ctx.translate(-19.99, -14.93);
  ctx.bezierCurveTo(17.7, 13.897, 17.5, 13.998, 17.4, 14.198);
  ctx.lineTo(17, 14);
  ctx.bezierCurveTo(17, 13.7, 17.2, 13.4, 17.4, 13.1);
  ctx.lineTo(18.297, 12.299);
  ctx.bezierCurveTo(18.797, 11.998, 19.297, 11.799, 19.797, 11.899);
  ctx.bezierCurveTo(20.2, 11.9, 20.5, 12.2, 20.8, 12.6);
  ctx.closePath();
  ctx.moveTo(27.4, 12.4);
  ctx.lineTo(26.5, 12.9);
  ctx.lineTo(27.4, 14.1);
  ctx.lineTo(26.8, 14.5);
  ctx.lineTo(25.99, 13.3);
  ctx.lineTo(23.3, 15);
  ctx.lineTo(22.99, 14.4);
  ctx.lineTo(23.2, 9.1);
  ctx.lineTo(23.8, 8.7);
  ctx.lineTo(26.2, 12.3);
  ctx.lineTo(26.99, 11.8);
  ctx.lineTo(27.4, 12.4);
  ctx.closePath();
  ctx.moveTo(25.6, 12.7);
  ctx.lineTo(24.4, 11);
  ctx.translate(41.2069, 1.1117);
  ctx.rotate(0);
  ctx.arc(0, 0, 19.5, 2.6098, 2.68552, 0);
  ctx.rotate(0);
  ctx.translate(-41.2069, -1.1117);
  ctx.translate(17.015, 10.15);
  ctx.rotate(0);
  ctx.arc(0, 0, 6.7, -0.067, 0.067, 0);
  ctx.rotate(0);
  ctx.translate(-17.015, -10.15);
  ctx.lineTo(23.6, 14);
  ctx.lineTo(25.6, 12.7);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconEdgeNumber;
