import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramProcess(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(13.5, 13.5);
  ctx.lineTo(36.5, 13.5);
  ctx.bezierCurveTo(36.5, 13.5, 36.5, 13.5, 36.5, 13.5);
  ctx.lineTo(36.5, 36.5);
  ctx.bezierCurveTo(36.5, 36.5, 36.5, 36.5, 36.5, 36.5);
  ctx.lineTo(13.5, 36.5);
  ctx.bezierCurveTo(13.5, 36.5, 13.5, 36.5, 13.5, 36.5);
  ctx.lineTo(13.5, 13.5);
  ctx.bezierCurveTo(13.5, 13.5, 13.5, 13.5, 13.5, 13.5);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramProcess;
