import { defineMessages } from 'react-intl';

const m = defineMessages({
  legend: {
    id: 'legend_Legend',
    defaultMessage: 'Legend',
  },
  components: {
    id: 'components_Legend',
    defaultMessage: 'Components',
  },
  edges: {
    id: 'edges_Legend',
    defaultMessage: 'Edges',
  },
  traceEdge: {
    id: 'traceEdge_Legend',
    defaultMessage: 'Tracing edge',
  },
  transactionEdge: {
    id: 'transactionEdge_Legend',
    defaultMessage: 'Transaction edge',
  },
  activeEdge: {
    id: 'activeEdge_Legend',
    defaultMessage: 'Active edge',
  },
  edgeNumber: {
    id: 'edgeNumber_Legend',
    defaultMessage: 'Edge number',
  },
  menu: {
    id: 'menu_Legend',
    defaultMessage: 'Menu',
  },
  radar: {
    id: 'radar_Legend',
    defaultMessage: 'Graph navigator',
  },
  radarTooltip: {
    id: 'radarTooltip_Legend',
    defaultMessage:
      'Graph navigator: A scaled-down version of the visible graph area.',
  },
  valencyTooltip: {
    id: 'valencyTooltip_Legend',
    defaultMessage:
      'Valency: Displays the number of parent and child actors in the current layer.',
  },
  layerPanel: {
    id: 'layerPanel_Legend',
    defaultMessage: 'Layer panel',
  },
  layerPanelTooltip: {
    id: 'layerPanelTooltip_Legend',
    defaultMessage: 'Layer Panel: Provides the "Actor Bag" on the graph.',
  },
  autoAlignment: {
    id: 'autoAlignment_Legend',
    defaultMessage: 'Auto-alignment',
  },
  autoAlignmentTooltip: {
    id: 'autoAlignmentTooltip',
    defaultMessage:
      'Auto-alignment: A panel offering actor auto-alignment types.',
  },
  lassoTooltip: {
    id: 'lassoTooltip_Legend',
    defaultMessage:
      'Lasso: Tool for selecting actors using a freeform lasso selection.',
  },
  settingsTooltip: {
    id: 'settingsTooltip_Legend',
    defaultMessage:
      'Settings: Panel for configuring custom settings of the layer.',
  },
  stateMenuTooltip: {
    id: 'stateMenuTooltip_Legend',
    defaultMessage: 'State: Allows for state creation options.',
  },
  stateMenu: {
    id: 'stateMenu_Legend',
    defaultMessage: 'State',
  },
  streamTooltip: {
    id: 'streamTooltip_Legend',
    defaultMessage: 'Stream: Displays a comment stream specific to the layer.',
  },
  blockDiagram: {
    id: 'blockDiagram_Legend',
    defaultMessage: 'Block diagram (Coming soon)',
  },
  metrics: {
    id: 'metrics_Legend',
    defaultMessage: 'Metrics Icons',
  },
  layerMetric: {
    id: 'layerMetric_Legend',
    defaultMessage: 'Layer',
  },
  actorMetric: {
    id: 'actorMetric_Legend',
    defaultMessage: 'Actor',
  },
  edgeMetric: {
    id: 'edgeMetric_Legend',
    defaultMessage: 'Edge',
  },
  cursorArrow: {
    id: 'cursorArrow_Legend',
    defaultMessage: 'Arrow',
  },
  cursorHand: {
    id: 'cursorHand_Legend',
    defaultMessage: 'Hand',
  },
  cursorArrowEdge: {
    id: 'cursorArrowEdge_Legend',
    defaultMessage: 'Edge',
  },
  cursorText: {
    id: 'cursorText_Legend',
    defaultMessage: 'Text',
  },
  cursorDrag: {
    id: 'cursorDrag_Legend',
    defaultMessage: 'Drag',
  },
  cursorState: {
    id: 'cursorState_Legend',
    defaultMessage: 'State',
  },
  cursorLasso: {
    id: 'cursorLasso_Legend',
    defaultMessage: 'Lasso',
  },
  noAccessEdit: {
    id: 'noAccessEdit_Legend',
    defaultMessage: 'No access edit',
  },
  noAccessView: {
    id: 'noAccessView_Legend',
    defaultMessage: 'No access view',
  },
  cursorResize: {
    id: 'cursorResize_Legend',
    defaultMessage: 'Diagonal resize',
  },
  cursorSides: {
    id: 'cursorSides_Legend',
    defaultMessage: 'Horizontal/Vertical',
  },
  cursors: {
    id: 'cursors_Legend',
    defaultMessage: 'Cursors',
  },
  actorStatus: {
    id: 'actorStatus_Legend',
    defaultMessage: 'Actor status',
  },
  traceActor: {
    id: 'traceActor_Legend',
    defaultMessage: 'Tracing actor',
  },
  balanceActor: {
    id: 'balanceActor_Legend',
    defaultMessage: 'Credit transaction',
  },
  addEdge: {
    id: 'addEdge_Legend',
    defaultMessage: 'Add edge',
  },
  flagActor: {
    id: 'flagActor_Legend',
    defaultMessage: 'Flag actor',
  },
  actorNumber: {
    id: 'actorNumber_Legend',
    defaultMessage: 'Actor number',
  },
  pinActor: {
    id: 'pinActor_Legend',
    defaultMessage: 'Pin actor',
  },
  blockTerminator: {
    id: 'blockTerminator_Legend',
    defaultMessage: 'Start stop',
  },
  blockOffPage: {
    id: 'blockOffPage_Legend',
    defaultMessage: 'Off-page reference',
  },
  blockData: {
    id: 'blockData_Legend',
    defaultMessage: 'Data',
  },
  blockPreparation: {
    id: 'blockPreparation_Legend',
    defaultMessage: 'Preparation',
  },
  blockProcess: {
    id: 'blockProcess_Legend',
    defaultMessage: 'Process',
  },
  blockDecision: {
    id: 'blockDecision_Legend',
    defaultMessage: 'Decision',
  },
  blockPredefProcess: {
    id: 'blockPredefProcess_Legend',
    defaultMessage: 'Predefined process',
  },
  blockDoc: {
    id: 'blockDoc_Legend',
    defaultMessage: 'Document',
  },
  blockDocs: {
    id: 'blockDocs_Legend',
    defaultMessage: 'Documents',
  },
  addEdgeTooltip: {
    id: 'addEdgeTooltip_Legend',
    defaultMessage:
      'Add Edge: Indicates the edge-creation state when hovering over an actor.',
  },
  flagActorTooltip: {
    id: 'flagActorTooltip_Legend',
    defaultMessage: 'Flag Actor: Marks the actor with a flag.',
  },
  actorNumberTooltip: {
    id: 'actorNumberTooltip_Legend',
    defaultMessage:
      'Actor Number: Displays the order of actor addition to the layer.',
  },
  pinActorTooltip: {
    id: 'pinActorTooltip_Legend',
    defaultMessage: 'Pin Actor: Indicates restricted movement of the actor.',
  },
  balanceActorTooltip: {
    id: 'balanceActorTooltip_Legend',
    defaultMessage:
      "Credit transaction: Transaction was made to the actor's account.",
  },
  tracingTooltip: {
    id: 'tracingTooltip_Legend',
    defaultMessage: "Tracing Actor: Highlights the actor's previous position.",
  },
  blockTerminatorTooltip: {
    id: 'blockTerminatorTooltip_Legend',
    defaultMessage: 'Start/Stop: Marks the beginning or end of a process.',
  },
  blockOffPageTooltip: {
    id: 'blockOffPageTooltip_Legend',
    defaultMessage:
      'Off-page Reference: Links the current process to another graph or layer.',
  },
  blockDataTooltip: {
    id: 'blockDataTooltip_Legend',
    defaultMessage: 'Data: Represents data input or output.',
  },
  blockPreparationTooltip: {
    id: 'blockPreparationTooltip_Legend',
    defaultMessage:
      'Preparation: Defines initial parameters or preparatory steps.',
  },
  blockProcessTooltip: {
    id: 'blockProcessTooltip_Legend',
    defaultMessage:
      'Process: Indicates an action or operation within the process.',
  },
  blockDecisionTooltip: {
    id: 'blockDecisionTooltip_Legend',
    defaultMessage:
      'Decision: A decision point leading to alternative process paths.',
  },
  blockPredefProcessTooltip: {
    id: 'blockPredefProcessTooltip_Legend',
    defaultMessage:
      'Predefined Process: Highlights a subprocess or separately executed procedure.',
  },
  blockDocTooltip: {
    id: 'blockDocTooltip_Legend',
    defaultMessage: 'Document: Represents the creation or use of a document.',
  },
  blockDocsTooltip: {
    id: 'blockDocsTooltip_Legend',
    defaultMessage:
      'Documents: Signifies a set of documents involved in the process',
  },
  cursorArrowTooltip: {
    id: 'cursorArrowTooltip_Legend',
    defaultMessage: 'Arrow: Default navigation cursor for the graph layer.',
  },
  cursorHandTooltip: {
    id: 'cursorHandTooltip_Legend',
    defaultMessage:
      'Hand: Activated when hovering over objects on the graph layer.',
  },
  cursorDragTooltip: {
    id: 'cursorDragTooltip_Legend',
    defaultMessage: 'Drag: Used for dragging actors.',
  },
  cursorStateTooltip: {
    id: 'cursorStateTooltip_Legend',
    defaultMessage: 'State: Cursor used to create new states.',
  },
  cursorLassoTooltip: {
    id: 'cursorLassoTooltip_Legend',
    defaultMessage: 'Lasso: Tool for selecting an area using the lasso.',
  },
  cursorSidesTooltip: {
    id: 'cursorSidesTooltip',
    defaultMessage:
      'Horizontal/Vertical Resize: Adjusts actor size horizontally or vertically.',
  },
  noAccessEditTooltip: {
    id: 'noAccessEditTooltip_Legend',
    defaultMessage:
      'No Access Edit: Indicates restricted access to editing the actor.',
  },
  noAccessViewTooltip: {
    id: 'noAccessViewTooltip_Legend',
    defaultMessage:
      'No Access View: Indicates restricted viewing access to the actor.',
  },
  cursorArrowEdgeTooltip: {
    id: 'cursorArrowEdgeTooltip_Legend',
    defaultMessage: 'Edge Cursor: Used to create edges between actors.',
  },
  cursorTextTooltip: {
    id: 'cursorTextTooltip_Legend',
    defaultMessage: 'Text Cursor: For text input or editing.',
  },
  cursorResizeTooltip: {
    id: 'cursorResizeTooltip_Legend',
    defaultMessage: 'Diagonal Resize: Resizes the actor diagonally.',
  },
});

export default m;
