import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconScript(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.7058823529411757, 0);
  ctx.scale(1.411764705882353, 1.411764705882353);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.beginPath();
  ctx.moveTo(2.86225, 0.5);
  ctx.bezierCurveTo(1.46701, 0.5, 0.335938, 1.63107, 0.335938, 3.02632);
  ctx.lineTo(0.335938, 13.1316);
  ctx.bezierCurveTo(0.335938, 14.5268, 1.46701, 15.6579, 2.86225, 15.6579);
  ctx.lineTo(6.23067, 15.6579);
  ctx.bezierCurveTo(6.69576, 15.6579, 7.07278, 15.2809, 7.07278, 14.8158);
  ctx.bezierCurveTo(7.07278, 14.3507, 6.69576, 13.9737, 6.23067, 13.9737);
  ctx.lineTo(2.86225, 13.9737);
  ctx.bezierCurveTo(2.39717, 13.9737, 2.02015, 13.5967, 2.02015, 13.1316);
  ctx.lineTo(2.02015, 3.02632);
  ctx.bezierCurveTo(2.02015, 2.56123, 2.39717, 2.18421, 2.86225, 2.18421);
  ctx.lineTo(11.2833, 2.18421);
  ctx.bezierCurveTo(11.7484, 2.18421, 12.1254, 2.56123, 12.1254, 3.02632);
  ctx.lineTo(12.1254, 8.92105);
  ctx.bezierCurveTo(12.1254, 9.38613, 12.5024, 9.76316, 12.9675, 9.76316);
  ctx.bezierCurveTo(13.4326, 9.76316, 13.8096, 9.38613, 13.8096, 8.92105);
  ctx.lineTo(13.8096, 3.02632);
  ctx.bezierCurveTo(13.8096, 1.63107, 12.6786, 0.5, 11.2833, 0.5);
  ctx.lineTo(2.86225, 0.5);
  ctx.closePath();
  ctx.moveTo(13.9943, 11.7134);
  ctx.bezierCurveTo(14.338, 11.3569, 14.8951, 11.3569, 15.2387, 11.7134);
  ctx.bezierCurveTo(15.5824, 12.0699, 15.5824, 12.6478, 15.2387, 13.0042);
  ctx.lineTo(12.1441, 16.2144);
  ctx.lineTo(12.1284, 16.2311);
  ctx.bezierCurveTo(12.0417, 16.321, 11.9415, 16.3882, 11.8345, 16.4328);
  ctx.bezierCurveTo(11.5171, 16.5652, 11.1404, 16.4981, 10.8833, 16.2313);
  ctx.bezierCurveTo(10.8752, 16.2229, 10.8672, 16.2143, 10.8595, 16.2057);
  ctx.lineTo(9.01735, 14.2948);
  ctx.bezierCurveTo(8.67371, 13.9383, 8.67371, 13.3604, 9.01735, 13.004);
  ctx.bezierCurveTo(9.36098, 12.6475, 9.91812, 12.6475, 10.2618, 13.004);
  ctx.lineTo(11.506, 14.2946);
  ctx.lineTo(13.9943, 11.7134);
  ctx.closePath();
  ctx.moveTo(5.3912, 6.39474);
  ctx.bezierCurveTo(5.3912, 6.85982, 5.01418, 7.23684, 4.5491, 7.23684);
  ctx.bezierCurveTo(4.08401, 7.23684, 3.70699, 6.85982, 3.70699, 6.39474);
  ctx.bezierCurveTo(3.70699, 5.92965, 4.08401, 5.55263, 4.5491, 5.55263);
  ctx.bezierCurveTo(5.01418, 5.55263, 5.3912, 5.92965, 5.3912, 6.39474);
  ctx.closePath();
  ctx.moveTo(4.5491, 10.6039);
  ctx.bezierCurveTo(5.01418, 10.6039, 5.3912, 10.2269, 5.3912, 9.76184);
  ctx.bezierCurveTo(5.3912, 9.29676, 5.01418, 8.91974, 4.5491, 8.91974);
  ctx.bezierCurveTo(4.08401, 8.91974, 3.70699, 9.29676, 3.70699, 9.76184);
  ctx.bezierCurveTo(3.70699, 10.2269, 4.08401, 10.6039, 4.5491, 10.6039);
  ctx.closePath();
  ctx.moveTo(6.23331, 6.39474);
  ctx.bezierCurveTo(6.23331, 5.92965, 6.61033, 5.55263, 7.07541, 5.55263);
  ctx.lineTo(9.60173, 5.55263);
  ctx.bezierCurveTo(10.0668, 5.55263, 10.4438, 5.92965, 10.4438, 6.39474);
  ctx.bezierCurveTo(10.4438, 6.85982, 10.0668, 7.23684, 9.60173, 7.23684);
  ctx.lineTo(7.07541, 7.23684);
  ctx.bezierCurveTo(6.61033, 7.23684, 6.23331, 6.85982, 6.23331, 6.39474);
  ctx.closePath();
  ctx.moveTo(7.07541, 8.91974);
  ctx.bezierCurveTo(6.61033, 8.91974, 6.23331, 9.29676, 6.23331, 9.76184);
  ctx.bezierCurveTo(6.23331, 10.2269, 6.61033, 10.6039, 7.07541, 10.6039);
  ctx.lineTo(9.60173, 10.6039);
  ctx.bezierCurveTo(10.0668, 10.6039, 10.4438, 10.2269, 10.4438, 9.76184);
  ctx.bezierCurveTo(10.4438, 9.29676, 10.0668, 8.91974, 9.60173, 8.91974);
  ctx.lineTo(7.07541, 8.91974);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconScript;
