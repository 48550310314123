import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Icon,
  Chip,
  Utils,
  TextField,
  Label,
  Tooltip,
  cr,
  CorezoidLightTheme as theme,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import cn from 'classnames';
import mes from './intl';
import scss from './Title.scss';

const TITLE_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
};

function Title({
  title,
  onSubmit,
  canEdit,
  defTitle,
  nullable,
  lineCount,
  type,
  fontSize,
  fullWidth,
  readOnly,
  isNew,
}) {
  const t = useIntl();
  const [editMode, setEditMode] = useState(null);
  const [value, setValue] = useState(title);
  const titleLabel = title || defTitle;
  const valueWithLinks = AppUtils.hilightLinks(
    Utils.sanitizeHtml(Utils.stripHtml(Utils.nToBr(titleLabel)), {
      allowedTags: false,
    })
  );

  useEffect(() => {
    if (title !== value && !editMode) setValue(title);
  }, [title, editMode]);

  const handleOnCancel = () => {
    setEditMode(null);
  };

  const handleEditMode = (e) => {
    // get title div styles to calculate line count
    const style = window.getComputedStyle(e.target);
    const lineHeight = AppUtils.toDecimalInt(style['line-height']);
    const height = AppUtils.toDecimalInt(style.height);
    const lines = Math.round(height / lineHeight);
    // save multiline in edit mode (if title is 2 rows, edit field should be also 2 rows)
    setEditMode({ multiline: lines > 1, rows: lines });
  };

  const handleOnSubmit = () => {
    const trimmedValue = value.trim();
    if (!nullable && !trimmedValue.length) return;
    onSubmit(trimmedValue);
    handleOnCancel();
  };

  const handleInputKeyUp = (e) => {
    if (['Enter'].includes(e.key)) {
      handleOnSubmit();
    } else if (['Escape'].includes(e.key)) {
      handleOnCancel();
    }
  };

  return (
    <Stack.H fullWidth={fullWidth} alignItems="center">
      {cr(
        [
          editMode,
          <div
            style={{ width: '100%' }}
            onClick={(e) => {
              if (lineCount === 1) e.stopPropagation();
            }}
          >
            <TextField
              className={scss.editField}
              autoFocus
              bordered
              unspaced
              multilineType="text"
              multiline={editMode?.multiline}
              rows={editMode?.rows}
              autoSelect="mount"
              error={!nullable ? value.length === 0 : false}
              value={value}
              onChange={({ value }) => setValue(value)}
              onBlur={handleOnSubmit}
              onKeyUp={handleInputKeyUp}
            />
          </div>,
        ],
        [
          true,
          <Stack.H
            key="preview"
            className={cn(scss.titleWrap, scss[type], {
              [scss.editable]: canEdit,
            })}
            size="xsmall"
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
              if (canEdit && e.target.tagName !== 'A') {
                handleEditMode(e);
              }
            }}
          >
            {cr([
              readOnly,
              <Chip
                className={scss.readOnly}
                type="rectangular"
                size="small"
                label={t(mes.readOnly)}
                fontSize="small"
                fontWeight="normal"
                closeIcon={false}
                unspaced
                bgColor={theme.palette.black}
                borderColor={theme.palette.black}
              />,
            ])}
            <Tooltip value={titleLabel}>
              <div
                className={cn(scss.title, { [scss.isNew]: isNew })}
                style={{
                  WebkitLineClamp: lineCount,
                  fontSize: `${theme.label.fontSize[fontSize]}px`,
                }}
                dangerouslySetInnerHTML={{
                  __html: valueWithLinks,
                }}
              />
            </Tooltip>
            <Icon type="edit" size="small" />
          </Stack.H>,
        ]
      )}
    </Stack.H>
  );
}

Title.defaultProps = {
  title: '',
  canEdit: false,
  readOnly: false,
  nullable: false,
  isNew: false,
  fullWidth: true,
  lineCount: 1,
  type: TITLE_TYPE.primary,
  fontSize: Label.SIZES.large,
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TITLE_TYPE)),
  fontSize: PropTypes.oneOf(Object.values(Label.SIZES)),
  fullWidth: PropTypes.bool,
  lineCount: PropTypes.number,
  defTitle: PropTypes.string,
  nullable: PropTypes.bool,
  isNew: PropTypes.bool,
};

export default Title;
