import { Utils } from 'mw-style-react';

export const INIT_MEETING = Utils.createRequestTypes('INIT_MEETING');
export const JOIN_MEETING = Utils.createRequestTypes('JOIN_MEETING');
export const CREATE_MEETING = Utils.createRequestTypes('CREATE_MEETING');
export const CALL_MEETING = Utils.createRequestTypes('CALL_MEETING');
export const ACCEPT_MEETING = Utils.createRequestTypes('ACCEPT_MEETING');
export const REJECT_MEETING = Utils.createRequestTypes('REJECT_MEETING');
export const RECORD_MEETING = Utils.createRequestTypes('RECORD_MEETING');
export const GET_MEETING_PARTICIPANTS = Utils.createRequestTypes(
  'GET_MEETING_PARTICIPANTS'
);
export const MANAGE_MEETING_PARTICIPANTS = Utils.createRequestTypes(
  'MANAGE_MEETING_PARTICIPANTS'
);
export const MEETING_MODERATOR_CHANGED = 'MEETING_MODERATOR_CHANGED';

export const WS_SIP_JOIN = 'WS_SIP_JOIN';
export const WS_SIP_LEFT = 'WS_SIP_LEFT';
export const WS_SIP_CALL = 'WS_SIP_CALL';
export const WS_SIP_ACCEPT = 'WS_SIP_ACCEPT';
export const WS_SIP_REJECT = 'WS_SIP_REJECT';
export const WS_SIP_END = 'WS_SIP_END';
export const WS_SIP_MUTE = 'WS_SIP_MUTE';
export const WS_SIP_REMOVE_PARTICIPANT = 'WS_SIP_REMOVE_PARTICIPANT';
export const WS_MEETING_REMINDER_ACTOR = 'WS_MEETING_REMINDER_ACTOR';
export const WS_MEETING_STARTED_ACTOR = 'WS_MEETING_STARTED_ACTOR';

export const GET_ACTIVE_MEETINGS = Utils.createRequestTypes(
  'GET_ACTIVE_MEETINGS'
);
export const ADD_ACTIVE_MEETING =
  Utils.createRequestTypes('ADD_ACTIVE_MEETING');
export const REMOVE_ACTIVE_MEETING = Utils.createRequestTypes(
  'REMOVE_ACTIVE_MEETING'
);

export const MEETING_NOTIFY = {
  CALL: 'sipCall',
  ACCEPT: 'sipAccept',
  REJECT: 'sipReject',
  FAILED: 'sipFailed',
};

export const MANAGE_MEETING_ACTIONS = {
  mute: 'mute',
  remove: 'remove',
};

export const CALL_STATUS = {
  CALL: 'call',
  REJECT: 'reject',
};

export const MEETING_CHANNEL = 'MEETING_CHANNEL';
export const STOP_MEETING_NOTIFY = 'STOP_MEETING_NOTIFY';
