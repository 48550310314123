/**
 * UI elements that can be manually shown/hidden with URL params
 */
const TOGGLING_UI_ELEMENT = {
  sidebar: 'sidebar',
  streamsTabs: 'streamsTabs',
  streamsControls: 'streamsControls',
  headerControls: 'headerControls',
  eventControls: 'eventControls',
  eventInfo: 'eventInfo',
  header: 'header',
  showLastReaction: 'showLastReaction',
};

export default TOGGLING_UI_ELEMENT;
