import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent } from 'mw-style-react';
import { useIntl } from 'hooks';
import History from '../../../History';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './ChangesHistory.scss'; // NOSONAR

function ChangesHistory(props) {
  const { data, visibility, onClose } = props;
  const t = useIntl();

  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.actorHistory, { title: data.title || 'actor' })}
      size="xlarge"
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <History {...data} />
      </ModalContent>
    </Modal>
  );
}

ChangesHistory.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default ChangesHistory;
