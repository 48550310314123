import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { TextField, Calendar, DateUtils, Space, Label } from 'mw-style-react';
import { DATE_FORMAT_2 } from 'constants';
import { useIntl } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import scss from '../../CreateActorsFilter.scss';

function SpacedTitle({ ...props }) {
  return (
    <Space bottom size={Space.SIZE.xsmall}>
      <Label color={Label.COLOR.black} fontWeight="semibold" {...props} />
    </Space>
  );
}

function BalanceDateRange({
  periodAmountFrom,
  periodAmountTo,
  errors,
  onChange,
}) {
  const t = useIntl();
  const fields = [
    {
      id: 'periodAmountFrom',
      value: periodAmountFrom,
      error: !!errors.periodAmountTo,
      label: t(mes.balanceFrom),
      calendarClassName: scss.filter__calendar,
    },
    {
      id: 'periodAmountTo',
      value: periodAmountTo,
      error: !!errors.periodAmountTo || !!errors.periodAmountFrom,
      helperText: t(mes[errors.periodAmountTo || errors.periodAmountFrom]),
      label: t(mes.balanceTo),
      calendarClassName: cn(scss.filter__calendar, scss.dateTo),
    },
  ];

  const todayDateEnd = Math.round(
    DateUtils.endOf(new Date().getTime(), 'day') / 1000
  );

  const handleChangeDate = ({ id, value }) => {
    const fDate = id === 'periodAmountFrom' ? 'startOf' : 'endOf';
    const date = DateUtils[fDate](value.startDate * 1000, 'day');
    const time = new Date(date).getTime();
    const checkDate =
      id === 'periodAmountFrom'
        ? periodAmountTo < time
        : periodAmountFrom > time;
    onChange({ id, value: time, error: checkDate ? 'invalidRange' : false });
  };

  const handleResetDate = useCallback(
    (id) => onChange({ id, value: undefined, error: false }),
    [onChange]
  );

  return fields.map(
    ({ id, value, error, helperText, label, calendarClassName }) => (
      <div className={scss.filter__item}>
        <TextField
          className={scss.filter__textfield}
          size="medium"
          bordered
          unspaced
          value={
            value
              ? DateUtils.toDate(AppUtils.toUnixSeconds(value), DATE_FORMAT_2)
              : ''
          }
          error={error}
          helperText={helperText}
          label={<SpacedTitle value={label} />}
          resettable
          onReset={() => handleResetDate(id)}
          calendar={() => (
            <Calendar
              id={id}
              className={calendarClassName}
              size="small"
              value={{
                startDate: AppUtils.toUnixSeconds(value),
              }}
              onChange={handleChangeDate}
              maxDate={todayDateEnd}
            />
          )}
        />
      </div>
    )
  );
}

BalanceDateRange.propTypes = {
  periodAmountFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  periodAmountTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default BalanceDateRange;
