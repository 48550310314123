import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Label,
  TextField,
  Tooltip,
  Icon,
  Upload,
  Avatar,
  Divider,
  Select,
  MenuItem,
  Space,
  Stack,
} from 'mw-style-react';
import { SPACE_SIZE } from 'mw-style-react/build/constants';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import { UPLOAD_FILE, SCRIPT_SHARE_TYPES } from 'constants';
import { RE_APP_SHORT_NAME } from '@control-front-end/common/constants/regExp';
import useIntl from 'useIntl';
import mes from './intl';
import sLocal from './ScriptSettings.scss';

function ScriptSettings({ values, errors, handleOnChange, isEmbedded }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const pictureUrl = values.picture
    ? AppUtils.makeAppUrl(`/download/${values.picture}`)
    : '';

  const renderLabelWithHintIcon = (label, hint) => (
    <Stack.H fullWidth size={Stack.SIZE.xxsmall} alignItems="center">
      <Label value={label} fontSize="small" fontWeight="semibold" />
      <Tooltip value={hint} breakWord topLevel>
        <Icon type="info" size="small" />
      </Tooltip>
    </Stack.H>
  );

  const handleUploadPicture = ({ value }) => {
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files: value,
        callback: (attachments) => {
          const picture = attachments[0].fileName;
          handleOnChange({ id: 'picture', value: picture });
        },
      },
    });
  };

  const handleTitleChange = ({ value }) => {
    handleOnChange({ id: 'title', value });
    const invalidSymbolsRegexp = new RegExp('[^0-9a-zA-Z_-\\s]', 'ig');
    const scriptTitle = AppUtils.transliterate(value).replace(
      invalidSymbolsRegexp,
      ''
    );
    const ref = scriptTitle.split(' ').join('-').toLowerCase();
    setTimeout(
      () => handleOnChange({ id: 'ref', value: ref, error: false }),
      0
    );
  };

  const renderShareTypes = () =>
    SCRIPT_SHARE_TYPES.map((shareType) => (
      <MenuItem
        key={shareType}
        label={t(mes[`scriptSharedWith_${shareType}`]) || ''}
        value={shareType}
      />
    ));

  return (
    <Stack.V
      size={Stack.SIZE.none}
      className={cn(sLocal.settings, { [sLocal.embeddedSettings]: isEmbedded })}
    >
      <div styleName="sLocal.row">
        <div styleName="sLocal.settings__picture">
          <Upload
            styleName={cn('sLocal.settings__picture__upload', {
              'sLocal.loaded': !!values.picture,
            })}
            type="avatar"
            accept="image/*"
            onChange={handleUploadPicture}
          >
            <Avatar
              size="xxlarge"
              bgColor="#FFF"
              src={values.picture ? pictureUrl : ''}
            />
          </Upload>
        </div>
        <Stack.V fullWidth size={Stack.SIZE.xsmall}>
          <Stack.H fullWidth size={Stack.SIZE.medium}>
            <TextField
              id="title"
              className={cn(sLocal.row__field, sLocal.scriptTitle)}
              autoFocus
              bordered
              label={t(mes.title)}
              value={values.title || ''}
              error={!!errors.title}
              helperText={t(mes[errors.title])}
              onChange={handleTitleChange}
            />
            <TextField
              id="shortName"
              className={cn(sLocal.row__field, sLocal.shortName, {
                [sLocal.embeddedField]: isEmbedded,
              })}
              bordered
              unspaced
              label={renderLabelWithHintIcon(
                t(mes.shortName),
                t(mes.shortNameHint)
              )}
              value={values.shortName || ''}
              error={!!errors.shortName}
              helperText={t(mes[errors.shortName])}
              onChange={handleOnChange}
            />
          </Stack.H>
          <Stack
            fullWidth
            horizontal={isEmbedded}
            size={isEmbedded ? Stack.SIZE.medium : Stack.SIZE.xxsmall}
          >
            <TextField
              id="description"
              className={cn(sLocal.row__field, {
                [sLocal.embeddedField]: isEmbedded,
              })}
              bordered
              label={t(mes.description)}
              value={values.description || ''}
              error={!!errors.description}
              onChange={handleOnChange}
            />
            <Space
              top
              bottom
              size="large"
              className={cn({
                [sLocal.embeddedField]: isEmbedded,
              })}
            >
              <Select
                styleName="sLocal.row__field"
                label={t(mes.sharedWith)}
                helperText=""
                bordered
                unspaced
                value={values.sharedWith[0].value}
                onChange={({ value }) => {
                  handleOnChange({
                    id: 'sharedWith',
                    value: [
                      { value, title: t(mes[`scriptSharedWith_${value}`]) },
                    ],
                  });
                }}
              >
                {renderShareTypes()}
              </Select>
            </Space>
          </Stack>
        </Stack.V>
      </div>
      <Divider styleName="sLocal.row__divider" />
      <Space top bottom size={SPACE_SIZE.medium}>
        <Label
          styleName="sLocal.row__label"
          fontWeight="semibold"
          value={t(mes.corCredentials)}
        />
      </Space>
      <div styleName="sLocal.settings__credentials">
        {['apiLogin', 'apiSecret', 'companyId', 'devProcId', 'prodProcId'].map(
          (field) => {
            let fieldType = field === 'apiSecret' ? 'password' : 'text';
            if (['apiLogin', 'devProcId', 'prodProcId'].includes(field)) {
              fieldType = 'int';
            }
            return (
              <TextField
                key={field}
                id={field}
                styleName="sLocal.row__field"
                bordered
                label={`${t(mes[field])} *`}
                value={values[field] || ''}
                error={!!errors[field]}
                type={fieldType}
                regexp={field === 'ref' ? RE_APP_SHORT_NAME : null}
                helperText={t(mes[errors[field]])}
                onChange={handleOnChange}
              />
            );
          }
        )}
      </div>
    </Stack.V>
  );
}

ScriptSettings.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleOnChange: PropTypes.func,
};

export default ScriptSettings;
