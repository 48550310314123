import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl';

const m = defineMessages({
  hiddenReply: {
    id: 'hiddenReply',
    defaultMessage: 'A reply to a hidden message can only be hidden',
  },
});

export default { ...globalIntl, ...m };
