import { useRef } from 'react';

const useClickHandlers = (onClick, onDoubleClick, delay = 250) => {
  const clickTimeout = useRef(null);

  return () => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      onDoubleClick();
    } else {
      clickTimeout.current = setTimeout(() => {
        onClick();
        clickTimeout.current = null;
      }, delay);
    }
  };
};

export default useClickHandlers;
