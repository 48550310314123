import React from 'react';
import { Checkbox, Space } from 'mw-style-react';
import scss from './BulkActionsHeader.scss';

function ItemCheckBox({ itemId, active, onClick }) {
  return (
    <div
      className={scss.checkbox}
      onDoubleClick={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        onClick(itemId, !active, e.shiftKey);
      }}
    >
      <Space size="xxsmall">
        <Checkbox value={active} />
      </Space>
    </div>
  );
}

export default ItemCheckBox;
