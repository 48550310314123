import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  createSmartForm: {
    id: 'createSmartForm_SmartFormSelection',
    defaultMessage: 'Create Smart Form',
  },
  pleaseWaitWhileCreating: {
    id: 'pleaseWaitWhileCreating_SmartFormSelection',
    defaultMessage: 'Please wait while smart form is creating...',
  },
  wrongFormat: {
    id: 'wrongFormat_SmartFormSelection',
    defaultMessage: 'Wrong format (see the hint)',
  },
  errorNoTemplateSelected: {
    id: 'errorNoTemplateSelected_SmartFormSelection',
    defaultMessage: 'No Smart Form template selected',
  },
  errorCreatingSmartForm: {
    id: 'errorCreatingSmartForm_SmartFormSelection',
    defaultMessage: 'Error creating SmartForm',
  },
});

export default { ...globalIntl, ...m };
