import {
  GET_ACTORS_FILTERS,
  UPDATE_FILTER,
  UPDATE_SYSTEM_FILTER,
  CLEAR_ACTORS_FILTERS,
  SET_ACTIVE_ACTORS_FILTER,
  ADD_ACTORS_FILTER,
  HIDE_ACTORS_FILTER,
  SYSTEM_FILTERS,
  FILTER_ACCESS,
} from '@control-front-end/common/constants/actorsFilters';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: SYSTEM_FILTERS,
  active: 'all',
  limit: 100,
  offset: 0,
  total: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_ACTORS_FILTERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_ACTORS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_SYSTEM_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [FILTER_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [HIDE_ACTORS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_ACTORS_FILTER](state, action) {
    return { ...state, active: action.payload };
  },
  [CLEAR_ACTORS_FILTERS]() {
    return initState;
  },
});
