import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconList(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.primary;
  ctx.lineWidth = 2;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.beginPath();
  ctx.moveTo(25, 11.0001);
  ctx.lineTo(9, 11.0001);
  ctx.moveTo(25, 3.00008);
  ctx.lineTo(9, 3.00008);
  ctx.moveTo(25, 19.0001);
  ctx.lineTo(9, 19.0001);
  ctx.moveTo(3.66667, 11.0001);
  ctx.bezierCurveTo(3.66667, 11.7365, 3.06971, 12.3334, 2.33333, 12.3334);
  ctx.bezierCurveTo(1.59695, 12.3334, 1, 11.7365, 1, 11.0001);
  ctx.bezierCurveTo(1, 10.2637, 1.59695, 9.66675, 2.33333, 9.66675);
  ctx.bezierCurveTo(3.06971, 9.66675, 3.66667, 10.2637, 3.66667, 11.0001);
  ctx.closePath();
  ctx.moveTo(3.66667, 3.00008);
  ctx.bezierCurveTo(3.66667, 3.73646, 3.06971, 4.33341, 2.33333, 4.33341);
  ctx.bezierCurveTo(1.59695, 4.33341, 1, 3.73646, 1, 3.00008);
  ctx.bezierCurveTo(1, 2.2637, 1.59695, 1.66675, 2.33333, 1.66675);
  ctx.bezierCurveTo(3.06971, 1.66675, 3.66667, 2.2637, 3.66667, 3.00008);
  ctx.closePath();
  ctx.moveTo(3.66667, 19.0001);
  ctx.bezierCurveTo(3.66667, 19.7365, 3.06971, 20.3334, 2.33333, 20.3334);
  ctx.bezierCurveTo(1.59695, 20.3334, 1, 19.7365, 1, 19.0001);
  ctx.bezierCurveTo(1, 18.2637, 1.59695, 17.6667, 2.33333, 17.6667);
  ctx.bezierCurveTo(3.06971, 17.6667, 3.66667, 18.2637, 3.66667, 19.0001);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconList;
