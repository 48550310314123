import React from 'react';
import MainLayout from '../containers/layouts/MainLayout/MainLayout';
import ErrorLayout from '../containers/layouts/ErrorLayout/ErrorLayout';
import EmptyLayout from '../containers/layouts/EmptyLayout/EmptyLayout';

const routes = [
  {
    id: 'events',
    path: '/events/:acc',
    exact: false,
    layout: MainLayout,
    component: React.lazy(() => import('./Events')),
  },
  {
    id: 'actorsGraph',
    path: '/actors_graph/:acc',
    exact: false,
    layout: MainLayout,
    component: React.lazy(() => import('./ActorsGraph')),
  },
  {
    id: 'actorsGraphLayer',
    path: '/layer/:acc',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('./ActorsGraph')),
  },
  {
    id: 'actorView',
    path: '/actor/:acc',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('./ActorsGraph')),
  },
  {
    id: 'actorsGraph',
    path: '/actors_graph/:acc',
    exact: false,
    layout: MainLayout,
    component: React.lazy(() => import('./ActorsGraph')),
  },
  {
    id: 'outerGraph',
    path: '/outer_graph/:acc',
    exact: false,
    layout: MainLayout,
    component: React.lazy(() => import('./OuterGraph')),
  },
  {
    id: 'chart',
    path: '/dashboard/:acc/view/:id',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('./DashboardPage')),
  },
  {
    id: 'eventsCalendar',
    path: '/eventsCalendar/:acc/view',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('./EventsCalendarPage')),
  },
  {
    id: 'eventsCalendarWidget',
    path: '/eventsCalendar/:acc/widget/:streamId?',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('./EventsCalendarWidget')),
  },
  {
    id: 'eventsListWidget',
    path: '/eventsWidget/:acc',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('./WidgetEventListPage')),
  },
  {
    id: 'form',
    path: '/form/:acc/edit/:formId?',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('../components/FormSelection')),
  },
  {
    id: 'files',
    path: '/files/:acc/:action?/:id?',
    exact: true,
    layout: MainLayout,
    component: React.lazy(() => import('./Files')),
  },
  {
    id: 'settings',
    path: '/settings/:acc',
    exact: false,
    layout: MainLayout,
    component: React.lazy(() => import('./Settings')),
  },
  {
    id: 'import_export',
    path: '/import_export',
    exact: true,
    layout: MainLayout,
    component: React.lazy(() => import('./ImportAndExport')),
  },
  {
    id: 'not_found',
    path: '/not_found',
    exact: true,
    layout: ErrorLayout,
    component: React.lazy(() => import('./NotFound')),
  },
  {
    id: 'app_error',
    path: '/app_error',
    exact: true,
    layout: ErrorLayout,
    component: React.lazy(() => import('./AppError')),
  },
  {
    id: 'access_denied',
    path: '/access_denied',
    exact: true,
    layout: ErrorLayout,
    component: React.lazy(() => import('./AccessDenied')),
  },
  {
    id: 'under_construction',
    path: '/under_construction',
    exact: true,
    layout: MainLayout,
    component: React.lazy(() => import('./UnderConstruction')),
  },
  {
    id: 'error',
    path: '/error/:id?',
    exact: true,
    layout: ErrorLayout,
    component: React.lazy(() => import('./Error')),
  },
  {
    id: 'scriptsItem',
    path: '/scripts_item/:acc',
    exact: false,
    layout: MainLayout,
    whiteLabel: true,
    component: React.lazy(() => import('@control-front-end/scripts')),
  },
  {
    id: 'script',
    path: '/script/:acc',
    exact: false,
    layout: EmptyLayout,
    component: React.lazy(() => import('@control-front-end/scripts')),
  },
  {
    id: 'publicScript',
    path: '/:pageId',
    exact: true,
    layout: EmptyLayout,
    component: React.lazy(() => import('@control-front-end/scripts')),
  },
];

export default routes;
