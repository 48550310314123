function SVGIconCredit(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(-13, -13);
  ctx.save();
  ctx.fillStyle = '#22C933';
  ctx.globalAlpha = 0.1;
  ctx.beginPath();
  ctx.moveTo(25, 15);
  ctx.lineTo(25, 15);
  ctx.bezierCurveTo(30.522847498307936, 15, 35, 19.477152501692064, 35, 25);
  ctx.lineTo(35, 25);
  ctx.bezierCurveTo(35, 30.522847498307936, 30.522847498307936, 35, 25, 35);
  ctx.lineTo(25, 35);
  ctx.bezierCurveTo(19.477152501692064, 35, 15, 30.522847498307936, 15, 25);
  ctx.lineTo(15, 25);
  ctx.bezierCurveTo(15, 19.477152501692064, 19.477152501692064, 15, 25, 15);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = '#22C933';
  ctx.beginPath();
  ctx.moveTo(20.2463, 23.7764);
  ctx.lineTo(24.4095, 20.2037);
  ctx.bezierCurveTo(24.4887, 20.1386, 24.5821, 20.0876, 24.6843, 20.0536);
  ctx.bezierCurveTo(24.887, 19.9821, 25.1144, 19.9821, 25.3171, 20.0536);
  ctx.bezierCurveTo(25.4193, 20.0876, 25.5127, 20.1386, 25.5919, 20.2037);
  ctx.lineTo(29.7551, 23.7764);
  ctx.bezierCurveTo(29.9663, 23.9576, 30.0488, 24.2218, 29.9715, 24.4694);
  ctx.bezierCurveTo(29.8942, 24.717, 29.6688, 24.9104, 29.3803, 24.9767);
  ctx.bezierCurveTo(29.0918, 25.043, 28.784, 24.9722, 28.5728, 24.791);
  ctx.lineTo(25.8333, 22.433);
  ctx.lineTo(25.8333, 29.2855);
  ctx.bezierCurveTo(25.8333, 29.6801, 25.4606, 30, 25.0007, 30);
  ctx.bezierCurveTo(24.5409, 30, 24.168, 29.6801, 24.168, 29.2855);
  ctx.lineTo(24.168, 22.433);
  ctx.lineTo(21.4287, 24.791);
  ctx.bezierCurveTo(21.2723, 24.9263, 21.0595, 25.0024, 20.8375, 25.0024);
  ctx.bezierCurveTo(20.6155, 25.0024, 20.4026, 24.9263, 20.2463, 24.791);
  ctx.bezierCurveTo(20.0887, 24.6568, 20, 24.4742, 20, 24.2837);
  ctx.bezierCurveTo(20, 24.0932, 20.0887, 23.9105, 20.2463, 23.7764);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCredit;
