import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorArrowEdge(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(-2, -1);
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(8, 15);
  ctx.lineTo(7, 4);
  ctx.lineTo(16, 10);
  ctx.lineTo(12, 11);
  ctx.lineTo(15.5, 16);
  ctx.lineTo(14, 17);
  ctx.lineTo(10.5, 12);
  ctx.lineTo(8, 15);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(7.50205, 15.0453);
  ctx.lineTo(7.61134, 16.2474);
  ctx.lineTo(8.38411, 15.3201);
  ctx.lineTo(10.4654, 12.8225);
  ctx.lineTo(13.5904, 17.2867);
  ctx.lineTo(13.8707, 17.6871);
  ctx.lineTo(14.2773, 17.416);
  ctx.lineTo(15.7774, 16.416);
  ctx.lineTo(16.2029, 16.1323);
  ctx.lineTo(15.9096, 15.7133);
  ctx.lineTo(12.8265, 11.3088);
  ctx.lineTo(16.1213, 10.4851);
  ctx.lineTo(17.2178, 10.2109);
  ctx.lineTo(16.2774, 9.58397);
  ctx.lineTo(7.27735, 3.58397);
  ctx.lineTo(6.40739, 3.004);
  ctx.lineTo(6.50205, 4.04527);
  ctx.lineTo(7.50205, 15.0453);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(22, 17.5);
  ctx.bezierCurveTo(22, 18.3284, 21.3284, 19, 20.5, 19);
  ctx.bezierCurveTo(20.2684, 19, 20.0491, 18.9475, 19.8533, 18.8538);
  ctx.lineTo(16.8538, 21.8533);
  ctx.bezierCurveTo(16.9475, 22.0491, 17, 22.2684, 17, 22.5);
  ctx.bezierCurveTo(17, 23.3284, 16.3284, 24, 15.5, 24);
  ctx.bezierCurveTo(14.6716, 24, 14, 23.3284, 14, 22.5);
  ctx.bezierCurveTo(14, 21.6716, 14.6716, 21, 15.5, 21);
  ctx.bezierCurveTo(15.7316, 21, 15.9509, 21.0525, 16.1467, 21.1462);
  ctx.lineTo(19.1462, 18.1467);
  ctx.bezierCurveTo(19.0525, 17.9509, 19, 17.7316, 19, 17.5);
  ctx.bezierCurveTo(19, 16.6716, 19.6716, 16, 20.5, 16);
  ctx.bezierCurveTo(21.3284, 16, 22, 16.6716, 22, 17.5);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorArrowEdge;
