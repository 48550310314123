import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconSettings(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.2, 1.2);
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(16.9162, 7.2625);
  ctx.bezierCurveTo(17.015, 7.40875, 17.18, 7.5, 17.3587, 7.5);
  ctx.lineTo(17.5, 7.5);
  ctx.bezierCurveTo(18.163, 7.5, 18.7989, 7.76339, 19.2678, 8.23223);
  ctx.bezierCurveTo(19.7366, 8.70107, 20, 9.33695, 20, 9.99999);
  ctx.bezierCurveTo(20, 10.663, 19.7366, 11.2989, 19.2678, 11.7678);
  ctx.bezierCurveTo(18.7989, 12.2366, 18.163, 12.5, 17.5, 12.5);
  ctx.lineTo(17.4287, 12.5);
  ctx.bezierCurveTo(17.3229, 12.5003, 17.2196, 12.5316, 17.1314, 12.59);
  ctx.bezierCurveTo(17.0432, 12.6484, 16.974, 12.7314, 16.9325, 12.8287);
  ctx.bezierCurveTo(16.8874, 12.927, 16.8727, 13.0365, 16.8903, 13.1432);
  ctx.bezierCurveTo(16.9079, 13.2499, 16.957, 13.3489, 17.0312, 13.4275);
  ctx.lineTo(17.0812, 13.4775);
  ctx.bezierCurveTo(17.3135, 13.7098, 17.4978, 13.9855, 17.6235, 14.289);
  ctx.bezierCurveTo(17.7492, 14.5925, 17.8139, 14.9178, 17.8139, 15.2462);
  ctx.bezierCurveTo(17.8139, 15.5747, 17.7492, 15.9, 17.6235, 16.2035);
  ctx.bezierCurveTo(17.4978, 16.507, 17.3135, 16.7827, 17.0812, 17.015);
  ctx.bezierCurveTo(16.849, 17.2473, 16.5732, 17.4315, 16.2697, 17.5572);
  ctx.bezierCurveTo(15.9662, 17.6829, 15.641, 17.7476, 15.3125, 17.7476);
  ctx.bezierCurveTo(14.984, 17.7476, 14.6587, 17.6829, 14.3553, 17.5572);
  ctx.bezierCurveTo(14.0518, 17.4315, 13.776, 17.2473, 13.5437, 17.015);
  ctx.lineTo(13.5, 16.9712);
  ctx.bezierCurveTo(13.4214, 16.895, 13.3216, 16.8442, 13.2137, 16.8254);
  ctx.bezierCurveTo(13.1058, 16.8067, 12.9947, 16.8209, 12.895, 16.8662);
  ctx.bezierCurveTo(12.7982, 16.9074, 12.7155, 16.9759, 12.6571, 17.0634);
  ctx.bezierCurveTo(12.5987, 17.1509, 12.5671, 17.2535, 12.5662, 17.3587);
  ctx.lineTo(12.5662, 17.5);
  ctx.bezierCurveTo(12.5662, 18.163, 12.3029, 18.7989, 11.834, 19.2678);
  ctx.bezierCurveTo(11.3652, 19.7366, 10.7293, 20, 10.0662, 20);
  ctx.bezierCurveTo(9.4032, 20, 8.76732, 19.7366, 8.29848, 19.2678);
  ctx.bezierCurveTo(7.82964, 18.7989, 7.56625, 18.163, 7.56625, 17.5);
  ctx.bezierCurveTo(7.56996, 17.3741, 7.53274, 17.2503, 7.46017, 17.1473);
  ctx.bezierCurveTo(7.3876, 17.0443, 7.28359, 16.9676, 7.16375, 16.9287);
  ctx.bezierCurveTo(7.06614, 16.8853, 6.95781, 16.8718, 6.85253, 16.8901);
  ctx.bezierCurveTo(6.74725, 16.9083, 6.64977, 16.9574, 6.5725, 17.0312);
  ctx.lineTo(6.5225, 17.0812);
  ctx.bezierCurveTo(6.05339, 17.5503, 5.41716, 17.8139, 4.75375, 17.8139);
  ctx.bezierCurveTo(4.09034, 17.8139, 3.4541, 17.5503, 2.985, 17.0812);
  ctx.bezierCurveTo(2.5159, 16.6121, 2.25236, 15.9759, 2.25236, 15.3125);
  ctx.bezierCurveTo(2.25236, 14.6491, 2.5159, 14.0128, 2.985, 13.5437);
  ctx.lineTo(3.02875, 13.5);
  ctx.bezierCurveTo(3.10502, 13.4214, 3.15583, 13.3216, 3.17456, 13.2137);
  ctx.bezierCurveTo(3.19329, 13.1058, 3.17907, 12.9947, 3.13375, 12.895);
  ctx.bezierCurveTo(3.0926, 12.7982, 3.02407, 12.7155, 2.93658, 12.6571);
  ctx.bezierCurveTo(2.84908, 12.5987, 2.74644, 12.5671, 2.64125, 12.5662);
  ctx.lineTo(2.5, 12.5662);
  ctx.bezierCurveTo(1.83696, 12.5662, 1.20107, 12.3029, 0.732233, 11.834);
  ctx.bezierCurveTo(0.263392, 11.3652, 0, 10.7293, 0, 10.0662);
  ctx.bezierCurveTo(0, 9.4032, 0.263392, 8.76732, 0.732233, 8.29848);
  ctx.bezierCurveTo(1.20107, 7.82964, 1.83696, 7.56625, 2.5, 7.56625);
  ctx.bezierCurveTo(2.62594, 7.56996, 2.74969, 7.53274, 2.85268, 7.46017);
  ctx.bezierCurveTo(2.95568, 7.3876, 3.03237, 7.28359, 3.07125, 7.16375);
  ctx.bezierCurveTo(3.11472, 7.06614, 3.12819, 6.95781, 3.10994, 6.85253);
  ctx.bezierCurveTo(3.09168, 6.74725, 3.04254, 6.64977, 2.96875, 6.5725);
  ctx.lineTo(2.91875, 6.5225);
  ctx.bezierCurveTo(2.44965, 6.05339, 2.18611, 5.41716, 2.18611, 4.75375);
  ctx.bezierCurveTo(2.18611, 4.09034, 2.44965, 3.4541, 2.91875, 2.985);
  ctx.bezierCurveTo(3.38785, 2.5159, 4.02409, 2.25236, 4.6875, 2.25236);
  ctx.bezierCurveTo(5.35091, 2.25236, 5.98714, 2.5159, 6.45625, 2.985);
  ctx.lineTo(6.5, 3.02875);
  ctx.bezierCurveTo(6.57719, 3.1043, 6.67521, 3.15503, 6.78147, 3.17441);
  ctx.bezierCurveTo(6.88773, 3.19379, 6.99735, 3.18093, 7.09625, 3.1375);
  ctx.bezierCurveTo(7.14963, 3.11381, 7.20535, 3.09579, 7.2625, 3.08375);
  ctx.bezierCurveTo(7.33497, 3.03475, 7.39444, 2.96887, 7.43582, 2.89179);
  ctx.bezierCurveTo(7.47719, 2.81471, 7.49921, 2.72873, 7.5, 2.64125);
  ctx.lineTo(7.5, 2.5);
  ctx.bezierCurveTo(7.5, 2.17169, 7.56466, 1.8466, 7.6903, 1.54329);
  ctx.bezierCurveTo(7.81593, 1.23998, 8.00008, 0.964379, 8.23223, 0.732233);
  ctx.bezierCurveTo(8.46437, 0.500086, 8.73997, 0.315938, 9.04329, 0.190301);
  ctx.bezierCurveTo(9.3466, 0.0646644, 9.67169, 0, 9.99999, 0);
  ctx.bezierCurveTo(10.3283, 0, 10.6534, 0.0646644, 10.9567, 0.190301);
  ctx.bezierCurveTo(11.26, 0.315938, 11.5356, 0.500086, 11.7678, 0.732233);
  ctx.bezierCurveTo(11.9999, 0.964379, 12.1841, 1.23998, 12.3097, 1.54329);
  ctx.bezierCurveTo(12.4353, 1.8466, 12.5, 2.17169, 12.5, 2.5);
  ctx.lineTo(12.5, 2.57125);
  ctx.bezierCurveTo(12.5, 2.7875, 12.63, 2.9825, 12.8375, 3.07125);
  ctx.bezierCurveTo(12.9349, 3.11445, 13.043, 3.12777, 13.148, 3.10952);
  ctx.bezierCurveTo(13.2531, 3.09128, 13.3503, 3.04229, 13.4275, 2.96875);
  ctx.lineTo(13.4775, 2.91875);
  ctx.bezierCurveTo(13.9466, 2.44965, 14.5828, 2.18611, 15.2462, 2.18611);
  ctx.bezierCurveTo(15.5747, 2.18611, 15.9, 2.25081, 16.2035, 2.37652);
  ctx.bezierCurveTo(16.507, 2.50222, 16.7827, 2.68647, 17.015, 2.91875);
  ctx.bezierCurveTo(17.2473, 3.15102, 17.4315, 3.42677, 17.5572, 3.73026);
  ctx.bezierCurveTo(17.6829, 4.03374, 17.7476, 4.35901, 17.7476, 4.6875);
  ctx.bezierCurveTo(17.7476, 5.01598, 17.6829, 5.34125, 17.5572, 5.64474);
  ctx.bezierCurveTo(17.4315, 5.94822, 17.2473, 6.22397, 17.015, 6.45625);
  ctx.lineTo(16.9712, 6.5);
  ctx.bezierCurveTo(16.8957, 6.57719, 16.845, 6.67521, 16.8256, 6.78147);
  ctx.bezierCurveTo(16.8062, 6.88773, 16.8191, 6.99735, 16.8625, 7.09625);
  ctx.bezierCurveTo(16.8862, 7.14963, 16.9042, 7.20535, 16.9162, 7.2625);
  ctx.closePath();
  ctx.moveTo(9.99999, 13.3337);
  ctx.bezierCurveTo(10.8842, 13.3337, 11.7321, 12.9825, 12.3573, 12.3573);
  ctx.bezierCurveTo(12.9825, 11.7321, 13.3337, 10.8842, 13.3337, 9.99999);
  ctx.bezierCurveTo(13.3337, 9.11583, 12.9825, 8.26788, 12.3573, 7.64268);
  ctx.bezierCurveTo(11.7321, 7.01748, 10.8842, 6.66625, 9.99999, 6.66625);
  ctx.bezierCurveTo(9.11583, 6.66625, 8.26788, 7.01748, 7.64268, 7.64268);
  ctx.bezierCurveTo(7.01748, 8.26788, 6.66625, 9.11583, 6.66625, 9.99999);
  ctx.bezierCurveTo(6.66625, 10.8842, 7.01748, 11.7321, 7.64268, 12.3573);
  ctx.bezierCurveTo(8.26788, 12.9825, 9.11583, 13.3337, 9.99999, 13.3337);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconSettings;
