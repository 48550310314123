import html2canvas from 'html2canvas';

export default {
  /**
   * copyToClipboard PNG
   */
  async copyToClipboardPng({ rootElement, onSuccess }) {
    if (!rootElement) return;

    // eslint-disable-next-line
    const canvas = await html2canvas(rootElement, { useCORS: true });

    canvas.toBlob(async (blob) => {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob }),
        ]);
        onSuccess?.();
      } catch (err) {
        // eslint-disable-next-line
        console.error('Failed to copy image: ', err);
      }
    }, 'image/png');
  },
  /**
   * exportToPng
   */
  async exportToPng({ rootElement, title = 'dashboard', onSuccess }) {
    if (!rootElement) return;

    try {
      // Use html2canvas to capture the element as an image
      // eslint-disable-next-line
      const canvas = await html2canvas(rootElement, { useCORS: true });

      const dataUrl = canvas.toDataURL('image/png');

      // Remove special characters and spaces for the filename
      const fileName = title
        .replace(/[<>:"/\\|?*]+/g, '')
        .replace(/\s+/g, '_')
        .toLowerCase();

      // Create a download link
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${fileName}.png`;
      link.click();
      onSuccess?.();
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error capturing image:', error);
    }
  },
};
