import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';
import uniqBy from 'lodash/uniqBy';
import {
  cr,
  Tab,
  TabItem,
  Stack,
  withForm,
  PopoverBoundary,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import AccessRules from '@control-front-end/common/components/AccessRules';
import { RE_APP_SHORT_NAME } from '@control-front-end/common/constants/regExp';
import { DEL_MODAL, NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { UPDATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import {
  CREATE_SCRIPT,
  UPDATE_SCRIPT_BAR_SETTINGS,
  UPDATE_SCRIPT_ENV,
} from '@control-front-end/common/constants/scripts';
import { useIntl } from 'hooks';
import ScriptSettings from './components/ScriptSettings';
import CustomBarSettings from './components/CustomBarSettings';
import mesScriptSettings from './components/ScriptSettings/intl';
import mes from '../../intl';
import scss from './ScriptParamsEdit.scss';

const TABS = {
  settings: 'settings',
  access: 'access',
  customBarSettings: 'customBarSettings',
};

const makeEnvActions = (data, credentials) => {
  const envActions = [];
  data.envs.forEach((env) => {
    const envIsChanged = Object.keys(credentials[env.title]).some(
      (key) => credentials[env.title][key] !== env.corezoidCredentials[key]
    );
    if (envIsChanged) {
      envActions.push({
        scriptId: data.id,
        envId: env.id,
        credentials: credentials[env.title],
      });
    }
  });
  return envActions;
};

const afterEnvsUpdate = ({ newEnvs, updatedEnvs, newData, mainCallback }) => {
  newEnvs.forEach((env, index) => {
    const updatedData = updatedEnvs.find((i) => i.id === env.id);
    if (updatedData) newEnvs.splice(index, 1, updatedData);
  });
  mainCallback?.({ ...newData, envs: newEnvs });
};

function ScriptParamsEditBase(props) {
  const {
    data = {},
    values,
    handleOnChange,
    onContentChange,
    onSubmitRef,
    handleSubmit,
    errors,
    isEmbedded = false,
  } = props;
  const t = useIntl();
  const [activeTab, setActiveTab] = useState(TABS.settings);
  const { boundaryRef } = useContext(PopoverBoundary.Context);

  useEffect(() => {
    onSubmitRef.current = handleSubmit;
  }, [onSubmitRef]);

  return (
    <Stack.V fullHeight>
      <Tab
        value={activeTab}
        type="auto"
        underline={true}
        onChange={({ value }) => setActiveTab(value)}
      >
        <TabItem label={t(mes.settings)} value={TABS.settings} />
        <TabItem
          label={t(mes.scriptAccess)}
          value={TABS.access}
          visibility={data.id ? 'hidden' : 'visible'}
        />
        <TabItem
          label={t(mes.customBarSettings)}
          value={TABS.customBarSettings}
        />
      </Tab>
      <Stack.V
        fullWidth
        fullHeight
        forwardRef={boundaryRef}
        className={scss.content}
      >
        {cr(
          [
            activeTab === TABS.settings,
            () => (
              <ScriptSettings
                values={values}
                errors={errors}
                isEmbedded={isEmbedded}
                handleOnChange={(scriptData) => {
                  handleOnChange(scriptData);
                  onContentChange();
                }}
              />
            ),
          ],
          [
            activeTab === TABS.access,
            () => (
              <AccessRules
                styleType="embeded"
                rules={values.access || data.access}
                ownerId={data.ownerId}
                onChange={(access) =>
                  handleOnChange({ id: TABS.access, value: access })
                }
              />
            ),
          ],
          [
            activeTab === TABS.customBarSettings,
            () => (
              <CustomBarSettings
                picture={values.picture}
                users={values.customBarSettings.users}
                id={data.id}
                ownerId={data.ownerId}
                onChange={({ id, value, ...rest }) => {
                  handleOnChange({
                    id: id || TABS.customBarSettings,
                    value: value || rest,
                  });
                  onContentChange();
                }}
              />
            ),
          ]
        )}
      </Stack.V>
    </Stack.V>
  );
}

ScriptParamsEditBase.propTypes = {
  data: PropTypes.object,
  handleOnChange: PropTypes.func,
  onContentChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
};

const ScriptParamsEdit = withForm(
  {
    mapPropsToValues(props) {
      const defaultSharedWith = [
        { value: 'userList', title: 'Users from the list' },
      ];
      if (!props.data?.id)
        return {
          sharedWith: defaultSharedWith,
          access: props.data ? props.data.access : [],
          customBarSettings: { users: new Map() },
        };
      const { title, ref, description, data, picture, envs, access } =
        props.data;
      const develop = envs?.find((i) => i.title === 'develop') || {};
      const production = envs?.find((i) => i.title === 'production') || {};
      const {
        apiLogin,
        apiSecret,
        companyId,
        procId: devProcId,
      } = develop.corezoidCredentials || {};
      const { procId: prodProcId } = production.corezoidCredentials || {};
      return {
        title,
        shortName: ref,
        description,
        sharedWith: data.sharedWith,
        picture,
        access,
        apiLogin,
        apiSecret,
        companyId,
        devProcId,
        prodProcId,
        customBarSettings: { users: new Map() },
      };
    },
    yup: Yup.object().shape({
      title: Yup.string().min(2).required('field_required'),
      shortName: Yup.string()
        .matches(new RegExp(RE_APP_SHORT_NAME), 'wrong_format')
        .required('field_required'),
      description: Yup.string().nullable(true),
      sharedWith: Yup.string(),
      picture: Yup.string().nullable(true),
      apiLogin: Yup.string().required('field_required'),
      apiSecret: Yup.string().required('field_required'),
      companyId: Yup.string().required('field_required'),
      devProcId: Yup.string().required('field_required'),
      prodProcId: Yup.string().required('field_required'),
    }),
    handleErrors: (allErrors, values, dispatch) => {
      const errorFieldsTitles = Object.keys(allErrors).map(
        (key) => mesScriptSettings[key]?.defaultMessage
      );
      setTimeout(
        () =>
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: AppUtils.createRid(),
              type: NOTIFY_LEVEL.ERROR,
              label: `Errors in fields: ${errorFieldsTitles.join(', ')}`,
            },
          }),
        500
      );
    },
    handleSubmit: (values, dispatch, formActions, props) => {
      const { data } = props;
      formActions.callback = props.callback;
      const {
        title,
        shortName,
        description,
        sharedWith,
        picture,
        access,
        apiLogin,
        apiSecret,
        companyId,
        devProcId,
        prodProcId,
        customBarSettings,
      } = values;
      const credentials = { apiLogin, apiSecret, companyId };
      const corezoidCredentials = {
        develop: { ...credentials, procId: devProcId },
        production: { ...credentials, procId: prodProcId },
      };
      if (data.id) {
        dispatch({
          type: UPDATE_ACTOR.REQUEST,
          payload: {
            id: data.id,
            title,
            ref: shortName,
            description,
            picture,
            formId: data.formId,
            formData: { sharedWith },
            access,
          },
          callback: (newData) => {
            const envActions = makeEnvActions(data, corezoidCredentials);
            if (envActions.length) {
              formActions.onClose = props.onClose;
              dispatch({
                type: UPDATE_SCRIPT_ENV.REQUEST,
                payload: { envs: envActions },
                formActions,
                callback: (updatedEnvs) =>
                  afterEnvsUpdate({
                    newEnvs: structuredClone(data.envs),
                    updatedEnvs,
                    newData,
                    mainCallback: props?.callback,
                  }),
              });
            } else {
              props.callback?.({ ...newData });
            }

            dispatch({
              type: UPDATE_SCRIPT_BAR_SETTINGS.REQUEST,
              payload: {
                access,
                customBarUsers: customBarSettings?.users,
                id: data.id,
              },
            });
            dispatch({ type: DEL_MODAL });
          },
        });
      } else {
        dispatch({
          type: CREATE_SCRIPT.REQUEST,
          payload: {
            title,
            ref: shortName,
            description,
            sharedWith,
            picture,
            corezoidCredentials,
            customBarUsers: Array.from(customBarSettings.users.values()),
            access: uniqBy(
              [...access, ...customBarSettings.users],
              (user) => user.userId || user.saId
            ),
          },
          formActions,
          callback: () => {
            dispatch({ type: DEL_MODAL });
          },
        });
      }
    },
  },
  ScriptParamsEditBase
);

export default ScriptParamsEdit;
