import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconAdd(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(8, 0);
  ctx.lineTo(8, 0);
  ctx.bezierCurveTo(12.41827799864635, 0, 16, 3.5817220013536515, 16, 8);
  ctx.lineTo(16, 8);
  ctx.bezierCurveTo(16, 12.41827799864635, 12.41827799864635, 16, 8, 16);
  ctx.lineTo(8, 16);
  ctx.bezierCurveTo(3.5817220013536515, 16, 0, 12.41827799864635, 0, 8);
  ctx.lineTo(0, 8);
  ctx.bezierCurveTo(0, 3.5817220013536515, 3.5817220013536515, 0, 8, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(16, 8);
  ctx.bezierCurveTo(16, 10.1217, 15.1571, 12.1566, 13.6569, 13.6569);
  ctx.bezierCurveTo(12.1566, 15.1571, 10.1217, 16, 8, 16);
  ctx.bezierCurveTo(5.87827, 16, 3.84344, 15.1571, 2.34315, 13.6569);
  ctx.bezierCurveTo(0.842855, 12.1566, 0, 10.1217, 0, 8);
  ctx.bezierCurveTo(0, 5.87827, 0.842855, 3.84344, 2.34315, 2.34315);
  ctx.bezierCurveTo(3.84344, 0.842855, 5.87827, 0, 8, 0);
  ctx.bezierCurveTo(10.1217, 0, 12.1566, 0.842855, 13.6569, 2.34315);
  ctx.bezierCurveTo(15.1571, 3.84344, 16, 5.87827, 16, 8);
  ctx.closePath();
  ctx.moveTo(8.5, 4.5);
  ctx.bezierCurveTo(8.5, 4.36739, 8.44732, 4.24021, 8.35355, 4.14645);
  ctx.bezierCurveTo(8.25979, 4.05268, 8.13261, 4, 8, 4);
  ctx.bezierCurveTo(7.86739, 4, 7.74021, 4.05268, 7.64645, 4.14645);
  ctx.bezierCurveTo(7.55268, 4.24021, 7.5, 4.36739, 7.5, 4.5);
  ctx.lineTo(7.5, 7.5);
  ctx.lineTo(4.5, 7.5);
  ctx.bezierCurveTo(4.36739, 7.5, 4.24021, 7.55268, 4.14645, 7.64645);
  ctx.bezierCurveTo(4.05268, 7.74021, 4, 7.86739, 4, 8);
  ctx.bezierCurveTo(4, 8.13261, 4.05268, 8.25979, 4.14645, 8.35355);
  ctx.bezierCurveTo(4.24021, 8.44732, 4.36739, 8.5, 4.5, 8.5);
  ctx.lineTo(7.5, 8.5);
  ctx.lineTo(7.5, 11.5);
  ctx.bezierCurveTo(7.5, 11.6326, 7.55268, 11.7598, 7.64645, 11.8536);
  ctx.bezierCurveTo(7.74021, 11.9473, 7.86739, 12, 8, 12);
  ctx.bezierCurveTo(8.13261, 12, 8.25979, 11.9473, 8.35355, 11.8536);
  ctx.bezierCurveTo(8.44732, 11.7598, 8.5, 11.6326, 8.5, 11.5);
  ctx.lineTo(8.5, 8.5);
  ctx.lineTo(11.5, 8.5);
  ctx.bezierCurveTo(11.6326, 8.5, 11.7598, 8.44732, 11.8536, 8.35355);
  ctx.bezierCurveTo(11.9473, 8.25979, 12, 8.13261, 12, 8);
  ctx.bezierCurveTo(12, 7.86739, 11.9473, 7.74021, 11.8536, 7.64645);
  ctx.bezierCurveTo(11.7598, 7.55268, 11.6326, 7.5, 11.5, 7.5);
  ctx.lineTo(8.5, 7.5);
  ctx.lineTo(8.5, 4.5);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconAdd;
