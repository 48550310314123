import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconParent(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.2, 1.2);
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(10.012, 12.0452);
  ctx.bezierCurveTo(8.04765, 12.0452, 6.45523, 13.6065, 6.45523, 15.5326);
  ctx.bezierCurveTo(6.45523, 17.4586, 8.04765, 19.02, 10.012, 19.02);
  ctx.bezierCurveTo(11.9763, 19.02, 13.5688, 17.4586, 13.5688, 15.5326);
  ctx.bezierCurveTo(13.5688, 13.6065, 11.9763, 12.0452, 10.012, 12.0452);
  ctx.closePath();
  ctx.moveTo(10.0118, 10.8828);
  ctx.bezierCurveTo(9.35704, 10.8828, 8.82623, 10.352, 8.82623, 9.69721);
  ctx.lineTo(8.82623, 6.23288);
  ctx.lineTo(5.78254, 6.23288);
  ctx.bezierCurveTo(5.15842, 6.23288, 4.84746, 5.47686, 5.29097, 5.03773);
  ctx.lineTo(8.98066, 1.38452);
  ctx.bezierCurveTo(9.52146, 0.849068, 10.3913, 0.844851, 10.9373, 1.37503);
  ctx.lineTo(14.7041, 5.03299);
  ctx.bezierCurveTo(15.1542, 5.47007, 14.8448, 6.23288, 14.2174, 6.23288);
  ctx.lineTo(11.1974, 6.23288);
  ctx.lineTo(11.1974, 9.69721);
  ctx.bezierCurveTo(11.1974, 10.352, 10.6666, 10.8828, 10.0118, 10.8828);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconParent;
