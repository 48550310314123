import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCompany(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.571428571428573, 0);
  ctx.scale(1.1428571428571428, 1.1428571428571428);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.beginPath();
  ctx.moveTo(8.125, 19.25);
  ctx.lineTo(8.125, 16.9062);
  ctx.bezierCurveTo(8.125, 16.475, 8.475, 16.125, 8.90625, 16.125);
  ctx.lineTo(11.0938, 16.125);
  ctx.bezierCurveTo(11.525, 16.125, 11.8813, 16.475, 11.8813, 16.9125);
  ctx.lineTo(11.8813, 19.25);
  ctx.lineTo(18.75, 19.25);
  ctx.lineTo(18.75, 6.3125);
  ctx.bezierCurveTo(18.75, 6.12372, 18.7128, 5.9368, 18.6406, 5.76239);
  ctx.bezierCurveTo(18.5683, 5.58799, 18.4625, 5.42952, 18.329, 5.29603);
  ctx.bezierCurveTo(18.1955, 5.16255, 18.037, 5.05666, 17.8626, 4.98442);
  ctx.bezierCurveTo(17.6882, 4.91218, 17.5013, 4.875, 17.3125, 4.875);
  ctx.lineTo(16.25, 4.875);
  ctx.lineTo(16.25, 3.2875);
  ctx.bezierCurveTo(16.25, 2.4375, 15.5625, 1.75, 14.7125, 1.75);
  ctx.lineTo(5.2875, 1.75);
  ctx.bezierCurveTo(4.4375, 1.75, 3.75, 2.4375, 3.75, 3.2875);
  ctx.lineTo(3.75, 4.875);
  ctx.lineTo(2.6875, 4.875);
  ctx.bezierCurveTo(2.49872, 4.875, 2.3118, 4.91218, 2.13739, 4.98442);
  ctx.bezierCurveTo(1.96299, 5.05666, 1.80452, 5.16255, 1.67103, 5.29603);
  ctx.bezierCurveTo(1.53755, 5.42952, 1.43166, 5.58799, 1.35942, 5.76239);
  ctx.bezierCurveTo(1.28718, 5.9368, 1.25, 6.12372, 1.25, 6.3125);
  ctx.lineTo(1.25, 19.25);
  ctx.lineTo(8.125, 19.25);
  ctx.closePath();
  ctx.moveTo(3.75, 15.5);
  ctx.lineTo(3.125, 15.5);
  ctx.bezierCurveTo(2.78125, 15.5, 2.5, 15.2188, 2.5, 14.875);
  ctx.lineTo(2.5, 14.25);
  ctx.bezierCurveTo(2.5, 13.9062, 2.78125, 13.625, 3.125, 13.625);
  ctx.lineTo(3.75, 13.625);
  ctx.lineTo(3.75, 15.5);
  ctx.closePath();
  ctx.moveTo(3.75, 12.375);
  ctx.lineTo(3.125, 12.375);
  ctx.bezierCurveTo(2.78125, 12.375, 2.5, 12.0938, 2.5, 11.75);
  ctx.lineTo(2.5, 11.125);
  ctx.bezierCurveTo(2.5, 10.7812, 2.78125, 10.5, 3.125, 10.5);
  ctx.lineTo(3.75, 10.5);
  ctx.lineTo(3.75, 12.375);
  ctx.closePath();
  ctx.moveTo(3.75, 9.25);
  ctx.lineTo(3.125, 9.25);
  ctx.bezierCurveTo(2.78125, 9.25, 2.5, 8.96875, 2.5, 8.625);
  ctx.lineTo(2.5, 8);
  ctx.bezierCurveTo(2.5, 7.65625, 2.78125, 7.375, 3.125, 7.375);
  ctx.lineTo(3.75, 7.375);
  ctx.lineTo(3.75, 9.25);
  ctx.closePath();
  ctx.moveTo(16.25, 7.375);
  ctx.lineTo(16.875, 7.375);
  ctx.bezierCurveTo(17.2188, 7.375, 17.5, 7.65625, 17.5, 8);
  ctx.lineTo(17.5, 8.625);
  ctx.bezierCurveTo(17.5, 8.975, 17.2188, 9.25, 16.875, 9.25);
  ctx.lineTo(16.25, 9.25);
  ctx.lineTo(16.25, 7.375);
  ctx.closePath();
  ctx.moveTo(16.25, 10.5);
  ctx.lineTo(16.875, 10.5);
  ctx.bezierCurveTo(17.2188, 10.5, 17.5, 10.7812, 17.5, 11.125);
  ctx.lineTo(17.5, 11.75);
  ctx.bezierCurveTo(17.5, 12.1, 17.2188, 12.375, 16.875, 12.375);
  ctx.lineTo(16.25, 12.375);
  ctx.lineTo(16.25, 10.5);
  ctx.closePath();
  ctx.moveTo(16.25, 13.625);
  ctx.lineTo(16.875, 13.625);
  ctx.bezierCurveTo(17.2188, 13.625, 17.5, 13.9062, 17.5, 14.25);
  ctx.lineTo(17.5, 14.875);
  ctx.bezierCurveTo(17.5, 15.225, 17.2188, 15.5, 16.875, 15.5);
  ctx.lineTo(16.25, 15.5);
  ctx.lineTo(16.25, 13.625);
  ctx.closePath();
  ctx.moveTo(7.1875, 11.75);
  ctx.bezierCurveTo(7.01875, 11.75, 6.875, 11.6125, 6.875, 11.4375);
  ctx.lineTo(6.875, 10.1812);
  ctx.bezierCurveTo(6.875, 10.0125, 7.0125, 9.86875, 7.1875, 9.86875);
  ctx.lineTo(9.06875, 9.86875);
  ctx.bezierCurveTo(9.2375, 9.86875, 9.38125, 10.0062, 9.38125, 10.1812);
  ctx.lineTo(9.38125, 11.4375);
  ctx.bezierCurveTo(9.37961, 11.521, 9.34531, 11.6004, 9.28571, 11.6589);
  ctx.bezierCurveTo(9.22611, 11.7173, 9.14597, 11.75, 9.0625, 11.75);
  ctx.lineTo(7.1875, 11.75);
  ctx.closePath();
  ctx.moveTo(13.125, 10.1875);
  ctx.lineTo(13.125, 11.4438);
  ctx.bezierCurveTo(13.125, 11.6125, 12.9875, 11.75, 12.8188, 11.7563);
  ctx.lineTo(10.9375, 11.7563);
  ctx.bezierCurveTo(10.7625, 11.7563, 10.625, 11.6125, 10.625, 11.4438);
  ctx.lineTo(10.625, 10.1875);
  ctx.bezierCurveTo(10.625, 10.0125, 10.7688, 9.875, 10.9375, 9.875);
  ctx.lineTo(12.8125, 9.875);
  ctx.bezierCurveTo(12.9875, 9.875, 13.125, 10.0188, 13.125, 10.1875);
  ctx.closePath();
  ctx.moveTo(9.375, 13.3125);
  ctx.lineTo(9.375, 14.5688);
  ctx.bezierCurveTo(9.375, 14.7375, 9.2375, 14.875, 9.06875, 14.8813);
  ctx.lineTo(7.1875, 14.8813);
  ctx.bezierCurveTo(7.0125, 14.8813, 6.875, 14.7375, 6.875, 14.5688);
  ctx.lineTo(6.875, 13.3125);
  ctx.bezierCurveTo(6.875, 13.1375, 7.01875, 13, 7.1875, 13);
  ctx.lineTo(9.0625, 13);
  ctx.bezierCurveTo(9.2375, 13, 9.375, 13.1438, 9.375, 13.3125);
  ctx.closePath();
  ctx.moveTo(13.125, 13.3125);
  ctx.lineTo(13.125, 14.5688);
  ctx.bezierCurveTo(13.125, 14.7375, 12.9875, 14.875, 12.8188, 14.8813);
  ctx.lineTo(10.9375, 14.8813);
  ctx.bezierCurveTo(10.7625, 14.8813, 10.625, 14.7375, 10.625, 14.5688);
  ctx.lineTo(10.625, 13.3125);
  ctx.bezierCurveTo(10.625, 13.1375, 10.7688, 13, 10.9375, 13);
  ctx.lineTo(12.8125, 13);
  ctx.bezierCurveTo(12.9875, 13, 13.125, 13.1438, 13.125, 13.3125);
  ctx.closePath();
  ctx.moveTo(9.375, 3.9375);
  ctx.lineTo(9.375, 5.19375);
  ctx.bezierCurveTo(9.375, 5.3625, 9.2375, 5.5, 9.06875, 5.50625);
  ctx.lineTo(7.1875, 5.50625);
  ctx.bezierCurveTo(7.0125, 5.50625, 6.875, 5.3625, 6.875, 5.19375);
  ctx.lineTo(6.875, 3.9375);
  ctx.bezierCurveTo(6.875, 3.7625, 7.01875, 3.625, 7.1875, 3.625);
  ctx.lineTo(9.0625, 3.625);
  ctx.bezierCurveTo(9.2375, 3.625, 9.375, 3.76875, 9.375, 3.9375);
  ctx.closePath();
  ctx.moveTo(13.125, 3.9375);
  ctx.lineTo(13.125, 5.19375);
  ctx.bezierCurveTo(13.125, 5.3625, 12.9875, 5.5, 12.8188, 5.50625);
  ctx.lineTo(10.9375, 5.50625);
  ctx.bezierCurveTo(10.7625, 5.50625, 10.625, 5.3625, 10.625, 5.19375);
  ctx.lineTo(10.625, 3.9375);
  ctx.bezierCurveTo(10.625, 3.7625, 10.7688, 3.625, 10.9375, 3.625);
  ctx.lineTo(12.8125, 3.625);
  ctx.bezierCurveTo(12.9875, 3.625, 13.125, 3.76875, 13.125, 3.9375);
  ctx.closePath();
  ctx.moveTo(9.375, 7.0625);
  ctx.lineTo(9.375, 8.31875);
  ctx.bezierCurveTo(9.375, 8.4875, 9.2375, 8.625, 9.06875, 8.63125);
  ctx.lineTo(7.1875, 8.63125);
  ctx.bezierCurveTo(7.0125, 8.63125, 6.875, 8.4875, 6.875, 8.31875);
  ctx.lineTo(6.875, 7.0625);
  ctx.bezierCurveTo(6.875, 6.8875, 7.01875, 6.75, 7.1875, 6.75);
  ctx.lineTo(9.0625, 6.75);
  ctx.bezierCurveTo(9.2375, 6.75, 9.375, 6.89375, 9.375, 7.0625);
  ctx.closePath();
  ctx.moveTo(13.125, 7.0625);
  ctx.lineTo(13.125, 8.31875);
  ctx.bezierCurveTo(13.125, 8.4875, 12.9875, 8.625, 12.8188, 8.63125);
  ctx.lineTo(10.9375, 8.63125);
  ctx.bezierCurveTo(10.7625, 8.63125, 10.625, 8.4875, 10.625, 8.31875);
  ctx.lineTo(10.625, 7.0625);
  ctx.bezierCurveTo(10.625, 6.8875, 10.7688, 6.75, 10.9375, 6.75);
  ctx.lineTo(12.8125, 6.75);
  ctx.bezierCurveTo(12.9875, 6.75, 13.125, 6.89375, 13.125, 7.0625);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCompany;
