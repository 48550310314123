import cytoscape from 'cytoscape';
import { CorezoidLightTheme as theme, cr } from 'mw-style-react';
import {
  ACTOR_STATUS,
  DISABLED_EL_OPACITY,
  FADED_GRAPH_EL_OPACITY,
  GRAPH_NODE_SIZE,
  TRACE_OPACITY,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import { polygonToCellPositions } from '@control-front-end/utils/modules/utilsCellCoords';
import renderIconOnCanvas from './SVGIcons';

const LAYER_GRID_SHIFT = 1;

const CanvasRenderer = cytoscape('renderer', 'canvas');
const renderIcon = renderIconOnCanvas();

let globalOps = {};

const LABEL_GAP = 8;
const SMALL_ICON_WIDTH = 12;
const ACTOR_COLORS_PIE_RADIUS = 8;
const fontFamily = theme.label?.fontFamily?.endsWith(';')
  ? theme.label?.fontFamily?.slice(0, -1)
  : theme.label?.fontFamily;

const calcOpacity = (opacity, level = 0) =>
  opacity.default - level * opacity.step;

const getPointOnCircle = (x, y, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;
  return {
    x: x + (radius / 2) * Math.cos(angleInRadians),
    y: y + (radius / 2) * Math.sin(angleInRadians),
  };
};

const getNodeAnchorPoint = (node, anchor = 'center') => {
  const { x, y } = node.position();
  const shape = node.style('shape');
  const w = node.width();
  const h = node.height();
  switch (anchor) {
    case 'left_top':
      return shape === 'ellipse'
        ? getPointOnCircle(x, y, w, 225)
        : { x: x - w / 2, y: y - h / 2 };
    case 'left_bottom':
      return shape === 'ellipse'
        ? getPointOnCircle(x, y, w, 135)
        : { x: x - w / 2, y: y + h / 2 };
    case 'right_top':
      return shape === 'ellipse'
        ? getPointOnCircle(x, y, w, 315)
        : { x: x + w / 2, y: y - h / 2 };
    case 'right_bottom':
      return shape === 'ellipse'
        ? getPointOnCircle(x, y, w, 45)
        : { x: x + w / 2, y: y + h / 2 };
    case 'center_top':
      return { x, y: y - h / 2 };
    case 'center_bottom':
      return { x, y: y + h / 2 };
    case 'center':
    default:
      return { x, y };
  }
};

function renderText({ ctx, text, opacity = 1, translatePos: { x, y } }) {
  ctx.save();
  ctx.translate(x, y);
  ctx.font = `${theme.label.fontSize.small}px ${fontFamily}`;
  ctx.fillStyle = theme.palette.black;
  ctx.textBaseline = 'top';
  ctx.textAlign = 'left';
  ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
  ctx.fillText(text, 0, 0);
  ctx.restore();
}

function renderIconWithText({
  ctx,
  position,
  icon,
  text,
  gap = 4,
  opacity = 1,
  iconWidth = SMALL_ICON_WIDTH,
}) {
  const { x, y } = position;
  renderIcon[icon](ctx, { x, y: y - 1 }, opacity); // 1px-shift to align with text as 'center'
  renderText({
    ctx,
    text,
    opacity,
    translatePos: { x: x + iconWidth + gap, y },
  });
}

function renderNodeNumber(ctx, node, data) {
  if (!node.actorNumberWidth) {
    node.actorNumberWidth =
      AppUtils.measureTextWidth({ text: data.actorNumber }) + 5;
  }
  const topCenterPoint = getNodeAnchorPoint(node, 'center_top');
  const x = topCenterPoint.x - node.actorNumberWidth / 2;
  const y = topCenterPoint.y - 4;
  const height = 16;
  const width = node.actorNumberWidth;
  // Draw rectangle
  AppUtils.renderRectangle({
    ctx,
    x,
    y,
    width,
    height,
    borderRadius: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  });
  // Draw the text inside the rectangle
  ctx.font = `${theme.label.fontSize.small}px ${fontFamily}`;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillStyle = `rgba(0, 0, 0, ${node.opacity || 1})`;
  ctx.fillText(data.actorNumber, x + width / 2, y + height / 2);
}

function renderNodeInfo(ctx, node, data) {
  // calculate labels position according to node shape
  let x;
  let y;
  if (node.style('shape') === 'polygon') {
    const { x1, y1 } = node.boundingBox(); // top left corner
    x = x1 + 8;
    y = y1 + 8;
  } else {
    // left side of the node (GraphStyles text align)
    x = node.position().x + GRAPH_NODE_SIZE / 2 + LABEL_GAP;
    y = node.position().y;
  }

  // render balance above the node label
  const yOffsetUnderLabel = -23;

  if (!AppUtils.isUndefined(data.balance)) {
    renderIconWithText({
      ctx,
      icon: data.balanceVector,
      position: { x, y: y + yOffsetUnderLabel },
      text:
        node.scratch('balanceFormatted') ||
        AppUtils.formattedAmount(data.balance),
      opacity: node.opacity,
    });
  }

  // render cell position and counters below the label
  const yOffsetBelowLabel = 12;
  if (globalOps.showNodesCoordinates && data.cellPosition) {
    const cellPositionText = data.polygon
      ? polygonToCellPositions(data.polygon)
          .filter(Boolean)
          .map(({ x, y }) => `${x}, ${y}`)
          .join(' : ')
      : `${data.cellPosition.x}, ${data.cellPosition.y}`;
    renderText({
      ctx,
      text: cellPositionText,
      opacity: node.opacity,
      translatePos: { x, y: y + yOffsetBelowLabel },
    });
    x += AppUtils.measureTextWidth({ text: cellPositionText }) + LABEL_GAP;
  }

  const children =
    node.scratch('stateLinkedNodes') ||
    node.outgoers((n) => n.group() === 'nodes' && !n.data('isTrace')).length;
  if (children) {
    renderIconWithText({
      ctx,
      icon: 'children',
      position: { x, y: y + yOffsetBelowLabel },
      text: children,
      opacity: node.opacity,
      gap: 2,
    });
    x +=
      AppUtils.measureTextWidth({ text: children }) +
      SMALL_ICON_WIDTH +
      LABEL_GAP;
  }
  [
    'linksCount',
    'graphsCount',
    'layersCount',
    'actorsCount',
    'tplsCount',
  ].forEach((counter) => {
    const value = data[counter];
    if (!value) return;
    renderIconWithText({
      ctx,
      icon: counter,
      position: { x, y: y + yOffsetBelowLabel },
      text: value,
      opacity: node.opacity,
      gap: 2,
    });
    x +=
      AppUtils.measureTextWidth({ text: value }) + SMALL_ICON_WIDTH + LABEL_GAP;
  });
}

function drawNodeOverlay(ctx, node) {
  if (node.hasClass('hiddenLabel')) return;

  const data = node.data();

  if (data.polygon) {
    const leftCorner = getNodeAnchorPoint(node, 'left_top');
    ctx.save();
    ctx.translate(
      leftCorner.x - LAYER_GRID_SHIFT / 2,
      leftCorner.y - LAYER_GRID_SHIFT / 2
    );
    AppUtils.drawRectangleGrid({
      ctx,
      width: node.width() + LAYER_GRID_SHIFT,
      height: node.height() + LAYER_GRID_SHIFT,
      color: data.color || theme.palette.gray,
    });
    ctx.restore();
  }

  node.opacity = cr(
    [node.hasClass('faded'), FADED_GRAPH_EL_OPACITY],
    [node.hasClass('disabled'), DISABLED_EL_OPACITY],
    [data.isTrace, calcOpacity(TRACE_OPACITY.border, data.traceLevel)],
    [true, 1]
  );

  renderNodeInfo(ctx, node, data);

  if (data.actorNumber || data.id === 'actorNumber') {
    renderNodeNumber(ctx, node, data);
  }

  if (data.layerSettings?.pin) {
    const pinPoint = getNodeAnchorPoint(node, 'left_top');
    renderIcon.pinned(
      ctx,
      { x: pinPoint.x - 8, y: pinPoint.y - 8 },
      node.opacity
    );
  }

  if (data.layerStarred) {
    renderIcon.flag(ctx, getNodeAnchorPoint(node, 'left_bottom'), node.opacity);
  }

  if (data.status && Object.values(ACTOR_STATUS).includes(data.status)) {
    const statusPoint = getNodeAnchorPoint(node, 'right_top');
    renderIcon[data.status](
      ctx,
      {
        x: statusPoint.x - 8,
        y: statusPoint.y - 8,
      },
      node.opacity
    );
  }

  if (data.formType === 'system' && renderIcon[data.formTitle]) {
    // for state actor icon is rendered only on actor layer (where no polygon)
    if (data.polygon && data.laId) return;
    const center = getNodeAnchorPoint(node, 'center');
    renderIcon[data.formTitle](
      ctx,
      { x: center.x - 12, y: center.y - 12 },
      node.opacity
    );
  }

  if (data.icon && renderIcon[data.icon]) {
    const center = getNodeAnchorPoint(node, 'center');
    renderIcon[data.icon](
      ctx,
      { x: center.x - 12, y: center.y - 12 },
      node.opacity
    );
  }

  if (data.adornmentIcon) {
    const center = getNodeAnchorPoint(node, 'center_bottom');
    renderIcon[data.adornmentIcon](ctx, { x: center.x - 8, y: center.y - 8 });
  }

  // used in legend nodes
  if (data.bgIcon && renderIcon[data.bgIcon]) {
    renderIcon[data.bgIcon](
      ctx,
      getNodeAnchorPoint(node, 'left_top'),
      node.opacity
    );
  }
  if (data.icon === 'coordinate') {
    const { x, y } = getNodeAnchorPoint(node, 'center');
    const coordText = 'C,10';
    const height = 20;
    const padding = 2;
    const width = AppUtils.measureTextWidth({ text: coordText }) + padding * 2;
    AppUtils.renderRectangle({
      ctx,
      x: x - width / 2,
      y: y - height / 2,
      width,
      height,
      borderRadius: 4,
      color: theme.palette.white,
    });
    renderText({
      ctx,
      text: coordText,
      translatePos: {
        x: x - width / 2 + padding * 2,
        y: y - height / 2 + padding * 2,
      },
    });
  }

  if (data.allColors?.length > 1) {
    const dataColors = data.allColors
      .filter((i) => i.type === 'field')
      .map((i) => AppUtils.hexToRgba(i.color, node.opacity));
    AppUtils.renderPieChart(
      ctx,
      dataColors,
      getNodeAnchorPoint(node, 'right_bottom'),
      ACTOR_COLORS_PIE_RADIUS
    );
  }
}

let __init = false;
export default function init(ops) {
  globalOps = ops || {};
  if (__init) return;
  __init = true;

  CanvasRenderer.prototype.drawNodeOverlay = drawNodeOverlay;
}
