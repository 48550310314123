import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  SEARCH_FORMS,
  GET_FORM,
  GET_FORMS,
  PARENT_SYSTEM_FORMS,
} from '@control-front-end/common/constants/forms';
import useIntl from 'useIntl';
import mes from './intl';
import SelectEntityField from '../SelectEntityField';

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

/**
 * Поиск шаблонов акторов
 */
function SelectActorsTemplate(props) {
  const {
    exclude = [],
    withRelations = false,
    withDefault = true,
    withTotal,
    formTypes = 'system,connector',
    isParent = false,
    isSameRoot = false,
    rootOnly = false,
    rootFormId,
    onBlur,
    onCreate,
    value,
  } = props;
  const dispatch = useDispatch();
  const t = useIntl();
  const auth = useSelector((state) => state.auth);
  const [stateValue, setStateValue] = useState(value);
  const [templates, setTemplates] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const excludeSet = exclude.map((i) => i.id);

  useEffect(() => {
    if (value?.id) {
      setStateValue(value);
    } else if (value?.value && !value.title) {
      dispatch({
        type: GET_FORM.REQUEST,
        payload: {
          formId: value.value,
          withRelations,
          withDefault,
          handleAccessDenied: true,
        },
        callback: (data) => {
          setStateValue({
            value: data.id,
            title: data.title,
            accessDenied: data.accessDenied,
          });
        },
      });
    } else if (value && !value.value) {
      setStateValue(null);
    }
    if (Array.isArray(value)) setStateValue(value);
  }, [value.id, value.length, value.value]);

  const getData = (loadMore = false) => {
    const localState = loadMore ? templates : initState;
    setLoading(true);
    dispatch({
      type: GET_FORMS.REQUEST,
      payload: {
        loadMore,
        localState,
        withDefault,
        formTypes,
        withTotal,
        withRelations,
      },
      callback: (data) => {
        if (data) setTemplates(data);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (query.length < 1) return;
      setLoading(true);
      dispatch({
        type: SEARCH_FORMS.REQUEST,
        payload: { query, withRelations, withDefault },
        callback: (data) => {
          setTemplates({ ...initState, ...data });
          setLoading(false);
        },
      });
    }, 500);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query]);

  const handleBlur = () => {
    setQuery('');
    setTemplates(initState);
    if (onBlur) onBlur();
  };

  const handleSearch = (query) => {
    if (!query.length) getData();
    setQuery(query);
  };

  const handleScroll = () => {
    if (isLoading) return;
    getData(true);
  };

  const getTitle = (item) => {
    if (!item.isDefault) return item.title ?? item.name;
    return item.ownerId === auth.id
      ? 'My Default'
      : `${item.ownerName ?? item.userName}'s Default`;
  };

  /**
   * Check if the template is disabled due to custom requirements
   */
  const checkIsDisabled = (item) => {
    // We need UAT, but form is not UAT
    if (rootOnly && item.parentId) return true;
    // Parent required, but template not eligible as parent
    if (isParent && item.type && !PARENT_SYSTEM_FORMS.includes(item.title)) {
      return true;
    }
    return (
      isSameRoot &&
      rootFormId &&
      !item.forms?.some(({ id }) => id === rootFormId)
    );
  };

  const templatesOptions = useMemo(
    () =>
      templates.list
        .filter(({ id, forms }) => {
          const isExcluded = excludeSet.includes(id);
          const isAccessible =
            !withRelations || (!!forms && forms.every((j) => !j.accessDenied));
          return !isExcluded && isAccessible;
        })
        .map((tpl) => {
          const isDisabled = checkIsDisabled(tpl);
          const title = getTitle(tpl);
          return {
            ...tpl,
            title:
              isParent && isDisabled
                ? `${title} (${t(mes.cantBeParentForm)})`
                : title,
            isDisabled,
          };
        }),
    [templates.list, excludeSet, withRelations, isSameRoot, rootFormId]
  );

  return (
    <SelectEntityField
      {...props}
      objType="template"
      list={templatesOptions}
      onSearch={handleSearch}
      onFocus={() => getData()}
      onBlur={handleBlur}
      onScroll={handleScroll}
      value={stateValue}
      isLoading={isLoading}
      onCreate={onCreate}
    />
  );
}

SelectActorsTemplate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  exclude: PropTypes.array,
  withRelations: PropTypes.bool,
  withDefault: PropTypes.bool,
  withTotal: PropTypes.bool,
  formTypes: PropTypes.string,
  isParent: PropTypes.bool,
  isSameRoot: PropTypes.bool,
  rootOnly: PropTypes.bool,
  rootFormId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onCreate: PropTypes.func,
};

export default SelectActorsTemplate;
