import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconAlignment(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(15.477, 0.00195606);
  ctx.bezierCurveTo(11.451, 0.00645606, 8.00252, 2.90446, 7.27802, 6.89446);
  ctx.bezierCurveTo(6.24884, 7.45604, 5.37222, 8.26025, 4.72423, 9.23734);
  ctx.bezierCurveTo(4.07624, 10.2144, 3.67644, 11.3349, 3.55952, 12.5015);
  ctx.bezierCurveTo(2.36914, 13.0331, 1.38382, 13.9372, 0.75207, 15.0776);
  ctx.bezierCurveTo(0.120321, 16.218, -0.123617, 17.5328, 0.0570241, 18.824);
  ctx.bezierCurveTo(0.231411, 20.1141, 0.822054, 21.3122, 1.73926, 22.2362);
  ctx.bezierCurveTo(2.65647, 23.1602, 3.85015, 23.7596, 5.13902, 23.9435);
  ctx.bezierCurveTo(6.42684, 24.1213, 7.73748, 23.8726, 8.87057, 23.2352);
  ctx.bezierCurveTo(10.0036, 22.5978, 10.8968, 21.6069, 11.4135, 20.414);
  ctx.bezierCurveTo(12.5733, 20.2962, 13.6866, 19.8966, 14.6566, 19.2501);
  ctx.bezierCurveTo(15.6265, 18.6035, 16.4237, 17.7296, 16.9785, 16.7045);
  ctx.bezierCurveTo(21.309, 16.004, 24.3615, 12.047, 23.9655, 7.64746);
  ctx.bezierCurveTo(23.5695, 3.24796, 19.8615, -0.0925439, 15.477, 0.00195606);
  ctx.closePath();
  ctx.moveTo(9.51902, 18.5285);
  ctx.bezierCurveTo(9.51902, 18.6485, 9.51902, 18.7685, 9.43502, 18.9005);
  ctx.bezierCurveTo(9.23285, 19.7259, 8.74341, 20.4524, 8.05436, 20.9499);
  ctx.bezierCurveTo(7.36531, 21.4474, 6.52167, 21.6833, 5.67452, 21.6155);
  ctx.bezierCurveTo(4.82737, 21.5427, 4.03359, 21.1714, 3.4347, 20.5679);
  ctx.bezierCurveTo(2.83581, 19.9643, 2.47072, 19.1676, 2.40452, 18.32);
  ctx.bezierCurveTo(2.33315, 17.4721, 2.56442, 16.6263, 3.0573, 15.9327);
  ctx.bezierCurveTo(3.55019, 15.2391, 4.27283, 14.7425, 5.09702, 14.531);
  ctx.lineTo(5.43152, 14.411);
  ctx.bezierCurveTo(6.56702, 14.1965, 7.73402, 14.561, 8.55152, 15.3845);
  ctx.bezierCurveTo(9.36902, 16.208, 9.73052, 17.384, 9.51902, 18.5285);
  ctx.closePath();
  ctx.moveTo(15.477, 14.123);
  ctx.bezierCurveTo(15.4612, 14.2, 15.4411, 14.2761, 15.417, 14.351);
  ctx.bezierCurveTo(15.22, 15.1938, 14.7978, 15.9674, 14.1955, 16.589);
  ctx.bezierCurveTo(13.5932, 17.2107, 12.8333, 17.657, 11.997, 17.8805);
  ctx.bezierCurveTo(11.997, 17.6645, 11.997, 17.4605, 11.9265, 17.2445);
  ctx.bezierCurveTo(11.8545, 17.0285, 11.9265, 16.9205, 11.9265, 16.7645);
  ctx.bezierCurveTo(11.9265, 16.6085, 11.7825, 16.307, 11.7105, 16.079);
  ctx.bezierCurveTo(11.64, 15.851, 11.628, 15.791, 11.568, 15.647);
  ctx.bezierCurveTo(11.4655, 15.4184, 11.3462, 15.1978, 11.211, 14.987);
  ctx.bezierCurveTo(11.211, 14.879, 11.103, 14.759, 11.031, 14.651);
  ctx.bezierCurveTo(10.8195, 14.3383, 10.5806, 14.0452, 10.317, 13.775);
  ctx.lineTo(10.0785, 13.5695);
  ctx.bezierCurveTo(9.87888, 13.3846, 9.66798, 13.2122, 9.44702, 13.0535);
  ctx.bezierCurveTo(9.3173, 12.9684, 9.182, 12.8923, 9.04202, 12.8255);
  ctx.bezierCurveTo(8.85149, 12.7034, 8.65248, 12.5951, 8.44652, 12.5015);
  ctx.bezierCurveTo(8.29256, 12.4352, 8.13291, 12.3829, 7.96952, 12.3455);
  ctx.bezierCurveTo(7.76903, 12.2635, 7.56173, 12.1992, 7.35002, 12.1535);
  ctx.bezierCurveTo(7.18202, 12.1535, 7.00502, 12.1535, 6.82502, 12.0815);
  ctx.lineTo(6.09752, 12.0095);
  ctx.bezierCurveTo(6.31404, 11.1752, 6.75159, 10.4149, 7.36404, 9.80848);
  ctx.bezierCurveTo(7.9765, 9.2021, 8.74119, 8.77215, 9.57752, 8.56396);
  ctx.lineTo(9.81752, 8.50396);
  ctx.bezierCurveTo(10.1109, 8.44128, 10.41, 8.40861, 10.71, 8.40646);
  ctx.bezierCurveTo(13.3425, 8.40646, 15.477, 10.5575, 15.477, 13.2095);
  ctx.bezierCurveTo(15.4758, 13.5163, 15.4441, 13.8223, 15.3825, 14.123);
  ctx.lineTo(15.477, 14.123);
  ctx.closePath();
  ctx.moveTo(17.862, 13.931);
  ctx.lineTo(17.862, 13.211);
  ctx.bezierCurveTo(17.862, 9.23146, 14.6595, 6.00646, 10.71, 6.00646);
  ctx.lineTo(9.99452, 6.00646);
  ctx.bezierCurveTo(10.5148, 4.81796, 11.4093, 3.83209, 12.5418, 3.19898);
  ctx.bezierCurveTo(13.6742, 2.56588, 14.9826, 2.32023, 16.2675, 2.49946);
  ctx.bezierCurveTo(17.5535, 2.68466, 18.7441, 3.28358, 19.6594, 4.20563);
  ctx.bezierCurveTo(20.5747, 5.12767, 21.1648, 6.3227, 21.3405, 7.60996);
  ctx.bezierCurveTo(21.5226, 8.89824, 21.2817, 10.2108, 20.6543, 11.3506);
  ctx.bezierCurveTo(20.0268, 12.4903, 19.0464, 13.3958, 17.8605, 13.931);
  ctx.lineTo(17.862, 13.931);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconAlignment;
