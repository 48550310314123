import PropTypes from 'prop-types';
import React, { useRef, useMemo } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Label, Icon, Upload as UploadMW, FileUtils, cr } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { UPLOAD_FILE, SET_MODAL } from 'constants';
import './Upload.scss';

/**
 * Компонент Upload
 */
function Upload(props) {
  const {
    id,
    visibility,
    title,
    error,
    onChange,
    styleType = 'form',
    value = [],
    extra = {},
    multiple = false,
    setLoading = () => {},
  } = props;
  const dispatch = useDispatch();
  const imageEl = useRef([]);
  const multipleUpload = multiple || extra.multiple;

  const filesList = useMemo(() => {
    if (Array.isArray(value)) return value;
    return value.fileName ? [value] : [];
  }, [value]);

  /**
   * Uploading files
   */
  const handleOnUploadFile = ({ value: files }) => {
    setLoading(true);
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files: [...filesList, ...files],
        callback: (fileList) => {
          const uploadedFiles = fileList.map((file) => ({
            id: file.id,
            type: file.type,
            title: file.title,
            size: file.size,
            fileName: file.fileName,
            filePath: file.filePath,
          }));
          onChange({ id, value: uploadedFiles, error: false });
          setLoading(false);
        },
      },
    });
  };

  /**
   * Удаление загруженного файла
   */
  const handleDelClick = (event, file) => {
    event.stopPropagation();
    if (visibility === 'disabled') return;
    const newValue = filesList.filter(
      (item) => item.fileName !== file.fileName
    );
    onChange({ id, value: newValue, error: false });
  };

  /**
   *  Показать увеличенное изображение прикрепленного файла
   */
  const showImage = (fileName) => {
    AppUtils.correctImgSize(imageEl.current[fileName], (imageModal) => {
      dispatch({
        type: SET_MODAL,
        payload: {
          name: 'ImagePreview',
          data: imageModal,
        },
      });
    });
  };

  /**
   *  Скачивание файла
   */
  const downloadFile = (file) => {
    const filePath =
      file.filePath || AppUtils.makeAppUrl(`/download/${file.fileName}`);
    FileUtils.download(filePath, file.title);
  };

  const renderUploadedFile = (file) => {
    const { fileName, title: fileTitle, type, size } = file;
    return type.indexOf('image') !== -1 ? (
      <div styleName="upload__img__box" key={fileName}>
        <div styleName="upload__img" onClick={() => showImage(fileName)}>
          <img
            ref={(ref) => {
              imageEl.current[fileName] = ref;
            }}
            alt={fileTitle}
            src={AppUtils.makeAppUrl(`/download/${fileName}`)}
          />
        </div>
        <div styleName="upload__img__info">
          <Label value={fileTitle} visibility={visibility} />
          <div
            styleName="upload__img__info__icon"
            onClick={(e) => {
              handleDelClick(e, file);
            }}
          >
            <Icon type="trash" visibility={visibility} />
          </div>
        </div>
      </div>
    ) : (
      <div
        styleName="upload__doc"
        key={fileName}
        onClick={() => downloadFile(file)}
      >
        <div styleName="upload__doc__info">
          <div>
            <Icon type="picture" size="large" visibility={visibility} />
          </div>
          <div styleName="upload__doc__info__desc">
            <Label value={fileTitle} visibility={visibility} />
            <Label
              fontSize="small"
              value={FileUtils.biteToSize(size)}
              visibility={visibility}
            />
          </div>
        </div>
        <div
          styleName="upload__doc__info"
          onClick={(e) => {
            handleDelClick(e, file);
          }}
        >
          <Icon type="trash" visibility={visibility} />
        </div>
      </div>
    );
  };

  const renderValue = () => {
    const filesRenderList = [];
    for (const file of filesList) {
      filesRenderList.push(renderUploadedFile(file));
    }
    return filesRenderList;
  };

  return (
    <div styleName={cn('upload', styleType)}>
      <Label
        styleName="upload__title"
        value={title}
        fontWeight="normal"
        fontSize="small"
        visibility={visibility}
        error={error}
      />
      {cr(
        [value.fileName || value.length, renderValue()],
        [
          multipleUpload || !filesList.length,
          <UploadMW
            id={id}
            styleName="upload__area"
            browseLinkLabel={extra.extraText || 'Click here to attach a file'}
            label=""
            visibility={visibility}
            error={error}
            accept={extra.accept}
            preview="true"
            onChange={(obj) => handleOnUploadFile(obj)}
            multiple={multipleUpload}
          />,
        ],
        true
      )}
    </div>
  );
}

Upload.propTypes = {
  id: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(['form', 'modal']),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  visibility: PropTypes.string,
  error: PropTypes.bool,
  extra: PropTypes.object,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
};

export default Upload;
