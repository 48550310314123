import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  sharedWith: {
    id: 'sharedWith',
    defaultMessage: 'Shared withs',
  },
  accessRights: {
    id: 'accessRights',
    defaultMessage: 'Access rights',
  },
  execute: {
    id: 'execute',
    defaultMessage: 'Execute',
  },
  modify: {
    id: 'modify_SelectedUsers',
    defaultMessage: 'Edit',
  },
  mixedRole: {
    id: 'mixedRole',
    defaultMessage: 'Mixed role',
  },
});

export default { ...globalIntl, ...m };
