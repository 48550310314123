import 'symbol-observable'; // eslint-disable-line
import { configureStore } from '@reduxjs/toolkit';
import {
  APP_NAME,
  SET_LOCATION,
  PM_START,
  APP_AUTH_START,
  PM_APP_NAME,
  GET_CONFIG,
} from 'constants';
import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import accountsSaga from '@control-front-end/common/sagas/accounts';
import authSaga from '@control-front-end/common/sagas/auth';
import initSaga from '@control-front-end/common/sagas/init';
import supportSaga from '@control-front-end/common/sagas/support';
import usersSaga from '@control-front-end/common/sagas/users';
import notifySaga from '@control-front-end/common/sagas/notify';
import formsFiltersSaga from '@control-front-end/common/sagas/formFilters';
import formListSaga from '@control-front-end/common/sagas/formList';
import formViewSaga from '@control-front-end/common/sagas/formView';
import formAccountsSaga from '@control-front-end/common/sagas/formAccounts';
import actorsAccountsSaga from '@control-front-end/common/sagas/actorsAccounts';
import actorsExecutorsSaga from '@control-front-end/common/sagas/accountExecutors';
import accountNamesSaga from '@control-front-end/common/sagas/accountNames';
import accountCurrenciesSaga from '@control-front-end/common/sagas/accountCurrencies';
import searchSaga from '@control-front-end/common/sagas/search';
import uploadFileSaga from '@control-front-end/common/sagas/uploadFile';
import postMessage from '@control-front-end/common/sagas/postMessage';
import scriptContentSaga from '@control-front-end/common/sagas/scriptContent';
import scriptsSaga from '@control-front-end/common/sagas/scripts';
import smartFormsSaga from '@control-front-end/common/sagas/smartForms';
import accessRulesSaga from '@control-front-end/common/sagas/accessRules';
import meetingSaga from '@control-front-end/common/sagas/meeting';
import historySaga from '../sagas/history';
import transactionsFiltersSaga from '../sagas/transactionsFilters';
import transactionsSaga from '../sagas/transactions';
import transfersSaga from '../sagas/transfers';
import triggersSaga from '../sagas/triggers';
import suggestionsSaga from '../sagas/suggestions';
import communications from '../sagas/communications';
import staticLayer from '../sagas/staticLayer';
import reactionsSaga, { getReactionsWatcherSaga } from '../sagas/reactionsSaga';
import streamsSaga from '../sagas/streamsSaga';
import wsConnectionSaga from '../sagas/wsConnection.js';
import wsNotifySaga from '../sagas/wsNotify.js';
import wsExternalPacket from '../sagas/wsExternalPacket';
import checkVersionSaga from '../sagas/checkVersion.js';
import actorsFilters from '../sagas/actorsFilters';
import actorsFolders from '../sagas/actorsFolders';
import electron from '../sagas/electron';
import graphEdges from '../sagas/graph/graphEdges';
import graphDiscovery from '../sagas/graph/graphDiscovery';
import graphNodes from '../sagas/graph/graphNodes';
import graphCommon from '../sagas/graph/graphCommon';
import graphFolders from '../sagas/graph/graphFolders';
import systemActors from '../sagas/graph/systemActors';
import actorView from '../sagas/actorView';
import actorsAttaches from '../sagas/actorsAttaches';
import dashboardsSaga from '../sagas/dahboards';
import connectors from '../sagas/graph/connectors';
import graphRealtime, {
  wsBalancesWatcherSaga,
} from '../sagas/graph/graphRealtime';
import layerElements from '../routes/ActorsGraph/sagas/layers/layerElements';
import layerManage from '../routes/ActorsGraph/sagas/layers/layerManage';
import layerPin from '../routes/ActorsGraph/sagas/layers/layerPin';
import layerAreas from '../routes/ActorsGraph/sagas/layers/layerAreas';
import layerNavigation from '../routes/ActorsGraph/sagas/layers/layerNavigation';
import soundSaga from '../sagas/sound';
import filesSaga from '../routes/Files/sagas/files';
import webhooks from '../routes/Settings/sagas/webhooks';
import tasks, {
  wsImportWatcherSaga,
  wsExportWatcherSaga,
} from '../routes/ImportAndExport/sagas/tasks';
import makeRootReducer, { injectReducer } from '../reducers/index.js';
import sagaMiddleware from '../sagas/runSaga';
import history from './history';
import fullScreen from '../sagas/fullScreen';

// ======================================================
// Инициализация Store
// ======================================================
const store = configureStore({
  reducer: makeRootReducer(),
  devTools: __DEV__,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware
    ),
});

// ======================================================
//  Запуск sagas, для прослушивания action.
// ======================================================
const sagas = [
  authSaga,
  initSaga,
  usersSaga,
  notifySaga,
  supportSaga,
  accountsSaga,
  searchSaga,
  reactionsSaga,
  getReactionsWatcherSaga,
  streamsSaga,
  wsConnectionSaga,
  wsNotifySaga,
  wsExternalPacket,
  checkVersionSaga,
  uploadFileSaga,
  scriptsSaga,
  scriptContentSaga,
  formsFiltersSaga,
  formViewSaga,
  formListSaga,
  formAccountsSaga,
  smartFormsSaga,
  meetingSaga,

  actorsAccountsSaga,
  actorsExecutorsSaga,
  transfersSaga,
  transactionsSaga,
  transactionsFiltersSaga,
  triggersSaga,
  accountNamesSaga,
  accountCurrenciesSaga,
  staticLayer,
  suggestionsSaga,
  communications,
  postMessage,
  graphEdges,
  graphDiscovery,
  graphCommon,
  graphNodes,
  graphRealtime,
  wsBalancesWatcherSaga,
  graphFolders,
  systemActors,
  actorsAttaches,
  actorView,
  dashboardsSaga,
  connectors,
  actorsFilters,
  actorsFolders,
  accessRulesSaga,
  historySaga,
  electron,

  layerElements,
  layerManage,
  layerPin,
  layerAreas,
  layerNavigation,

  soundSaga,

  filesSaga,

  webhooks,

  tasks,
  wsImportWatcherSaga,
  wsExportWatcherSaga,
  fullScreen,
];
for (const sagaItem of sagas) {
  sagaMiddleware.run(sagaItem);
}
// ======================================================

store.asyncReducers = {};
store.runSaga = sagaMiddleware.run;
store.injectReducer = injectReducer;

// ======================================================
// Запрос получения конфигурации при старте
// ======================================================
const { dispatch } = store;
dispatch({ type: APP_NAME, payload: 'AdminPanel' });
if (!AppUtils.isPublicScript()) {
  const queryParams = Utils.getQueryParam(document.location.search);
  const { appName, jwt } = queryParams;
  dispatch({ type: APP_AUTH_START.REQUEST, payload: { jwt } });
  dispatch({
    type: PM_START,
    payload: { appName: appName || PM_APP_NAME },
  });
} else {
  dispatch({ type: GET_CONFIG.REQUEST });
}

if ('ga' in window) ga('set', 'page', document.location.pathname);
store.unsubscribeHistory = history.listen((nextLocation) => {
  dispatch({ type: SET_LOCATION, payload: nextLocation.pathname });
  if ('ga' in window) ga('set', 'page', document.location.pathname);
});

export default store;
