import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Notify, PopoverBoundary, cr } from 'mw-style-react';

import { HIDE_NOTIFY } from 'constants';
import { Modal, Menu, SlideoutMenu, Widget } from 'components';
import MeetingCall from '@control-front-end/common/components/MeetingCall';
import getURLSettings from '@control-front-end/utils/getURLSettings';
import useScheduleMeetingNotifier from '@control-front-end/app/src/hooks/useScheduleMeetingNotifier';
import '../../../styles/main.scss';

function MainLayout({ children, goToUndefPage }) {
  const dispatch = useDispatch();

  const { theme, modal, widgets, notify, settings, meeting } = useSelector(
    (state) => ({
      theme: state.theme,
      modal: state.modal,
      widgets: state.widgets,
      accounts: state.accounts,
      settings: state.settings,
      notify: state.notify,
      meeting: state.meeting,
    })
  );
  useScheduleMeetingNotifier();

  const __showSidebar = getURLSettings().UI.sidebar;

  const handleNotifyClose = useCallback(
    ({ id }) => {
      dispatch({ type: HIDE_NOTIFY.REQUEST, payload: { id } });
    },
    [dispatch]
  );

  useEffect(() => {
    goToUndefPage(notify, dispatch);
  }, [notify, dispatch, goToUndefPage]);

  // Render Modal
  const renderModal = () => {
    return modal?.map?.((m, index) => (
      <Modal key={m.modalId || `${m.name}${index}`} {...m} />
    ));
  };

  // Render Widgets
  const renderWidgets = () => {
    return widgets?.map?.((m) => (m ? <Widget key={m.id} {...m} /> : null));
  };

  // Render Notify
  const renderNotify = () => {
    const items = notify?.items || [];
    return (
      <Notify styleName="notify" value={items} onClose={handleNotifyClose} />
    );
  };

  // Render Meeting room
  const renderMeeting = () => {
    return cr([meeting, <MeetingCall {...meeting} />]);
  };

  return (
    <PopoverBoundary.Consumer>
      {({ globalBoundaryRef }) => (
        <div ref={globalBoundaryRef} className={`theme-${theme} theme`}>
          {settings?.loaded ? (
            <div styleName="main">
              <div styleName="main__root" id="mainRoot">
                <div styleName="main__contentWrap">
                  {__showSidebar ? <Menu /> : null}
                  <div id="mainContent" styleName="main__content">
                    {children}
                  </div>
                </div>
                {__showSidebar ? <SlideoutMenu /> : null}
              </div>
            </div>
          ) : null}
          {renderModal()}
          {renderWidgets()}
          {renderNotify()}
          {renderMeeting()}
        </div>
      )}
    </PopoverBoundary.Consumer>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  goToUndefPage: PropTypes.func.isRequired,
};

export default withRouter(MainLayout);
