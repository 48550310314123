import { useDispatch } from 'react-redux';
import { NOTIFY_LEVEL, SHOW_NOTIFY, PM_APP_NAME, HIDE_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { mapKeys, mapValues } from 'lodash';

/**
 * Hook for notifications
 */
function useNotifications() {
  const dispatch = useDispatch();

  const showNotification = (type, message, extra) => {
    const notificationId = AppUtils.createRid();
    const payload = {
      id: notificationId,
      type: NOTIFY_LEVEL[type.toUpperCase()],
      label: message,
      ...extra,
    };

    if (window.frameElement) {
      window.top.postMessage(
        {
          type: 'SHOW_NOTIFY_TOP_WINDOW',
          appName: PM_APP_NAME,
          data: { payload },
        },
        window.parent.origin
      ); // NOSONAR
    } else {
      dispatch({
        type: SHOW_NOTIFY.REQUEST,
        payload,
      });
    }

    return { id: notificationId };
  };

  const hideNotification = (id) => {
    dispatch({
      type: HIDE_NOTIFY.REQUEST,
      payload: { id },
    });
  };

  return {
    showNotification, // deprecated, use showNotification with level-key
    hide: hideNotification,
    ...mapValues(
      mapKeys(NOTIFY_LEVEL, (value, key) => key.toLowerCase()),
      (level) => {
        return (message, extra) => showNotification(level, message, extra);
      }
    ),
  };
}

export default useNotifications;
