import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorSides(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0, 2);
  ctx.translate(-2, 0);
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(24.1128, 10.3336);
  ctx.lineTo(20.7616, 12.847);
  ctx.bezierCurveTo(20.3473, 13.1577, 19.7562, 12.8621, 19.7562, 12.3443);
  ctx.lineTo(19.7562, 11.0876);
  ctx.lineTo(8.44582, 11.0876);
  ctx.lineTo(8.44582, 12.3443);
  ctx.bezierCurveTo(8.44582, 12.8621, 7.85469, 13.1577, 7.44045, 12.847);
  ctx.lineTo(4.08923, 10.3336);
  ctx.bezierCurveTo(3.75411, 10.0822, 3.75411, 9.57956, 4.08923, 9.32822);
  ctx.lineTo(7.44045, 6.8148);
  ctx.bezierCurveTo(7.85469, 6.50413, 8.44582, 6.79969, 8.44582, 7.31748);
  ctx.lineTo(8.44582, 8.57419);
  ctx.lineTo(19.7562, 8.57419);
  ctx.lineTo(19.7562, 7.31748);
  ctx.bezierCurveTo(19.7562, 6.79969, 20.3473, 6.50412, 20.7616, 6.8148);
  ctx.lineTo(24.1128, 9.32822);
  ctx.bezierCurveTo(24.4479, 9.57956, 24.4479, 10.0822, 24.1128, 10.3336);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(21.0616, 13.247);
  ctx.lineTo(24.4128, 10.7336);
  ctx.bezierCurveTo(25.0146, 10.2822, 25.0146, 9.37956, 24.4128, 8.92822);
  ctx.lineTo(24.1128, 9.32822);
  ctx.lineTo(24.4128, 8.92822);
  ctx.lineTo(21.0616, 6.4148);
  ctx.bezierCurveTo(20.3177, 5.85691, 19.2562, 6.38767, 19.2562, 7.31748);
  ctx.lineTo(19.2562, 8.07419);
  ctx.lineTo(8.94582, 8.07419);
  ctx.lineTo(8.94582, 7.31748);
  ctx.bezierCurveTo(8.94582, 6.38767, 7.88431, 5.85691, 7.14045, 6.4148);
  ctx.lineTo(3.78923, 8.92822);
  ctx.bezierCurveTo(3.18744, 9.37956, 3.18744, 10.2822, 3.78923, 10.7336);
  ctx.lineTo(7.14045, 13.247);
  ctx.bezierCurveTo(7.88431, 13.8049, 8.94582, 13.2741, 8.94582, 12.3443);
  ctx.lineTo(8.94582, 11.5876);
  ctx.lineTo(19.2562, 11.5876);
  ctx.lineTo(19.2562, 12.3443);
  ctx.bezierCurveTo(19.2562, 13.2741, 20.3177, 13.8049, 21.0616, 13.247);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorSides;
