import { put, takeEvery } from 'redux-saga/effects';
import { TOGGLE_FULLSCREEN } from 'constants';

function* handleToggleFullScreen({ payload = {} }) {
  const { isFullScreen = false, descriptor = null } = payload;

  // Dispatch a new state
  yield put({
    type: TOGGLE_FULLSCREEN.SUCCESS,
    payload: {
      isFullScreen,
      descriptor,
    },
  });
}

export function* watchFullScreen() {
  yield takeEvery(TOGGLE_FULLSCREEN.REQUEST, handleToggleFullScreen);
}

export default watchFullScreen;
