import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramDoc(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(11.2903, 15);
  ctx.lineTo(38.2258, 15);
  ctx.bezierCurveTo(38.3861, 15, 38.5161, 15.13, 38.5161, 15.2903);
  ctx.lineTo(38.5161, 32.1832);
  ctx.bezierCurveTo(37.4595, 31.3032, 36.5164, 30.5569, 35.5215, 30.0018);
  ctx.bezierCurveTo(33.9664, 29.134, 32.2974, 28.7258, 29.8387, 28.7258);
  ctx.bezierCurveTo(27.1805, 28.7258, 25.4967, 29.8714, 23.933, 30.9355);
  ctx.lineTo(23.9213, 30.9434);
  ctx.lineTo(23.9096, 30.9513);
  ctx.bezierCurveTo(22.3591, 32.0063, 20.8636, 33.0239, 18.3778, 33.3301);
  ctx.bezierCurveTo(15.7829, 33.6497, 13.9325, 33.1999, 12.7617, 32.3788);
  ctx.bezierCurveTo(11.6203, 31.5785, 11, 30.3404, 11, 28.7581);
  ctx.lineTo(11, 15.2903);
  ctx.bezierCurveTo(11, 15.13, 11.13, 15, 11.2903, 15);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramDoc;
