import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramOffPage(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(13.5, 15.5);
  ctx.bezierCurveTo(13.5, 13.8431, 14.8431, 12.5, 16.5, 12.5);
  ctx.lineTo(33.5, 12.5);
  ctx.bezierCurveTo(35.1569, 12.5, 36.5, 13.8431, 36.5, 15.5);
  ctx.lineTo(36.5, 28.3109);
  ctx.bezierCurveTo(36.5, 29.3339, 35.9788, 30.2863, 35.1172, 30.8378);
  ctx.lineTo(26.6172, 36.2778);
  ctx.bezierCurveTo(25.6314, 36.9087, 24.3686, 36.9087, 23.3828, 36.2778);
  ctx.lineTo(14.8828, 30.8378);
  ctx.bezierCurveTo(14.0212, 30.2863, 13.5, 29.3339, 13.5, 28.3109);
  ctx.lineTo(13.5, 15.5);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramOffPage;
