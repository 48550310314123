import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCommentsWidget(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0, 0.75);
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.beginPath();
  ctx.moveTo(16, 3.18);
  ctx.bezierCurveTo(16, 2.07876, 16, 1.52814, 15.7891, 1.10604);
  ctx.bezierCurveTo(15.5955, 0.718616, 15.2814, 0.404497, 14.894, 0.210911);
  ctx.bezierCurveTo(14.4719, 0, 13.9212, 0, 12.82, 0);
  ctx.lineTo(3.18, 0);
  ctx.bezierCurveTo(2.07876, 0, 1.52814, 0, 1.10604, 0.210911);
  ctx.bezierCurveTo(0.718616, 0.404497, 0.404497, 0.718616, 0.210911, 1.10604);
  ctx.bezierCurveTo(0, 1.52814, 0, 2.07876, 0, 3.18);
  ctx.lineTo(0, 12);
  ctx.lineTo(0, 12.6918);
  ctx.bezierCurveTo(0, 13.7394, 0, 14.2633, 0.211502, 14.5344);
  ctx.bezierCurveTo(0.403656, 14.7808, 0.699904, 14.9231, 1.01231, 14.9193);
  ctx.bezierCurveTo(1.35617, 14.9151, 1.7652, 14.5878, 2.58327, 13.9334);
  ctx.lineTo(5, 12);
  ctx.lineTo(12.82, 12);
  ctx.bezierCurveTo(13.9212, 12, 14.4719, 12, 14.894, 11.7891);
  ctx.bezierCurveTo(15.2814, 11.5955, 15.5955, 11.2814, 15.7891, 10.894);
  ctx.bezierCurveTo(16, 10.4719, 16, 9.92124, 16, 8.82);
  ctx.lineTo(16, 3.18);
  ctx.closePath();
  ctx.moveTo(3, 4);
  ctx.bezierCurveTo(3, 3.44772, 3.44772, 3, 4, 3);
  ctx.lineTo(12, 3);
  ctx.bezierCurveTo(12.5523, 3, 13, 3.44772, 13, 4);
  ctx.bezierCurveTo(13, 4.55228, 12.5523, 5, 12, 5);
  ctx.lineTo(4, 5);
  ctx.bezierCurveTo(3.44772, 5, 3, 4.55228, 3, 4);
  ctx.closePath();
  ctx.moveTo(4, 7);
  ctx.bezierCurveTo(3.44772, 7, 3, 7.44772, 3, 8);
  ctx.bezierCurveTo(3, 8.55229, 3.44772, 9, 4, 9);
  ctx.lineTo(8, 9);
  ctx.bezierCurveTo(8.55228, 9, 9, 8.55229, 9, 8);
  ctx.bezierCurveTo(9, 7.44772, 8.55228, 7, 8, 7);
  ctx.lineTo(4, 7);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCommentsWidget;
