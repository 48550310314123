import { select, throttle, takeEvery } from 'redux-saga/effects';
import { MAKE_SOUND, SOUND_CATEGORY, STOP_SOUND } from 'constants';
import soundPlayer from '@control-front-end/utils/soundPlayer';

/**
 * Play sound
 */
function* makeSound({
  payload: { type, category = SOUND_CATEGORY.graph, settings = {} },
}) {
  const { sound: graphSounds } = yield select((state) => state.settings);
  // Make sure you are enabled graph sounds as a separate setting
  if (category === SOUND_CATEGORY.graph && !graphSounds) return;
  soundPlayer.play(category, type, settings);
}

/**
 * Stop sound
 */
function stopSound() {
  soundPlayer.pause();
}

function* soundSaga() {
  yield throttle(10, MAKE_SOUND, makeSound);
  yield takeEvery(STOP_SOUND, stopSound);
}

export default soundSaga;
