import { Utils } from 'mw-style-react';
import { utils as xlsxUtils, writeFile } from 'xlsx';
import {
  RE_UUID_GLOBAL,
  FLOAT_NUMBER_INPUT,
  URL_REGEXP,
  RE_UUID_SHORT,
} from '@control-front-end/common/constants/regExp';
import {
  RECORD_PREFIX,
  STORAGE_KEY,
} from '@control-front-end/common/constants';
import {
  DEFAULT_EVENT_PANEL_WIDTH,
  DEFAULT_EVENT_PANEL_WIDTH_RATIO,
  MAX_EVENT_PANEL_WIDTH,
  MAX_EVENT_PANEL_WIDTH_RATIO,
  MAX_PANEL_WIDTH,
} from '@control-front-end/common/constants/graphActors';
import utilsHtml from './modules/utilsHtml';
import utilsDate from './modules/utilsDate';
import utilsAccounts from './modules/utilsAccounts';
import utilsFilter from './modules/utilsFilter';
import utilsAccess from './modules/utilsAccess';
import utilsArray from './modules/utilsArray';
import utilsRandom from './modules/utilsRandom';
import utilsKeyboard from './modules/utilsKeyboard';
import utilsDashboard from './modules/utilsDashboard';
import transliterateMap from './modules/transliterateMap.json';
import similarText from './modules/similarText';
import utilsCanvas from './modules/utilsCanvas';
import utilsStateMarkup from './modules/utilsStateMarkup';
import utilsSmartChips from './modules/utilsSmartChips';
import utilsColor from './colors';
import utilsAdaptive from './modules/utilsAdaptive';
import utilsSystemLayers from './modules/utilsSystemLayers';

/**
 * Утилиты
 */
const AppUtils = {
  // Получить сообщение локализации
  getMes(context) {
    const ct = context.intl;
    return function res(mesId, values) {
      try {
        return ct.formatMessage(mesId, values);
      } catch (e) {
        return mesId;
      }
    };
  },

  // fix file path if it is meeting record
  fixMeetingRecord(url) {
    if (!url.includes(RECORD_PREFIX)) return url;
    return url.replace('/download/', '/sip/download/');
  },

  // Ф-ция формирования url запроса
  makeAppUrl(url, queryParams = {}) {
    url = AppUtils.fixMeetingRecord(url);
    const apiVersion = '1.0';
    const baseHost = url.includes('/download/') ? window.BASE_HOST || '' : '';
    const fullUrl = `${baseHost}/api/${apiVersion}${url}`;
    return AppUtils.makeUrl(fullUrl, { ...queryParams });
  },

  // Сформировать url с get параметрами
  makeUrl(url, params) {
    if (!params || !Object.keys(params).length) return url;
    const divider = url.includes('?') ? '&' : '?';
    return `${url}${divider}${Utils.serialize(params)}`;
  },

  // Сформировать аватар пользователя обычного/системного/анонимного
  makeUserAvatar(model, config) {
    const userType = model.userType || model.ownerType || model.type;
    if (userType === 'group') return '';
    if (userType === 'anonymous')
      return config.avatarPath.replace('{userId}', '0');
    const userId = model.saId || model.userId || model.ownerId || model.id || 0;
    return userId === 0
      ? config.systemUserAvatar
      : config.avatarPath.replace('{userId}', userId);
  },

  isValidAccountId(accId) {
    const accIdRegexp = new RegExp(`(${RE_UUID_GLOBAL}|${RE_UUID_SHORT})`);
    return accIdRegexp.test(accId);
  },

  getAccountId(pathname) {
    const pathParts = pathname.split('/');
    return pathParts[2];
  },

  makeShortAccountId(acccountId) {
    if (!acccountId) return '0';
    return acccountId.split('-')[0];
  },

  isValidUuid(value = '') {
    const id = value.toString();
    const uudidRegex = new RegExp(`(${RE_UUID_GLOBAL})`);
    return uudidRegex.test(id);
  },

  isValidUrl(value) {
    if (typeof value !== 'string') return false;
    const checkUrl = new RegExp(URL_REGEXP);
    return checkUrl.test(value);
  },

  setControlWidget({ widgetUrl, widgetSettings, sipSettings, onLoad }) {
    window.ctrlSettings = {};
    if (widgetSettings?.actorId) {
      window.ctrlSettings.webWidget = widgetSettings;
    }
    if (sipSettings?.actorId) {
      window.ctrlSettings.webSIP = sipSettings;
    }
    window.ctrl =
      window.ctrl ||
      // eslint-disable-next-line func-names
      function () {
        // eslint-disable-next-line prefer-rest-params
        (window.ctrl.q = window.ctrl.q || []).push(arguments); // NOSONAR
      };
    const script = document.createElement('script');
    const parent = document.getElementsByTagName('script')[0].parentNode;
    script.async = 1;
    script.src = `${widgetUrl}/shim.js`;
    script.onload = onLoad;
    parent.appendChild(script);

    document.addEventListener('click', (event) => {
      const target = event.target.closest('[data-chip="chat"]');
      if (target && window.ctrl) {
        window.ctrl('webWidget', 'open');
      }
    });
  },

  getWidgetScript(widgetUrl) {
    return ` <script>
(function () {
  window.ctrl = window.ctrl || function () {
    (window.ctrl.q = window.ctrl.q || []).push(arguments);
  };
  var script = document.createElement("script");
  var parent = document.getElementsByTagName("script")[0].parentNode;
  script.async = 1;
  script.src = "${widgetUrl}/shim.js";
  parent.appendChild(script);
})();
</script>`;
  },

  makeWidgetEmbedCode(widgetUrl, type, id) {
    const customSettings =
      type === 'webComments' ? ', elementId: "{{element_id}}"' : '';
    return `<script>
    window.ctrlSettings = {${type}: {actorId: "${id}"${customSettings}}
    };
  </script>
  ${this.getWidgetScript(widgetUrl)}`;
  },

  makeScriptEmbedCode(widgetUrl, accId, ref) {
    return `<script>
      window.ctrlSettings = {
        webScript: {
          scripts: [{ props: ${btoa(
            JSON.stringify({ accId, ref })
          )}, elementId: 'formWidget', }]
        }
    };
    </script>
    ${this.getWidgetScript(widgetUrl)}
    `;
  },

  createAndDownloadJson(filename, data) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:application/json;charset=utf-8,${JSON.stringify(data, null, 2)}`
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },

  createAndDownloadSheet(filename, fileType, data) {
    const formattedData = AppUtils.stringifyNestedObjects(data);
    const workBook = xlsxUtils.book_new();
    const workSheet = xlsxUtils.json_to_sheet(formattedData);
    Object.keys(workSheet).forEach((cell) => {
      if (workSheet[cell].t === 'n') {
        workSheet[cell].w = workSheet[cell].v.toString();
      }
    });
    xlsxUtils.book_append_sheet(workBook, workSheet, 'Transactions');
    writeFile(workBook, `${filename}.${fileType}`, { bookType: fileType });
  },

  transliterate(word) {
    return word
      .split('')
      .map((char) => transliterateMap[char] || char)
      .join('');
  },

  camelCaseToPhrase(str) {
    return str
      .split(/(?=[A-Z])/)
      .map((s) => s.toLowerCase())
      .join(' ');
  },

  toReadableFileSize(size) {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }`;
  },

  isUndefined(value) {
    return typeof value === 'undefined' || value === null || value.length === 0;
  },

  isNumeric(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
  },

  isFloatNumberInput(value) {
    const floatInputRegexp = new RegExp(FLOAT_NUMBER_INPUT);
    return floatInputRegexp.test(value);
  },

  toBool(val) {
    return val === 'true' || val === true;
  },

  isEmptyObject(obj) {
    return !obj || Object.keys(obj).length === 0;
  },

  /**
   * @deprecated use mw-style-react/utils realization
   */
  pickBy(obj, predicate) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => predicate(value, key))
    );
  },

  /**
   * @deprecated use mw-style-react/utils realization
   */
  omitBy(obj, predicate) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => !predicate(value, key))
    );
  },

  isNil(value) {
    return value === null || value === undefined;
  },

  isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  /**
   * Checks if a string is valid JSON and parses it if valid.
   *
   * @param {*} str - The input to check and parse. Typically expected to be a string.
   * @returns {{ isJSON: boolean, parsedObj: any }} An object containing:
   *   - `isJSON`: A boolean indicating whether the input is valid JSON.
   *   - `parsedObj`: The parsed object if the input is valid JSON, or `null` if it is not.
   */
  isParsedJSON(str) {
    let resultOb = null;
    try {
      resultOb = JSON.parse(str);
    } catch (e) {
      return { isJSON: false, parsedObj: resultOb };
    }
    return { isJSON: true, parsedObj: resultOb };
  },

  /**
   * Converts a value to a formatted JSON string with customizable indentation.
   *
   * @param {*} value - The input value to be converted to JSON format.
   * @param {number} [spaces=2] - The number of spaces to use for indentation. Defaults to 2.
   * @returns {string} The formatted JSON string with the specified indentation.
   */
  getFormattedJSON(value, spaces = 2) {
    return JSON.stringify(value, null, spaces);
  },

  isDebug(url) {
    const qParams = Utils.getQueryParam(url);
    return !!qParams.debug;
  },

  isIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },

  getPlatform() {
    // remove deprecated property to userAgentData (check compatibility)
    return navigator.platform; // NOSONAR
  },

  isMobile() {
    const { userAgent, maxTouchPoints } = navigator;
    const platform = this.getPlatform();
    // eslint-disable-next-line max-len
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        userAgent
      ) ||
      (/MacIntel/.test(platform) &&
        Boolean(maxTouchPoints) &&
        maxTouchPoints > 2)
    );
  },

  toCamelCase(str) {
    const camelCase = str.replace(/_([a-zA-Z])/g, (m, w) => w.toUpperCase());
    return camelCase.charAt(0).toLowerCase() + camelCase.slice(1);
  },

  camelCaseToSplitWords(input) {
    // Use regex to find all places where a lowercase letter is followed by an uppercase letter
    // and replace with the lowercase letter followed by a space and the uppercase letter
    return input.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  },

  // Сделать имутабельный сложный объект
  deepFreeze(obj) {
    // извлекаем свойства, определенное в obj
    const propNames = Object.getOwnPropertyNames(obj);
    // прежде чем заморозить сам объект, замораживаем свойства отдельно
    propNames.forEach((name) => {
      const prop = obj[name];
      // замораживаем prop, если он является объектом
      if (typeof prop === 'object' && prop !== null) {
        this.deepFreeze(prop);
      }
    });
    // замораживаем сам объект
    return Object.freeze(obj);
  },
  isPublicScript() {
    return /script\/.*?\/view_public/.test(location.pathname);
  },

  isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
  },

  isSafari() {
    return (
      /constructor/i.test(window.HTMLElement) ||
      // eslint-disable-next-line func-names
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window.safari ||
          (typeof window.safari !== 'undefined' &&
            window.safari.pushNotification)
      )
    );
  },

  isWindowsPlatform() {
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const platform = this.getPlatform();
    return windowsPlatforms.includes(platform);
  },

  isMacLike() {
    const platform = this.getPlatform();
    return /(Mac|iPhone|iPod|iPad)/i.test(platform);
  },

  // Открыть вкладку без копирования sessionStorage
  // (для реалтайма при работе в нескольких вкладках)
  openTabWithNewId(url) {
    window.open(url, '_blank', 'noopener=true');
  },

  isClickNotDrag(mouseEvent) {
    return (
      Math.abs(mouseEvent.movementX) < 2 && Math.abs(mouseEvent.movementY) < 2
    );
  },

  numberNormalize(value, minInput, maxInput, minOutput, maxOutput) {
    value = Math.min(maxInput, Math.max(minInput, value));
    return (
      ((value - minInput) / (maxInput - minInput)) * (maxOutput - minOutput) +
      minOutput
    );
  },

  jsonParse(str, defaultValue) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return typeof defaultValue !== 'undefined' ? defaultValue : str;
    }
  },

  divideNotZero(a, b, resultIfZero) {
    return b === 0 ? resultIfZero : a / b;
  },

  safeSearchQuery(q = '') {
    if (q === '.' || q === '..') return '';
    return encodeURIComponent(q);
  },

  safeJSONParse(str = '', defValue = '') {
    try {
      return JSON.parse(str);
    } catch (e) {
      return defValue;
    }
  },

  toDecimalInt(value) {
    return Number.parseInt(value, 10);
  },

  getDefaultPanelParams(storageKeyName) {
    if (storageKeyName === STORAGE_KEY.panelWidth.default)
      return { defaultWidth: MAX_PANEL_WIDTH, maxWidth: MAX_PANEL_WIDTH };
    const mainContentEl = document.getElementById('mainContent');
    const mainContentElWidth =
      mainContentEl?.getBoundingClientRect().width || 0;
    return {
      defaultWidth:
        mainContentElWidth * DEFAULT_EVENT_PANEL_WIDTH_RATIO ||
        DEFAULT_EVENT_PANEL_WIDTH,
      maxWidth:
        mainContentElWidth * MAX_EVENT_PANEL_WIDTH_RATIO ||
        MAX_EVENT_PANEL_WIDTH,
    };
  },
};

export default Object.assign(
  AppUtils,
  utilsHtml,
  utilsDate,
  utilsAccounts,
  utilsFilter,
  utilsArray,
  utilsRandom,
  utilsKeyboard,
  utilsDashboard,
  utilsAccess,
  similarText,
  utilsCanvas,
  utilsStateMarkup,
  utilsSmartChips,
  utilsColor,
  utilsAdaptive,
  utilsSystemLayers
);
