import { PERMISSIONS } from '@control-front-end/common/constants/permissions';

export default function getMenu({ config, handleMyVerseClick }) {
  const runControlWidget = () => {
    if (window.ctrl) window.ctrl('webWidget', 'open');
  };

  /**
   * Make menu items for system scripts
   */
  const systemScripts = () => {
    const menuItems = [];
    const scripts = config.appSettings?.system_scripts || [];
    for (const i of scripts) {
      const link = `/scripts_item/{{accId}}/view/${i.ref}/production/index?workspaceId={{accId}}`;
      menuItems.push({
        id: i.ref,
        title: i.title,
        permissions: [],
        visibility: 'visible',
        link,
      });
    }
    return menuItems;
  };

  return [
    {
      id: 'events',
      title: 'events',
      icon: 'stream',
      permissions: [],
      filterId: 'events',
      filterKey: 'myToDoTasks',
      link: '/events/{{accId}}/list/all',
      innerLinks: ['/events/{{accId}}/list'],
      visibility: 'visible',
      open: true,
      children: [],
    },
    {
      id: 'actors_graph',
      title: 'simulator',
      icon: 'graph',
      permissions: [],
      link: '/actors_graph/{{accId}}/graphs',
      innerLinks: [
        '/actors_graph/{{accId}}/graphs',
        '/actors_graph/{{accId}}/list',
        '/outer_graph/{{accId}}',
        '/actors_graph/{{accId}}/accounts',
        '/actors_graph/{{accId}}/transfers/list',
        '/actors_graph/{{accId}}/transactions/list',
      ],
      visibility: 'visible',
      open: true,
      children: [
        {
          id: 'graphs',
          title: 'graphs',
          permissions: [],
          link: '/actors_graph/{{accId}}/graphs',
        },
        {
          id: 'actors',
          title: 'actorsBag',
          permissions: [],
          link: '/actors_graph/{{accId}}/list',
        },
        {
          id: 'outerGraph',
          title: 'outerGraph',
          icon: 'skew_graph',
          permissions: [PERMISSIONS.COMPANY_COMMUNICATIONS_READONLY],
          link: '/outer_graph/{{accId}}/recent',
          innerLinks: [
            '/outer_graph/{{accId}}/recent',
            '/outer_graph/{{accId}}/connected',
          ],
          visibility: config.companyCommunications ? 'visible' : 'hidden',
        },
        {
          id: 'accounts',
          title: 'accounts',
          permissions: ['accounts:readonly'],
          link: '/actors_graph/{{accId}}/accounts',
        },
        {
          id: 'transfers',
          title: 'transfers',
          permissions: ['transfers:readonly'],
          link: '/actors_graph/{{accId}}/transfers/list',
          innerLinks: [
            '/actors_graph/{{accId}}/transfers/view',
            '/actors_graph/{{accId}}/transfers/list',
          ],
        },
        {
          id: 'transactions',
          title: 'transactions',
          permissions: ['transactions:readonly'],
          link: '/actors_graph/{{accId}}/transactions/list',
          innerLinks: [
            '/actors_graph/{{accId}}/transactions/view',
            '/actors_graph/{{accId}}/transactions/list',
          ],
        },
        {
          id: 'starredScripts',
          title: '',
          system: true,
          starred: true,
          systemTemplate: 'scripts',
          permissions: ['accounts:readonly'],
          link: '',
        },
      ],
    },
    {
      id: 'editors',
      title: 'editors',
      icon: 'edit',
      permissions: [
        'forms:readonly',
        'scripts:readonly',
        'communications:readonly',
      ],
      link: '/actors_graph/{{accId}}/forms',
      innerLinks: [
        '/actors_graph/{{accId}}/forms',
        '/actors_graph/{{accId}}/scripts',
        '/actors_graph/{{accId}}/communications/',
      ],
      visibility: 'visible',
      open: true,
      children: [
        {
          id: 'scripts',
          title: 'smartForms',
          permissions: ['scripts:readonly'],
          link: '/actors_graph/{{accId}}/scripts',
          innerLinks: ['/scripts/{{accId}}'],
        },
        {
          id: 'forms',
          title: 'accountTemplates',
          permissions: ['forms:readonly'],
          link: '/actors_graph/{{accId}}/forms',
        },
        {
          id: 'communications',
          title: 'communications',
          icon: 'widget',
          permissions: ['communications:readonly'],
          link: '/actors_graph/{{accId}}/communications/',
          visibility: 'visible',
        },
        ...systemScripts(),
      ],
    },
    // This section is temporary hidded and will be reimagned later
    {
      id: 'files',
      title: 'data',
      icon: 'files',
      permissions: [],
      filterId: 'files',
      filterKey: 'my',
      link: '/files/{{accId}}/list/my',
      innerLinks: ['/files/{{accId}}/list/'],
      visibility: 'hidden',
      open: false,
      children: [],
    },
    {
      id: 'settings',
      title: 'settings',
      icon: 'settings',
      link: '/settings/{{accId}}/',
      innerLinks: ['/settings/{{accId}}', '/import_export'],
      visibility: 'visible',
      open: false,
      children: [
        {
          id: 'webhooks',
          title: 'webhooks',
          permissions: ['settings:readonly'],
          link: '/settings/{{accId}}/webhooks',
        },
        {
          id: 'notifications',
          title: 'notifications',
          link: '/settings/{{accId}}/notifications',
        },
        {
          id: 'import_export',
          title: 'importAndExport',
          icon: 'export_import',
          permissions: ['import_export:management'],
          link: '/import_export',
          visibility: 'visible',
        },
      ],
    },
    {
      id: 'buy',
      title: 'buy',
      icon: 'wallet',
      permissions: [],
      link: '',
      externalLink: 'https://www.simulator.company?page=get_page',
      visibility: 'visible',
      open: false,
      children: [],
    },
    {
      id: 'profile',
      title: 'myVerse',
      icon: 'client',
      permissions: [],
      link: '/actors_graph/{{accId}}/profile',
      visibility: 'visible',
      open: false,
      children: [],
      onClick: handleMyVerseClick,
    },
    {
      id: 'contactUs',
      title: 'contactUs',
      icon: 'comment',
      permissions: [],
      link: '',
      visibility: config.controlWidgetId ? 'visible' : 'hidden',
      open: false,
      children: [],
      onClick: runControlWidget,
    },
  ].filter(Boolean);
}
