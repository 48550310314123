import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import cn from 'classnames';
import { TextField, Calendar as CalendarMW } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import './Calendar.scss';

/**
 * Компонент Calendar
 */
function Calendar(props) {
  const {
    id,
    title,
    value = {},
    visibility,
    extra = {},
    error,
    getErrorMsg,
    onChange,
  } = props;

  const renderCalendar = useCallback(
    () => (
      <CalendarMW
        id={id}
        size="small"
        minDate={extra.minDate}
        maxDate={extra.maxDate}
        time={extra.time}
        updateOnInputChange
        timeZone={extra.timeZone}
        dateRange={extra.dateRange}
        isStatic={extra.static}
        value={value}
        onChange={onChange}
      />
    ),
    [value]
  );

  return (
    <TextField
      id={id}
      bordered={true}
      styleName={cn('calendar')}
      label={title}
      visibility={visibility}
      value={AppUtils.makeCalendarItemValue(value, extra)}
      resettable={visibility !== 'disabled'}
      onReset={(e) => {
        e.stopPropagation();
        onChange({ id, value: {} });
      }}
      error={error}
      helperText={getErrorMsg(props)}
      calendar={renderCalendar}
    />
  );
}

Calendar.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.bool,
  visibility: PropTypes.string,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  getErrorMsg: PropTypes.func,
};

export default Calendar;
