import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconCursorState(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.9230769230769234, 0);
  ctx.scale(0.9230769230769231, 0.9230769230769231);
  ctx.translate(-1, 0);
  ctx.save();
  ctx.fillStyle = theme.palette.black;
  ctx.beginPath();
  ctx.moveTo(6, 14);
  ctx.lineTo(5, 3);
  ctx.lineTo(14, 9);
  ctx.lineTo(10, 10);
  ctx.lineTo(13.5, 15);
  ctx.lineTo(12, 16);
  ctx.lineTo(8.5, 11);
  ctx.lineTo(6, 14);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.palette.white;
  ctx.beginPath();
  ctx.moveTo(5.50205, 14.0453);
  ctx.lineTo(5.61134, 15.2474);
  ctx.lineTo(6.38411, 14.3201);
  ctx.lineTo(8.46543, 11.8225);
  ctx.lineTo(11.5904, 16.2867);
  ctx.lineTo(11.8707, 16.6871);
  ctx.lineTo(12.2773, 16.416);
  ctx.lineTo(13.7774, 15.416);
  ctx.lineTo(14.2029, 15.1323);
  ctx.lineTo(13.9096, 14.7133);
  ctx.lineTo(10.8265, 10.3088);
  ctx.lineTo(14.1213, 9.48507);
  ctx.lineTo(15.2178, 9.21094);
  ctx.lineTo(14.2774, 8.58397);
  ctx.lineTo(5.27735, 2.58397);
  ctx.lineTo(4.40739, 2.004);
  ctx.lineTo(4.50205, 3.04527);
  ctx.lineTo(5.50205, 14.0453);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 0.555556;
  ctx.beginPath();
  ctx.moveTo(15, 18);
  ctx.lineTo(25, 18);
  ctx.bezierCurveTo(25, 18, 25, 18, 25, 18);
  ctx.lineTo(25, 25);
  ctx.bezierCurveTo(25, 25, 25, 25, 25, 25);
  ctx.lineTo(15, 25);
  ctx.bezierCurveTo(15, 25, 15, 25, 15, 25);
  ctx.lineTo(15, 18);
  ctx.bezierCurveTo(15, 18, 15, 18, 15, 18);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconCursorState;
