import { CorezoidLightTheme as theme } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';

const FADED_OPACITY = 0.2;
const DEFAULT_BORDER_WIDTH = 2;
const ACCENT_BORDER_WIDTH = 3;
const DEFAULT_TEXT_COLOR = theme.palette.black;

function resetAlpha(color, newAlpha = 1) {
  if (!color.includes('rgba')) return AppUtils.hexToRgba(color, newAlpha);
  return color.replace(
    /rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/,
    `rgba($1, $2, $3, ${newAlpha})`
  );
}

/**
 * Helper function to get a color with adjusted alpha value.
 * If not `rgba`, convert hex to `rgba` directly.
 */
function getAdjustedColor(color, alpha) {
  return color.includes('rgba')
    ? resetAlpha(color, alpha)
    : AppUtils.hexToRgba(color, alpha);
}

/**
 * Ensures the dataset has backing store for original colors.
 */
function ensureOriginalColors(dataset) {
  if (!dataset._originalBackgroundColor) {
    dataset._originalBackgroundColor = dataset.backgroundColor;
    dataset._originalBorderColor = dataset.borderColor;
  }
}

/**
 * Applies styles to the dataset based on its highlighted state.
 */
function applyDatasetStyles(dataset, isHighlighted) {
  ensureOriginalColors(dataset);
  dataset.borderWidth = isHighlighted
    ? ACCENT_BORDER_WIDTH
    : DEFAULT_BORDER_WIDTH;
  dataset.backgroundColor = getAdjustedColor(
    dataset._originalBackgroundColor,
    isHighlighted ? 1 : FADED_OPACITY
  );
  dataset.borderColor = getAdjustedColor(
    dataset._originalBorderColor,
    isHighlighted ? 1 : FADED_OPACITY
  );
}

/**
 * Generates legend labels with visual adjustments based on highlighting or fading.
 */
function generateLegendLabels(chart, elements, faded = false) {
  const focusedIndexes = elements.map((i) => i.datasetIndex);
  return chart.data.datasets.map((dataset, index) => {
    const isHighlighted = focusedIndexes.includes(index);
    const alpha = isHighlighted || !faded ? 1 : FADED_OPACITY;
    const adjustedBgColor = getAdjustedColor(
      dataset._originalBackgroundColor || dataset.backgroundColor,
      alpha
    );
    return {
      text: dataset.label,
      backgroundColor: adjustedBgColor,
      fillStyle: adjustedBgColor,
      strokeStyle: adjustedBgColor,
      fontColor: getAdjustedColor(DEFAULT_TEXT_COLOR, alpha),
      hidden: !chart.isDatasetVisible(index),
    };
  });
}

export const highlightLineChartItem = (chart, datasetIndex) => {
  chart.data.datasets.forEach((dataset, index) => {
    applyDatasetStyles(dataset, index === datasetIndex);
  });
  chart.update();
};

export const resetLineChartStyle = (chart) => {
  chart.data.datasets.forEach((dataset) => {
    ensureOriginalColors(dataset);
    dataset.borderWidth = DEFAULT_BORDER_WIDTH;
    dataset.backgroundColor = dataset._originalBackgroundColor;
    dataset.borderColor = dataset._originalBorderColor;
  });
  chart.update();
};

export const highlightLegendItem = (chart, elements) => {
  chart.options.plugins.legend.labels.generateLabels = () =>
    generateLegendLabels(chart, elements, true);
  chart.update();
};

export const resetLegendStyle = (chart) => {
  chart.options.plugins.legend.labels.generateLabels = () =>
    generateLegendLabels(chart, [], false);
  chart.update();
};

/**
 * Finds dataset index from a legend item.
 */
export const findDatasetIndex = (legend, legendItem) =>
  legend.legendItems.findIndex(
    (item) =>
      legendItem.text === item.text && legendItem.fillStyle === item.fillStyle
  );
