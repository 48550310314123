import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  vote: {
    id: 'vote',
    defaultMessage: 'Vote',
  },
  responses: {
    id: 'responses',
    defaultMessage: 'Responses',
  },
  members: {
    id: 'members',
    defaultMessage: 'Members',
  },
  sent: {
    id: 'sent',
    defaultMessage: 'Sent',
  },
  expired: {
    id: 'expired',
    defaultMessage: 'Expired',
  },
  requiredField: {
    id: 'requiredField',
    defaultMessage: 'Field is required',
  },
  downloadResp: {
    id: 'downloadResp',
    defaultMessage: 'Download responses (.csv)',
  },
  filterResp: {
    id: 'filterResp',
    defaultMessage: 'Filter responses',
  },
});

export default { ...globalIntl, ...m };
