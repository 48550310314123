import { defineMessages } from 'react-intl';

const m = defineMessages({
  actorHistory: {
    id: 'actorHistory',
    defaultMessage: "History of '{title}' fields",
  },
});

export default m;
