import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconTrigger(ctx, opacity, hexToRgba) {
  const color = hexToRgba(theme.palette.primary, opacity);
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(0.75, 0);
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(10.1055, 15.7109);
  ctx.lineTo(14.457, 11.3516);
  ctx.lineTo(13.7539, 10.6484);
  ctx.lineTo(10.1055, 14.2891);
  ctx.lineTo(8.70703, 12.8984);
  ctx.lineTo(8.00391, 13.6016);
  ctx.lineTo(10.1055, 15.7109);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(10.8945, 7);
  ctx.lineTo(6.54297, 7);
  ctx.lineTo(9.54297, 1);
  ctx.lineTo(6.16016, 1);
  ctx.lineTo(2.16016, 9);
  ctx.lineTo(5.66797, 9);
  ctx.lineTo(2.66797, 15);
  ctx.lineTo(2.89453, 15);
  ctx.lineTo(10.8945, 7);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(13.3164, 6);
  ctx.lineTo(3.31641, 16);
  ctx.lineTo(1.05078, 16);
  ctx.lineTo(4.05078, 10);
  ctx.lineTo(0.542969, 10);
  ctx.lineTo(5.54297, 0);
  ctx.lineTo(11.1602, 0);
  ctx.lineTo(8.16016, 6);
  ctx.lineTo(13.3164, 6);
  ctx.closePath();
  ctx.moveTo(6.54297, 7);
  ctx.lineTo(10.8945, 7);
  ctx.lineTo(2.89453, 15);
  ctx.lineTo(2.66797, 15);
  ctx.lineTo(5.66797, 9);
  ctx.lineTo(2.16016, 9);
  ctx.lineTo(6.16016, 1);
  ctx.lineTo(9.54297, 1);
  ctx.lineTo(6.54297, 7);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconTrigger;
