import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconLasso(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(0.788379, 8.89784);
  ctx.bezierCurveTo(0.788379, 3.85579, 6.06911, 0, 12.3942, 0);
  ctx.bezierCurveTo(18.7193, 0, 24.0001, 3.85579, 24.0001, 8.89784);
  ctx.bezierCurveTo(24.0001, 13.9399, 18.7193, 17.7957, 12.3942, 17.7957);
  ctx.bezierCurveTo(11.0635, 17.7957, 9.77907, 17.625, 8.58027, 17.3086);
  ctx.bezierCurveTo(8.69582, 19.9505, 7.06194, 22.411, 4.52547, 23.2255);
  ctx.lineTo(4.23927, 23.3093);
  ctx.lineTo(1.81547, 23.9522);
  ctx.bezierCurveTo(1.03993, 24.158, 0.248102, 23.6821, 0.0468592, 22.8893);
  ctx.bezierCurveTo(-0.140009, 22.1531, 0.248064, 21.4026, 0.92539, 21.1345);
  ctx.lineTo(1.0867, 21.0814);
  ctx.lineTo(3.5105, 20.4384);
  ctx.bezierCurveTo(4.79098, 20.0988, 5.64962, 18.9358, 5.68288, 17.6473);
  ctx.bezierCurveTo(4.52544, 17.2576, 3.68994, 16.1434, 3.68994, 14.8297);
  ctx.lineTo(3.69, 14.8101);
  ctx.bezierCurveTo(1.89017, 13.2565, 0.788379, 11.2009, 0.788379, 8.89784);
  ctx.closePath();
  ctx.moveTo(6.99046, 13.4039);
  ctx.bezierCurveTo(7.14039, 13.4479, 7.28211, 13.5163, 7.41014, 13.6057);
  ctx.bezierCurveTo(7.28329, 13.5169, 7.14194, 13.4481, 6.99046, 13.4039);
  ctx.closePath();
  ctx.moveTo(12.3943, 14.8298);
  ctx.bezierCurveTo(11.3642, 14.8298, 10.382, 14.7063, 9.47314, 14.4821);
  ctx.bezierCurveTo(9.30478, 13.0078, 8.0788, 11.8637, 6.5914, 11.8637);
  ctx.bezierCurveTo(6.09288, 11.8637, 5.62372, 11.9923, 5.21391, 12.2187);
  ctx.bezierCurveTo(4.24905, 11.2558, 3.68987, 10.1036, 3.68987, 8.89787);
  ctx.bezierCurveTo(3.68987, 5.74968, 7.50233, 2.96597, 12.3943, 2.96597);
  ctx.bezierCurveTo(17.2862, 2.96597, 21.0986, 5.74968, 21.0986, 8.89787);
  ctx.bezierCurveTo(21.0986, 12.046, 17.2862, 14.8298, 12.3943, 14.8298);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconLasso;
