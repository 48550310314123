import { put, takeLatest, select } from 'redux-saga/effects';
import { SHOW_NOTIFY, HIDE_NOTIFY } from 'constants';

/**
 * Show notification
 * @param action
 * @returns {IterableIterator<*>}
 */
function* showNotify(action) {
  const { items } = yield select((state) => state.notify);
  // Создаем новый массив
  const newItems = items.slice(0);
  const item = action.payload;
  newItems.unshift(item);
  yield put({ type: SHOW_NOTIFY.SUCCESS, payload: { items: newItems } });
}

/**
 * Hide the notification
 *
 * @param {Object} payload - The input parameter containing details of the notification to hide.
 * @param {Object} payload.payload - The payload containing the id of the notification.
 * @param {*} payload.payload.id - The unique identifier of the notification to be hidden.
 * @return {Generator} Yields effects to manage the state and update the notifications list.
 */
function* hideNotify({ payload, callback }) {
  const { items } = yield select((state) => state.notify);
  const newItems = items.filter((i) => i.id !== payload.id);
  yield put({ type: HIDE_NOTIFY.SUCCESS, payload: { items: newItems } });
  callback?.(newItems);
}

function* notify() {
  yield takeLatest(SHOW_NOTIFY.REQUEST, showNotify);
  yield takeLatest(HIDE_NOTIFY.REQUEST, hideNotify);
}
export default notify;
