import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramPredefProcess(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(20, 0);
  ctx.translate(-11.5, 12);
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(1, 1);
  ctx.lineTo(32, 1);
  ctx.lineTo(32, 24);
  ctx.lineTo(1, 24);
  ctx.lineTo(1, 1);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(25.5, 1);
  ctx.lineTo(25.5, 23.5);
  ctx.moveTo(7, 1);
  ctx.lineTo(7, 24);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramPredefProcess;
