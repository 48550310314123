import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import assign from 'lodash/assign';
import takeControlledLatest from '@control-front-end/common/sagas/takeControlledLatest';
import {
  RECENT_ACTORS,
  RequestStatus,
  WS_DELETE_ACCESS,
  WS_CREATE_ACCESS,
} from '@control-front-end/common/constants';
import {
  SEARCH_ACTORS,
  GET_ALL_ACTORS,
  GET_TEMPLATE_ACTORS,
  GET_TEMPLATE_ACTORS_EVERY,
  SET_ACTORS_REQ_STATUS,
  WS_UPDATE_ACTOR,
  UPDATE_ACTOR_VIEW,
  UPDATE_ACTOR_UNREAD_REACTION,
  CLEAR_LIST_ACTORS,
  WS_DELETE_ACTOR,
  GET_ACTORS_LIST,
  ACTORS_LIST_TYPES,
  ACTOR_FILTER_ORDER_BY,
  DEFAULT_ACTOR_FILTER,
} from '@control-front-end/common/constants/graphActors';
import {
  STREAM_BY_PRIVS,
  READ_ALL_STREAM,
} from '@control-front-end/common/constants/streams';
import {
  SYSTEM_FILTERS,
  GET_ACTORS_FILTERS,
  ADD_ACTORS_FILTER,
  HIDE_ACTORS_FILTER,
  UPDATE_FILTER,
  FILTER_ACCESS,
  UPDATE_SYSTEM_FILTER,
  SET_ACTIVE_ACTORS_FILTER,
} from '@control-front-end/common/constants/actorsFilters';
import {
  WS_CREATE_REACTION,
  WS_DELETE_REACTION,
} from '@control-front-end/common/constants/reactions';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import { has } from 'lodash';
import { getForm } from '@control-front-end/common/sagas/formView';
import { extendModelWithAvatars, isActiveStream } from './graph/graphHelpers';
import { getActor } from './graph/graphNodes';
import { checkUserAccess } from './graph/graphRealtime';
import { makeUpdatedReactionStats } from './reactionsSaga';
import { getRecentActors } from './suggestions';
import { getStreamsActors } from './streamsSaga';

/**
 *  Group actors by title
 */
export function groupActorsByTitle(items) {
  items.forEach((i, index) => {
    if (i.title.length > 500) return;
    const item = { ...i };
    if (index === 0) return;
    const prevItem = items[index - 1];
    const sim = AppUtils.similarText(prevItem.title, item.title, true);
    if (sim < 50) return;
    if (!prevItem.folderId) prevItem.folderId = prevItem.id;
    item.folderId = prevItem.folderId;
    items.splice(index, 1, item);
  });
}

function getPaginationParams({ payload = {}, state = {} }) {
  const { limit: customLimit, offset: customOffset } = payload;
  const { limit: stateLimit = 20, offset: stateOffset = 0 } = state;
  const limit = customLimit || stateLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : stateOffset;
  return { limit, offset };
}

/**
 *  Search actors
 */
function* searchActors({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { query, params, localState, loadMore } = payload;
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) {
    yield put({
      type: SEARCH_ACTORS.SUCCESS,
      payload: {
        list: [],
        total: 0,
        reqStatus: RequestStatus.SUCCESS,
      },
    });
    return;
  }
  let actorsState;
  if (localState) {
    actorsState = localState;
  } else {
    actorsState = yield select((state) => state.actorsList);
  }
  const { list, endList } = actorsState;
  const { limit, offset } = getPaginationParams({
    payload,
    state: actorsState,
  });
  if (endList) return;
  // Set loading in progress status
  if (!localState) {
    yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.PROGRESS });
  }
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/search/${accId}/${searchQ}`,
    queryParams: { ...params, limit, offset },
  });
  // Set loading success status
  if (!localState) {
    yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.SUCCESS });
  }
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data.list) {
    obj.actorId = obj.id;
    yield call(extendModelWithAvatars, obj);
  }
  const newActorsList = loadMore ? structuredClone(list) : [];
  newActorsList.push(...data.data.list);
  if (!localState) {
    yield put({
      type: SEARCH_ACTORS.SUCCESS,
      payload: {
        list: newActorsList,
        offset: offset + limit,
        total: data.data.total,
        reqStatus: RequestStatus.SUCCESS,
      },
    });
  }
  if (callback) callback(data.data.list);
}

function parseFilter(data) {
  const { filter = '{}' } = data;
  return JSON.parse(filter);
}

/**
 * Get actor filter to use it for filtering actors
 */
function* getActorFilter(id) {
  const { list } = yield select((state) => state.actorsFilters);
  const loadedFilter = list.find((item) => item.id === id);
  if (loadedFilter) return parseFilter(loadedFilter.data);
  const actor = yield getActor({ payload: { id } });
  return parseFilter(actor.data);
}

/**
 *  Filter actors by template
 */
export function* getActorsByTemplate({ payload, callback }) {
  const {
    actorFilterId,
    loadMore,
    filter = {},
    orderBy = DEFAULT_ACTOR_FILTER.orderBy,
    orderByKey,
    orderValue = DEFAULT_ACTOR_FILTER.orderValue,
    orderWithStarred = DEFAULT_ACTOR_FILTER.orderWithStarred,
    withStats = true,
    localState,
    groupByTitle = false,
  } = payload;
  let actorsState;
  if (localState) {
    actorsState = localState;
  } else {
    actorsState = yield select((state) => state.actorsList);
  }
  const { reqStatus, list, endList, filter: prevFilter } = actorsState;
  if (reqStatus === RequestStatus.PROGRESS || (loadMore && endList)) {
    if (callback) callback();
    return;
  }
  const { limit, offset } = getPaginationParams({
    payload,
    state: actorsState,
  });
  const starred = payload.starred || actorsState.starred;
  // Set loading in progress status
  if (!localState) {
    yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.PROGRESS });
  }
  if (offset > 0) {
    assign(filter, prevFilter);
  } else if (filter.qFormId) {
    const formView = yield select((state) => state.formView);
    // use an existed form in store to avoid unnecessary API request
    const form =
      +formView.id === +filter.qFormId
        ? formView
        : yield call(getForm, {
            payload: { formId: filter.qFormId, withRelations: true },
          });
    const rootForm = form.forms.find((i) => !i.parentId);
    filter.formId = rootForm?.id || form.id;
  } else if (actorFilterId) {
    const actorFilter = yield getActorFilter(actorFilterId);
    // avoid unnecessary API request in case of irrelevant filter (other root form)
    // used ONLY in a Graphs or Scripts list (customFormId is Graphs or Script formId)
    if (filter.customFormId && actorFilter.formId !== filter.customFormId) {
      yield put({
        type: SET_ACTORS_REQ_STATUS,
        payload: RequestStatus.SUCCESS,
      });
      callback?.();
      return;
    }
    assign(filter, actorFilter, {
      orderBy:
        actorFilter.orderBy ||
        (Boolean(actorFilter.accountNameId && actorFilter.currencyId) &&
          ACTOR_FILTER_ORDER_BY.balance) ||
        DEFAULT_ACTOR_FILTER.orderBy,
    });
  }
  const formId = filter.formId || payload.formId;
  filter.qFormId =
    !!filter.selectedForms && !filter.qFormId
      ? filter.selectedForms.join(',')
      : filter.qFormId;
  filter.isUat = false;
  const ownerIds = filter.owner?.id;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams: {
      limit,
      offset,
      orderBy,
      orderByKey,
      orderValue,
      starred,
      withStats,
      orderWithStarred,
      ownerIds,
      ...filter,
    },
  });
  // Set loading success status
  if (!localState) {
    yield put({ type: SET_ACTORS_REQ_STATUS, payload: RequestStatus.SUCCESS });
  }
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data.list) {
    yield call(extendModelWithAvatars, obj);
  }
  const newActorsList = loadMore ? structuredClone(list) : [];
  newActorsList.push(...data.data.list);
  newActorsList.forEach((i) => {
    i.actorId = i.id;
  });
  if (groupByTitle) groupActorsByTitle(newActorsList);
  if (localState && callback) {
    callback({
      ...localState,
      list: newActorsList,
      offset: offset + limit,
      endList: !data.data.list.length,
      formId,
      filter,
    });
    return;
  }
  const total = !AppUtils.isUndefined(data.data.total)
    ? data.data.total
    : actorsState.total;
  yield put({
    type: GET_TEMPLATE_ACTORS.SUCCESS,
    payload: {
      list: newActorsList,
      limit,
      offset: offset + limit,
      endList: newActorsList.length === total,
      total,
      formId: +formId,
      filter,
      orderBy: filter.orderBy ?? orderBy,
      orderValue: filter.orderValue ?? orderValue,
      reqStatus: RequestStatus.SUCCESS,
    },
  });
  if (callback) callback(data.data.list);
}

/**
 *  Filter all actors accessible to user sorted by template
 */
function* getAllActors({ payload, callback }) {
  const { loadMore } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const actorsState = yield select((state) => state.actorsList);
  const { reqStatus, list, limit, offset, endList } = actorsState;
  if (reqStatus === RequestStatus.PROGRESS || endList) return;
  // Set loading in progress status
  yield put({ type: SET_ACTORS_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/actors/${accId}`,
    queryParams: { limit, offset },
  });
  // Set loading success status
  yield put({ type: SET_ACTORS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data) {
    yield call(extendModelWithAvatars, obj);
  }
  const newActorsList = loadMore ? structuredClone(list) : [];
  newActorsList.push(...data.data);
  newActorsList.forEach((i) => {
    i.actorId = i.id;
  });
  yield put({
    type: GET_TEMPLATE_ACTORS.SUCCESS,
    payload: {
      list: newActorsList,
      offset: offset + limit,
      endList: !data.data,
      reqStatus: RequestStatus.SUCCESS,
    },
  });
  if (callback) callback(data.data.list);
}

export function* updateActorUnreadReaction({
  payload: { actorId, unreadReactions, time, skipRequest },
  callback,
}) {
  const actorView = yield select((state) => state.actorView);

  if (actorView.id !== actorId) return;

  if (!skipRequest) {
    const { result } = yield call(api, {
      method: 'post',
      url: `/reactions/read/${actorId}`,
      body: {
        // Post difference (the amount of reactions that have been read)
        count: actorView.unreadReactions - unreadReactions,
        time,
      },
    });
    if (result !== RequestStatus.SUCCESS) return;
  }

  yield put({
    type: UPDATE_ACTOR_VIEW.SUCCESS,
    payload: {
      ...actorView,
      unreadReactions,
    },
  });

  const actorsList = yield select((state) => state.actorsList);

  yield put({
    type: UPDATE_ACTOR_UNREAD_REACTION.SUCCESS,
    payload: {
      list: actorsList.list.map((item) =>
        item.actorId === actorId
          ? {
              ...item,
              unreadReactions,
            }
          : item
      ),
    },
  });
  if (callback) callback();
}

/**
 * Filter update by WebSocket
 */
function* wsUpdateFilter({ payload }) {
  const { model: updatedFilter } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  if (updatedFilter.accId !== accId) return;
  const { list, active } = yield select((state) => state.actorsFilters);
  const findIndex = list.findIndex((i) => i.id === updatedFilter.id);
  if (findIndex === -1) return;
  const copyList = structuredClone(list);
  const actorM = { ...copyList[findIndex] };
  copyList.splice(findIndex, 1, { ...actorM, ...updatedFilter });
  yield put({
    type: UPDATE_FILTER.SUCCESS,
    payload: { list: copyList },
  });
  // reload actors list by active filter
  if (updatedFilter.id === active) {
    yield put({ type: CLEAR_LIST_ACTORS });
    const filter = JSON.parse(updatedFilter.data.filter);
    const { rowsPerPage } = yield select((state) => state.settings);
    yield getActorsByTemplate({
      payload: {
        formId: filter.formId,
        filter,
        orderBy:
          filter.orderBy ||
          (Boolean(filter.accountNameId && filter.currencyId) &&
            ACTOR_FILTER_ORDER_BY.balance) ||
          DEFAULT_ACTOR_FILTER.orderBy,
        loadMore: false,
        limit: rowsPerPage,
      },
    });
  }
}

/**
 * Update actor access in the list
 */
function* wsCreateAccess({ payload }) {
  const { model: updatedActor } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  if (updatedActor.accId !== accId) return;
  const { list } = yield select((state) => state.actorsList);
  const findIndex = list.findIndex((i) => i.id === updatedActor.id);
  if (findIndex === -1) return;
  const copyList = list.slice();
  const actorM = { ...copyList[findIndex] };
  copyList.splice(findIndex, 1, { ...actorM, ...updatedActor });
  yield put({
    type: GET_TEMPLATE_ACTORS.SUCCESS,
    payload: { list: copyList },
  });
}

// Reaction types that don't cause dialog bubling to the top
const NON_BUBLING_REACTION_TYPES = ['view', 'freeze'];

/**
 * New reaction by WebSocket
 */
function* wsNewReaction({ payload }) {
  const { active: accId } = yield select((state) => state.accounts);
  const auth = yield select((state) => state.auth);
  if (payload.model.accId !== accId) return;
  const reaction = { ...payload.model };
  const { list, formId, orderBy } = yield select((state) => state.actorsList);
  const copyList = list.slice();
  const { treeInfo, updatedAt } = reaction;
  if (treeInfo.rootActorFormId !== formId) return;
  // If event is found in current list, then just sort list
  // Else make get event request, add it to top and remove last event in list
  const indexActor = copyList.findIndex((i) => i.id === treeInfo.rootActorId);

  if (indexActor === -1) {
    const { result, data } = yield call(api, {
      method: 'get',
      url: `/actors/${treeInfo.rootActorId}`,
      queryParams: {
        lastReaction: true,
        reactionsStats: true,
        reactionsCount: true,
        streams: true,
      },
      handleErrCodes: [403, 404],
    });
    if (result !== RequestStatus.SUCCESS) return;
    const isActiveS = yield isActiveStream(data.data);
    if (!isActiveS) return;
    yield call(extendModelWithAvatars, data.data);
    copyList.unshift(data.data);

    yield put({
      type: GET_TEMPLATE_ACTORS.SUCCESS,
      payload: { list: copyList },
    });
    return;
  }

  const actorM = { ...copyList.splice(indexActor, 1)[0] };
  if (!NON_BUBLING_REACTION_TYPES.includes(reaction.data.type)) {
    actorM.updatedAt = updatedAt;
  }
  actorM.lastReaction = reaction;
  actorM.reactionsCount += 1;

  /**
   * Needed for case when user receives reaction via group assess but hasn't personal access
   * (Because "unread reactions" functionality works only for personal access)
   */
  const hasPersonalAccess = actorM.access.find((i) => i.userId === auth.id);

  if (reaction.userId !== auth.id && hasPersonalAccess) {
    actorM.unreadReactions += 1;
  }
  actorM.reactionsStats = makeUpdatedReactionStats(
    actorM.reactionsStats,
    'add',
    reaction
  );

  if (reaction.data.type === 'view' && actorM.unread) {
    const actorView = yield select((state) => state.actorView);

    // Priority to actorView because it has more comprehensive and frash data
    const actorsCustomStreams =
      (actorView?.id === actorM.id ? actorView : actorM).streams || [];

    const actorsStreams = [
      ...actorsCustomStreams,
      ...Object.keys(
        AppUtils.pickBy(
          actorM.privs,
          (value, key) => value && STREAM_BY_PRIVS[key]
        )
      ),
    ];
    const streams = yield select((state) => state.streams);
    const authId = yield select((state) => state.auth.id);
    const sameUserView = reaction.userId === authId;
    actorM.unread = !sameUserView;

    const copyList = structuredClone(streams.list);
    // If view reaction is of same user, decrease unread counters for corresponding streams
    if (sameUserView)
      copyList
        .filter(({ id }) => actorsStreams.includes(id))
        .forEach((stream) => {
          if (stream.unreadCounter > 0) {
            stream.unreadCounter -= 1;
          }
        });

    yield put({
      type: READ_ALL_STREAM.SUCCESS,
      payload: { list: copyList },
    });
  }

  if (
    orderBy === 'created_at' ||
    NON_BUBLING_REACTION_TYPES.includes(reaction.data.type)
  ) {
    copyList.splice(indexActor, 0, actorM); // Place an actor at the same place where it was before
  }
  if (
    orderBy === 'updated_at' &&
    !NON_BUBLING_REACTION_TYPES.includes(reaction.data.type)
  ) {
    copyList.unshift(actorM); // Place an actor at the list start
  }

  yield put({ type: GET_TEMPLATE_ACTORS.SUCCESS, payload: { list: copyList } });
}

/**
 * Reaction removed by WebSocket
 */
function* wsDeleteReaction({ payload }) {
  const { active: accId } = yield select((state) => state.accounts);
  const auth = yield select((state) => state.auth);
  if (payload.model.accId !== accId) return;
  const reaction = { ...payload.model };
  const { list } = yield select((state) => state.actorsList);
  const copyList = structuredClone(list);
  if (!copyList.length) return;
  const { treeInfo, updatedAt } = reaction;
  // If event is found in current list, then update reaction stats
  // and update lastReaction if it is the one deleted
  const indexActor = copyList.findIndex((i) => i.id === treeInfo.rootActorId);
  if (indexActor === -1) return;
  const actorM = { ...copyList[indexActor] };
  actorM.updatedAt = updatedAt;
  const { view, list: rList } = yield select((state) => state.reactions);
  const rIndex = rList.findIndex((r) => r.id === reaction.id);
  if (actorM.lastReaction && actorM.lastReaction.id === reaction.id) {
    const newIndex = rIndex !== -1 ? rIndex + 1 : 0;
    actorM.lastReaction = view === 'flat' ? rList[newIndex] : null;
  }
  actorM.reactionsCount -= 1;

  const unreadReactionRemoved =
    rList[rIndex] && rList[rIndex].listIndex < actorM.unreadReactions;

  /**
   * Needed for case when user receives reaction via group assess but hasn't personal access
   * (Because "unread reactions" functionality works only for personal access)
   */
  const hasPersonalAccess = actorM.access.find((i) => i.userId === auth.id);

  if (
    reaction.userId !== auth.id &&
    hasPersonalAccess &&
    unreadReactionRemoved
  ) {
    actorM.unreadReactions -= 1;
  }
  actorM.reactionsStats = makeUpdatedReactionStats(
    actorM.reactionsStats,
    'delete',
    reaction
  );
  copyList.splice(indexActor, 1, actorM);
  yield put({
    type: GET_TEMPLATE_ACTORS.SUCCESS,
    payload: { list: copyList },
  });
}

function* getAvailableFilters(list) {
  const { actorsBagSystemTabs = {} } = yield select((state) => state.settings);
  return list.filter(
    (item) =>
      !has(actorsBagSystemTabs, item.id) ||
      actorsBagSystemTabs[item.id] !== false
  );
}

/**
 * Load filters
 */
function* getFilters({ payload, callback }) {
  const { loadMore, starred, localState } = payload;
  let filtersState;
  if (localState) {
    filtersState = localState;
  } else {
    filtersState = yield select((state) => state.actorsFilters);
  }
  const systemForms = yield select((state) => state.systemForms);
  const formId = systemForms.actorfilters.id;
  const { limit, offset, endList } = filtersState;
  if (endList) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams: {
      limit,
      offset,
      orderBy: 'created_at',
      orderValue: 'ASC',
      starred,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list, total } = data.data;
  for (const obj of list) yield call(extendModelWithAvatars, obj);
  const prevList = yield select((state) => state.actorsFilters.list);
  const systemFilters = yield call(getAvailableFilters, SYSTEM_FILTERS);
  const curList = yield call(getAvailableFilters, prevList);
  const newActorsList = loadMore
    ? curList.concat(list)
    : [...systemFilters, ...list];
  const newPayload = {
    list: newActorsList,
    limit,
    offset: offset + limit,
    endList: newActorsList.length - systemFilters.length === total,
    total,
    init: true,
  };
  if (localState) {
    callback(newPayload);
    return;
  }
  yield put({
    type: GET_ACTORS_FILTERS.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Add filter to active list
 */
function* addFilter({ payload, callback }) {
  const filters = yield select((state) => state.actorsFilters);
  const systemForms = yield select((state) => state.systemForms);
  const filtersForm = systemForms.actorfilters;
  if (filtersForm.id !== payload.formId && !payload.isSystem) return;
  const copyList = filters.list.slice();
  copyList.push(payload);
  yield put({
    type: ADD_ACTORS_FILTER.SUCCESS,
    payload: {
      list: copyList,
      offset: filters.offset + 1,
      total: filters.total + 1,
      active: payload.id,
    },
  });
  if (callback) callback(payload);
}

/**
 * Remove filter from active list
 */
function* hideFilter({ payload, callback }) {
  const { list, offset, active } = yield select((state) => state.actorsFilters);
  const copyList = list.filter((i) => i.id !== payload.id);
  if (copyList.length === list.length) return;
  yield put({
    type: HIDE_ACTORS_FILTER.SUCCESS,
    payload: {
      list: copyList,
      offset: offset - 1 < 0 ? 0 : offset - 1,
    },
  });
  if (payload.id === active) {
    yield put({ type: SET_ACTIVE_ACTORS_FILTER, payload: 'all' });
    yield put({ type: CLEAR_LIST_ACTORS });
    yield put({ type: RECENT_ACTORS.REQUEST, payload: {} });
  }
  if (callback) callback(payload);
}

/**
 * Remove filter by WebSocket
 */
function* wsRemoveFilter({ payload }) {
  const { model: removedFilter } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  if (removedFilter.accId !== accId) return;
  yield hideFilter({ payload: { id: removedFilter.id } });
}

/**
 * Remove filter if no access (WS event)
 */
function* wsRemoveNoAccessFilter({ payload }) {
  const { model, objType } = payload;
  if (objType !== 'actor') return;
  const { active: accId } = yield select((state) => state.accounts);
  if (model.accId !== accId) return;
  const access = model.access.concat(payload.model.defaultAccess);
  const hasAccess = yield call(checkUserAccess, access);
  if (hasAccess) return;
  yield hideFilter({ payload: { id: model.id } });
}

/**
 * Change access rights to filter
 */
function* manageFilterAccess({ payload }) {
  const { id, access } = payload;
  const { list } = yield select((state) => state.actorsFilters);
  const copyList = structuredClone(list);
  const filterActor = copyList.find((i) => i.id === id);
  if (!filterActor) return;
  filterActor.access = access;
  yield put({
    type: FILTER_ACCESS.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Change form in system filter
 */
function* updateSystemFilter({ payload }) {
  const { id, formId, formTitle, formColor } = payload;
  const { list } = yield select((state) => state.actorsFilters);
  const copyList = structuredClone(list);
  const findIndex = copyList.findIndex((i) => i.id === id);
  if (findIndex === -1) return;
  const sysFilter = {
    ...copyList[findIndex],
    subtitle: formTitle,
    formId,
    formTitle,
    formColor,
  };
  copyList.splice(findIndex, 1, sysFilter);
  yield put({
    type: UPDATE_SYSTEM_FILTER.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Actors list changes queue
 */
function* getActorsList({ payload, callback }) {
  const { typeList } = payload;
  switch (typeList) {
    case ACTORS_LIST_TYPES.RECENT:
      yield call(getRecentActors, { callback });
      break;
    case ACTORS_LIST_TYPES.TEMPLATE:
      yield call(getActorsByTemplate, { payload, callback });
      break;
    case ACTORS_LIST_TYPES.STREAM:
      yield call(getStreamsActors, { payload, callback });
      break;
    default:
      break;
  }
}

function* actorsFilters() {
  yield takeEvery(SEARCH_ACTORS.REQUEST, searchActors);
  yield takeLatest(GET_TEMPLATE_ACTORS.REQUEST, getActorsByTemplate);
  yield takeEvery(GET_TEMPLATE_ACTORS_EVERY.REQUEST, getActorsByTemplate);
  yield takeEvery(GET_ALL_ACTORS.REQUEST, getAllActors);
  yield takeEvery(GET_ACTORS_FILTERS.REQUEST, getFilters);
  yield takeEvery(ADD_ACTORS_FILTER.REQUEST, addFilter);
  yield takeEvery(HIDE_ACTORS_FILTER.REQUEST, hideFilter);
  yield takeEvery(FILTER_ACCESS.REQUEST, manageFilterAccess);
  yield takeEvery(UPDATE_SYSTEM_FILTER.REQUEST, updateSystemFilter);
  yield takeEvery(
    UPDATE_ACTOR_UNREAD_REACTION.REQUEST,
    updateActorUnreadReaction
  );
  yield takeEvery(WS_UPDATE_ACTOR, wsUpdateFilter);
  yield takeEvery(WS_DELETE_ACTOR, wsRemoveFilter);
  yield takeEvery(WS_CREATE_ACCESS, wsCreateAccess);
  yield takeEvery(WS_DELETE_ACCESS, wsRemoveNoAccessFilter);
  yield takeEvery(WS_CREATE_REACTION, wsNewReaction);
  yield takeEvery(WS_DELETE_REACTION, wsDeleteReaction);
  yield takeControlledLatest(
    GET_ACTORS_LIST.REQUEST,
    getActorsList,
    function* onCancel() {
      yield put({
        type: SET_ACTORS_REQ_STATUS,
        payload: RequestStatus.SUCCESS,
      });
    }
  );
}

export default actorsFilters;
