function SVGIconNote(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(1.2, 1.2);
  ctx.translate(-2, -2);
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = '#8FDF82';
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.beginPath();
  ctx.moveTo(17, 15);
  ctx.lineTo(7, 15);
  ctx.lineTo(7, 13);
  ctx.lineTo(17, 13);
  ctx.lineTo(17, 15);
  ctx.closePath();
  ctx.moveTo(17, 19);
  ctx.lineTo(7, 19);
  ctx.lineTo(7, 17);
  ctx.lineTo(17, 17);
  ctx.lineTo(17, 19);
  ctx.closePath();
  ctx.moveTo(7, 9);
  ctx.lineTo(15, 9);
  ctx.lineTo(15, 11);
  ctx.lineTo(7, 11);
  ctx.lineTo(7, 9);
  ctx.closePath();
  ctx.moveTo(18, 2);
  ctx.lineTo(18, 5);
  ctx.lineTo(16, 5);
  ctx.lineTo(16, 2);
  ctx.lineTo(13, 2);
  ctx.lineTo(13, 5);
  ctx.lineTo(11, 5);
  ctx.lineTo(11, 2);
  ctx.lineTo(8, 2);
  ctx.lineTo(8, 5);
  ctx.lineTo(6, 5);
  ctx.lineTo(6, 2);
  ctx.lineTo(3, 2);
  ctx.lineTo(3, 22);
  ctx.lineTo(21, 22);
  ctx.lineTo(21, 2);
  ctx.lineTo(18, 2);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconNote;
