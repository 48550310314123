import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramPreparation(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(8, 25);
  ctx.lineTo(15.9333, 15);
  ctx.lineTo(34.0667, 15);
  ctx.lineTo(42, 25);
  ctx.lineTo(34.0667, 35);
  ctx.lineTo(15.9333, 35);
  ctx.lineTo(8, 25);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramPreparation;
