import { call, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_ACTOR_LINKS,
  ACTOR_DECOMPOSE,
  ACTORS_AGGREGATION,
  GET_LAYER_CLUSTERS_BOUNDARY,
} from '@control-front-end/common/constants/graphLayers';
import api from '@control-front-end/common/sagas/api';
import { manageLayerElements } from './layerElements';
import { createMassEdges } from '../../../../sagas/graph/graphEdges';
import { manageGraphFolder } from '../../../../sagas/graph/graphFolders';
import { addActor, createActor } from '../../../../sagas/graph/graphNodes';
import { sendReducerMsg } from '../../../../sagas/graph/graphHelpers';

/**
 * Получить все связанные слои с актором
 */
function* getActorLinks({ payload, callback }) {
  const { graphFolderId, actorId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/layers_links/actor/${graphFolderId}/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_ACTOR_LINKS.SUCCESS });
  callback(data.data);
}

/**
 * Создать или взять выбранный слой для разметки
 */
function* getMarkupLayerId({ layerId, graphFolderId, layerName }) {
  const systemForms = yield select((state) => state.systemForms);
  const layersFormId = systemForms.layers.id;
  if (layerId) return layerId;
  const layerModel = yield createActor({
    payload: {
      title: layerName,
      formId: layersFormId,
      manageLayer: false,
    },
  });
  yield manageGraphFolder({
    payload: {
      action: 'create',
      graphFolderId,
      layerId: layerModel.id,
    },
  });
  return layerModel.id;
}

/**
 * Декомпозиция актора
 */
function* actorDecompose({ payload, callback }) {
  const graphL = yield select((state) => state.graphLayers);
  const { actorModel, position } = payload;
  const layerId = yield getMarkupLayerId({
    ...payload,
    graphFolderId: graphL.graphFolderId,
  });
  yield addActor({
    payload: { actorModel, position, layerId, manageLayer: true },
  });
  yield sendReducerMsg({
    type: ACTOR_DECOMPOSE.SUCCESS,
    payload: {
      nodes: [],
    },
    layerId,
  });
  callback(layerId);
}

/**
 * Агрегация актора
 */
function* actorAggregation({ payload, callback }) {
  const graphL = yield select((state) => state.graphLayers);
  const { edges, rootActor } = payload;
  const layerId = yield getMarkupLayerId({
    ...payload,
    graphFolderId: graphL.graphFolderId,
  });
  if (graphL.active !== layerId) {
    yield manageLayerElements({
      payload: {
        layerId,
        body: [
          {
            action: 'create',
            data: {
              id: rootActor.id,
              model: rootActor,
              position: rootActor.position,
              type: 'node',
            },
          },
        ],
      },
    });
  }
  yield createMassEdges({ payload: { edges, subscribeBalances: true } });
  yield sendReducerMsg({
    type: ACTORS_AGGREGATION.SUCCESS,
    payload: {
      nodes: [],
    },
    layerId,
  });
  yield put({ type: ACTORS_AGGREGATION.SUCCESS });
  if (callback) callback(layerId);
}

/**
 * Generator function to fetch the boundary details of layer clusters based on the actor's identifier.
 * The method calls an API endpoint, processes the result, and then dispatches an action with the retrieved data.
 */
function* getLayerClustersBoundary({ payload, callback }) {
  const { actorId, eps, minPoints } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph_layers/clusters_boundary/${actorId}`,
    queryParams: { eps, minPoints },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_LAYER_CLUSTERS_BOUNDARY.SUCCESS, payload: data.data });
  callback(data.data);
}

function* layerNavigation() {
  yield takeEvery(GET_ACTOR_LINKS.REQUEST, getActorLinks);
  yield takeEvery(ACTOR_DECOMPOSE.REQUEST, actorDecompose);
  yield takeEvery(ACTORS_AGGREGATION.REQUEST, actorAggregation);
  yield takeLatest(
    GET_LAYER_CLUSTERS_BOUNDARY.REQUEST,
    getLayerClustersBoundary
  );
}

export default layerNavigation;
