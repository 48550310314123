import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import {
  Stack,
  Space,
  Label,
  Icon,
  Button,
  Popover,
  Menu,
  MenuItem,
  Checkbox,
  Tooltip,
  cr,
  Card,
} from 'mw-style-react';

import { useIntl, useNotifications } from 'hooks';
import { checkUserPermissions } from 'selectors';
import { BULK_ACTIONS_LIMIT } from '@control-front-end/common/constants';
import { BULK_ACTORS_ACTIONS } from '@control-front-end/common/constants/actorsFolders';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import SelectActors from '@control-front-end/common/components/SelectActors';

import AppUtils from '@control-front-end/utils/utils';
import scss from './BulkActionsHeader.scss';
import mes from './intl';

const ACTIONS_NEED_SHOW_MORE = [
  BULK_ACTORS_ACTIONS.export,
  BULK_ACTORS_ACTIONS.account,
  BULK_ACTORS_ACTIONS.remove,
];
const BULK_SELECT_ACTIONS = {
  add: 'add',
  remove: 'remove',
};

/**
 * Bulk actions on actors/forms menu
 */
function BulkActionsHeader(props) {
  const {
    objType,
    list,
    total,
    actions,
    selectedIds,
    canRemoveAll = false,
    allExistedSelected = false,
    selectAllExisted = () => {},
    handleBulkSelect,
    handleAddAccounts = () => {},
    handleRemoveSelected = () => {},
    handleRemoveAll = () => {},
    handleShare = () => {},
    handleExport = () => {},
    handleManageTags = () => {},
    handleDownloadButton = () => {},
    handleAddToFolder,
  } = props;
  const t = useIntl();
  const popupRef = useRef();
  const systemForms = useSelector((state) => state.systemForms);
  const checkPermissions = useSelector(checkUserPermissions) || {};
  const showNotification = useNotifications();
  const [selectionMenu, toggleSelectionMenu] = useState(false);
  const limitExceeded = selectedIds.length > BULK_ACTIONS_LIMIT;
  const allSelected = useMemo(
    () => list.every((item) => selectedIds.includes(item.id)),
    [list, selectedIds]
  );
  const someSelected = useMemo(
    () => !allSelected && list.some((item) => selectedIds.includes(item.id)),
    [list, selectedIds, allSelected]
  );
  const activeSelection = allSelected || someSelected;
  const showMoreActions = useMemo(
    () => ACTIONS_NEED_SHOW_MORE.some((item) => actions.includes(item)),
    [actions]
  );
  const [menu, toggleMenu] = useState(false);

  const handleClickOutsideMenu = (e) => {
    if (popupRef.current && popupRef.current.contains(e.target)) return;
    toggleMenu(false);
  };

  const notifyLimitError = () =>
    showNotification.error(
      t(mes.bulkLimitExceeded, {
        limit: BULK_ACTIONS_LIMIT,
      })
    );

  useEffect(() => {
    if (limitExceeded) notifyLimitError();
    // if some actors were selected/unselected manually, reset bulk selection checkbox
    if (allExistedSelected && !allSelected) selectAllExisted(false);
  }, [selectedIds.length]);

  /**
   * Модальное окно подтверждения удаления
   */
  const handleRemoveItems = () => {
    if (allExistedSelected) {
      handleRemoveAll();
    } else {
      handleRemoveSelected();
    }
  };

  const handleMenuClick = ({ value }) => {
    if (value !== BULK_ACTORS_ACTIONS.export && limitExceeded) {
      notifyLimitError();
      return;
    }
    switch (value) {
      case BULK_ACTORS_ACTIONS.export:
        handleExport();
        break;
      case BULK_ACTORS_ACTIONS.account:
        handleAddAccounts();
        break;
      case BULK_ACTORS_ACTIONS.remove:
        handleRemoveItems();
        break;
      case BULK_ACTORS_ACTIONS.access:
        handleShare();
        break;
      case BULK_ACTORS_ACTIONS.tags:
        handleManageTags();
        break;
      case BULK_ACTORS_ACTIONS.download:
        handleDownloadButton();
        break;
      default:
        break;
    }
    // MenuItem wrapped to Popover caused specific value
    if (typeof value === 'string') toggleMenu(false);
  };

  const renderActionsMenu = () => (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu
        size="small"
        width={240}
        onClick={handleMenuClick}
        onClose={handleClickOutsideMenu}
      >
        <MenuItem
          size="small"
          label={t(mes.addToExport)}
          value="export"
          visibility={
            actions.includes(BULK_ACTORS_ACTIONS.export) &&
            !allExistedSelected &&
            checkPermissions([PERMISSIONS.IMPORT_EXPORT_MANAGEMENT])
              ? 'visible'
              : 'hidden'
          }
        />
        <MenuItem
          size="small"
          label={t(mes.openAccount)}
          value="account"
          visibility={
            actions.includes(BULK_ACTORS_ACTIONS.account) && !allExistedSelected
              ? 'visible'
              : 'hidden'
          }
        />
        <div onClick={(e) => e.stopPropagation()}>
          <Popover
            topLevel
            anchors={{
              binding: Popover.ANCHOR.right_top,
              content: Popover.ANCHOR.left_top,
            }}
            content={({ onClose }) => (
              <Card forwardRef={popupRef}>
                <SelectActors
                  multiselect={false}
                  autoFocus
                  value={{}}
                  formId={systemForms.state.id}
                  formData={{
                    polygon: JSON.stringify(AppUtils.makeDefaultPolygon()),
                  }}
                  bordered
                  wrap
                  onChange={({ value }) => {
                    handleAddToFolder(value.id, selectedIds);
                    toggleMenu(false);
                  }}
                  onClose={onClose}
                />
              </Card>
            )}
          >
            <MenuItem
              size="small"
              label={t(mes.addToFolder)}
              value="folder"
              visibility={actions.includes('folder') ? 'visible' : 'hidden'}
            />
          </Popover>
        </div>
        <MenuItem
          size="small"
          label={t(mes.remove)}
          value="remove"
          visibility={
            actions.includes(BULK_ACTORS_ACTIONS.remove) ? 'visible' : 'hidden'
          }
        />
      </Menu>
    </div>
  );

  const handleSelectionMenuClick = ({ value }) => {
    switch (value) {
      case 'deselect':
        handleBulkSelect([]);
        selectAllExisted(false);
        break;
      case 'select_page':
        handleBulkSelect(BULK_SELECT_ACTIONS.add);
        selectAllExisted(false);
        break;
      case 'select_all':
        handleBulkSelect(BULK_SELECT_ACTIONS.add);
        selectAllExisted(true);
        break;
      default:
        break;
    }
  };

  const title = props.title || <Label value={t(mes[`${objType}Name`])} />;
  return (
    <Stack.H size="small" alignItems="center">
      <Stack.H
        size="none"
        alignItems="center"
        className={cn(scss.selectionBox, {
          [scss.active]: Boolean(selectionMenu),
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Space size="xxsmall">
          <Checkbox
            className={scss.checkbox}
            value={allSelected}
            onChange={() =>
              handleBulkSelect(
                activeSelection
                  ? BULK_SELECT_ACTIONS.remove
                  : BULK_SELECT_ACTIONS.add
              )
            }
          />
        </Space>
        {someSelected ? <div className={scss.someSelection} /> : null}
        <Popover
          toggleOnRepeatedClick
          topLevel
          anchors={{
            binding: Popover.ANCHOR.right_top,
            content: Popover.ANCHOR.left_top,
          }}
          content={({ onClose }) => (
            <Menu
              size="small"
              width={240}
              onClick={handleSelectionMenuClick}
              onClose={onClose}
            >
              <MenuItem
                size="small"
                label={t(mes.deselectAll)}
                value="deselect"
                visibility={selectedIds.length ? 'visible' : 'hidden'}
              />
              <MenuItem
                size="small"
                label={t(mes.selectAllOnPage, { count: list.length })}
                value="select_page"
              />
              <MenuItem
                size="small"
                label={t(mes.selectAllActors, { count: total })}
                value="select_all"
                visibility={
                  checkPermissions([PERMISSIONS.ACTORS_MANAGEMENT]) &&
                  canRemoveAll
                    ? 'visible'
                    : 'hidden'
                }
              />
            </Menu>
          )}
        >
          {({ onToggle }) => (
            <Button
              type="quinary"
              size="xxsmall"
              icon="dropdown"
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
                toggleSelectionMenu(!selectionMenu);
              }}
              visibility={canRemoveAll ? 'visible' : 'hidden'}
            />
          )}
        </Popover>
      </Stack.H>
      {cr(
        [
          selectedIds.length,
          <Stack.H size="xsmall" alignItems="center">
            <Label
              fontWeight="semibold"
              color={Label.COLOR.black}
              value={
                allExistedSelected
                  ? t(mes.allActorsSelected, { count: total })
                  : t(mes.itemsSelected, { count: selectedIds.length })
              }
            />
            {actions.includes(BULK_ACTORS_ACTIONS.access) &&
            !allExistedSelected &&
            checkPermissions([PERMISSIONS.USERS_READONLY]) ? (
              <Tooltip value={t(mes.shareWith)}>
                <Button
                  type="quinary"
                  size="xsmall"
                  icon={<Icon type="share" />}
                  onClick={() =>
                    handleMenuClick({ value: BULK_ACTORS_ACTIONS.access })
                  }
                />
              </Tooltip>
            ) : null}
            {actions.includes(BULK_ACTORS_ACTIONS.download) ? (
              <Tooltip value={t(mes.download)}>
                <Button
                  type="quinary"
                  size="xsmall"
                  icon={<Icon type="download" />}
                  onClick={() =>
                    handleMenuClick({ value: BULK_ACTORS_ACTIONS.download })
                  }
                />
              </Tooltip>
            ) : null}
            {actions.includes(BULK_ACTORS_ACTIONS.tags) &&
            !allExistedSelected ? (
              <Tooltip value={t(mes.manageTags)}>
                <Button
                  type="quinary"
                  icon="hash"
                  onClick={() =>
                    handleMenuClick({ value: BULK_ACTORS_ACTIONS.tags })
                  }
                />
              </Tooltip>
            ) : null}
            <Popover
              opened={menu}
              anchors={{
                binding: Popover.ANCHOR.right_top,
                content: Popover.ANCHOR.left_top,
              }}
              content={() => renderActionsMenu()}
            >
              <Button
                type="quinary"
                size="xsmall"
                icon={<Icon type="more" />}
                onClick={() => toggleMenu(!menu)}
                visibility={showMoreActions ? 'visible' : 'hidden'}
              />
            </Popover>
          </Stack.H>,
        ],
        [true, title]
      )}
    </Stack.H>
  );
}

BulkActionsHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  objType: PropTypes.oneOf([
    'actor',
    'form',
    'account',
    'currency',
    'attachment',
  ]),
  actions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(BULK_ACTORS_ACTIONS))
  ).isRequired,
  selectedIds: PropTypes.array,
  list: PropTypes.array,
  total: PropTypes.number,
  canRemoveAll: PropTypes.bool,
  allExistedSelected: PropTypes.bool,
  selectAllExisted: PropTypes.func,
  handleBulkSelect: PropTypes.func.isRequired,
  handleShare: PropTypes.func,
  handleExport: PropTypes.func,
  handleDownloadButton: PropTypes.func,
  handleAddAccounts: PropTypes.func,
  handleRemoveSelected: PropTypes.func,
  handleRemoveAll: PropTypes.func,
};

export default BulkActionsHeader;
