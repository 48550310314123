import { URL_REGEXP } from '@control-front-end/common/constants/regExp';

export const ID = {
  id: 'id',
  class: 'edit',
  title: 'ID',
  required: true,
};

export const EDIT_TYPE = {
  id: 'type',
  class: 'select',
  title: 'Type',
  value: 'text',
  options: [
    { value: 'text', title: 'Text' },
    { value: 'password', title: 'Password' },
    { value: 'email', title: 'Email' },
    { value: 'phone', title: 'Phone' },
    { value: 'int', title: 'Integer' },
    { value: 'float', title: 'Float' },
  ],
};

export const TITLE = {
  id: 'title',
  class: 'edit',
  title: 'Title',
  required: true,
};

export const VISIBILITY = {
  id: 'visibility',
  class: 'select',
  title: 'Visibility',
  value: 'visible',
  options: [
    { value: 'visible', title: 'Visible' },
    { value: 'disabled', title: 'Disabled' },
  ],
};

export const REQUIRED = {
  id: 'required',
  class: 'select',
  title: 'Required',
  value: 'false',
  options: [
    { value: 'true', title: 'Yes' },
    { value: 'false', title: 'No' },
  ],
};

export const REGEXP = {
  id: 'regexp',
  title: 'Regular expression',
  class: 'edit',
};

export const ERROR_MSG = {
  id: 'errorMsg',
  title: 'Error message',
  class: 'edit',
};

export const VALUE = {
  id: 'value',
  title: 'Default value',
  class: 'edit',
};

export const OPTIONS = {
  id: 'options',
  class: 'multi',
};

export const ALIGN = {
  id: 'align',
  class: 'select',
  title: 'Align',
  value: 'left',
  options: [
    { value: 'left', title: 'Left' },
    { value: 'right', title: 'Right' },
    { value: 'center', title: 'Center' },
  ],
};

export const AUTOCOMPLETE = {
  id: 'autocomplete',
  class: 'select',
  title: 'Autocomplete search',
  value: 'false',
  options: [
    { value: 'true', title: 'Yes' },
    { value: 'false', title: 'No' },
  ],
};

// ================================================================= //

const edit = {
  main: [ID, TITLE, EDIT_TYPE, VISIBILITY, REQUIRED, REGEXP, ERROR_MSG, VALUE],
  extra: [
    {
      id: 'extra',
      class: 'multi',
      extraFields: [
        { id: 'multiline', title: 'Multiline text', class: 'check' },
        { id: 'rows', title: 'Number of rows', class: 'edit', type: 'int' },
        { id: 'jsonEditor', title: 'JSON-like text', class: 'check' },
      ],
    },
  ],
};

const select = {
  main: [
    ID,
    TITLE,
    VISIBILITY,
    REQUIRED,
    ERROR_MSG,
    { ...VALUE, class: 'select', options: 'DYNAMIC' },
    AUTOCOMPLETE,
  ],
  options: [OPTIONS],
};

const multiSelect = {
  main: [
    ID,
    TITLE,
    VISIBILITY,
    REQUIRED,
    ERROR_MSG,
    {
      id: 'value',
      title: 'Default value',
      class: 'multiSelect',
      options: 'DYNAMIC',
    },
  ],
  options: [OPTIONS],
};

const radio = {
  main: [
    ID,
    TITLE,
    VISIBILITY,
    {
      id: 'align',
      class: 'select',
      title: 'Align',
      value: 'vertical',
      options: [
        { value: 'vertical', title: 'Vertical' },
        { value: 'horizontal', title: 'Horizontal' },
      ],
    },
    REQUIRED,
    ERROR_MSG,
    { ...VALUE, class: 'select', options: 'DYNAMIC' },
  ],
  options: [OPTIONS],
};

const check = {
  main: [
    ID,
    TITLE,
    VISIBILITY,
    REQUIRED,
    ERROR_MSG,
    {
      id: 'value',
      class: 'select',
      title: 'Default value',
      options: [
        { value: 'true', title: 'Yes' },
        { value: 'false', title: 'No' },
      ],
    },
  ],
};

const link = {
  main: [ID, TITLE, VISIBILITY, ALIGN, { ...VALUE, regexp: URL_REGEXP }],
};

const label = {
  main: [ID, VISIBILITY, ALIGN, VALUE],
};

const button = {
  main: [
    ID,
    TITLE,
    {
      id: 'type',
      class: 'select',
      title: 'Type',
      value: 'default',
      options: [
        { value: 'default', title: 'Default' },
        { value: 'text', title: 'Text' },
        { value: 'secondary', title: 'Secondary' },
        { value: 'tertiary', title: 'Tertiary' },
        { value: 'quaternary', title: 'Quaternary' },
        { value: 'error', title: 'Error' },
      ],
    },
    VISIBILITY,
  ],
};

const image = {
  main: [ID, TITLE, VISIBILITY, ALIGN, { ...VALUE, regexp: URL_REGEXP }],
  extra: [
    {
      id: 'extra',
      class: 'multi',
      extraFields: [
        { id: 'width', title: 'Maximum width', class: 'edit', type: 'int' },
        { id: 'height', title: 'Maximum height', class: 'edit', type: 'int' },
      ],
    },
  ],
};

const upload = {
  main: [ID, TITLE, VISIBILITY, REQUIRED, ERROR_MSG],
  extra: [
    {
      id: 'extra',
      class: 'multi',
      extraFields: [
        {
          id: 'extraText',
          title: 'Text in upload area',
          class: 'edit',
          type: 'string',
        },
        { id: 'accept', title: 'Accept types', class: 'edit', type: 'string' },
        {
          id: 'multiple',
          title: 'Upload multiple files',
          class: 'check',
        },
      ],
    },
  ],
};

const calendar = {
  main: [
    ID,
    TITLE,
    VISIBILITY,
    REQUIRED,
    ERROR_MSG,
    {
      id: 'value',
      title: 'Default value',
      class: 'calendar',
    },
  ],
  extra: [
    {
      id: 'extra',
      class: 'multi',
      extraFields: [
        { id: 'minDate', title: 'Minimum date', class: 'calendar' },
        { id: 'maxDate', title: 'Maximum date', class: 'calendar' },
        { id: 'time', title: 'Enable time selection', class: 'check' },
        { id: 'timeZone', title: 'Enable time zone selection', class: 'check' },
        {
          id: 'dateRange',
          title: 'Enable date range selection',
          class: 'check',
        },
        { id: 'static', title: 'Static date', class: 'check' },
      ],
    },
  ],
};

export default {
  edit,
  select,
  multiSelect,
  radio,
  check,
  link,
  label,
  button,
  image,
  calendar,
  upload,
};
