import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagram(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.scale(0.6, 0.6);
  ctx.translate(-2, -1);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.translate(0, 40);
  ctx.scale(0.1, -0.1);
  ctx.save();
  ctx.fillStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.beginPath();
  ctx.moveTo(65, 330);
  ctx.lineTo(16, 280);
  ctx.lineTo(58, 237);
  ctx.bezierCurveTo(86, 209, 100, 185, 100, 168);
  ctx.bezierCurveTo(100, 144, 96, 141, 63, 138);
  ctx.lineTo(25, 135);
  ctx.lineTo(25, 70);
  ctx.lineTo(25, 5);
  ctx.lineTo(120, 5);
  ctx.lineTo(215, 5);
  ctx.lineTo(215, 70);
  ctx.lineTo(215, 135);
  ctx.lineTo(178, 138);
  ctx.bezierCurveTo(144, 141, 140, 144, 140, 169);
  ctx.bezierCurveTo(140, 187, 151, 207, 173, 228);
  ctx.bezierCurveTo(202, 256, 212, 260, 263, 260);
  ctx.lineTo(320, 260);
  ctx.lineTo(320, 210);
  ctx.bezierCurveTo(320, 169, 315, 155, 295, 137);
  ctx.bezierCurveTo(259, 104, 254, 68, 282, 37);
  ctx.bezierCurveTo(316, -1, 351, -7, 383, 22);
  ctx.bezierCurveTo(425, 59, 428, 94, 391, 131);
  ctx.bezierCurveTo(362, 159, 360, 167, 360, 231);
  ctx.lineTo(360, 300);
  ctx.lineTo(282, 300);
  ctx.bezierCurveTo(204, 300, 204, 300, 165, 340);
  ctx.bezierCurveTo(144, 362, 123, 380, 120, 380);
  ctx.bezierCurveTo(117, 380, 92, 358, 65, 330);
  ctx.closePath();
  ctx.moveTo(150, 305);
  ctx.lineTo(174, 279);
  ctx.lineTo(147, 252);
  ctx.lineTo(120, 225);
  ctx.lineTo(93, 252);
  ctx.lineTo(66, 279);
  ctx.lineTo(90, 305);
  ctx.bezierCurveTo(103, 319, 116, 330, 120, 330);
  ctx.bezierCurveTo(124, 330, 137, 319, 150, 305);
  ctx.closePath();
  ctx.moveTo(370, 105);
  ctx.bezierCurveTo(385, 87, 380, 60, 358, 46);
  ctx.bezierCurveTo(323, 24, 284, 73, 310, 105);
  ctx.bezierCurveTo(326, 124, 354, 124, 370, 105);
  ctx.closePath();
  ctx.moveTo(180, 70);
  ctx.lineTo(180, 40);
  ctx.lineTo(120, 40);
  ctx.lineTo(60, 40);
  ctx.lineTo(60, 70);
  ctx.lineTo(60, 100);
  ctx.lineTo(120, 100);
  ctx.lineTo(180, 100);
  ctx.lineTo(180, 70);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagram;
