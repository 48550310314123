import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import {
  Button,
  Divider,
  Label,
  MenuItem,
  Modal,
  ModalButtons,
  ModalContent,
  Radio,
  RadioItem,
  Select,
  Utils,
  Space,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import {
  CREATE_ACTOR,
  UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import { TIMEFRAME_NAME } from '@control-front-end/common/constants';
import { TRANSACTION_TYPES } from '@control-front-end/common/constants/actorAccounts';
import { GET_TRANSACTIONS_FILTER } from '@control-front-end/common/constants/transactionsFilters';
import Toggle from '@control-front-end/common/components/Toggle';
import SelectActors from '@control-front-end/common/components/SelectActors';
import SelectUsers from '@control-front-end/common/components/SelectUsers';
import ErrorBanner from '@control-front-end/common/components/ErrorBanner/ErrorBanner';
import { ActorGeneralFields, ActorModalIdChip } from 'components';
import DateRange from './components/DateRange';
import AmountValue from './components/AmountValue';
import AccountsFilter from './components/AccountsFilter';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateTransactionsFilter.scss'; // NOSONAR

function CreateTransactionsFilter(props) {
  const { data, visibility, onClose, callback } = props;
  const { objType, actorId, formId, onSaveActor } = data;
  const t = useIntl();
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms);
  const filtersFormId =
    formId ||
    (objType === 'transaction'
      ? systemForms.transactionfilters.id
      : systemForms.transferfilters.id);
  const formView = useSelector((state) => state.formView);
  const [errors, setErrors] = useState({});
  const [deletedActor, setDeletedActor] = useState(false);
  const [transactionFilter, setTransactionsFilter] = useState({});
  const [filter, setFilters] = useState(null);
  const formColor = formView.form ? formView.form.color : null;
  const action = actorId ? 'update' : 'create';
  const transactionTypes = Object.keys(TRANSACTION_TYPES).map(
    (key) => TRANSACTION_TYPES[key]
  );

  useEffect(() => {
    if (actorId) {
      dispatch({
        type: GET_TRANSACTIONS_FILTER.REQUEST,
        payload: { actorId },
        callback: (data) => {
          setTransactionsFilter(data.actor);
          setFilters(data.filter);
        },
        errorCallback: () => setDeletedActor(true),
      });
    } else {
      setFilters({
        range: TIMEFRAME_NAME.lastMonth,
        ...AppUtils.getRangeDate(TIMEFRAME_NAME.lastMonth, true),
        accounts: [],
        accountType: 'fact',
      });
    }
  }, [actorId]);

  /**
   * Change filter title\avatar
   */
  const handleChangeActorData = (actorData) => {
    setTransactionsFilter((prevActor) => {
      return { ...prevActor, ...actorData };
    });
  };

  const isValidFilter = () => {
    if (deletedActor) return true;
    const { from, to, accounts } = filter;
    if (Object.keys(errors).length) return false;
    const accountsSelected =
      accounts.length && !!accounts[0].nameId && !!accounts[0].currencyId;
    return from && to && filter.accounts && accountsSelected;
  };

  /**
   * Create filter
   */
  const handleSaveAndSearch = () => {
    if (deletedActor) {
      onClose();
      return;
    }
    const copyFilter = { ...filter };
    if (!isValidFilter()) return;
    copyFilter.accounts = filter.accounts
      .filter((pair) => !!pair.nameId && !!pair.currencyId)
      .map(({ nameId, currencyId }) => ({
        nameId,
        currencyId,
      }));
    if (!copyFilter.amount) delete copyFilter.oper;
    if (filter.actors?.length) {
      copyFilter.actors = filter.actors.map((i) => i.id);
    }
    if (filter.owner) {
      copyFilter.ownerId = filter.owner.id || filter.owner.userId;
      delete copyFilter.owner;
    }
    if (filter.type === 'all') delete copyFilter.type;
    if (filter.range !== TIMEFRAME_NAME.custom) {
      delete copyFilter.from;
      delete copyFilter.to;
    }
    dispatch({
      type: actorId ? UPDATE_ACTOR.REQUEST : CREATE_ACTOR.REQUEST,
      payload: {
        id: actorId,
        title: transactionFilter.title,
        picture: transactionFilter.picture,
        color: transactionFilter.color,
        formId: filtersFormId,
        formData: {
          filter: JSON.stringify(copyFilter),
        },
        manageLayer: false,
      },
      callback: (actor) => {
        onClose();
        if (callback) callback(actor);
        if (onSaveActor) onSaveActor(filtersFormId, actor, actor.data);
      },
    });
  };

  /**
   * Changing filter`s parameters
   */
  const handleChangeFilterField = ({ id, value, error }) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      newFilters[id] = value;
      return newFilters;
    });
    setErrors((prevErr) => {
      const newErrors = { ...prevErr };
      if (error) {
        newErrors[id] = error;
      } else {
        delete newErrors[id];
      }
      return newErrors;
    });
  };

  /**
   * Visibility of fields
   */
  const fieldsVisibility = deletedActor ? 'disabled' : 'visible';

  return (
    <Modal
      id="createActorsFilterModal"
      styleName="m.modal l.modal"
      size="xlarge"
      onClose={onClose}
      label={t(mes[`${action}Filter`])}
      visibility={visibility}
    >
      <ActorModalIdChip actorId={actorId} formId={filtersFormId} />
      <ModalContent styleName="m.modal__content l.content">
        <div styleName="l.general">
          <ActorGeneralFields
            title={transactionFilter.title || ''}
            pictureUrl={transactionFilter.pictureUrl}
            colors={[
              { type: 'actor', color: transactionFilter.color },
              { type: 'form', color: formColor },
            ]}
            showOptional={false}
            onChange={handleChangeActorData}
          />
        </div>
        {deletedActor ? (
          <Space left top right size="large">
            <ErrorBanner
              mainText={t(mes.mainTextTransactionFilter)}
              extraText={t(mes.extraTextTransactionFilter)}
            />
          </Space>
        ) : null}
        {filter ? (
          <div styleName="l.filter">
            <SelectActors
              id="actors"
              bordered={true}
              multiselect={true}
              hideCreateBtn={true}
              label={t(mes.actors)}
              value={filter.actors || []}
              exclude={filter.actors}
              onChange={handleChangeFilterField}
              visibility={fieldsVisibility}
            />
            <Toggle isOpen={true} title={t(mes.accountOptions)}>
              <div styleName="l.filter__section">
                <div styleName="l.filter__item">
                  <Label
                    fontWeight="semibold"
                    value={t(mes.accountType)}
                    visibility={fieldsVisibility}
                  />
                  <Radio
                    id="accountType"
                    value={filter.accountType}
                    align="horizontal"
                    onChange={handleChangeFilterField}
                    visibility={fieldsVisibility}
                  >
                    <RadioItem label={t(mes.accFact)} value="fact" />
                    <RadioItem label={t(mes.accPlan)} value="plan" />
                  </Radio>
                </div>
                <Divider styleName="l.filter__divider" />
                {!deletedActor ? (
                  <AccountsFilter
                    accounts={filter.accounts}
                    onChange={(value) =>
                      handleChangeFilterField({ id: 'accounts', value })
                    }
                  />
                ) : null}
              </div>
            </Toggle>
            <Toggle isOpen={true} title={t(mes.advancedOptions)}>
              <div styleName="l.filter__section">
                <DateRange
                  {...filter}
                  errors={errors}
                  onChange={handleChangeFilterField}
                />
                <div styleName="l.filter__owner">
                  <SelectUsers
                    id="owner"
                    bordered={true}
                    multiselect={false}
                    hideCreateBtn={true}
                    fullModel={true}
                    label={t(mes.owner)}
                    placeholder={t(mes.enterOwnerName)}
                    value={filter.owner}
                    exclude={[]}
                    onChange={handleChangeFilterField}
                  />
                </div>
                <AmountValue
                  oper={filter.oper || 'eq'}
                  amount={filter.amount}
                  errors={errors}
                  onChange={handleChangeFilterField}
                />
                <div styleName="filter__row">
                  <Select
                    id="type"
                    styleName="l.filter__status"
                    label={t(mes.transactionStatus)}
                    bordered={true}
                    value={filter.type || 'all'}
                    onChange={handleChangeFilterField}
                  >
                    <MenuItem
                      key="all"
                      value="all"
                      size="small"
                      label={t(mes.all)}
                    />
                    {transactionTypes.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        size="small"
                        label={Utils.toUpperLatter(item)}
                      />
                    ))}
                  </Select>
                  <div styleName="l.filter__item">
                    <Label
                      fontWeight="semibold"
                      value={t(mes.transactionIncomeType)}
                    />
                    <Radio
                      id="incomeType"
                      value={filter.incomeType}
                      align="horizontal"
                      onChange={handleChangeFilterField}
                    >
                      <RadioItem label={t(mes.all)} value={null} />
                      <RadioItem label={t(mes.debit)} value="debit" />
                      <RadioItem label={t(mes.credit)} value="credit" />
                    </Radio>
                  </div>
                </div>
              </div>
            </Toggle>
          </div>
        ) : null}
      </ModalContent>
      <ModalButtons styleName="l.buttons">
        <Button
          label={t(mes.saveAndSearch)}
          size="smallplus"
          onClick={handleSaveAndSearch}
          visibility={filter && isValidFilter() ? 'visible' : 'disabled'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateTransactionsFilter.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateTransactionsFilter;
