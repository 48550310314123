import { useEffect } from 'react';

const useTabNavigation = (
  containerRef,
  onTabPress,
  deps = [],
  elementsArrayRefs = []
) => {
  const getNextEvent = (isUpDirection) => {
    const list = elementsArrayRefs?.current;
    if (!list?.length) return;

    const currentIndex = list.findIndex((item) => item?.isActive?.());
    const directionShiftValue = isUpDirection ? -1 : 1;

    // Calculate the next index, wrapping around the list
    const nextIndex =
      (currentIndex + directionShiftValue + list.length) % list.length;

    list[nextIndex]?.activate?.();
  };

  useEffect(() => {
    const containerEl = containerRef?.current;
    if (!containerEl) return;
    const handleKeyDownWithClosure = (e) => {
      if (['Tab'].includes(e.key)) {
        getNextEvent(e.shiftKey);
        e.stopPropagation();
        e.preventDefault();
      }
    };

    if (onTabPress?.()) {
      containerEl.addEventListener('keydown', handleKeyDownWithClosure);
    }

    return () =>
      containerEl.removeEventListener('keydown', handleKeyDownWithClosure);
  }, deps);
};
export default useTabNavigation;
