import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconDiagramTerminator(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(15, 17);
  ctx.lineTo(35, 17);
  ctx.bezierCurveTo(39.418277998646346, 17, 43, 20.58172200135365, 43, 25);
  ctx.lineTo(43, 25);
  ctx.bezierCurveTo(43, 29.41827799864635, 39.418277998646346, 33, 35, 33);
  ctx.lineTo(15, 33);
  ctx.bezierCurveTo(10.58172200135365, 33, 7, 29.41827799864635, 7, 25);
  ctx.lineTo(7, 25);
  ctx.bezierCurveTo(7, 20.58172200135365, 10.58172200135365, 17, 15, 17);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconDiagramTerminator;
