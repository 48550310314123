import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  shortName: {
    id: 'shortName_SmartFormSelection',
    defaultMessage: 'Short name',
  },
  shortNameHint: {
    id: 'shortNameHint_SmartFormSelection',
    defaultMessage:
      // eslint-disable-next-line max-len
      'A unique name for this smart form. It must only contain letters, numbers, dashes, underscores and must be at least 5 characters long.',
  },
});

export default { ...globalIntl, ...m };
