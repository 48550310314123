/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import safeReqExp from 'safe-regex';
import React, { PureComponent } from 'react';
import { Utils } from 'mw-style-react';
import FormUtils from '@control-front-end/utils/formUtils';
import AppUtils from '@control-front-end/utils/utils';
import { intlShape, injectIntl } from 'react-intl';
import Section from './Section';
import Row from './Row';
import {
  Button,
  Calendar,
  Check,
  Edit,
  Image,
  Label,
  Link,
  Radio,
  Select,
  MultiSelect,
  Upload,
} from './Items';
import mes from '../intl';

const _ITEMS_ = {
  Row,
  Button,
  Calendar,
  Check,
  Edit,
  Image,
  Label,
  Link,
  Radio,
  Select,
  MultiSelect,
  Upload,
};

class FormTab extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getErrorMsg = this.getErrorMsg.bind(this);
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  // коллбэк для сохранения значения item
  handleChange(item) {
    const { form: formSource, onChange, onError } = this.props;
    item = Array.isArray(item) ? item : [item];
    // Создаем новый объект формы
    const form = { ...formSource };
    // Поиск изменившегося item, установка нового значения
    form.sections.forEach((sec) => {
      const allItems = FormUtils.getSectionItems(sec);
      item.forEach((it) => {
        const findItem = allItems.find((i) => i.id === it.id);
        if (findItem) {
          for (const prop in it) {
            // eslint-disable-line no-restricted-syntax
            findItem[prop] = it[prop];
          }
          if (onError && !findItem.error) {
            onError({ form, errorToRemove: findItem });
          }
        }
      });
    });
    if (onChange) onChange(form);
  }

  // Проверка на пустое поле
  _isEmptyValue(value) {
    return (
      (typeof value === 'object' && !Object.keys(value).length) ||
      !value ||
      !value.length
    );
  }

  // Отображение ошибки, выбор необходимой ошибки
  getErrorMsg({ error, value, required, errorMsg }) {
    if (error && required && this._isEmptyValue(value)) {
      return this.i(mes.requiredField);
    }
    if (error) {
      return errorMsg || 'Incorrect data';
    }
    return '';
  }

  // Поиск элементов с ошибками
  _checkErrors(form) {
    const { onError } = this.props;
    const noValueClass = FormUtils.getNoValueClass();
    const errors = [];
    const formItems = FormUtils.getFormItems(form);
    for (const item of formItems) {
      const {
        id,
        title,
        class: className,
        value,
        required,
        regexp,
        options = [],
      } = item;
      if (noValueClass.includes(className)) continue;
      if (!safeReqExp(regexp)) {
        item.error = true;
        continue;
      }
      const isEmpty = FormUtils.isEmptyValue(className, value, options);
      const isInvalidDate =
        className === 'calendar' && FormUtils.checkDateItemError(item);
      const isMismatchRegex = FormUtils.isMismatchRegex(value, regexp);
      if ((isEmpty && required) || isMismatchRegex || isInvalidDate) {
        item.error = true;
        errors.push({ id, title });
      } else if (!isEmpty && item.error) {
        errors.push({ id, title });
      } else {
        item.error = false;
      }
    }
    if (onError) onError({ form, errors });
    return errors.length > 0;
  }

  // Отправить форму
  handleSubmit(buttonId) {
    const { form, onSubmit } = this.props;
    if (this._checkErrors(form)) return false;
    const data = FormUtils.getFormData(form);
    if (onSubmit) onSubmit({ buttonId, data });
    return data;
  }

  // Значение поля Item (если некорректный тип данных - актуализируется)
  getItemValue({ value, class: itemClass, options, items: rowItems }) {
    switch (itemClass) {
      case 'select':
        if (Array.isArray(value)) {
          return value;
        }
        if (Array.isArray(options)) {
          return options.filter((i) => i.value === value);
        }
        return [];
      case 'check':
        return typeof value === 'boolean' ? value : AppUtils.toBool(value);
      case 'calendar':
        return typeof value === 'object' ? value : {};
      case 'upload':
      case 'multiSelect':
        return Array.isArray(value) ? value : [];
      case 'edit':
      case 'radio':
      case 'image':
      case 'link':
        return ['string', 'number'].includes(typeof value) ? value : '';
      case 'row':
        if (Array.isArray(rowItems)) {
          for (const rowItem of rowItems) {
            rowItem.value = this.getItemValue(rowItem);
          }
        }
        return value;
      default:
        return value;
    }
  }

  // Отрисовать Items секции
  renderItems(items) {
    const { formId, form, isPreview, onFormItemLoading } = this.props;
    const rItems = [];
    let index = 0;
    for (const item of items) {
      if (!item.class) continue;
      const ItemName = Utils.toUpperLatter(item.class);
      const Item = _ITEMS_[ItemName];
      if (!Item) continue;
      item.value = this.getItemValue(item);
      if (item.extra && typeof item.extra === 'string') {
        item.extra = {};
      }
      // if non-manual optionsSource has no key, it's a newly added item
      // in form preview we shouldn't get its options because it will cause error 400
      if (item?.extra?.optionsSource?.type !== 'manual' && !item.key) {
        item.newSource = false;
      }
      rItems.push(
        <Item
          key={`${item.id}_${index}`}
          formId={formId}
          onFormItemLoading={onFormItemLoading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          getErrorMsg={this.getErrorMsg}
          getFormData={() => FormUtils.getFormData(form)}
          isPreview={isPreview}
          {...item}
          title={item.required ? `${item.title} *` : item.title}
        />
      );
      index += 1;
    }
    return rItems;
  }

  // Групировка элементов формы в строку
  groupRowItems(items) {
    const newItems = [];
    const addedRows = [];
    items.forEach((item) => {
      const rowId = item.row;
      if (!rowId) {
        newItems.push(item);
      } else if (!addedRows.includes(rowId)) {
        const rowItems = items.filter((i) => i.row === rowId);
        newItems.push({
          class: 'row',
          id: `row_${rowId}`,
          items: rowItems,
          elm: _ITEMS_,
        });
        addedRows.push(rowId);
      }
    });
    return newItems;
  }

  // Отрисовать секции
  renderSections(form, activeSection, wrap) {
    const { formId, openAllSections } = this.props;
    if (!form.sections) return null;
    return form.sections.map((section, i) => {
      const sectionItems = this.groupRowItems(section.content || []);
      const hideSectionContent =
        sectionItems.every((item) => item.visibility === 'hidden') ||
        sectionItems.length === 0;
      const hidden = section.visibility === 'hidden' || !sectionItems.length;
      const active = activeSection === i;
      const error = section.content.some((j) => j.error);
      return (
        <Section
          key={i}
          sectionId={`${formId}_${i}`}
          title={section.title || `Section ${i + 1}`}
          contentItems={this.renderItems(sectionItems)}
          hideSectionContent={hideSectionContent}
          hidden={hidden}
          error={error}
          toggleWrap={wrap}
          openSomeSections={active}
          openAllSections={openAllSections}
        />
      );
    });
  }

  render() {
    const { form, visibility, activeSection, wrapSections } = this.props;
    return (
      <div
        style={{
          display: visibility ? 'flex' : 'none',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {this.renderSections(form, activeSection, wrapSections)}
      </div>
    );
  }
}

FormTab.defaultProps = {
  visibility: true,
  wrapSections: false,
  onFormItemLoading: () => {},
};

FormTab.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  form: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPreview: PropTypes.bool,
  visibility: PropTypes.bool,
  activeSection: PropTypes.number,
  wrapSections: PropTypes.bool,
  openAllSections: PropTypes.bool,
  onFormItemLoading: PropTypes.func,
};

FormTab.contextTypes = { intl: intlShape };

export default injectIntl(FormTab, { withRef: true });
