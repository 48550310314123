import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconTraceEdge(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = theme.fullPalette.gray;
  ctx.setLineDash([1.52, 1.52]);
  ctx.beginPath();
  ctx.moveTo(42.5, 7.53613);
  ctx.bezierCurveTo(23.8334, 28.4512, 10.4999, 17.5679, 10.5, 42.9647);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconTraceEdge;
