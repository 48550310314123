import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconLinks(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.scale(1.5, 1.5);
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(16, 3);
  ctx.bezierCurveTo(16, 4.65685, 14.6569, 6, 13, 6);
  ctx.bezierCurveTo(12.5369, 6, 12.0982, 5.89505, 11.7066, 5.70763);
  ctx.lineTo(5.70763, 11.7066);
  ctx.bezierCurveTo(5.89505, 12.0982, 6, 12.5369, 6, 13);
  ctx.bezierCurveTo(6, 14.6569, 4.65685, 16, 3, 16);
  ctx.bezierCurveTo(1.34315, 16, 0, 14.6569, 0, 13);
  ctx.bezierCurveTo(0, 11.3431, 1.34315, 10, 3, 10);
  ctx.bezierCurveTo(3.46315, 10, 3.90178, 10.105, 4.29342, 10.2924);
  ctx.lineTo(10.2924, 4.29342);
  ctx.bezierCurveTo(10.105, 3.90178, 10, 3.46315, 10, 3);
  ctx.bezierCurveTo(10, 1.34315, 11.3431, 0, 13, 0);
  ctx.bezierCurveTo(14.6569, 0, 16, 1.34315, 16, 3);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconLinks;
