import { defineMessages } from 'react-intl';

const m = defineMessages({
  meetCalling: {
    id: 'meetCalling',
    defaultMessage: 'is calling you...',
  },
});

export default m;
