import { createReducer } from '@reduxjs/toolkit';
import update from 'lodash/update';
import set from 'lodash/set';

import {
  NEW_TRANSFER,
  TRANSFER_SIDE,
} from '@control-front-end/common/constants/actorAccounts';

// ------------------------------------
// Reducers for work on creation new numeric transfers
// ------------------------------------
const emptyItem = {
  actor: null,
  account: null,
  value: 0,
};

const TRANSFER_SIDE_MAP = {
  [TRANSFER_SIDE.TARGET]: 'to',
  [TRANSFER_SIDE.SOURCE]: 'from',
};

const initState = {
  [TRANSFER_SIDE_MAP[TRANSFER_SIDE.TARGET]]: [emptyItem],
  [TRANSFER_SIDE_MAP[TRANSFER_SIDE.SOURCE]]: [emptyItem],
};

export default createReducer(initState, {
  [NEW_TRANSFER.NUMERIC.SET](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [NEW_TRANSFER.NUMERIC.SOURCE.SET_ACTOR](state, action) {
    const { index, value } = action.payload;
    update(state, `from.[${index}]`, (item) => ({
      ...item,
      account: emptyItem.account,
      actor: value,
    }));
  },
  [NEW_TRANSFER.NUMERIC.SOURCE.SET_ACCOUNT](state, action) {
    update(state, `from.[${action.payload.index}]`, (item) => ({
      ...item,
      account: action.payload.value,
    }));
  },
  [NEW_TRANSFER.NUMERIC.SOURCE.SET_VALUE](state, action) {
    const { index, value } = action.payload;
    const floatValue = Number.parseFloat(value || 0);
    update(state, `from.[${index}]`, (item) => ({ ...item, value }));

    // If there is only one source with value - automaticly make equal distribution for targets
    if (
      floatValue &&
      state.from.length === 1 &&
      state.to.every(
        ({ value }) =>
          Number.parseFloat(value || 0) ===
          Number.parseFloat(state.to[0].value || 0)
      )
    ) {
      state.to.forEach((item, index) =>
        set(state, `to.[${index}]`, {
          ...item,
          value: floatValue / state.to.length,
        })
      );
    }
  },
  [NEW_TRANSFER.NUMERIC.SOURCE.ADD](state) {
    return { ...state, from: [...state.from, emptyItem] };
  },
  [NEW_TRANSFER.NUMERIC.SOURCE.REMOVE](state, action) {
    return {
      ...state,
      from: state.from.filter((i, iIndex) => iIndex !== action.payload),
    };
  },
  [NEW_TRANSFER.NUMERIC.TARGET.ADD](state) {
    return { ...state, to: [...state.to, emptyItem] };
  },
  [NEW_TRANSFER.NUMERIC.TARGET.REMOVE](state, action) {
    return {
      ...state,
      to: state.to.filter((i, iIndex) => iIndex !== action.payload),
    };
  },
  [NEW_TRANSFER.NUMERIC.TARGET.SET_ACTOR](state, action) {
    const { index, value } = action.payload;
    update(state, `to.[${index}]`, (item) => ({
      ...item,
      account: emptyItem.account,
      actor: value,
    }));
  },
  [NEW_TRANSFER.NUMERIC.TARGET.SET_ACCOUNT](state, action) {
    update(state, `to.[${action.payload.index}]`, (item) => ({
      ...item,
      account: action.payload.value,
    }));
  },
  [NEW_TRANSFER.NUMERIC.TARGET.SET_VALUE](state, action) {
    const { index, value } = action.payload;
    const floatValue = Number.parseFloat(value || 0);
    update(state, `to.[${index}]`, (item) => ({ ...item, value }));

    // If there is only one target with value - automaticly make equal distribution for sources
    if (
      floatValue &&
      state.to.length === 1 &&
      state.from.every(
        ({ value }) =>
          Number.parseFloat(value || 0) ===
          Number.parseFloat(state.from[0].value || 0)
      )
    ) {
      state.from.forEach((item, index) =>
        set(state, `from.[${index}]`, {
          ...item,
          value: floatValue / state.from.length,
        })
      );
    }
  },
  [NEW_TRANSFER.NUMERIC.UPDATE_SWAPPED](state, action) {
    const { index, updatedFromAccount, updatedToAccount } = action.payload;

    update(state, `from.[${index}]`, () => updatedToAccount);
    update(state, `to.[${index}]`, () => updatedFromAccount);
  },
  [NEW_TRANSFER.NUMERIC.COPY](state, action) {
    const { index, side } = action.payload;

    const currentSide = TRANSFER_SIDE_MAP[side];

    if (index !== undefined && state?.[currentSide]?.[index]) {
      return {
        ...state,
        [currentSide]: [
          ...state[currentSide],
          { ...state[currentSide][index] },
        ],
      };
    }
  },
  [NEW_TRANSFER.NUMERIC.CLEAR]() {
    return initState;
  },
});
