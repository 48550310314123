import React, { useCallback, useMemo } from 'react';
import { Icon, Checkbox, Select, Stack, Button, Label } from 'mw-style-react';
import { FilterAccPairPopover } from 'components';
import { useIntl } from 'hooks';
import { ViewDataPropType } from '../../propTypes';
import m from './intl';
import scss from './AccountsSelector.scss';

function AccountSelector({ onChange, onRemoveItem, value, index }) {
  const t = useIntl();

  const handleChange = useCallback(
    ({ value }) => onChange({ value, index }),
    [index, onChange]
  );

  const togglesArray = [
    { key: 'showInMeeting', label: t(m.showInMeeting), needReset: true },
    { key: 'displayCurrency', label: t(m.currency), needReset: false },
  ];

  // Convert the array into an object for quick access
  const toggles = useMemo(() => {
    return new Map(togglesArray.map(({ key, needReset }) => [key, needReset]));
  }, []);

  const handleToggle = useCallback(
    (key) => () => {
      if (!toggles.has(key)) return;

      onChange({
        value: {
          ...value,
          [key]: !value[key],
        },
        index,
        needReset: toggles.get(key),
      });
    },
    [onChange, value, index, toggles]
  );

  const handleRemove = useCallback(
    () => onRemoveItem({ index }),
    [onRemoveItem]
  );

  return (
    <Stack horizontal fullWidth alignItems="center" size={Stack.SIZE.small}>
      <div style={{ flexGrow: 1 }}>
        <FilterAccPairPopover onChange={handleChange}>
          <Select
            size="medium"
            value={`${value.accountName}, ${value.currencyName}`}
            bordered
            unspaced
            className={scss.select}
          />
        </FilterAccPairPopover>
      </div>
      {togglesArray.map(({ key, label }) => (
        <Checkbox
          key={key}
          value={value[key]}
          onChange={handleToggle(key)}
          unspaced
        >
          <Label value={label} />
        </Checkbox>
      ))}
      <Button
        type="text"
        size="xsmall"
        icon={<Icon type="trash" />}
        onClick={handleRemove}
      />
    </Stack>
  );
}

const MAX_ITEMS_AMOUNT = 3;

function AccountsSelector({ accounts, onChange }) {
  const t = useIntl();

  const handleChange = useCallback(
    ({ value, index, needReset }) => {
      const newAccounts = accounts.map((acc, i) => {
        if (i === index) {
          return {
            ...value,
            displayCurrency: Boolean(value.displayCurrency),
            showInMeeting: Boolean(value.showInMeeting),
          };
        }
        return needReset ? { ...acc, showInMeeting: false } : acc;
      });

      onChange({ value: newAccounts });
    },
    [onChange, accounts]
  );

  const handleRemove = useCallback(
    ({ index }) => {
      const newAccounts = [...accounts];
      newAccounts.splice(index, 1);
      onChange({
        value: newAccounts,
      });
    },
    [onChange, accounts]
  );

  const handleAdd = useCallback(
    ({ value }) => {
      onChange({
        value: [
          ...accounts,
          {
            ...value,
            displayCurrency: Boolean(value.displayCurrency),
            showInMeeting: Boolean(value.showInMeeting),
          },
        ],
      });
    },
    [onChange, accounts]
  );

  return (
    <Stack>
      {accounts.map((account, index) => {
        return (
          <AccountSelector
            key={index}
            index={index}
            value={account}
            onChange={handleChange}
            onRemoveItem={handleRemove}
          />
        );
      })}
      {accounts.length < MAX_ITEMS_AMOUNT ? (
        <FilterAccPairPopover onChange={handleAdd}>
          <Button size="small" icon="add" label={t(m.add)} type="text" />
        </FilterAccPairPopover>
      ) : null}
    </Stack>
  );
}

AccountsSelector.propTypes = {
  accounts: ViewDataPropType.accounts,
};

export default AccountsSelector;
