import { createReducer } from '@reduxjs/toolkit';
import {
  GET_ACTIVE_MEETINGS,
  ADD_ACTIVE_MEETING,
  REMOVE_ACTIVE_MEETING,
} from '@control-front-end/common/constants/meeting';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  list: [],
  total: 0,
};

export default createReducer(initState, {
  [GET_ACTIVE_MEETINGS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_ACTIVE_MEETING.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_ACTIVE_MEETING.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
});
