import getTranslation from '@control-front-end/utils/getTranslation';
import {
  NODES_COLORS_PALETTE,
  STATE_BACKGROUND_OPACITY,
} from '@control-front-end/common/constants/graphActors';
import { CorezoidLightTheme as theme, Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import m from './legendIntl';

const LEGEND_ICONS_MAPPING = {
  actor: null,
  graph: 'Graphs',
  state: 'State',
  stateMenu: 'State',
  script: 'Scripts',
  layer: 'Layers',
  event: 'Events',
  dashboard: 'Dashboards',
  stream: 'CommentsWidgets',
  layerPanel: 'list',
  autoAlignment: 'alignment',
  blockDiagram: 'diagram',
  account: 'note',
  actorMetric: 'actor',
  layerMetric: 'Layers',
  edgeMetric: 'links',
  blockDecision: 'blockCondition',
};

const LEGEND_BOX_BG_COLOR = '#CCC8FD';

const getLegendBox = (polygon) =>
  AppUtils.createImgByPolygon({
    polygon,
    color: LEGEND_BOX_BG_COLOR,
    opacity: STATE_BACKGROUND_OPACITY,
  });

const makeLayerNodes = ({ items, iconProp = 'icon', ...commonProps }) =>
  items.reduce((nodes, { id, position, layerSettings, ...customProps }) => {
    nodes[id] = {
      title: getTranslation(m[id]) || Utils.toUpperLatter(id),
      tooltip: getTranslation(m[`${id}Tooltip`]),
      [iconProp]: Object.keys(LEGEND_ICONS_MAPPING).includes(id)
        ? LEGEND_ICONS_MAPPING[id]
        : id,
      absolutePositioning: true,
      position,
      layerSettings,
      ...commonProps,
      ...customProps,
    };
    return nodes;
  }, {});

const NODES_STRUCTURE = {
  // ---------------- ROOT ----------------
  root_actor: {
    title: getTranslation(m.legend),
    position: { x: 0, y: 0 },
    absolutePositioning: true,
    classes: ['hide'],
    nodes: {
      // ---------------- COMPONENTS BLOCK ----------------
      components: {
        title: getTranslation(m.components),
        position: { x: -450, y: -200 },
        areaPicture: getLegendBox([
          [-675, -425],
          [-675, 25],
          [-225, 25],
          [-225, -425],
        ]),
        classes: ['stateMarkup', 'areaPicture'],
        nodes: {
          ...makeLayerNodes({
            items: [
              { id: 'graph', position: { x: -600, y: -350 } },
              {
                id: 'actor',
                position: { x: -400, y: -350 },
                color: theme.fullPalette.purple,
                customShape: 'ellipse',
              },
              { id: 'dashboard', position: { x: -600, y: -250 } },
              { id: 'state', position: { x: -400, y: -250 } },
              { id: 'script', position: { x: -600, y: -150 } },
              { id: 'event', position: { x: -400, y: -150 } },
              { id: 'layer', position: { x: -600, y: -50 } },
              { id: 'image', position: { x: -400, y: -50 } },
            ],
            iconProp: 'icon',
            customShape: 'roundrectangle',
          }),
        },
        absolutePositioning: true,
      },
      // ---------------- EDGES BLOCK ----------------
      edges: {
        title: getTranslation(m.edges),
        position: { x: 50, y: -250 },
        areaPicture: getLegendBox([
          [-175, -425],
          [-175, -75],
          [275, -75],
          [275, -425],
        ]),
        nodes: {
          ...makeLayerNodes({
            items: [
              { id: 'hierarchical', position: { x: -100, y: -350 } },
              { id: 'activeEdge', position: { x: -100, y: -250 } },
              { id: 'traceEdge', position: { x: 100, y: -250 } },
              { id: 'transactionEdge', position: { x: -100, y: -150 } },
              { id: 'edgeNumber', position: { x: 100, y: -350 } },
            ],
            iconProp: 'bgIcon',
            customShape: 'roundrectangle',
          }),
        },
      },
      // ---------------- MENU BLOCK ----------------
      menu: {
        title: getTranslation(m.menu),
        position: { x: 550, y: -150 },
        areaPicture: getLegendBox([
          [325, -425],
          [325, 125],
          [775, 125],
          [775, -425],
        ]),
        nodes: {
          ...makeLayerNodes({
            items: [
              { id: 'radar', position: { x: 400, y: -350 } },
              { id: 'valency', position: { x: 600, y: -350 } },
              { id: 'layerPanel', position: { x: 400, y: -250 } },
              { id: 'autoAlignment', position: { x: 600, y: -250 } },
              { id: 'lasso', position: { x: 400, y: -150 } },
              { id: 'settings', position: { x: 600, y: -150 } },
              { id: 'stateMenu', position: { x: 400, y: -50 } },
              { id: 'stream', position: { x: 600, y: -50 } },
              {
                id: 'blockDiagram',
                position: { x: 400, y: 50 },
                classes: ['disabled'],
              },
            ],
            iconProp: 'icon',
            customShape: 'roundrectangle',
          }),
        },
      },
      metrics: {
        title: getTranslation(m.metrics),
        position: { x: -450, y: 350 },
        areaPicture: getLegendBox([
          [-675, 75],
          [-675, 625],
          [-225, 625],
          [-225, 75],
        ]),
        nodes: {
          ...makeLayerNodes({
            items: [
              {
                id: 'layerMetric',
                position: { x: -600, y: 150 },
              },
              { id: 'account', position: { x: -400, y: 150 } },
              { id: 'edgeMetric', position: { x: -600, y: 250 } },
              { id: 'actorMetric', position: { x: -400, y: 250 } },
              { id: 'parent', position: { x: -600, y: 350 } },
              { id: 'child', position: { x: -600, y: 450 } },
              { id: 'credit', position: { x: -400, y: 350 } },
              { id: 'debit', position: { x: -400, y: 450 } },
              { id: 'coordinate', position: { x: -600, y: 550 } },
            ],
            iconProp: 'icon',
            classes: ['transparent'],
          }),
        },
      },
      cursors: {
        title: getTranslation(m.cursors),
        position: { x: 50, y: 300 },
        areaPicture: getLegendBox([
          [-175, -25],
          [-175, 625],
          [275, 625],
          [275, -25],
        ]),
        nodes: {
          ...makeLayerNodes({
            items: [
              {
                id: 'cursorArrow',
                position: { x: -100, y: 50 },
              },
              { id: 'cursorHand', position: { x: 100, y: 50 } },
              { id: 'cursorDrag', position: { x: -100, y: 150 } },
              { id: 'cursorState', position: { x: 100, y: 150 } },
              { id: 'cursorLasso', position: { x: -100, y: 250 } },
              { id: 'cursorSides', position: { x: 100, y: 250 } },
              { id: 'noAccessEdit', position: { x: -100, y: 350 } },
              { id: 'noAccessView', position: { x: 100, y: 350 } },
              { id: 'cursorArrowEdge', position: { x: -100, y: 450 } },
              { id: 'cursorResize', position: { x: 100, y: 450 } },
              { id: 'cursorText', position: { x: -100, y: 550 } },
            ],
            iconProp: 'icon',
            classes: ['transparent'],
          }),
        },
      },
      actorStatus: {
        title: getTranslation(m.actorStatus),
        position: { x: 550, y: 350 },
        areaPicture: getLegendBox([
          [325, 175],
          [325, 525],
          [775, 525],
          [775, 175],
        ]),
        nodes: {
          ...makeLayerNodes({
            items: [
              {
                id: 'addEdge',
                position: { x: 400, y: 250 },
                adornmentIcon: 'add',
                classes: ['activeFormNode'],
              },
              {
                id: 'flagActor',
                position: { x: 600, y: 250 },
                layerStarred: true,
              },
              {
                id: 'tracing', // trace node body without label
                position: { x: 600, y: 350 },
                isTrace: true,
                traceLevel: 0,
              },
              {
                id: 'actorNumber',
                position: { x: 400, y: 350 },
                actorNumber: '34',
              },
              {
                id: 'pinActor',
                position: { x: 400, y: 450 },
                layerSettings: {
                  pin: true,
                },
              },
              {
                id: 'balanceActor',
                position: { x: 600, y: 450 },
                balance: 15,
                balanceVector: 'up',
              },
            ],
            color: NODES_COLORS_PALETTE.Lips,
            customShape: 'ellipse',
          }),
        },
      },
      diagram: {
        title: getTranslation(m.blockDiagram),
        position: { x: 1050, y: -150 },
        areaPicture: getLegendBox([
          [825, -425],
          [825, 125],
          [1275, 125],
          [1275, -425],
        ]),
        nodes: {
          ...makeLayerNodes({
            items: [
              { id: 'blockTerminator', position: { x: 900, y: -350 } },
              { id: 'blockOffPage', position: { x: 1100, y: -350 } },
              { id: 'blockDecision', position: { x: 900, y: -250 } },
              { id: 'blockData', position: { x: 1100, y: -250 } },
              { id: 'blockPreparation', position: { x: 900, y: -150 } },
              { id: 'blockProcess', position: { x: 1100, y: -150 } },
              { id: 'blockPredefProcess', position: { x: 900, y: -50 } },
              { id: 'blockDoc', position: { x: 1100, y: -50 } },
              { id: 'blockDocs', position: { x: 900, y: 50 } },
            ],
            iconProp: 'bgIcon',
            customShape: 'roundrectangle',
            classes: ['disabled'],
          }),
        },
      },
      // node to display visible label for trace node
      traceActor: {
        title: getTranslation(m.traceActor),
        position: { x: 600, y: 350 },
        classes: ['transparent'],
      },
    },
  },
};

export default NODES_STRUCTURE;
