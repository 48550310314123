import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconRadar(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(12, 0);
  ctx.lineTo(12.1555, 0.00897026);
  ctx.bezierCurveTo(12.8186, 0.0859904, 13.3333, 0.649552, 13.3333, 1.33333);
  ctx.bezierCurveTo(13.3333, 2.06971, 12.7364, 2.66667, 12, 2.66667);
  ctx.bezierCurveTo(6.84534, 2.66667, 2.66667, 6.84534, 2.66667, 12);
  ctx.bezierCurveTo(2.66667, 17.1547, 6.84534, 21.3333, 12, 21.3333);
  ctx.bezierCurveTo(17.1547, 21.3333, 21.3333, 17.1547, 21.3333, 12);
  ctx.bezierCurveTo(21.3333, 9.42334, 20.2892, 7.09055, 18.601, 5.40165);
  ctx.lineTo(20.4866, 3.51604);
  ctx.bezierCurveTo(22.6574, 5.68751, 24, 8.68696, 24, 12);
  ctx.bezierCurveTo(24, 18.6274, 18.6274, 24, 12, 24);
  ctx.bezierCurveTo(5.37258, 24, 0, 18.6274, 0, 12);
  ctx.bezierCurveTo(0, 5.37258, 5.37258, 0, 12, 0);
  ctx.closePath();
  ctx.moveTo(12, 5.33333);
  ctx.lineTo(12.1555, 5.3423);
  ctx.bezierCurveTo(12.8186, 5.41932, 13.3333, 5.98289, 13.3333, 6.66667);
  ctx.bezierCurveTo(13.3333, 7.40305, 12.7364, 8, 12, 8);
  ctx.bezierCurveTo(9.79086, 8, 8, 9.79086, 8, 12);
  ctx.bezierCurveTo(8, 14.2091, 9.79086, 16, 12, 16);
  ctx.bezierCurveTo(14.2091, 16, 16, 14.2091, 16, 12);
  ctx.bezierCurveTo(16, 10.8961, 15.5528, 9.89662, 14.8297, 9.17287);
  ctx.lineTo(16.7154, 7.28726);
  ctx.bezierCurveTo(17.921, 8.49359, 18.6667, 10.1597, 18.6667, 12);
  ctx.bezierCurveTo(18.6667, 15.6819, 15.6819, 18.6667, 12, 18.6667);
  ctx.bezierCurveTo(8.3181, 18.6667, 5.33333, 15.6819, 5.33333, 12);
  ctx.bezierCurveTo(5.33333, 8.3181, 8.3181, 5.33333, 12, 5.33333);
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = theme.palette.primary;
  ctx.beginPath();
  ctx.moveTo(20.4848, 5.40167);
  ctx.bezierCurveTo(21.0055, 4.88097, 21.0055, 4.03675, 20.4848, 3.51605);
  ctx.bezierCurveTo(19.9641, 2.99535, 19.1199, 2.99535, 18.5992, 3.51605);
  ctx.lineTo(11.0567, 11.0585);
  ctx.bezierCurveTo(10.536, 11.5792, 10.536, 12.4234, 11.0567, 12.9441);
  ctx.bezierCurveTo(11.5774, 13.4648, 12.4216, 13.4648, 12.9423, 12.9441);
  ctx.lineTo(20.4848, 5.40167);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconRadar;
