import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconHierarchical(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.translate(8, 6);
  ctx.save();
  ctx.fillStyle = theme.fullPalette.gray;
  ctx.beginPath();
  ctx.moveTo(33.0, 1.0);
  ctx.lineTo(27.5, 2.6);
  ctx.lineTo(31.6, 6.6);
  ctx.lineTo(33.0, 1.0);
  ctx.closePath();
  ctx.moveTo(29.5, 3.9);
  ctx.bezierCurveTo(21.5, 11.7, 14.3, 13.7, 9.1, 16.8);
  ctx.translate(16.0044, 30.1165);
  ctx.rotate(0);
  ctx.arc(0, 0, 15.0, -2.0491, -2.6473, 1);
  ctx.rotate(0);
  ctx.translate(-16.0044, -30.1165);
  ctx.translate(27.6606, 34.0359);
  ctx.rotate(0);
  ctx.arc(0, 0, 27.2, -2.7238, -3.1954, 1);
  ctx.rotate(0);
  ctx.translate(-27.6606, -34.0359);
  ctx.lineTo(1.5, 35.5);
  ctx.bezierCurveTo(1.5, 29.9, 2.3, 26.1, 3.7, 23.5);
  ctx.translate(16.0997, 29.9997);
  ctx.rotate(0);
  ctx.arc(0, 0, 14.0, -2.6588, -2.0536, 0);
  ctx.rotate(0);
  ctx.translate(-16.0997, -29.9997);
  ctx.bezierCurveTo(14.6, 14.6, 22.0, 12.5, 30.2, 4.6);
  ctx.lineTo(29.5, 3.9);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconHierarchical;
