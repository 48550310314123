import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_FULLSCREEN } from 'constants';

const initialState = {
  isFullScreen: false,
  descriptor: null,
};

export default createReducer(initialState, {
  [TOGGLE_FULLSCREEN.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
});
