import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'hooks';
import { ProgressBar } from 'mw-style-react';
import FormUtils from '@control-front-end/utils/formUtils';
import { GET_ACTOR } from '@control-front-end/common/constants/graphActors';
import { getCachedActor } from '@control-front-end/app/src/selectors';
import mes from './intl';

const ACTOR_CACHE_TTL = 3 * 60 * 1000; // кэш актуален 3 минуты

/**
 * Чип актора для истории
 */
function ActorFieldValue(props) {
  const { accId, id } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const actorData = useSelector((state) => getCachedActor(state, props));
  const [isLoading, setLoading] = useState(false);
  const [hasAccess, setAccess] = useState(true);

  /**
   * Получить событие
   */
  const getActor = () => {
    setLoading(true);
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: { id, cache: true },
      callback: () => setLoading(false),
      errorCallback: () => {
        setLoading(false);
        setAccess(false);
      },
    });
  };

  useEffect(() => {
    const now = new Date().getTime();
    // Проверяем, есть ли свежий кэш данных актора в сторе
    const isRecentlyCachedData =
      actorData &&
      actorData.id === id &&
      now - actorData.reqTime < ACTOR_CACHE_TTL;
    if (isRecentlyCachedData) return;
    getActor();
  }, [id]);

  if (!actorData || isLoading)
    return <ProgressBar size="small" type="circle" />;

  const actorValue = hasAccess ? actorData : { id, title: t(mes.accessDenied) };
  return FormUtils.getActorChip(actorValue, accId);
}

ActorFieldValue.propTypes = {
  accId: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default ActorFieldValue;
