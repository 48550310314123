import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'hooks';
import { AttachList } from 'components';
import Toggle from '@control-front-end/common/components/Toggle';
import AppUtils from '@control-front-end/utils/utils';
import ActorScript from './components/ActorScript';
import CardActorInfo from './components/CardActorInfo';
import './Additions.scss';
import mes from './intl';

function Additions(props) {
  const {
    description,
    attachments = [],
    app = {},
    appSettings = {},
    cardActor = {},
    access,
    onChange,
    onToggle,
    uploadingFiles = [],
    onAttachmentRenamed,
  } = props;
  const t = useIntl();

  const handleOnAttachDelete = ({ fileName }) => {
    // mark file as deleted
    const newAttachments = attachments.filter(
      (attach) => attach.fileName !== fileName
    );
    onChange({ id: 'attachments', value: newAttachments });
    // remove from description
    const newDescription = AppUtils.removeImgBbCodeById(description, fileName);
    setTimeout(
      () => onChange({ id: 'description', value: newDescription }),
      100
    );
  };

  return (
    <div styleName="attaches">
      {attachments.length || uploadingFiles.length ? (
        <Toggle
          theme="grey"
          isOpen={true}
          onChange={(value) => {
            onToggle({ key: 'files', value });
          }}
          children={
            <div styleName="attaches__files">
              <AttachList
                list={attachments}
                canEdit
                onDelete={handleOnAttachDelete}
                uploadingFiles={uploadingFiles}
                onAttachmentRenamed={onAttachmentRenamed}
              />
            </div>
          }
          title={`${t(mes.attachments)} (${attachments.length})`}
        />
      ) : null}
      {app.id ? (
        <Toggle
          theme="grey"
          isOpen={true}
          onChange={(value) => {
            onToggle({ key: 'scripts', value });
          }}
          children={
            <div styleName="attaches__general">
              <ActorScript
                access={access}
                app={app}
                appSettings={appSettings}
                onChange={onChange}
              />
            </div>
          }
          title={t(mes.script)}
        />
      ) : null}
      {cardActor.id ? (
        <Toggle
          theme="grey"
          isOpen={true}
          onChange={(value) => {
            onToggle({ key: 'actors', value });
          }}
          children={
            <div styleName="attaches__general">
              <CardActorInfo cardActor={cardActor} onChange={onChange} />
            </div>
          }
          title={t(mes.actorCard)}
        />
      ) : null}
    </div>
  );
}

Additions.propTypes = {
  attachments: PropTypes.array.isRequired,
  app: PropTypes.object,
  appSettings: PropTypes.object,
  access: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  uploadingFiles: PropTypes.array,
  onAttachmentRenamed: PropTypes.func,
};

export default Additions;
