import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconStart(ctx, opacity, hexToRgba) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.miterLimit = 4;
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.save();
  ctx.fillStyle = 'rgba(0,0,0,0)';
  ctx.strokeStyle = hexToRgba(theme.palette.primary, opacity);
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(25, 39.0858);
  ctx.lineTo(11.3918, 25.4771);
  ctx.lineTo(25, 10.9621);
  ctx.lineTo(38.6082, 25.4771);
  ctx.lineTo(25, 39.0858);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
}

export default SVGIconStart;
