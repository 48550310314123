import React from 'react';
import { Badge, Chip, Label } from 'mw-style-react';
import PropTypes from 'prop-types';
import './NoAccessItems.scss';

function NoAccessItems({ list, label }) {
  return (
    <div styleName="noAccess">
      <Label value={label} fontWeight="semibold" />
      <div styleName="noAccess__list">
        {list.map((i) => (
          <Chip
            styleName="noAccess__list__item"
            key={i.id}
            label={i.title || i.name}
            size="small"
            type="rectangular"
            fontWeight="normal"
            closeIcon={false}
          >
            {i.type === 'currency' ? null : (
              <Badge bgColor={i.color || '#ffffff'} borderColor="#ffffff" />
            )}
          </Chip>
        ))}
      </div>
    </div>
  );
}

NoAccessItems.propTypes = {
  list: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default NoAccessItems;
