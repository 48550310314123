import PropTypes from 'prop-types';
import safeReqExp from 'safe-regex';
import React from 'react';
import cn from 'classnames';
import { cr, TextField } from 'mw-style-react';
import WrappedJSONEditor from '@control-front-end/common/components//WrappedJSONEditor/WrappedJSONEditor';
import scss from './Edit.scss';

/**
 * Компонент Edit
 */
function Edit(props) {
  const {
    id,
    title,
    value,
    visibility,
    type,
    extra,
    regexp,
    error,
    autoFocus,
    onChange,
    getErrorMsg,
  } = props;

  const reqExp = safeReqExp(regexp) ? regexp : undefined;

  const handleChangeJson = (newValue) => {
    onChange({ id, value: JSON.stringify(newValue) });
  };

  return cr(
    [
      extra?.jsonEditor,
      <WrappedJSONEditor
        {...props}
        onChange={handleChangeJson}
        className={scss.JSONEdit_border}
      />,
    ],
    [
      true,
      <TextField
        id={id}
        bordered={true}
        styleName={cn('edit')}
        type={type}
        label={title}
        visibility={visibility}
        value={value || ''}
        error={error}
        regexp={reqExp}
        autoFocus={autoFocus}
        autoSelect={autoFocus}
        length={extra.length}
        multiline={extra.multiline}
        multilineType="text"
        rows={extra.rows}
        helperText={getErrorMsg(props)}
        onChange={onChange}
      />,
    ]
  );
}

Edit.defaultProps = {
  extra: {},
};

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  regexp: PropTypes.string,
  autoFocus: PropTypes.bool,
  visibility: PropTypes.string,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  getErrorMsg: PropTypes.func,
};

export default Edit;
