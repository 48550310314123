import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  ProgressBar,
  MenuItem,
  Select,
  Label,
  Space,
  Stack,
  Divider,
  Popover,
  cr,
} from 'mw-style-react';

import AppUtils from '@control-front-end/utils/utils';
import { useSearchList, useIntl, useScroll } from 'hooks';
import {
  FILTER_HISTORY,
  GET_OBJECT_HISTORY,
  GET_USERS,
  MILLISECONDS_PER_SECOND,
} from 'constants';
import EmptyList from '@control-front-end/common/components/EmptyList';
import SelectUsers from '@control-front-end/common/components/SelectUsers';
import FilterRange from '@control-front-end/app/src/components/FilterRange';
import HistoryItem from './components/HistoryItem';
import scss from './History.scss';
import mes from './intl';

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};
const SAME_REQUEST_INTERVAL = 1000 * 5;
const USERS_ALL = 'all';
const DEFAULT_RANGE = AppUtils.getRangeDate('lastMonth');
const COMPLETE_MINUTE_MS = 999;
const DEFAULT_FILTER = {
  field: null,
  userId: USERS_ALL,
  userName: '',
  from: DEFAULT_RANGE.from * MILLISECONDS_PER_SECOND,
  to: DEFAULT_RANGE.to * MILLISECONDS_PER_SECOND + COMPLETE_MINUTE_MS,
};

function History(props) {
  const { containerRef, objType, objId, fields = [], access = [] } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const listRef = useRef();
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [history, setHistory] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const scrollRef = containerRef || listRef;

  /**
   * Make history changes with grouped access items
   */
  const makeChangesGrouped = useCallback((list) => {
    const grouped = [];
    let accessChanges = [];

    const addAccessChanges = () => {
      const lastIndex = accessChanges.length - 1;
      grouped.push({
        ...accessChanges[0],
        prevValue: accessChanges[lastIndex].prevValue,
        nextValue: accessChanges[0].nextValue,
      });
      accessChanges = [];
    };

    list.forEach((i, index) => {
      if (i.field !== 'access') {
        if (accessChanges.length) addAccessChanges();
        grouped.push(i);
        return;
      }
      if (!accessChanges.length) {
        accessChanges.push(i);
      } else {
        const firstValue = accessChanges[0];
        const isSameEditRequest =
          firstValue.userId === i.userId &&
          Math.abs(firstValue.createdAt - i.createdAt) < SAME_REQUEST_INTERVAL;

        if (isSameEditRequest) {
          accessChanges.push(i);
        } else {
          addAccessChanges();
          accessChanges = [i];
        }
      }
      const isEndOfList = index === list.length - 1;
      if (isEndOfList) addAccessChanges();
    });
    return grouped;
  }, []);

  const getHistory = (loadMore = false) => {
    const { limit, offset: curOffset, endList } = history;
    const { from, to, field: filterField } = filter;
    const field = filterField || props.field;
    if (isLoading || (loadMore && endList)) return;
    const offset = loadMore ? curOffset : 0;
    setLoading(true);
    dispatch({
      type:
        filter.userId === USERS_ALL
          ? GET_OBJECT_HISTORY.REQUEST
          : FILTER_HISTORY.REQUEST,
      payload:
        filter.userId === USERS_ALL
          ? {
              objType,
              objId,
              from,
              to,
              field,
              limit,
              offset: loadMore ? offset + limit : 0,
            }
          : {
              formField: field
                ? { fieldId: field.value, formId: field.formId }
                : null,
              from,
              to,
              limit,
              offset,
              actors: [objId],
              userId: filter.userId,
              loadMore,
            },
      callback: (data) => {
        const newList = filter.userId === USERS_ALL ? data : data.list;
        setLoading(false);
        const copyList = history.list.slice();
        const resList = loadMore ? copyList.concat(newList) : newList;
        setHistory({
          ...history,
          list: makeChangesGrouped(resList),
          offset: loadMore ? offset + limit : offset,
          endList: !newList.length,
        });
      },
    });
  };

  const { list: usersList, onScroll } = useSearchList({
    actionType: GET_USERS.REQUEST,
    searchParams: { query: '' },
    settings: { step: 15, scrollToLoad: 20 },
  });

  useEffect(() => {
    getHistory(false);
  }, [objId, filter]);

  useScroll({
    ref: scrollRef,
    callback: () => getHistory(true),
  });

  const accessUserIds = access.map((i) => i.userId);
  const accessedUsers = access
    .filter((user) => user.userType !== 'group')
    .map((user) => ({
      ...user,
      id: user.userId,
      value: user.name,
    }));
  const restUsers = usersList.filter((u) => !accessUserIds.includes(u.id));
  const sortedUsersList = accessedUsers.concat(restUsers);

  return (
    <>
      <Space fullWidth size="small">
        <Stack.H
          style={{ flexWrap: 'wrap' }}
          size="small"
          alignItems="center"
          fullWidth
        >
          <FilterRange
            from={filter.from}
            to={filter.to}
            fastNavigation
            onChange={(range) => setFilter((prev) => ({ ...prev, ...range }))}
          />
          <Select
            className={scss.history__select}
            bordered
            unspaced
            value={filter.field || t(mes.all)}
            onChange={({ value }) =>
              setFilter((prev) => ({ ...prev, field: value }))
            }
            visibility={fields.length ? 'visible' : 'hidden'}
          >
            <MenuItem value={null} label={t(mes.all)} />
            {fields.map((f) => (
              <MenuItem
                key={f.value}
                className={scss.history__select__item}
                value={f.value}
                label={f.title}
              >
                {cr([
                  f.formTitle,
                  <Label fontSize="small" value={f.formTitle} />,
                ])}
              </MenuItem>
            ))}
          </Select>
          <div>
            <Popover
              content={({ onClose }) => (
                <SelectUsers
                  id="owner"
                  bordered
                  wrap
                  multiselect={false}
                  autoFocus
                  hideCreateBtn
                  fullModel
                  value={filter.userId}
                  onChange={({ value }) => {
                    setFilter((prev) => ({
                      ...prev,
                      userId: value.id,
                      userName:
                        sortedUsersList.find((u) => u.id === value.id)?.value ||
                        '',
                    }));
                    onClose();
                  }}
                />
              )}
            >
              <Select
                className={scss.history__select}
                value={`By ${filter.userName || t(mes.all)}`}
                bordered
                unspaced
                exclude={accessedUsers}
                onChange={({ value }) =>
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    userId: value,
                    userName:
                      sortedUsersList.find((u) => u.id === value)?.value || '',
                  }))
                }
                onMenuScroll={onScroll}
              />
            </Popover>
          </div>
          <Button
            type="secondary"
            size="smallplus"
            fontWeight="semibold"
            label={t(mes.reset)}
            iconAfter="trash"
            onClick={() => setFilter(DEFAULT_FILTER)}
            visibility={
              filter.field || filter.userId !== USERS_ALL ? 'visible' : 'hidden'
            }
          />
        </Stack.H>
      </Space>
      <Stack.V forwardRef={listRef} size="small" fullWidth fullHeight>
        {cr(
          [isLoading && !history.list.length, <ProgressBar size="large" />],
          [!history.list.length, <EmptyList objType="history" />],
          [
            true,
            <Stack size={Stack.SIZE.xxsmall} divider={<Divider />}>
              {history.list.map((i) => {
                const field = fields.find((f) => i.field === f.value);
                return (
                  <HistoryItem key={i.id} {...i} extra={field?.extra ?? null} />
                );
              })}
              {cr([isLoading, <ProgressBar size="small" />])}
            </Stack>,
          ]
        )}
      </Stack.V>
    </>
  );
}

History.propTypes = {
  objType: PropTypes.oneOf([
    'actor',
    'formTemplate',
    'templateActors',
    'account',
  ]).isRequired,
  objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fields: PropTypes.array,
  access: PropTypes.array,
  field: PropTypes.string,
};

export default History;
